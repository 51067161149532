import {
  Accordion,
  AccordionButton,
  AccordionItem,
  AccordionPanel,
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Box,
  Input,
  Spinner,
  Stack,
  Table,
  Tbody,
  Text,
  Th,
  Thead,
  Tr,
  Wrap,
  useToast,
} from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getGroupClasses, resetGroupClassError } from '../redux/actions/groupClassActions';
import GroupClassTableItem from './GroupClassTableItem';
import AddNewGroupClass from './AddNewGroupClass';


const GroupClassesTab = () => {
  const dispatch = useDispatch();
  const { error, loading } = useSelector((state) => state.admin);
  const { groupClasses, groupClassUpdate } = useSelector((state) => state.groupClass);
  const toast = useToast();

  useEffect(() => {
      dispatch(getGroupClasses());
      dispatch(resetGroupClassError()); 
      if(groupClassUpdate){
          toast({
              description: 'Group Class is updated.',
              status: 'success',
              isClosable: true,
          });
      }
  }, [dispatch, toast, groupClassUpdate]);

return (
  <Box>
  {error && (
      <Alert status='error'>
          <AlertIcon />
          <AlertTitle>Upps!</AlertTitle>
          <AlertDescription>{error}</AlertDescription>
      </Alert>
  )}
  {loading && (
      <Wrap justify='center'>
          <Stack direction='row' spacing='4'>
              <Spinner mt='20' thickness='2px' speed='0.65s' emptyColor='gray.200' color='cyan.500' size='xl' />
          </Stack>
      </Wrap>
  )}
  
  {!error && !loading && (
      <Box>
          <Accordion allowToggle>
              <AccordionItem>
                  <h2>
                      <AccordionButton>
                          <Box flex='1' textAlign='right'>
                              <Box>
                                  <Text mr='8px' fontWeight='bold'>Add a new Group Class</Text>
                              </Box>
                          </Box>
                      </AccordionButton>
                  </h2>
                  <AccordionPanel pb='4'>
                      <Table>
                          <Tbody>
                              <AddNewGroupClass />
                          </Tbody>
                      </Table>
                  </AccordionPanel>
              </AccordionItem>
          </Accordion>
          {groupClasses && groupClasses.length > 0 &&
                      groupClasses.map((groupClass) => (
                          
          <Accordion key={groupClass._id} allowToggle>
              <AccordionItem>
                  <h2>
                      <AccordionButton>
                          <Box flex='1' textAlign='right'>
                              <Box>
                                  <Text key={groupClass._id} mr='8px' fontWeight='bold'>{groupClass.title}</Text>
                              </Box>
                          </Box>
                      </AccordionButton>
                  </h2>
                  <AccordionPanel pb='4'>
                      <Table>
                          <Tbody>
                          <GroupClassTableItem key={groupClass._id} groupClass={groupClass} />
                          </Tbody>
                      </Table>
                  </AccordionPanel>
              </AccordionItem>
          </Accordion>
          ))}
      </Box>
  )}
</Box>
);
};

export default GroupClassesTab;
