import { Box, Stack, Heading, Tabs, TabList, Tab, TabPanels, TabPanel, Text, Link } from '@chakra-ui/react';
import { Navigate, useLocation } from 'react-router-dom';
import { Link as ReactLink } from 'react-router-dom';
import { useSelector } from 'react-redux';
// import UsersTab from '../components/UsersTab';
/* import OrdersTab from '../components/OrdersTab';
import ReviewsTab from '../components/ReviewsTab';
import ProductsTab from '../components/ProductsTab'; */
import MentorsTab from '../components/MentorsTab';
import MentorReviewsTab from '../components/MentorReviewsTab';
import JobsTab from '../components/JobsTab';
import EventsTab from '../components/EventsTab';
import ProgramsTab from '../components/ProgramsTab';
import CertificatesTab from '../components/CertificatesTab';
import GroupClassesTab from '../components/GroupClassesTab';
import GroupClassReviewsTab from '../components/GroupClassReviewsTab';

const AdminConsoleScreen = () => {
  const { userInfo } = useSelector((state) => state.user);
  const location = useLocation();

  return userInfo && userInfo.isAdmin ? (
    <Box p='20px' minH='100vh'>
      <Stack direction={{ base: 'column', lg: 'row' }} align={{ lg: 'flex-start' }}>
        <Stack
          pr={{ base: '0', md: '14' }}
          spacing={{ base: '8', md: '10' }}
          flex='1.5'
          mb={{ base: '12', md: 'none' }}>
            <Heading fontSize='2xl' fontWeight='extrabold'>
              Admin Console
            </Heading>
            <Text><Link as={ReactLink} to={`/admin-user-issues`}> Deal with User Issues </Link></Text>
            <Text><Link as={ReactLink} to={`/admin-accounting-sum`}> Check coupon and annual discount credit balance </Link></Text>
            <Tabs size='md' variant='enclosed'>
              <TabList>
                <Tab>Mentors</Tab>
                <Tab>MentorReviews</Tab>
                <Tab>Jobs</Tab>
                <Tab>Events</Tab>
                <Tab>Programs</Tab>
                <Tab>Certificates</Tab>
                <Tab>GroupClasses</Tab>
                <Tab>GroupClassReviews</Tab>
              </TabList>
              <TabPanels>
                <TabPanel>
                  <MentorsTab />
                </TabPanel>
                <TabPanel>
                  <MentorReviewsTab />
                </TabPanel>
                <TabPanel>
                  <JobsTab />
                </TabPanel>
                <TabPanel>
                  <EventsTab />
                </TabPanel>
                <TabPanel>
                  <ProgramsTab />
                </TabPanel>
                <TabPanel>
                  <CertificatesTab />
                </TabPanel>
                <TabPanel>
                  <GroupClassesTab />
                </TabPanel>
                <TabPanel>
                  <GroupClassReviewsTab />
                </TabPanel>
              </TabPanels>
            </Tabs>
          </Stack>
      </Stack>
    </Box>
  ) : (
    <Navigate to='/' replace={true} state={{ from: location }} />
  );
};

export default AdminConsoleScreen;
