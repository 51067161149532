import {
    Accordion,
    AccordionButton,
    AccordionItem,
    AccordionPanel,
    Box,
    Text,
    useColorModeValue as mode,
    Link,
    Icon,
    Input,
    Textarea,
    Button,
    useToast,
    Center,
    VStack
} from '@chakra-ui/react';
import { FaArrowRight } from 'react-icons/fa';
/* import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux'; */
import { Link as ReactLink } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { sendCoachEmail, resetState } from '../redux/actions/userActions';
import { useDispatch, useSelector } from 'react-redux';


const ServicesScreen = () => {
    const [title, setTitle] = useState('');
    const [comment, setComment] = useState('');
    const [buttonLoading, setButtonLoading] = useState(false);
    const dispatch=useDispatch();
    const { error, serverStatus, serverMsg, userInfo } = useSelector((state) => state.user);
    const toast = useToast();

    useEffect(() => {
        setButtonLoading(false);
        if(serverStatus && serverMsg) {
            toast({
                description: `${serverMsg}`,
                status: 'success',
                isClosable: true
            });
            dispatch(resetState());
        }
    }, [error, toast, serverMsg, serverStatus, dispatch]);

/*     useEffect(() => {
        setButtonLoading(false);
        toast({
            description: 'The email is sent to coaching service department.',
            status: 'success',
            isClosable: true,
        });
    }, [dispatch, toast]);
 */


    const onSubmit = () => {
        setButtonLoading(true);
        dispatch(sendCoachEmail(title, comment));
    }

  return (
    <Box>
        <Box mt='2' mb='2' ml='4'>
            <Link fontWeight='bold' as={ReactLink} to='/mentors' color={mode('darkCerulean.900', 'yellow.200')}>
                            Mentor Service
            </Link>
            <Icon ml='2' color={mode('darkCerulean.900', 'yellow.200')} as={FaArrowRight} />
        </Box>
        {!userInfo ? (
            <Box mt='4' mb='2' ml='2'>
            <h2><Text mr='8px' fontWeight='bold' mb='4' color={mode('darkCerulean.900', 'yellow.200')}>Coach Services</Text></h2>
            <Text>Please log in to contact coach service.</Text>
            </Box>
        ) : serverStatus ? (
            <Center mb='4' >
                <VStack>
                    <Text my='10' fontSize='xl'>
                        You have successfully sent the email to coaching service department.
                    </Text>
                    <Button as={ReactLink} to='/' variant='outline' colorScheme='cyan' w='300px' _hover={{ bg: 'darkCerulean.500', color: 'white' }}>Home Page</Button>
                    <Button as={ReactLink} to='/chat/1' variant='outline' colorScheme='cyan' w='300px' _hover={{ bg: 'darkCerulean.500', color: 'white' }}>Chat with Qishi Club</Button>
                </VStack>
            </Center>
        ) : (
            <Box mt='4' mb='2'  ml='2'>
            <h2><Text mr='8px' fontWeight='bold' mb='4' color={mode('darkCerulean.900', 'yellow.200')}>Coach Services</Text></h2>
        <Input
                        onChange={(e) => {
                          setTitle(e.target.value);
                        }}
                        placeholder='Ask for coaching service'
                      />
                      <Textarea
                        onChange={(e) => {
                          setComment(e.target.value);
                        }}
                        placeholder={'I need a personal coach to help me improve quant research skill...'}
                      />
                      <Button
                        isLoading={buttonLoading}
                        loadingText='Sending'
                        w='140px'
                        colorScheme='darkCerulean'
                        onClick={() => onSubmit()}>
                          Send
                      </Button>
            </Box>
            )}
        <Accordion allowToggle>
                <AccordionItem>
                    <h2>
                        <AccordionButton _hover={{ bg: 'darkCerulean.500', color: 'white' }} color={mode('darkCerulean.900', 'yellow.200')}>
                            <Box flex='1' textAlign='left'>
                                <Box>
                                    <Text mr='8px' fontWeight='bold'>Visa Issue Lawyer Reference</Text>
                                </Box>
                            </Box>
                        </AccordionButton>
                    </h2>
                    <AccordionPanel pb='4'>
                    Le Zhang is our recommended visa issue lawyer. His email is : info@lezhanglaw.com . His website is : www.lezhanglaw.com . His telephone number is : 646-468-2245 . Please mention Qishi Club as reference when contacting him.
                    </AccordionPanel>
                </AccordionItem>
                <AccordionItem>
                    <h2>
                        <AccordionButton _hover={{ bg: 'darkCerulean.500', color: 'white' }} color={mode('darkCerulean.900', 'yellow.200')}>
                            <Box flex='1' textAlign='left'>
                                <Box>
                                    <Text mr='8px' fontWeight='bold' >Recruiter Reference</Text>
                                </Box>
                            </Box>
                        </AccordionButton>
                    </h2>
                    <AccordionPanel pb='4'>
                    Vivan Wei Fan is our recommended recruiter. She is working for Options Group recruiting company. Her email is : wfan@optionsgroup.com . Her wechat ID is: VivianFan-NewYork . Please mention Qishi Club as reference when contacting her.
                    </AccordionPanel>
                </AccordionItem> 
                <AccordionItem>
                    <h2>
                        <AccordionButton _hover={{ bg: 'darkCerulean.500', color: 'white' }} color={mode('darkCerulean.900', 'yellow.200')}>
                            <Box flex='1' textAlign='left'>
                                <Box>
                                    <Text mr='8px' fontWeight='bold' >Rental Agent Reference</Text>
                                </Box>
                            </Box>
                        </AccordionButton>
                    </h2>
                    <AccordionPanel pb='4'>
                    Shanshan Li is our recommended Real Estate Broker and Rental Agent, 
                    who is a licensed Associated Real Estate Broker at Douglas Elliman. 
                    Prior to entering the real estate industry, shw worked as a Quant Researcher at JP Morgan. 
                    Following true passion, along with her business acumen and market knowledge, 
                    she is committed to helping her clients realize their real estate goals in 
                    Great New York area. She could be contacted via email 
                    &#40; shanshan.li@elliman.com &#41; or wechat &#40; shaniavina&#41; . 
                    Please mention Qishi Club as reference when you reach out to her.
                    </AccordionPanel>
                </AccordionItem> 
            </Accordion>
    </Box>
  );
};

export default ServicesScreen;

