import axios from 'axios';
import { setError, setShippingAddress, clearOrder } from '../slices/order';
import { userUpdateSelf } from '../slices/user';

export const setAddress = (data) => (dispatch) => {
    dispatch(setShippingAddress(data));

};

export const setPayment = () => async(dispatch, getState) => {
    const {
        cart: { cartItems, subtotal, couponCode },
        user: { userInfo, referId },
    } = getState();

    const newOrder = { subtotal, cartItems, userInfo, promotion: couponCode, referId };

    try {
        const config = {headers: { Authorization: `Bearer ${userInfo.token}`, 'Content-Type': 'application/json' }};
        const { data } = await axios.post('api/checkout', newOrder, config);
        window.location.assign(data.url);
    } catch (error) {
            setError(
                error.response && error.response.data.message 
                    ? error.response.data.message 
                    : error.message 
                    ? error.message : 'Unexpected error has occured. Please try again later.'
            );
    }
};

export const parsePayment = (recordID, confirmString) => async(dispatch, getState) => {
    const {
        user: { userInfo, referId },
    } = getState();
    let couponCode = JSON.parse(localStorage.getItem('couponCode')) ?? '';
    const sendData = { userInfo, promotion: couponCode, referId };

    try {
        // need make it loading
        const config = {headers: { Authorization: `Bearer ${userInfo.token}`, 'Content-Type': 'application/json' }};
        const { data } = await axios.put(`${process.env.REACT_APP_WEBSITE_NAME}/api/records/self/${recordID}/${confirmString}`, sendData, config);
        dispatch(userUpdateSelf(data.userInfo));
        localStorage.setItem('userInfo', JSON.stringify(data.userInfo));
        window.location.assign(data.url);
        
    } catch (error) {
            setError(
                error.response && error.response.data.message 
                    ? error.response.data.message 
                    : error.message 
                    ? error.message : 'Unexpected error has occured. Please try again later.'
            );
    }
};


export const resetOrder = () => async (dispatch) => {
    dispatch(clearOrder());
};
