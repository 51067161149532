import { createSlice } from '@reduxjs/toolkit';

export const initialState = {
    loading: false,
    error: null,
    certificates: [],
    certificate: null,
    taken: false,
    certificateUpdate: false,
    takerUpdate: false,
};

export const certificatesSlice = createSlice({
    name: 'certificates',
    initialState,
    reducers: {
        setLoading: (state) => {
            state.loading = true;
        },
        setCertificates: (state, { payload }) => {
            state.loading = false;
            state.error = null;
            state.certificates = payload;
        },
        setCertificate: (state, { payload }) => {
            state.loading = false;
            state.error = null;
            state.certificate = payload;
            state.taken = false;
        },
        setError: (state, { payload }) => {
            state.loading = false;
            state.error = payload;
        },
        certificateTaken: (state, {payload}) => {
            state.loading = false;
            state.error = null;
            state.taken = payload;
        },
        resetError: (state) => {
            state.error = null;
            state.taken = false;
            state.certificateUpdate = false;
            state.takerUpdate = false;
        },
        setCertificateUpdateFlag: (state) => {
            state.certificateUpdate = true;
            state.loading = false;
        },
        setTakerUpdateFlag: (state) => {
            state.takerUpdate = true;
            state.loading = false;
        },
    },
});

export const {
    setLoading, 
    setError, 
    setCertificates, 
    setCertificate,
    certificateTaken,
    setCertificateUpdateFlag,
    resetError,
    setTakerUpdateFlag,
} = certificatesSlice.actions;

export default certificatesSlice.reducer;

export const certificateSelector = (state) => state.certificates;
