import { 
    setLoading, 
    setError, 
    setAccountings, 
    setAccounting,
    resetError,
    setAnnualFee,
    setAnnualFeeMessage,
    setCreditSetList,
    setCouponSetList,
    setStartAccDate,
    setBeginOfThisYear,
    setFirstDayOfLastMonth,
    setCreditBalanceBefore,
    setCreditBalance,
    setCouponBalanceBefore,
    setCouponBalance,
} from '../slices/accounting';
import axios from 'axios';

export const getAccountingsByUserId = (id) => async (dispatch, getState) => {
    const {
        user: { userInfo },
    } = getState();

    const config = { headers: { Authorization: `Bearer ${userInfo.token}`, 'Content-Type': 'application/json' }};

    dispatch(setLoading());
    try {
        const { data } = await axios.get(`/api/accountings/${id}`, config);
        dispatch(setAccountings(data.accountings));
        dispatch(setAnnualFee(data.membershipAnnualFee));
        dispatch(setAnnualFeeMessage(data.membershipMessage));
    } catch (error) {
        dispatch(
            setError(
                error.response && error.response.data.message 
                    ? error.response.data.message 
                    : error.message 
                    ? error.message : 'Unexpected error has occured. Please try again later.'
            )
        );
    }
};

export const getAccounting = (id) => async (dispatch, getState) => {
    const {
        user: { userInfo },
    } = getState();

    const config = { headers: { Authorization: `Bearer ${userInfo.token}`, 'Content-Type': 'application/json' }};

    dispatch(setLoading(true));
    try {
        const { data } = await axios.get(`/api/accountings/single/${id}`, config);
        dispatch(setAccounting(data));
    } catch (error) {
        dispatch(
            setError(
                error.response && error.response.data.message 
                    ? error.response.data.message 
                    : error.message 
                    ? error.message : 'Unexpected error has occured. Please try again later.'
            )
        );
    }

};

export const resetAccountingError = () => async (dispatch) => {
    dispatch(resetError());
};

export const getAccountingSummary = () => async (dispatch, getState) => {
    const {
        user: { userInfo },
    } = getState();

    const config = { headers: { Authorization: `Bearer ${userInfo.token}`, 'Content-Type': 'application/json' }};

    dispatch(setLoading(true));
    try {
        const { data } = await axios.get(`/api/accountings/sum`, config);
        dispatch(setCreditSetList(data.creditSetList));
        dispatch(setCouponSetList(data.couponSetList));
        dispatch(setStartAccDate(data.startAccDate));
        dispatch(setBeginOfThisYear(data.beginOfThisYear));
        dispatch(setFirstDayOfLastMonth(data.firstDayOfLastMonth));
        dispatch(setCreditBalanceBefore(data.creditBalanceBefore));
        dispatch(setCreditBalance(data.creditBalance));
        dispatch(setCouponBalanceBefore(data.couponBalanceBefore));
        dispatch(setCouponBalance(data.couponBalance));
    } catch (error) {
        dispatch(
            setError(
                error.response && error.response.data.message 
                    ? error.response.data.message 
                    : error.message 
                    ? error.message : 'Unexpected error has occured. Please try again later.'
            )
        );
    }

};
