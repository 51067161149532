import { Center, Text, Box, Button } from '@chakra-ui/react';
import { BsBoxSeamFill } from 'react-icons/bs';
import { Link as ReactLink } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { changeMembership } from '../redux/actions/adminActions';


const ChangeMembershipScreen = () => {

  const dispatch = useDispatch();
  const { level, id } = useParams();


  useEffect(() => {
    dispatch(changeMembership(level, id));
  }, [dispatch]);

  return (
    <Center height='100vh' flexDirection='Column'>
      <Text fontSize={{ base: 'md', md: 'xl', lg: '4xl' }}>You changed the membership level of this user {id} into level {level}.</Text>
    </Center>
  );
};

export default ChangeMembershipScreen;