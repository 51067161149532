import {
    TableContainer,
    Stack,
    Spinner,
    Alert,
    AlertIcon,
    AlertDescription,
    AlertTitle,
    Th,
    Tbody,
    Tr,
    Thead,
    Button,
    ListItem,
    UnorderedList,
    Table,
    Td,
    Wrap,
    Text,
    Divider,
    useToast,
    Link,
  } from '@chakra-ui/react';
  import { useDispatch, useSelector } from 'react-redux';
  import { CheckCircleIcon } from '@chakra-ui/icons';
  import { getAccountingsByUserId } from '../redux/actions/accountingActions';
  import { useEffect } from 'react';
  import { Link as ReactLink } from 'react-router-dom';
  import { Navigate, useLocation } from 'react-router-dom';
  import { addCartItemAnnualFee, addCartItemCoupon } from '../redux/actions/cartActions';
  
  
  const YourAccountingScreen = () => {
    const dispatch = useDispatch();
    const { userInfo } =  useSelector((state) => state.user);
    const { loading, error, accountings, annualFee, annualFeeMessage } = useSelector((state) => state.accounting);
    const { cartItems } = useSelector((state) => state.cart);
    const location = useLocation();
    const toast = useToast();

    useEffect(() => {
      if(userInfo) {
        dispatch(getAccountingsByUserId(userInfo._id));
      }
    }, [dispatch, userInfo]);

    const checkAnnualFee = () => {
        return accountings
        .filter(accounting => (accounting.category === 'AnnualFeeCharge' || accounting.category === 'AnnualFeeCredit'))
        .reduce((sum, accounting) => sum + (accounting.isCharge ? (-accounting.amount) : accounting.amount), 0);
    }

    const checkCoupon = () => {
        return accountings
        .filter(accounting => (accounting.category === 'Coupon'))
        .reduce((sum, accounting) => sum + (accounting.isCharge ? (-accounting.amount) : accounting.amount), 0);
    }

    const addItem = () => {
      let price = checkAnnualFee();
      
      if(!(cartItems.some((cartItem) => cartItem.id === 'MembershipLevel10'))) {
           dispatch(addCartItemAnnualFee('MembershipLevel10', 'Membership', -price));
       }
       toast({
         description: 'Item has been added.',
         status: 'success',
         isClosable: true,
       });
    };

    const addItemCoupon = () => {
      let price = checkCoupon();
      
      if(!(cartItems.some((cartItem) => cartItem.id === 'CouponToPay'))) {
           dispatch(addCartItemCoupon('CouponToPay', 'Coupon', -price));
       }
       toast({
         description: 'Item has been added.',
         status: 'success',
         isClosable: true,
       });
    };

  
    return userInfo ? (
      <>
        {loading ? (
          <Wrap direction='column' align='center' mt='20px' justify='center' minHeight='100vh'>
              <Stack direction='row' spacing='4'>
                <Spinner mt='20' thickness='2px' speed='0.65s' emptyColor='gray.200' color='cyan.500' size='xl' />
              </Stack>
          </Wrap>
        ) : error ? (
          <Alert status='error'>
            <AlertIcon />
            <AlertTitle>We are sorry!</AlertTitle>
            <AlertDescription><Link as={ReactLink} textDecoration='underline' to='/logout' >You might need to logout and login again to access this page. </Link></AlertDescription>
          </Alert>
        ) : (
          accountings && (
          <>
          {annualFee > 0 && (
                <Text>{annualFeeMessage}</Text>
          )}
          <Text>Your Annual Fee History</Text>
          <TableContainer mt='2' mb='2'>
            <Table variant='striped'>
              <Thead>
                <Tr>
                  <Th>Category</Th>
                  <Th>Club Charge</Th>
                  <Th>Club Paid</Th>
                  <Th>Amount</Th>
                  <Th>Description</Th>
                  <Th>Date</Th>
                </Tr>
              </Thead>
              <Tbody>
                {accountings.filter(accounting => (accounting.category === 'AnnualFeeCharge' || accounting.category === 'AnnualFeeCredit')).map((accounting) => (
                  <Tr key={accounting._id}>
                    <Td>{accounting.category}</Td>
                    <Td>{accounting.isCharge ? <CheckCircleIcon color='cyan.500' /> : ''}</Td>
                    <Td>{accounting.isPay ? <CheckCircleIcon color='cyan.500' /> : ''}</Td>
                    <Td>{accounting.amount}</Td>
                    <Td>{accounting.description}</Td>
                    <Td>{new Date(accounting.placeDate).toDateString()}</Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>
          </TableContainer>
          <Text mb='2'>{checkAnnualFee() >= 0 ? `You have ${checkAnnualFee()} USD membership annual fee credit. The credit can only be used for paying annual fee.` : `You own ${checkAnnualFee()} annual fee. Please pay it asap.`}</Text>
          {checkAnnualFee() < 0 && (
            <Button mt = '2' variant='outline' colorScheme='cyan' onClick={() => addItem()}>
            Add Annual Fee to cart
          </Button>
          )}
          <Divider/>
          <Text mt='2'>Your Coupon History</Text>
          <TableContainer mt='2' mb='2'>
            <Table variant='striped'>
              <Thead>
                <Tr>
                  <Th>Category</Th>
                  <Th>Club Charge</Th>
                  <Th>Club Paid</Th>
                  <Th>Amount</Th>
                  <Th>Description</Th>
                  <Th>Date</Th>
                </Tr>
              </Thead>
              <Tbody>
                {accountings.filter(accounting => (accounting.category === 'Coupon')).map((accounting) => (
                  <Tr key={accounting._id}>
                    <Td>{accounting.category}</Td>
                    <Td>{accounting.isCharge ? <CheckCircleIcon color='cyan.500' /> : ''}</Td>
                    <Td>{accounting.isPay ? <CheckCircleIcon color='cyan.500' /> : ''}</Td>
                    <Td>{accounting.amount}</Td>
                    <Td>{accounting.description}</Td>
                    <Td>{new Date(accounting.placeDate).toDateString()}</Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>
          </TableContainer>
          <Text mb='2'>You have $ {checkCoupon()} coupon in total. Coupon can be used as Cash in Club.</Text>
          {checkCoupon() < 0 && (
            <Button mt = '2' variant='outline' colorScheme='cyan' onClick={() => addItemCoupon()}>
            Add Coupon to pay to cart
          </Button>
          )}
          </>
          )
        )}
      </>
    ) : (<Navigate to='/login' replace={true} state={{from: location }} />);
  };
  
  export default YourAccountingScreen;
