
import { MinusIcon, SmallAddIcon } from '@chakra-ui/icons';
import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Badge,
  Box,
  Button,
  Flex,
  HStack,
  Heading,
  Image,
  SimpleGrid,
  Spinner,
  Stack,
  Text,
  Wrap,
  useToast,
  Textarea,
  Input,
  Tooltip,
  FormControl,
  FormLabel,
  Divider,
  Link,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  Table,
  Tbody,
} from '@chakra-ui/react';
import { BiChat } from 'react-icons/bi';
import { MdContactMail } from "react-icons/md";
import { PiChalkboardTeacherThin } from "react-icons/pi";
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { getCertificate } from '../redux/actions/certificateActions';
import { useEffect, useState } from 'react';
import { Link as ReactLink } from 'react-router-dom';
import { addCartItemGeneral } from '../redux/actions/cartActions';
import TakerTableItem from '../components/TakerTableItem';
import Papa from 'papaparse';
import { saveAs } from 'file-saver';


const CertificateScreen = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const { loading, error, certificate, taken } = useSelector((state) => state.certificate);
  const toast = useToast();
  const { cartItems } = useSelector((state) => state.cart);
  const [applyBoxOpen, setApplyBoxOpen] = useState(false);
  const { userInfo, groupClassTakenSetList } = useSelector((state) => state.user);
  const [buttonLoading, setButtonLoading] = useState(false);
  // check if expire soon

  useEffect(() => {
    dispatch(getCertificate(id));
    setApplyBoxOpen(false);

    if(taken) {
      toast({
        description: 'You have applied to take this certificate exam.',
        status: 'success',
        isClosable: 'true',
      });
      setApplyBoxOpen(false);
    }
  }, [dispatch, id, toast, taken]);

  const hasUserTaken = () => certificate.takers.some((item) => item.userId.toString() === userInfo._id.toString());
  // const isUserQualified = false;
  const isUserFailed = () => {
    certificate.takers.some((item) => (item.userId.toString() === userInfo._id.toString() && item.isFail === true))
  };
  const isUserApproved = () => {
    certificate.takers.some((item) => (item.userId.toString() === userInfo._id.toString() && item.isApproved === true))
  };

  const isUserQualified = () => {
    let qualified = false;
    groupClassTakenSetList.forEach(groupClassTakenSet => {
      if (groupClassTakenSet.certificateId === id && groupClassTakenSet.rating > 7.0 ) {
        qualified = true;
      }
    })
    return qualified;
  }
  const addItem = () => {
    dispatch(addCartItemGeneral(certificate._id, 'Certificate', [], certificate.name, certificate.examFee, certificate.examFeeStripeId));
    toast({
      description: 'Item has been added.',
      status: 'success',
      isClosable: true,
    });
  };

  const isManager = () => {
    if(userInfo ) {
      if(userInfo.isAdmin) return true;
      if(certificate.managerUserId === userInfo._id) return true;
    }
    return false;
  }

  const convertToCSV = (data) => {
    const csv = Papa.unparse(data);
    return csv;
  };

  const downloadCSV = (csvData, filename) => {
    const blob = new Blob([csvData], { type: 'text/csv;charset=utf-8;' });
    saveAs(blob, filename);
  };

  const downloadTakers = () => {
   
    const csvData = convertToCSV(certificate.takers);
    downloadCSV(csvData, 'certificateTakers.csv');
    
  };


  return (
    <Wrap spacing='30px' justify='center' minHeight='100vh'>
      {loading ? (
        <Stack direction='row' spacing='4'>
          <Spinner mt='20' thickness='2px' speed='0.65s' emptyColor='gray.200' color='cyan.500' size='xl' />
        </Stack>
      ) : error ? (
        <Alert status='error'>
          <AlertIcon />
          <AlertTitle>We are sorry!</AlertTitle>
          <AlertDescription>{error}</AlertDescription>
        </Alert>
      ) : (
        certificate && (
          <Box
            maxW={{ base: '3xl', lg: '5xl' }}
            mx='auto'
            px={{ base: '4', md: '8', lg: '12' }}
            py={{ base: '6', md: '8', lg: '12' }}>
              <Stack direction={{ base: 'column', lg: 'row' }} align='flex-start'>
                <Stack pr={{ base:'0', md: 'row' }} flex='1.5' mb={{ base: '12', md: 'none' }}>

                <Heading fontSize='2xl' fontWeight='extrabold'>
                  {certificate.name} 
                </Heading>
                <Divider />
                <Stack spacing='5'>
                    <Box>
                        <Text fontSize='xl'>{certificate.category}</Text>
                    </Box>
                    <Divider />
                    <Text fontSize='md'>Description</Text>
                    <Text >{certificate.description}</Text>
                    <Divider />
                    <Text fontSize='md'>Exam Fee</Text>
                    <Text >${certificate.examFee}</Text>
                  
                  <Divider />
                  <Stack width='300px'>
                    <Flex alignItems='center'>
                      <MdContactMail size='20px' />
                      <Link fontWeight='medium' fontSize='sm' ml='2' as={ReactLink} to='/chat/6' >
                      More questions about this certificate?
                      </Link>
                    </Flex>
                    <Flex alignItems='center'>
                      <PiChalkboardTeacherThin size='20px' />
                      <Link fontWeight='medium' fontSize='sm' ml='2' as={ReactLink} to='/services' >
                        Need a coach to improve your skill?
                        </Link>
                    </Flex>
                    <Flex alignItems='center'>
                      <BiChat size='20px' />
                      <Link fontWeight='medium' fontSize='sm' ml='2' as={ReactLink} to='/chat/1' >
                        We're here for you 24/7
                      </Link>
                    </Flex>
                  </Stack>
                </Stack>
              </Stack>
              </Stack>

              {userInfo && (
                <>
                  <Tooltip label={hasUserTaken() ? (isUserApproved() ? 'You have successfully got this certificate!' : 
                  ( isUserFailed() ? 'You have failed this certificate exam, contact our office to try again.' : 
                   'You have already tried this certificate.')) : 
                   !isUserQualified() ? 'You are not qualified to take this certificate yet.' : ''} fontSize='medium'>
                  <Button variant='outline' isDisabled={hasUserTaken() || !isUserQualified() } colorScheme='cyan' onClick={() => addItem()}>
                    Add to cart
                  </Button>
                  </Tooltip>
                </>
              )}

              {isManager() && certificate.numberOfTakers > 0 && (
                  <>
                  <Divider mt='2' mb='2'/>
                  <Text>Information for manager: {certificate.numberOfTakers} has applied to take the exam. </Text>
                  <Button onClick={downloadTakers}>Download Takers</Button>
                  <Text fontWeight='bold'>Deal with takers of this certificate:</Text>
                  { certificate.takers.map((taker) => (
                  <>
                    <Accordion key={taker._id} allowToggle>
                    <AccordionItem>
                      <h2>
                        <AccordionButton>
                          <Box flex='1' textAlign='right'>
                            <Box>
                              <Text key={taker._id} mr='8px' fontWeight='bold'>{taker.name}</Text>
                            </Box>
                          </Box>
                        </AccordionButton>
                    </h2>
                    <AccordionPanel pb='4'>
                        <Table>
                            <Tbody>
                            <TakerTableItem key={taker._id} taker={taker} />
                            </Tbody>
                        </Table>
                    </AccordionPanel>
                  </AccordionItem>
                </Accordion>
                </>
                ))} 
                </>
              )}
            </Box>
        )
      ) }
    </Wrap>
  )
}

export default CertificateScreen
