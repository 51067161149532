import { DeleteIcon } from '@chakra-ui/icons';
import {
    Badge,
    Button,
    Flex,
    FormControl,
    FormLabel,
    Input,
    Switch,
    Td,
    Textarea,
    Tr,
    VStack,
    useDisclosure,
} from '@chakra-ui/react';
import { useRef, useState } from 'react';
import { MdOutlineDataSaverOn } from 'react-icons/md';
import { useDispatch, useSelector } from 'react-redux';
import { deleteJob, updateJob } from '../redux/actions/adminActions';
import ConfirmRemovalAlert from './ConfirmRemovalAlert';

const JobTableItem = ({job}) => {
    const { userInfo } = useSelector((state) => state.user);

    const cancelRef= useRef();
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [title, setTitle ] = useState(job.title);
    const [duration, setDuration ] = useState(job.duration);
    const [level, setLevel ] = useState(job.level);
    const [companyShown, setCompanyShown ] = useState(job.companyShown);
    const [companyReal, setCompanyReal ] = useState(job.companyReal);
    const [category, setCategory ] = useState(job.category);
    const [applicationDeadLine, setApplicationDeadLine ] = useState(job.applicationDeadLine);
    const [expireDate, setExpireDate ] = useState(job.expireDate);
    const [referUserId, setReferUserId ] = useState(job.referUserId);
    const [emailSentTo, setEmailSentTo ] = useState(job.emailSentTo);
    const [applicantLevel, setApplicantLevel ] = useState(job.applicantLevel);
    const [location, setLocation ] = useState(job.location);
    const [description, setDescription ] = useState(job.description);
    const [requirement, setRequirement ] = useState(job.requirement);

    const dispatch = useDispatch();

    const onSaveJob = () => {
        dispatch(updateJob(job._id, title, duration, level, companyShown, companyReal, category, applicationDeadLine, expireDate, referUserId, emailSentTo, 
            applicantLevel, location, description, requirement));
    };

    const openDeleteConfirmBox = () => {
        onOpen();
    };

  return (
    <>
    <Tr>
        <Td> Description: </Td>
        <Td>
            <Textarea
                w='80vh' 
                h='120px'
                value={description}
                onChange={(e) => setDescription(e.target.value)}
                size='m'
            />
        </Td>
    </Tr><Tr>
        <Td> Requirement: </Td>
        <Td>
            <Textarea
                w='80vh' 
                h='120px'
                value={requirement}
                onChange={(e) => setRequirement(e.target.value)}
                size='m'
            />
        </Td>
    </Tr><Tr>
        <Td>Title & Duration</Td>
        <Td>
            <Flex direction='column' gap='2'>
                <Input size='sm' value={title} onChange={(e) => setTitle(e.target.value)} />
                <Input size='sm' value={duration} onChange={(e) => setDuration(e.target.value)} />
            </Flex>
        </Td>
    </Tr><Tr>
        <Td>Level & Category</Td>
        <Td>
            <Flex direction='column' gap='2'>
                <Input size='sm' value={level} onChange={(e) => setLevel(e.target.value)} />
                <Input size='sm' value={category} onChange={(e) => setCategory(e.target.value)} />
            </Flex>
        </Td>
    </Tr><Tr>
        <Td>CompanyShown & CompanyReal</Td>
        <Td>
            <Flex direction='column' gap='2'>
                <Input size='sm' value={companyShown} onChange={(e) => setCompanyShown(e.target.value)} />
                <Input size='sm' value={companyReal} onChange={(e) => setCompanyReal(e.target.value)} />
            </Flex>
        </Td>
    </Tr><Tr>
        <Td>ApplicationDeadLine & ExpireDate</Td>
        <Td>
            <Flex direction='column' gap='2'>
                <Input size='sm' value={applicationDeadLine} onChange={(e) => setApplicationDeadLine(e.target.value)} />
                <Input size='sm' value={expireDate} onChange={(e) => setExpireDate(e.target.value)} />
            </Flex>
        </Td>
    </Tr><Tr>
        <Td>ReferUserId & EmailSentTo</Td>
        <Td>
            <Flex direction='column' gap='2'>
                <Input size='sm' value={referUserId} onChange={(e) => setReferUserId(e.target.value)} />
                <Input size='sm' value={emailSentTo} onChange={(e) => setEmailSentTo(e.target.value)} />
            </Flex>
        </Td>
    </Tr><Tr>
        <Td>ApplicantLevel & Location</Td>
        <Td>
            <Flex direction='column' gap='2'>
                <Input size='sm' value={applicantLevel} onChange={(e) => setApplicantLevel(e.target.value)} />
                <Input size='sm' value={location} onChange={(e) => setLocation(e.target.value)} />
            </Flex>
        </Td>
    </Tr>
    {userInfo.isAdmin && (
    <ConfirmRemovalAlert 
                    isOpen={isOpen} 
                    onOpen={onOpen} 
                    onClose={onClose} 
                    cancelRef={cancelRef} 
                    itemToDelete={job}
                    deleteAction={deleteJob}
    />)}
    <Tr>
            <Td>Action</Td>
            <Td>
            <VStack align='start'>
                <Button colorScheme='red' variant='outline' onClick={openDeleteConfirmBox}>
                    <DeleteIcon mr='5px' />
                    Remove Job
                </Button>  
                <Button colorScheme='green' variant='outline' onClick={onSaveJob}>
                    <MdOutlineDataSaverOn style={{marginRight: '5px'}} />
                    Save Changes
                </Button>
            </VStack>
        </Td>
    </Tr>
    </>
  );
};

export default JobTableItem;