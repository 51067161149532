import {
  Box,
  Flex,
  Heading,
  HStack,
  Link,
  Stack,
  useColorModeValue as mode,
  Spinner,
  Alert,
  AlertIcon,
  AlertDescription,
  Wrap,
  AlertTitle,
  Button,
  Text,
} from '@chakra-ui/react';
import { FaArrowRight } from 'react-icons/fa';

import { Link as ReactLink } from 'react-router-dom';
import { useSelector } from 'react-redux';
import CartItem from '../components/CartItem';


const CartScreen = () => {
  const { loading, error, cartItems, subtotal, hasCoupon, couponAmount, couponCode } = useSelector((state) => state.cart);

  // const getHeadingContent = () => (cartItems.length === 1 ? '(1 Item)' : `(${cartItems.length} Items)`);

  return (
    <Wrap spacing='30px' justify='center' minHeight='100vh'>
      {loading ? (
        <Stack direction='row' spacing='4'>
          <Spinner mt='20' thickness='2px' speed='0.65s' emptyColor='gray.200' color='cyan.500' size='xl' />
        </Stack>
      ) : error ? (
        <Alert status='error'>
          <AlertIcon />
          <AlertTitle>We are sorry!</AlertTitle>
          <AlertDescription><Link as={ReactLink} textDecoration='underline' to='/logout' >You might need to logout and login again to access this page. </Link></AlertDescription>
        </Alert>
      ) : cartItems.length <= 0 ? (
        <Alert status='warning'>
          <AlertIcon />
          <AlertTitle>Your cart is empty</AlertTitle>
          <AlertDescription>
            <Link as={ReactLink} to='/programs'>
              Click here to see our programs.
            </Link>
          </AlertDescription>
        </Alert>
      ) : (
        <Box px='4' py='8' w={{ base: '95%', lg: '50%' }}>
          <Stack
            direction={{ base: 'column', lg: 'row' }}
            align={{ lg: 'flex-start' }}
            spacing={{ base: '8', md: '16' }}>
              <Stack spacing={{ base: '8', md: '10' }} flex='2'>
                <Heading fontSize='2xl' fontWeight='extrabold'>
                  Shopping Cart
                </Heading>
                <Stack spacing='6'>
                  {cartItems.map((cartItem) => (
                    <CartItem key={cartItem.id} cartItem={cartItem} />
                  ))}
                </Stack>
              </Stack>
            <Flex direction='column' align='center' flex='1'>
              <Stack
      minWidth='300px'
      spacing='8'
      borderWidth='1px'
      borderColor={mode('cyan.500', 'cyan.100')}
      rounded='lg'
      padding='8'
      w='full'>
        <Heading size='md'>Order Summary</Heading>
        <Stack spacing='6'>
          <Flex justify='space-between'>
            <Text fontWeight='medium' color={mode('gray.600', 'gray.400')}>
              Subtotal
            </Text>
            <Text fontWeight='medium'>${subtotal}</Text>
          </Flex>
          <Flex justify='space-between'>
            <Text fontWeight='medium' color={mode('gray.600', 'gray.400')}>
              {hasCoupon ? `Coupon: ${couponCode}` : ''}
            </Text>
            <Text fontWeight='medium'>{hasCoupon ? `$${couponAmount}` : ''}</Text>
          </Flex>
          <Flex justify='space-between'>
            <Text fontSize='xl' fontWeight='extrabold'>
              Total
            </Text>
            <Text fontWeight='medium'>${Number(subtotal) - Number(couponAmount)}</Text>
          </Flex>
        </Stack>
        <Button as={ReactLink} to='/checkout' colorScheme='cyan' size='lg' rightIcon={<FaArrowRight />}>
          Checkout
        </Button>
      </Stack>

              <HStack mt='6' fontWeight='semibold'>
                <p>or</p>
                <Link as={ReactLink} to='/programs' color={mode('cyan.500', 'cyan.200')}>
                  Continue Shopping
                </Link>
              </HStack>
            </Flex>
          </Stack>
        </Box>
      )}
    </Wrap>
  )
}

export default CartScreen