import { Text } from '@chakra-ui/react';

const FormattedDate = ({orgDate, title, shift}) => {

    const inputDate = new Date(orgDate); // Add 5 days to the input date
    if (shift !== '0' ) {
        inputDate.setDate(inputDate.getDate() + Number(shift));
    }
    const today = new Date();
    const differenceInMilliseconds = inputDate - today;

    const differenceInDays = differenceInMilliseconds / (1000 * 60 * 60 * 24);

    let myColor = 'darkCerulean.200';
    if (differenceInDays <= 5) {
        myColor = 'red';
    }

    const myFormattedDate = new Date(inputDate).toLocaleDateString('en-US', { 
        month: 'short', // Use 'short' for abbreviated month names 
        day: 'numeric', 
        year: 'numeric'
    });

    return (
    <Text color={myColor}>{title} {myFormattedDate}</Text>
    );
};

export default FormattedDate;
