import {
    Badge,
    Button,
    Flex,
    FormControl,
    FormLabel,
    Input,
    Switch,
    Td,
    Textarea,
    Tr,
    VStack,
    useDisclosure,
    useToast,
} from '@chakra-ui/react';
import { useRef, useState } from 'react';
import { MdOutlineDataSaverOn } from 'react-icons/md';
import { useDispatch, useSelector } from 'react-redux';
import { updateApplicant } from '../redux/actions/groupClassActions';

const ApplicantTableItem = ({applicant}) => {
    const { userInfo } = useSelector((state) => state.user);
    const toast = useToast();
    const cancelRef= useRef();
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [ isApproved, setIsApproved ] = useState(applicant.isApproved);
    const [ratingInThis, setRatingInThis ] = useState(applicant.ratingInThis);
    const [secretNote, setSecretNote ] = useState(applicant.secretNote);
    
    const dispatch = useDispatch();

    const onSaveApplicant = () => {
        dispatch(updateApplicant(applicant.name, applicant.level, ratingInThis, secretNote, applicant.email, applicant.userId, isApproved));
        toast({
            description: `Applicant ${applicant.name} is updated.`,
            status: 'success',
            isClosable: true,
        });
    };


  return (
    <>
    <Tr>
                              <Td> Name: </Td>
                              <Td> {applicant.name}</Td>
                            </Tr>
                            <Tr>
                              <Td> Email: </Td>
                              <Td> {applicant.email}</Td>
                            </Tr>
                            <Tr>
                              <Td> WechatId: </Td>
                              <Td> {applicant.wechatId ? applicant.wechatId : ''}</Td>
                            </Tr>
                            <Tr>
                              <Td> is Approved : </Td>

                              <Td>
                                <FormControl display='flex' alignItems='center'>
                                    <FormLabel htmlFor='isApprovedFlag' mb='0' fontSize='sm'>
                                        Set
                                        <Badge rounded='full' px='1' mx='1' fontSize='0.8m' colorScheme='green'>
                                            is Approved
                                        </Badge>
                                    </FormLabel>
                                    <Switch id='isApprovedFlag' onChange={() => setIsApproved(!isApproved)} isChecked={isApproved} />
                                </FormControl>
                              </Td>
                            </Tr>
                            <Tr>
                            <Td> Rating: </Td>
                            <Td>
                            <Input size='sm' value={ratingInThis} onChange={(e) => setRatingInThis(e.target.value)} />
                           </Td>
                        </Tr>
                            <Tr>
                            <Td> Secret Memo Note: </Td>
                            <Td>
                                <Textarea
                                    w='80vh' 
                                    h='120px'
                                    value={secretNote}
                                    onChange={(e) => setSecretNote(e.target.value)}
                                    size='m'
                                />
                           </Td>
                        </Tr>
                        <Tr>
                            <Td>Action</Td>
                            <Td>
                                <Button colorScheme='green' variant='outline' onClick={onSaveApplicant}>
                                <MdOutlineDataSaverOn style={{marginRight: '5px'}} />
                                    Save Changes
                                </Button>
                            </Td>
    </Tr>
    </>
  );
};

export default ApplicantTableItem;