// import React from 'react'
import {
    Box,
    Flex,
    Heading,
    HStack,
    Icon,
    Image,
    Link,
    Skeleton,
    Stack,
    useColorModeValue as mode,
    Text,
} from '@chakra-ui/react';
import { FaArrowRight } from 'react-icons/fa';
import { Link as ReactLink } from 'react-router-dom';
import { BsMortarboard } from 'react-icons/bs';



const LandingScreen = () => (
    <Box maxW='8xl' mx='auto' p={{ base: '0', lg: '12' }} minH='6xl'>
        <Stack direction={{ base: 'column-reverse', lg:'row' }} spacing={{ base: '0', lg:'20' }}>
            <Box
                width={{ lg: 'sm' }}
                transform={{ base: 'translateY(-50%)', lg: 'none' }}
                bg={{ base: mode('cyan.50', 'gray.700'), lg: 'transparent' }}
                mx={{ base: '6', md: '8', lg: '0' }}
                px={{ base: '6', md: '8', lg: '0' }}
                py={{ base: '6', md: '8', lg: '12' }}>
                <Stack spacing={{ base: '8', lg: '10' }}>
                    <Stack spacing={{ base: '2', lg: '4' }}>
                        <Flex alignItems='center'>
                            <Icon as={BsMortarboard} h={12} w={12} color={mode('darkCerulean.100', 'yellow.200')} />
                            <Text fontSize='4xl' fontWeight='bold'>
                                Qishi Career Path Club
                            </Text>
                        </Flex>
                        <Heading size='sm' fontWeight='normal'>
                        As a leading global study platform, Qishi Career Path Club empowers you to unlock your potential through learning, networking, and shaping a career path that aligns with your goals.
                        </Heading>
                    </Stack>
                    <HStack spacing='3'>
                        <Link as={ReactLink} to='/programs' color={mode('darkCerulean.100', 'yellow.200')}>
                            Discover now
                        </Link>
                        <Icon color={mode('darkCerulean.100', 'yellow.200')} as={FaArrowRight} />
                    </HStack>
                    <Stack spacing={{ base: '2', lg: '4' }}>
                        <Heading size='sm' fontWeight='normal'>
                        Hello, everyone! The Qishi Club has launched this new website! Since our registered users have not been migrated, we kindly ask everyone to register again on this new website. If you previously purchased a wild card memebership that is still valid, please contact us at qishicpc@gmail.com and provide your username or registered email from the old website, as well as your registered email for the new website. We will upgrade your account to the Gold Membership on the new website - which is equivalent to the wild card membership on the old website. Thank you for your support!
                        </Heading>
                        <HStack spacing='3'>
                        <Link as={ReactLink} to='/registration' color={mode('darkCerulean.100', 'yellow.200')}>
                            Register now
                        </Link>
                        <Icon color={mode('darkCerulean.100', 'yellow.200')} as={FaArrowRight} />
                        </HStack>
                    </Stack>

                </Stack>
            </Box>
            <Flex flex='1' overflow='hidden'>
                <Image
                    src={mode('images/landing-light.jpg','images/landing-dark.jpg')}
                    fallback={<Skeleton />}
                    maxH='550px'
                    minW='300px'
                    objectFit='cover'
                    flex='1'
                    />
            </Flex>
        </Stack>
    </Box>

);

export default LandingScreen