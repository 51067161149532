import {Box, Heading, Stack, Flex, Text, Button, useColorModeValue  as mode  } from '@chakra-ui/react';
import { useSelector, useDispatch } from 'react-redux';
import { Navigate, useLocation } from 'react-router-dom';
// import PayInformation from '../components/PayInformation';
// import YourOrderSummery from '../components/YourOrderSummery';
import { tryCoupon } from '../redux/actions/cartActions';
import { setPayment } from '../redux/actions/orderActions';
import { Link as ReactLink } from 'react-router-dom';
import { useEffect } from 'react';

const YourCheckoutScreen = () => {

    const dispatch = useDispatch();
    const { userInfo } = useSelector((state) => state.user);
    const { subtotal, hasCoupon, couponCode, couponAmount } = useSelector((state) => state.cart);
    const location = useLocation();
    console.log(location);

    useEffect(() => {
        if(userInfo && userInfo.couponBalance >= 5) {
            dispatch(tryCoupon(userInfo.couponBalance));
        }
    }, [dispatch, userInfo]);

    const onSubmit = async () => {
        dispatch(setPayment());
    };

    return userInfo ? (<Box
        minH='100vh'
        maxW={{base: '3xl', lg: '7xl' }}
        mx='auto'
        px={{ base: '4', md: '8', lg: '12' }}
        py={{ base: '6', md: '8', lg: '12' }}>
        <Stack spacing='8' direction={{ base: 'column', lg: 'row' }} align={{ base: 'revert', lg: 'flex-start' }}>
            <Stack spacing={{ base:'8', md: '10' }} flex='1.5' mb={{ base: '12', md: 'none' }}>
                <Stack>
                <Flex alignItems='center' gap='2' direction={{base: 'column', lg: 'row' }}>
                        <Button variant='outline' colorScheme='cyan' w='100%' as={ReactLink} to='/cart'>
                                Back to cart
                        </Button>
                        <Button
                            variant='outline'
                            colorScheme='cyan'
                            w='100%'
                            as={ReactLink}   
                            to='/payment'
                            onClick={onSubmit}>
                                Continue to Payment
                        </Button>
                    </Flex>
                </Stack>
            </Stack>
            <Flex direction='column' align='center' flex='1'>
            <Stack
      minWidth='300px'
      spacing='8'
      borderWidth='1px'
      borderColor={mode('cyan.500', 'cyan.100')}
      rounded='lg'
      padding='8'
      w='full'>
        <Heading size='md'>Order Summary</Heading>
        <Stack spacing='6'>
          <Flex justify='space-between'>
            <Text fontWeight='medium' color={mode('gray.600', 'gray.400')}>
              Subtotal
            </Text>
            <Text fontWeight='medium'>${subtotal}</Text>
          </Flex>
          <Flex justify='space-between'>
            <Text fontWeight='medium' color={mode('gray.600', 'gray.400')}>
              Coupon: {hasCoupon ? couponCode : 'you have no coupon.'}
            </Text>
            <Text fontWeight='medium'>{hasCoupon ? `$${couponAmount}` : ''}</Text>
          </Flex>
          <Flex justify='space-between'>
            <Text fontSize='xl' fontWeight='extrabold'>
              Total
            </Text>
            <Text fontWeight='medium'>${Number(subtotal) - Number(couponAmount)}</Text>
          </Flex>
        </Stack>
      </Stack>
            </Flex>
        </Stack>
    </Box>
     ) : (
     <Navigate to='/login' replace={true} state={{from: location}} />
     );

};
export default YourCheckoutScreen;
