import {
    Accordion,
    AccordionButton,
    AccordionItem,
    AccordionPanel,
    Alert,
    AlertDescription,
    AlertIcon,
    AlertTitle,
    Box,
    Spinner,
    Stack,
    Table,
    Tbody,
    Text,
    Th,
    Thead,
    Tr,
    Wrap,
    useToast,
} from '@chakra-ui/react';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getMentors, resetMentorError } from '../redux/actions/mentorActions';
import MentorTableItem from './MentorTableItem';
import AddNewMentor from './AddNewMentor';

const MentorsTab = () => {
    const dispatch = useDispatch();
    const { error, loading } = useSelector((state) => state.admin);
    const { mentors, mentorUpdate } = useSelector((state) => state.mentor);
    const toast = useToast();

    useEffect(() => {
        dispatch(getMentors());
        dispatch(resetMentorError()); // maybe you need do resererror and removal like in orderstab
        if(mentorUpdate){
            toast({
                description: 'Mentor is updated.',
                status: 'success',
                isClosable: true,
            });
        }
    }, [dispatch, toast, mentorUpdate]);

  return (
    <Box>
    {error && (
        <>
        <Alert status='error'>
            <AlertIcon />
            <AlertTitle>Upps!</AlertTitle>
            <AlertDescription>{error}</AlertDescription>
        </Alert>
        </>
    )}
    
    {loading && (
        <>
        <Wrap justify='center'>
            <Stack direction='row' spacing='4'>
                <Spinner mt='20' thickness='2px' speed='0.65s' emptyColor='gray.200' color='cyan.500' size='xl' />
            </Stack>
        </Wrap>
        </>
    )}
    {!error && !loading && (
        <Box>
            <Accordion allowToggle>
                <AccordionItem>
                    <h2>
                        <AccordionButton>
                            <Box flex='1' textAlign='right'>
                                <Box>
                                    <Text mr='8px' fontWeight='bold'>Add a new Mentor</Text>
                                </Box>
                            </Box>
                        </AccordionButton>
                    </h2>
                    <AccordionPanel pb='4'>
                        <Table>
                            <Tbody>
                                <AddNewMentor />
                            </Tbody>
                        </Table>
                    </AccordionPanel>
                </AccordionItem>
            </Accordion>
            {mentors && mentors.length > 0 &&
                        mentors.map((mentor) => (
                            
            <Accordion key={mentor._id} allowToggle>
                <AccordionItem>
                    <h2>
                        <AccordionButton>
                            <Box flex='1' textAlign='right'>
                                <Box>
                                    <Text key={mentor._id} mr='8px' fontWeight='bold'>{mentor.name}</Text>
                                </Box>
                            </Box>
                        </AccordionButton>
                    </h2>
                    <AccordionPanel pb='4'>
                        <Table>
                            <Tbody>
                            <MentorTableItem key={mentor._id} mentor={mentor} />
                            </Tbody>
                        </Table>
                    </AccordionPanel>
                </AccordionItem>
            </Accordion>
            
            ))}
        </Box> 
        
    )}
</Box>

  );
};

export default MentorsTab;