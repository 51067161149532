import { createSlice } from '@reduxjs/toolkit';

export const initialState = {
    loading: false,
    error: null,
    mentors: [],
    mentor: null,
    mentorChatMessage: [],
    reviewed: false,
    reviewRemoval: false,
    mentorUpdate: false,
    menteeUpdate: false,
};

export const mentorsSlice = createSlice({
    name: 'mentors',
    initialState,
    reducers: {
        setLoading: (state) => {
            state.loading = true;
        },
        setMentors: (state, { payload }) => {
            state.loading = false;
            state.error = null;
            state.mentors = payload;
            state.reviewRemoval = false;
        },
        setMentor: (state, { payload }) => {
            state.loading = false;
            state.error = null;
            state.mentor = payload;
            state.reviewed = false;
        },
        setMentorChatMessage: (state, { payload }) => {
            state.loading = false;
            state.error = null;
            state.mentorChatMessage = [...state.mentorChatMessage, payload];
            state.reviewed = false;
        },
        setError: (state, { payload }) => {
            state.loading = false;
            state.error = payload;
        },
        mentorReviewed: (state, {payload}) => {
            state.loading = false;
            state.error = null;
            state.reviewed = payload;
        },
        resetError: (state) => {
            state.error = null;
            state.reviewed = false;
            state.mentorUpdate = false;
            state.reviewRemoval = false;
        },
        setMentorUpdateFlag: (state) => {
            state.mentorUpdate = true;
            state.loading = false;
        },
        setReviewRemovalFlag: (state) => {
            state.error = null;
            state.reviewRemoval = true;
            state.loading = false;
        },
        setMenteeUpdateFlag: (state) => {
            state.menteeUpdate = true;
            state.loading = false;
        },
    },
});

export const {
    setLoading, 
    setError, 
    setMentors, 
    setMentor,
    setMentorChatMessage,
    mentorReviewed,
    setMentorUpdateFlag,
    resetError,
    setReviewRemovalFlag,
    setMenteeUpdateFlag,
} = mentorsSlice.actions;

export default mentorsSlice.reducer;

export const mentorSelector = (state) => state.mentors;

