import {
    Alert,
    AlertDescription,
    AlertIcon,
    AlertTitle,
    Box,
    Button,
    Flex,
    Heading,
    Image,
    Spinner,
    Stack,
    Text,
    Wrap,
    Textarea,
    List,
    ListItem,
    HStack,
    Input,
  } from '@chakra-ui/react';
  import { useDispatch, useSelector } from 'react-redux';
  import { useParams } from 'react-router-dom';
  import { getMentor, chatWithMentor } from '../redux/actions/mentorActions';
  import { useEffect, useState, useRef } from 'react';
  
  const MentorChatScreen = () => {
      /* const [amount, setAmount] = useState(1);  */
      const { id } = useParams();
    const dispatch = useDispatch();
    const { loading, error, mentor, mentorChatMessage } = useSelector((state) => state.mentor);
    const [content, setContent] = useState('');
    //const [checkMessage, setCheckMessage] = useState('');
    const { userInfo } = useSelector((state) => state.user);
    // const API_ENDPOINT = 'https://portal.2brain.ai/api/bot/chat/v1/chat/completions';
    //const [buttonLoading, setButtonLoading] = useState(false);
    const messagesEndRef = useRef(null);
    // const chatBoxRef = useRef(null);
    const today = new Date();
    const oneYearEarlier = new Date();
    oneYearEarlier.setFullYear(today.getFullYear() - 1);


    useEffect(() => {
        if(!mentor) {
            dispatch(getMentor(id));
        }
        scrollToBottom();
    }, [dispatch, id, mentor, mentorChatMessage, error]);

    /* useEffect(() => { 
      console.log('Setting up EventSource connection...');
      const eventSource = new EventSource(API_ENDPOINT); 
      console.log('Initiate EventSource connection...');
      eventSource.onopen = () => { console.log('EventSource connection opened'); };
      eventSource.onmessage = (event) => { 
        console.log("here");
        console.log(event.data);
        const newMessage = JSON.parse(event.data); 
        setCheckMessage((prevMessages) => [...prevMessages, newMessage.choices[0].delta.content]); 
      }; 
      
      eventSource.onerror = () => { 
        // setLoading(false); 
        eventSource.close(); 
      }; 
      return () => { 
        console.log('Closing EventSource connection');
        eventSource.close(); 
      }; 
    }, []);

    const postDataAndFetch = async (message) => { 
      const postData = {
        messages: [{
           "role": "user",
           "content": message
        }],
        user_id: userInfo._id,
        stream: true,
    };
      const CHAT_CHANJUAN_API_KEY = '2B-3thfLhw02TPoXBqJTmcNP17SWp0PP3UNKjljWB3dVkQhyFwSGR';
      try { 
        const response = await fetch(API_ENDPOINT, { 
          method: 'POST', 
          headers: { 
            'Authorization': `Bearer ${CHAT_CHANJUAN_API_KEY}`,
            'Content-Type': 'application/json', 
          }, 
          body: JSON.stringify(postData), 
        }); 
        if (!response.ok) { 
          throw new Error('Network response was not ok'); 
        } 
      } catch (error) { 
        console.error('Error posting data:', error); 
      } 
    }; */

    const onSubmit = ( ) => {
        dispatch(chatWithMentor(mentor._id, content));
        // postDataAndFetch(content);
        setContent('');
    };

    const scrollToBottom = () => { 
      messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' }); 
    };

    const handleKeyDown = (event) => { 
      if (event.key === 'Enter') { 
          onSubmit(); 
      } 
    };

    const checkIfQualify = () => {
      if(!mentor) {
        return false;
      }
      if(!userInfo) {
        return false;
      }
      if (userInfo.membershipLevel === 10) {
        return true;
      }
      if (mentor.mentees && mentor.mentees.length > 0 ) {
        return mentor.mentees.map((mentee) => (mentee.userId === userInfo._id && (new Date(mentee.createdAt) >= oneYearEarlier))).includes(true);
      }
      else {
        return false;
      }
    }

    return (
      <Wrap spacing='30px' justify='center' minHeight='100vh'>
        {error ? (
          <Alert status='error'>
            <AlertIcon />
            <AlertTitle>We are sorry!</AlertTitle>
            <AlertDescription>The Chatbox has following error: {error}, please try again later.</AlertDescription>
          </Alert>
        ) : !checkIfQualify() ? (
          <Alert status='error'>
            <AlertIcon />
            <AlertTitle>We are sorry!</AlertTitle>
            <AlertDescription>This Auto Consulting Service is only for Platinum members or this mentor's current year mentees . You can buy the mentoring session first and then start consulting.</AlertDescription>
          </Alert>
        ) : (
            <Box
              maxW={{ base: '3xl', lg: '5xl' }}
              minW={{ base: '1xl', lg: '5xl' }}
              mx='auto'
              px={{ base: '4', md: '8', lg: '12' }}
              py={{ base: '6', md: '8', lg: '12' }}>
                <Stack direction={{ base: 'row', lg: 'row' }} align='flex-start'>
                <Flex direction='column' align='center' flex='1' _dark={{ bg: 'gray.900' }}>
                  <Image
                    mb='30px'
                    src={mentor.image}
                    alt={mentor.name}
                    fallbackSrc='https://via.placeholder.com/250'
                  />
                </Flex>
                </Stack>
                <Stack>
                <Box 
                  maxHeight="300px" // Set the maximum height for the chatbox 
                  overflowY="scroll" // Make the box scrollable 
                  border="1px" 
                  borderColor="gray.200" // Add a border for better visibility 
                  p={4}
                  bg='gray.100'
                >
                  {mentorChatMessage.length > 0 && (<>
                <List> {mentorChatMessage.map((message, index) => ( 
                  <ListItem 
                  key={index} 
                  sx={{ backgroundColor: index % 2 === 0 ? 'gray.100' : 'cyan.100' }}
                  whiteSpace="pre-wrap">
                    <Box >
                    <Text mb='2' ml='2' mr='2'><strong>You: {message["you"]} </strong></Text>
                    <Text mb='2' ml='2' mr='2'><strong>{mentor.name}: </strong>{message["mentor"]}</Text>
                    </Box>
                  </ListItem> 
                  ))} 
                </List>
                <div ref={messagesEndRef}></div>
                </>)}
                {mentorChatMessage.length === 0 && (<>
                  <Text mb='2' ml='2' mr='2'><strong>{mentor.name}: </strong>How can I help you?</Text>
                  <div ref={messagesEndRef}></div>
                </>)}
                </Box>
                </Stack>
                {userInfo && (

                      <HStack flex='1.5' mb='20px' mt='20px'>
                        <Input
                          value={content}
                          onChange={(e) => {
                            setContent(e.target.value);
                          }}
                          placeholder={`Dear ${mentor.name}, I want to ask ...`}
                          onKeyDown={handleKeyDown}
                        />
                        <Button
                          isLoading={loading}
                          loadingText='Sending'
                          w='140px'
                          colorScheme='darkCerulean'
                          onClick={() => onSubmit()}>
                            Send
                        </Button>
                      </HStack>

                )}
              </Box>
          )
        }
      </Wrap>
    );
  };
  
  export default MentorChatScreen;