import {
    Tr,
    Td,
    Button,
    VStack,
    Textarea,
    Tooltip,
    Input,
    FormControl,
    Text,
    FormLabel,
    Switch,
    Badge,
    Spacer,
    Flex,
    Select,
} from '@chakra-ui/react';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { uploadAccounting } from '../redux/actions/adminActions';

const AddNewAccounting = (id) => {
    const dispatch = useDispatch();
    const today = new Date();

    const [isCharge, setIsCharge ] = useState(false);
    const [isPay, setIsPay ] = useState(true);
    const [amount, setAmount ] = useState(0);
    const [placeDate, setPlaceDate ] = useState(today);
    const [description, setDescription ] = useState('');
    const [category, setCategory ] = useState('AnnualFeeCredit');
    const [paymentMethod, setPaymentMethod ] = useState('Club');
    const [stripeId, setStripeId ] = useState('');
    const [referenceId, setReferenceId ] = useState('');

    const handleCategoryChange = (event) => {
        setCategory(event.target.value);
      };

    const handlePaymentMethodChange = (event) => {
        setPaymentMethod(event.target.value);
      };

    const createNewAccounting = () => {
        dispatch(
            uploadAccounting({
                userId : id.id, 
                isCharge, 
                isPay, 
                amount, 
                placeDate, 
                description, 
                category, 
                paymentMethod, 
                stripeId, 
                referenceId
            })
        );
    };

  return (
    <>
    <Tr>
        <Td> isCharge or isPay </Td>
        <Td>
            <Flex direction='column' gap='2'>
                <FormControl display='flex' alignItems='center'>
                    <FormLabel htmlFor='isChargeFlag' mb='0' fontSize='sm'>
                        Enable
                        <Badge rounded='full' px='1' mx='1' fontSize='0.8m' colorScheme='green'>
                            Charge
                        </Badge>
                        badge ?
                    </FormLabel>
                    <Switch id='isChargeFlag' onChange={() => setIsCharge(!isCharge)} isChecked={isCharge} />
                </FormControl>
                <FormControl display='flex' alignItems='center'>
                    <FormLabel htmlFor='isPayFlag' mb='0' fontSize='sm'>
                        Enable
                        <Badge rounded='full' px='1' mx='1' fontSize='0.8m' colorScheme='green'>
                            Pay
                        </Badge>
                        badge ?
                    </FormLabel>
                    <Switch id='isPayFlag' onChange={() => setIsPay(!isPay)} isChecked={isPay} />
                </FormControl>
            </Flex>
        </Td>
    </Tr>
    <Tr>
        <Td>Amount & Place Date</Td>
        <Td>
            <Flex direction='column' gap='2'>
                <Input size='sm' value={amount} onChange={(e) => setAmount(e.target.value)} />
                <Input size='sm' value={placeDate} onChange={(e) => setPlaceDate(e.target.value)} />
            </Flex>
        </Td>
    </Tr>
    <Tr>
        <Td>Description & Category</Td>
        <Td>
            <Flex direction='column' gap='2'>
                <Input size='sm' value={description} onChange={(e) => setDescription(e.target.value)} />
            <FormControl>
          <FormLabel htmlFor="category">Category:</FormLabel>
          <Select id="category" value={category} onChange={handleCategoryChange} required>
            <option value="AnnualFeeCharge">AnnualFeeCharge</option>
            <option value="AnnualFeeCredit">AnnualFeeCredit</option>
            <option value="Coupon">Coupon</option>
            <option value="EventCoupon">EventCoupon</option>
            <option value="ClassCoupon">ClassCoupon</option>
            <option value="PayEvent">PayEvent</option>
            <option value="PayClass">PayClass</option>
            <option value="Other">Other</option>
          </Select>
        </FormControl>
            </Flex>
        </Td>
    </Tr>
    <Tr>
        <Td>Payment Method</Td>
        <Td>
        <FormControl>
          <FormLabel htmlFor="paymentMethod">paymentMethod:</FormLabel>
          <Select id="paymentMethod" value={paymentMethod} onChange={handlePaymentMethodChange} required>
            <option value="Cash">Cash</option>
            <option value="Stripe">Stripe</option>
            <option value="Club">Club</option>
            <option value="Other">Other</option>
          </Select>
        </FormControl>
        </Td>
    </Tr>
    <Tr>
        <Td>Stripe ID & Reference ID</Td>
        <Td>
            <Flex direction='column' gap='2'>
                <Input size='sm' value={stripeId} onChange={(e) => setStripeId(e.target.value)} />
                <Input size='sm' value={referenceId} onChange={(e) => setReferenceId(e.target.value)} />
            </Flex>
        </Td>
    </Tr>
    <Tr>
        <Td>
            <VStack>
                <Button variant='outline' w='160px' colorScheme='cyan' onClick={createNewAccounting} >
                    <Text ml='2'>Save Accounting</Text>
                </Button>
            </VStack>
        </Td>
    </Tr>
    </>
  );
};

export default AddNewAccounting;