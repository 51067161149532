import { Box, Flex, IconButton, useDisclosure, HStack, Text, Stack, Button } from "@chakra-ui/react"; 
import { HamburgerIcon, CloseIcon } from "@chakra-ui/icons";
import { useDispatch, useSelector } from 'react-redux';
import { setSelectedPrograms } from "../redux/slices/program";

const ProgramHeader = () => {

    const { isOpen, onToggle } = useDisclosure(); 
    const dispatch = useDispatch();
    const { programs} = useSelector((state) => state.program);
    const Links = [
        { name: 'All', route: 'all' },
        { name: 'Quant Trading', route: 'quant trading' },
        { name: 'Machine Learning', route: 'machine learning' },
        { name: 'Financial Market', route: 'financial market' },
        { name: 'Computer Science', route: 'computer science' },
        { name: 'Club Work', route: 'club work' },
    ];

    const selectPrograms = (term) => {
        console.log(term);
        if(term === '' || term === 'all') {
            // setSelectTerm('all');
            dispatch(setSelectedPrograms(programs));
        } else {
            // search and select programs with term
            const filteredPrograms = programs.filter(
                program => program.subtitle && program.subtitle.toLowerCase().includes(term.toLowerCase()) 
            );
            dispatch(setSelectedPrograms(filteredPrograms));
            console.log(filteredPrograms.length);
        }
        
    };

    return ( 
    <Box bg="cyan.100" px={4}> 
    <Flex h={16} alignItems="center" justifyContent="space-between"> 
        <Box fontWeight="bold">Program List</Box> 
        <HStack as='nav' spacing='4' display={{ base: 'none', md: 'flex'}}>
        {Links.map((link) => (
            <Button key={link.route} variant='outline' onClick={ () => selectPrograms(link.route)}>
            <Text fontWeight='small' >{link.name}</Text>
            </Button>
        ))}
        </HStack>
        <IconButton size="md" icon={isOpen ? <CloseIcon /> : <HamburgerIcon />} aria-label="Toggle Navigation" display={{ md: "none" }} onClick={onToggle} /> 
    </Flex> 
    <Box display='flex'>
        {isOpen && (
        <Box pb='4' display={{ md: 'none' }}>
            <Stack as='nav' spacing='4'>
                {Links.map((link) => (
                    <Button key={link.route} variant='outline' onClick={ () => selectPrograms(link.route)}>
                    <Text fontWeight='small' >{link.name}</Text>
                    </Button>
                ))}
            </Stack>
        </Box>
        )}
    </Box>
    </Box>
    );
};

export default ProgramHeader;