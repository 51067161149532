import axios from 'axios';
import { setError, setLoading, setShippingCosts, cartItemAdd, cartItemRemoval, clearCart, setCouponSet } from '../slices/cart';

// addCartItemCoupon
export const addCartItemCoupon = (id, category, price)  => async (dispatch, getState) => {
    dispatch(setLoading(true));
    const today = new Date();
    const nextYear = today.getFullYear() + 1;
    let calculatePrice = price;
    // this commented lines are for test mode
    // const stripeId_100USD = 'price_1QDRnK2NH4weRJULLkTqKtnh';
    // const stripeId_50USD = 'price_1QDRoa2NH4weRJULmf6wgKcV';
    // const stripeId_10USD = 'price_1QDRoy2NH4weRJULbubu6osM';
    const stripeId_100USD = 'price_1QRfRl2NH4weRJULyE4YH0IZ';
    const stripeId_50USD = 'price_1QRfSP2NH4weRJUL8L0OFm6O';
    const stripeId_10USD = 'price_1QRfSs2NH4weRJUL5JmNexRa';
    const stripeId_1USD = 'price_1QRg6B2NH4weRJUL9UsBelg6';
    console.log(calculatePrice);
    const qty_100 = Math.floor(calculatePrice / 100);
    calculatePrice = calculatePrice % 100;
    const qty_50 = Math.floor(calculatePrice / 50);
    calculatePrice = calculatePrice % 50;
    const qty_10 = Math.floor(calculatePrice / 10);
    calculatePrice = calculatePrice % 10;
    const qty_1 = (calculatePrice % 1 > 0) ? Math.floor((calculatePrice + 1) / 1) : Math.floor(calculatePrice / 1);
    console.log(qty_100);
    console.log(qty_50);
    console.log(qty_10);
    console.log(qty_1);
    try {
        if(qty_100 > 0 ) {
            const itemToAdd = {
                id: id+"USD100",
                name: 'Coupon to pay',
                category: category,
                emails: [],
                price: 100,
                qty: qty_100,
                stripeId: stripeId_100USD,
            };
            dispatch(cartItemAdd(itemToAdd));
        }
        if(qty_50 > 0 ) {
            const itemToAdd = {
                id: id+"USD50",
                name: 'Coupon to pay',
                category: category,
                emails: [],
                price: 50,
                qty: qty_50,
                stripeId: stripeId_50USD,
            };
            dispatch(cartItemAdd(itemToAdd));
        }
        if(qty_10 > 0 ) {
            const itemToAdd = {
                id: id+"USD10",
                name: 'Coupon to pay',
                category: category,
                emails: [],
                price: 10,
                qty: qty_10,
                stripeId: stripeId_10USD,
            };
            dispatch(cartItemAdd(itemToAdd));
        }
        if(qty_1 > 0 ) {
            const itemToAdd = {
                id: id+"USD1",
                name: 'Coupon to pay',
                category: category,
                emails: [],
                price: 1,
                qty: qty_1,
                stripeId: stripeId_1USD,
            };
            dispatch(cartItemAdd(itemToAdd));
        }
    } catch (error) {
        dispatch(
            setError(
                error.response && error.response.data.message 
                    ? error.response.data.message 
                    : error.message 
                    ? error.message : 'Unexpected error has occured. Please try again later.'
            )
        );
    }
};

// addCartItemAnnualFee
export const addCartItemAnnualFee = (id, category, price)  => async (dispatch, getState) => {
    dispatch(setLoading(true));
    const today = new Date();
    const nextYear = today.getFullYear() + 1;
    let calculatePrice = price;
    // this commented lines are for test mode
    // const stripeId_100USD = 'price_1QDRnK2NH4weRJULLkTqKtnh';
    // const stripeId_50USD = 'price_1QDRoa2NH4weRJULmf6wgKcV';
    // const stripeId_10USD = 'price_1QDRoy2NH4weRJULbubu6osM';
    const stripeId_100USD = 'price_1QRfRl2NH4weRJULyE4YH0IZ';
    const stripeId_50USD = 'price_1QRfSP2NH4weRJUL8L0OFm6O';
    const stripeId_10USD = 'price_1QRfSs2NH4weRJUL5JmNexRa';
    const stripeId_1USD = 'price_1QRg6B2NH4weRJUL9UsBelg6';
    console.log(calculatePrice);
    const qty_100 = Math.floor(calculatePrice / 100);
    calculatePrice = calculatePrice % 100;
    const qty_50 = Math.floor(calculatePrice / 50);
    calculatePrice = calculatePrice % 50;
    const qty_10 = Math.floor(calculatePrice / 10);
    calculatePrice = calculatePrice % 10;
    const qty_1 = (calculatePrice % 1 > 0) ? Math.floor((calculatePrice + 1) / 1) : Math.floor(calculatePrice / 1);
    console.log(qty_100);
    console.log(qty_50);
    console.log(qty_10);
    console.log(qty_1);
    try {
        if(qty_100 > 0 ) {
            const itemToAdd = {
                id: id+"USD100",
                name: `Platinum ${nextYear}`,
                category: category,
                emails: [],
                price: 100,
                qty: qty_100,
                stripeId: stripeId_100USD,
            };
            dispatch(cartItemAdd(itemToAdd));
        }
        if(qty_50 > 0 ) {
            const itemToAdd = {
                id: id+"USD50",
                name: `Platinum ${nextYear}`,
                category: category,
                emails: [],
                price: 50,
                qty: qty_50,
                stripeId: stripeId_50USD,
            };
            dispatch(cartItemAdd(itemToAdd));
        }
        if(qty_10 > 0 ) {
            const itemToAdd = {
                id: id+"USD10",
                name: `Platinum ${nextYear}`,
                category: category,
                emails: [],
                price: 10,
                qty: qty_10,
                stripeId: stripeId_10USD,
            };
            dispatch(cartItemAdd(itemToAdd));
        }
        if(qty_1 > 0 ) {
            const itemToAdd = {
                id: id+"USD1",
                name: `Platinum ${nextYear}`,
                category: category,
                emails: [],
                price: 1,
                qty: qty_1,
                stripeId: stripeId_1USD,
            };
            dispatch(cartItemAdd(itemToAdd));
        }
    } catch (error) {
        dispatch(
            setError(
                error.response && error.response.data.message 
                    ? error.response.data.message 
                    : error.message 
                    ? error.message : 'Unexpected error has occured. Please try again later.'
            )
        );
    }
};


// cart Item should have : category (string): FormalClass, AuditClass, Certificate, Event, Mentor, MembershipLevel2
// targetId (string): Object ID
// managerEmails: [emails]
// name: Object name

export const addCartItemGeneral = (id, category, emails, name, price, stripeId) => async (dispatch, getState) => {
    dispatch(setLoading(true));
    let qty = 1;
    try {
        // const { data } = await axios.get(`/api/products/${id}`);
        const itemToAdd = {
            id: id,
            name: name,
            category: category,
            emails: emails,
            price: price,
            qty: qty,
            stripeId: stripeId,
        };
        dispatch(cartItemAdd(itemToAdd));
    } catch (error) {
        dispatch(
            setError(
                error.response && error.response.data.message 
                    ? error.response.data.message 
                    : error.message 
                    ? error.message : 'Unexpected error has occured. Please try again later.'
            )
        );
    }
};

export const addCartItem = (id, qty) => async (dispatch, getState) => {
    dispatch(setLoading(true));
    try {
        const { data } = await axios.get(`/api/products/${id}`);
        const itemToAdd = {
            id: data._id,
            name: data.name,
            subtitle: data.subtitle,
            image: data.images[0],
            price: data.price,
            stock: data.stock,
            brand: data.brand,
            qty: qty,
            stripeId: data.stripeId,
        };

        dispatch(cartItemAdd(itemToAdd));
    } catch (error) {
        dispatch(
            setError(
                error.response && error.response.data.message 
                    ? error.response.data.message 
                    : error.message 
                    ? error.message : 'Unexpected error has occured. Please try again later.'
            )
        );
    }
};

export const removeCartItem = (id) => async (dispatch) => {
    dispatch(setLoading(true));
    dispatch(cartItemRemoval(id));
};

export const tryCoupon = (couponBalance) => async (dispatch) => {
    dispatch(setLoading(true));
    dispatch(setCouponSet(couponBalance));
};

export const setShipping = (value) => async (dispatch) => {
    dispatch(setShippingCosts(value));
};

export const resetCart = () => (dispatch) => {
    dispatch(clearCart());
};