import { Text, Stack, Box, Button, Input } from '@chakra-ui/react';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { sendResetEmail } from '../redux/actions/userActions';
//addition
/* import { useEffect } from 'react'; */

const PasswordForgottenForm = () => {
    const dispatch = useDispatch();
    const [email, setEmail] = useState('');
    const handleChange= (event) => {
        setEmail(event.target.value);
    }
    //addition
    const [clientTitle, setClientTitle] = useState('Enter your email address below.');
    const [clientMsg, setClientMsg] = useState('We will send you an email with a link to reset your password.');

    /* useEffect(() => {
        setClientTitle('Email sent');
        setClientMsg('We have already sent the link to your email. Please go to your email to check');
    }, [dispatch]); */

  return (
    <>
        <Box my='4'>
            <Text as='b' color='red'>{clientTitle}</Text>
            <Text>{clientMsg}</Text>
        </Box>
        <Stack>
            <Input
                mb='4'
                type='text'
                name='email'
                placeholder='Your Email Address'
                label='Email'
                value={email}
                onChange={(e) => handleChange(e)}
            />
            <Button colorScheme='yellow' size='lg' fontSize='md' onClick={() => dispatch(sendResetEmail(email))}>
                Send Reset Email
            </Button>
        </Stack>
    </>
  )
}

export default PasswordForgottenForm