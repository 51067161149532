import { createSlice } from '@reduxjs/toolkit';

export const initialState = {
    loading: false,
    error: null,
    jobs: [],
    job: null,
    applied: false,
    jobUpdate: false,

};

export const jobsSlice = createSlice({
    name: 'jobs',
    initialState,
    reducers: {
        setLoading: (state) => {
            state.loading = true;
        },
        setJobs: (state, { payload }) => {
            state.loading = false;
            state.error = null;
            state.jobs = payload;
        },
        setJob: (state, { payload }) => {
            state.loading = false;
            state.error = null;
            state.job = payload;
            state.applied = false;
        },
        setError: (state, { payload }) => {
            state.loading = false;
            state.error = payload;
        },
        jobApplied: (state, {payload}) => {
            state.loading = false;
            state.error = null;
            state.applied = payload;
        },
        resetError: (state) => {
            state.error = null;
            state.applied = false;
            state.jobUpdate = false;
        },
        setJobUpdateFlag: (state) => {
            state.jobUpdate = true;
            state.loading = false;
        },
    },
});

export const {
    setLoading, 
    setError, 
    setJobs, 
    setJob,
    jobApplied,
    setJobUpdateFlag,
    resetError,
} = jobsSlice.actions;

export default jobsSlice.reducer;

export const jobSelector = (state) => state.jobs;

