import  {
    setLoading,
    setEvents,
    setEvent,
    setError, 
    resetError,
} from '../slices/event';

import axios from 'axios';

export const getEvents = () => async (dispatch) => {
    dispatch(setLoading(true));
    try {
        const { data } = await axios.get('/api/events');
        dispatch(setEvents(data));
    } catch (error) {
        dispatch(
            setError(
                error.response && error.response.data.message 
                    ? error.response.data.message 
                    : error.message 
                    ? error.message : 'Unexpected error has occured. Please try again later.'
            )
        );
    }
};

export const getEvent = (id) => async (dispatch) => {
    dispatch(setLoading(true));
    try {
        const { data } = await axios.get(`/api/events/${id}`);
        dispatch(setEvent(data));
    } catch (error) {
        dispatch(
            setError(
                error.response && error.response.data.message 
                    ? error.response.data.message 
                    : error.message 
                    ? error.message : 'Unexpected error has occured. Please try again later.'
            )
        );
    }

};

export const resetEventError = () => async (dispatch) => {
    dispatch(resetError());
};
