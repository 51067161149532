import { ChakraProvider, extendTheme, theme as defaultTheme } from '@chakra-ui/react';
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import Header from './components/Header';
import LandingScreen from './screens/LandingScreen';
import CartScreen from './screens/CartScreen';
import LoginScreen from './screens/LoginScreen';
import Footer from './components/Footer';
import EmailVerificationScreen from './screens/EmailVerificationScreen';
import PasswordResetScreen from './screens/PasswordResetScreen';
import RegistrationScreen from './screens/RegistrationScreen';
import axios from 'axios';
import { VStack, Spinner } from '@chakra-ui/react';
import { useState, useEffect } from 'react';
import { GoogleOAuthProvider } from '@react-oauth/google';
import CancelScreen from './screens/CancelScreen';
import SuccessScreen from './screens/SuccessScreen';
import AdminConsoleScreen from './screens/AdminConsoleScreen';
import ChatScreen from './screens/ChatScreen';
import ServicesScreen from './screens/ServicesScreen';
import MentorConsoleScreen from './screens/MentorConsoleScreen';
import MentorsScreen from './screens/MentorsScreen';
import MentorScreen from './screens/MentorScreen';
import JobScreen from './screens/JobScreen';
import JobsScreen from './screens/JobsScreen';
import EventsScreen from './screens/EventsScreen';
import EventScreen from './screens/EventScreen';
import ProgramsScreen from './screens/ProgramsScreen';
import ProgramScreen from './screens/ProgramScreen';
import GroupClassScreen from './screens/GroupClassScreen';
import CertificateScreen from './screens/CertificateScreen';
import AccountingsScreen from './screens/AccountingsScreen';
import YourAccountingScreen from './screens/YourAccountingScreen';
import YourProfileScreen from './screens/YourProfileScreen';
import YourCheckoutScreen from './screens/YourCheckOutScreen';
import YourRecordsScreen from './screens/YourRecordsScreen';
import ProgramAdminConsoleScreen from './screens/ProgramAdminConsoleScreen';
import EventAdminConsoleScreen from './screens/EventAdminConsoleScreen';
import JobAdminConsoleScreen from './screens/JobAdminConsoleScreen';
import ChangeMembershipScreen from './screens/ChangeMembershipScreen';
import UserIssuesScreen from './screens/UserIssuesScreen';
import MentorChatScreen from './screens/MentorChatScreen';
import AccountingSummary from './screens/AccountingSummary';
import OneUserOrdersScreen from './screens/OneUserOrdersScreen';

function App() {


  const theme = extendTheme({
    colors: {
      ...defaultTheme.colors,
      darkCerulean: {
        100: "#7aa9c3",  // Even lighter version
        200: "#4a7f9a",  // Lighter version
        500: "#13546e",  // Base version (original Dark Cerulean)
        900: "#0b3547",  // Darker version
      },
    },
    styles: {
			global: (props) => ({
				body: {
					bg: props.colorMode === 'light' && '#F7FAFC',
          // bg: props.colorMode === 'dark' && 'cyan.900',
          // bg: props.colorMode === 'dark' && 'darkCerulean.900',
				},
			}),
		},
  });

  const [googleClient, setGoogleClient] = useState(null);
  useEffect(() =>{
    const googleKey = async () => {
      const {data : googleId } = await axios.get('/api/config/google');
      setGoogleClient(googleId);
    }
    googleKey();
  }, [googleClient]);

  return (
    <ChakraProvider theme={theme}>
    {!googleClient ? ( 
    <VStack pt='37vh'>
      <Spinner mt='20' thickness='2px' speed='0.65s' emptyColor='gray.200' color='cyan.500' size='xl' />
    </VStack>
  ) : (
    <GoogleOAuthProvider clientId={googleClient}>
    
      <Router>
        <Header />
        <main>
          <Routes>
            <Route path='/' element={<LandingScreen />} />
            <Route path='/cart' element={<CartScreen />} />
            <Route path='/login' element={<LoginScreen />} />
            <Route path='/email-verify/:token' element={<EmailVerificationScreen />} />
            <Route path='/password-reset/:token' element={<PasswordResetScreen />} />
            <Route path='/registration' element={<RegistrationScreen />} />
            <Route path='/checkout' element={<YourCheckoutScreen />} />
            <Route path='/cancel' element={<CancelScreen />} />
            <Route path='record-history' element={<YourRecordsScreen />} />
            <Route path='/success/:id/:confirmid' element={<SuccessScreen />} />
            <Route path='/admin-console' element={<AdminConsoleScreen />} />
            <Route path='/admin-user-issues' element={<UserIssuesScreen />} />
            <Route path='/chat/:queryid' element={<ChatScreen />} />
            <Route path='/services' element={<ServicesScreen />} />
            <Route path='/mentor-console' element={<MentorConsoleScreen />} />
            <Route path='/mentors' element={<MentorsScreen />} />
            <Route path='/mentor/:id' element={<MentorScreen />} />
            <Route path='/mentorchat/:id' element={<MentorChatScreen />} />
            <Route path='/jobs' element={<JobsScreen />} />
            <Route path='/job/:id' element={<JobScreen />} />
            <Route path='/events' element={<EventsScreen />} />
            <Route path='/event/:id' element={<EventScreen />} />
            <Route path='/programs' element={<ProgramsScreen />} />
            <Route path='/program/:id' element={<ProgramScreen />} />
            <Route path='/certificate/:id' element={<CertificateScreen />} />
            <Route path='/groupclass/:id' element={<GroupClassScreen />} />
            <Route path='/accountings/:id' element={<AccountingsScreen />} />
            <Route path='/membership/:level/:id' element={<ChangeMembershipScreen />} />
            <Route path='accounting' element={<YourAccountingScreen />} />
            <Route path='profile' element={<YourProfileScreen />} />
            <Route path='/programadmin-console' element={<ProgramAdminConsoleScreen />} />
            <Route path='/eventadmin-console' element={<EventAdminConsoleScreen />} />
            <Route path='/jobadmin-console' element={<JobAdminConsoleScreen />} />
            <Route path='/admin-accounting-sum' element={<AccountingSummary />} />
            <Route path='/one-user-orders/:id' element={<OneUserOrdersScreen />} />
          </Routes>
        </main>
        <Footer />
      </Router>
    
    </GoogleOAuthProvider>
  )}
  </ChakraProvider>
  );
}

export default App;
