import { Box, Image, Text, Badge, Flex, IconButton, Skeleton, useToast, Tooltip } from '@chakra-ui/react';
import { BiExpand } from 'react-icons/bi';

import React from 'react';
import { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { BiChat } from 'react-icons/bi';
import { Link as ReactLink } from 'react-router-dom';
import { addCartItemGeneral } from '../redux/actions/cartActions';
import { useEffect } from 'react';
import { TbShoppingCartPlus } from 'react-icons/tb';

const MentorCard = ({ mentor, loading }) => {

    const dispatch = useDispatch();
    /* const [ isShown, setIsShown ] = useState(false); */
    const { cartItems } = useSelector((state) => state.cart);
    const toast = useToast();
    const [ cartPlusDisabled, setCartPlusDisabled ] = useState(false);
    const { userInfo } = useSelector((state) => state.user);

    useEffect(() => {
        const item = cartItems.find((cartItem) => cartItem.id === mentor._id);
        if(item && item.qty > 0) {
            setCartPlusDisabled(true);
        }
        if(!mentor.isAvailable) {
            setCartPlusDisabled(true);
        }
    }, [mentor, cartItems]);

    const addItem = (id) => {
        dispatch(addCartItemGeneral(id, 'Mentor', [mentor.email], mentor.name, mentor.fee, mentor.stripeId));
        /* if(cartItems.some((cartItem) => cartItem.id === id)) {
          const item = cartItems.find((cartItem) => cartItem.id === id);
          dispatch(addCartItem(id, item.qty + 1));

        } else {
          dispatch(addCartItem(id, 1));
        } */
        toast({
          description: 'You added this mentor session to your shopping cart.',
          status: 'success',
          isClosable: true,
        });
      };

  return (
    <Skeleton isLoaded={!loading}>
        <Box
            _hover={{transform: 'scale(1.1)', transitionDuration: '0.5s' }}
            borderWidth='1px'
            overflow='hidden'
            p='4'
            shadow='md'>
            <Image 
                src={mentor.image}
                fallbackSrc='https://via.placeholder.com/150'
                alt={mentor.name}
                height='200px'
            />
            {!mentor.isAvailable ? (
                    <Badge colorScheme='gray'> {mentor.busyReason}</Badge>
            ) : <Badge colorScheme='green'>Available </Badge>}
            <Text noOfLines = {1} fontSize='xl' fontWeight='semibold' mt='2'>
                {mentor.name} 
            </Text>
            <Text noOfLines = {1} fontSize='md' fontWeight='semibold' mt='2'>
                {mentor.title} 
            </Text>
            <Text noOfLines = {1} fontSize='md' color='gray.600'>
                {mentor.industry}
            </Text>
            <Flex justify='space-between' alignItems='center' mt='2'>
                <Text fontSize='sm' fontWeight='semibold' color='darkCerulean.200'>
                    Has helped
                </Text>
                <Badge colorScheme='darkCerulean'> 1{mentor.mentees.length} </Badge>
                <Text fontSize='sm' fontWeight='semibold' color='darkCerulean.200'>
                    mentees.
                </Text>

            </Flex>
            <Flex justify='space-between' mt='2'>
                <Tooltip
                    isDisabled={mentor.twobrain && mentor.twobrain !==''}
                    hasArrow
                    label='Not Available'>
                    <IconButton
                        isDisabled={!(mentor.twobrain && mentor.twobrain !=='')}
                        icon={<BiChat size='20px' />}
                        colorScheme='darkCerulean'
                        size='sm'
                        as={ReactLink}
                        to={`/mentorchat/${mentor._id}`}
                    />
                </Tooltip>
                <IconButton 
                icon={<BiExpand size='20'/>} 
                as={ReactLink}
                to={`/mentor/${mentor._id}`}
                colorScheme='darkCerulean' size='sm' />
                <Tooltip
                    isDisabled={!cartPlusDisabled}
                    hasArrow
                    label='Not Available'>
                    <IconButton
                        isDisabled = {cartPlusDisabled}
                        onClick={() => addItem(mentor._id)}
                        icon={<TbShoppingCartPlus size='20' />}
                        colorScheme='darkCerulean'
                        size='sm'
                    />
                    </Tooltip>
            </Flex>
            </Box>
    </Skeleton>
  );
};

export default MentorCard;