import { 
    setLoading, 
    setError, 
    setPrograms, 
    setSelectedPrograms,
    setProgram,
    programSubscribed,
    setProgramUpdateFlag,
    resetError,
    setSubscribeRemovalFlag
} from '../slices/program';
import axios from 'axios';

export const getPrograms = () => async (dispatch) => {
    dispatch(setLoading());
    try {
        const { data } = await axios.get(`/api/programs/`);
        dispatch(setPrograms(data));
    } catch (error) {
        dispatch(
            setError(
                error.response && error.response.data.message 
                    ? error.response.data.message 
                    : error.message 
                    ? error.message : 'Unexpected error has occured. Please try again later.'
            )
        );
    }
};

export const getProgram = (id) => async (dispatch) => {
    dispatch(setLoading(true));
    try {
        const { data } = await axios.get(`/api/programs/${id}`);
        dispatch(setProgram(data));
    } catch (error) {
        dispatch(
            setError(
                error.response && error.response.data.message 
                    ? error.response.data.message 
                    : error.message 
                    ? error.message : 'Unexpected error has occured. Please try again later.'
            )
        );
    }

};

export const createProgramSubscriber = (programId, userId, noteToManager) => async (dispatch, getState) => {
    const {
        user: {userInfo },
    } = getState();
    try {
        const config = { headers: { Authorization: `Bearer ${userInfo.token}`, 'Content-Type': 'application/json' }};
        await axios.post(`/api/programs/subscribers/${programId}`, { noteToManager, userId }, config);
        dispatch(programSubscribed(true));
    } catch (error) {
        dispatch(
            setError(
                error.response && error.response.data.message 
                    ? error.response.data.message 
                    : error.message 
                    ? error.message : 'Unexpected error has occured. Please try again later.'
            )
        );
    }
}

export const resetProgramError = () => async (dispatch) => {
    dispatch(resetError());
};