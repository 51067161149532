import {
    Tr,
    Td,
    Button,
    VStack,
    Textarea,
    Tooltip,
    Input,
    FormControl,
    Text,
    FormLabel,
    Switch,
    Badge,
    Spacer,
    Flex,
} from '@chakra-ui/react';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { uploadCertificate } from '../redux/actions/adminActions';

const AddNewCertificate = () => {
    const dispatch = useDispatch();
    const today = new Date();
    const [name, setName ] = useState('');
    const [category, setCategory ] = useState('');
    const [description, setDescription ] = useState('');
    const [expireDateTime, setExpireDateTime ] = useState(new Date(today.setMonth(today.getMonth() + 120)));
    const [managerUserId, setManagerUserId ] = useState('');
    const [examFee, setExamFee ] = useState(0);
    const [examFeeStripeId, setExamFeeStripeId ] = useState('');

    const createNewCertificate = () => {
        dispatch(
            uploadCertificate({
                name,
                category,
                description,
                expireDateTime,
                managerUserId,
                examFee,
                examFeeStripeId,
            })
        );
    };

  return (
    <>
    <Tr>
        <Td><Flex direction='row' gap='2'>
            <Text fontSize='sm'>Description</Text>
            <Textarea
                value={description}
                w='80vh'
                h='120px'
                onChange={(e) => setDescription(e.target.value)}
                placeholder='Certificate Description'
                size='sm'
            />
            </Flex>
        </Td>
        </Tr><Tr>
        <Flex direction='row' gap='2'>
        <Td>Name & Category</Td>
        <Td>
            <Flex direction='column' gap='2'>
                <Input size='sm' value={name} onChange={(e) => setName(e.target.value)} placeholder='Certificate Name'/>
                <Input size='sm' value={category} onChange={(e) => setCategory(e.target.value)} placeholder='Quantitative Finance, Machine Learning, etc'/>
            </Flex>
        </Td>
        </Flex>
    </Tr><Tr><Flex direction='row' gap='2'>
        <Td>ManagerUserId & ExpireDateTime</Td>
        <Td>
            <Flex direction='column' gap='2'>
                <Input w='400px' size='sm' value={managerUserId} onChange={(e) => setManagerUserId(e.target.value)} placeholder='manager User ID'/>
                <Input w='400px' size='sm' value={expireDateTime} onChange={(e) => setExpireDateTime(e.target.value)} placeholder='expire date time'/>
            </Flex>
        </Td></Flex>
    </Tr><Tr><Flex direction='row' gap='2'>
        <Td>Exam Fee & Exam Fee Stripe Id</Td>
        <Td>
            <Flex direction='column' gap='2'>
                <Input w='300px' size='sm' value={examFee} onChange={(e) => setExamFee(e.target.value)} placeholder='300'/>
                <Input w='300px' size='sm' value={examFeeStripeId} onChange={(e) => setExamFeeStripeId(e.target.value)} placeholder='price_...'/>
            </Flex>
        </Td></Flex>
    </Tr>
    <Tr>
        <Td>
            <VStack>
                <Button variant='outline' w='160px' colorScheme='cyan' onClick={createNewCertificate} >
                    <Text ml='2'>Save Certificate</Text>
                </Button>
            </VStack>
        </Td>
    </Tr>
    </>
  );
};

export default AddNewCertificate;