import { Text, Alert, AlertTitle, AlertIcon, AlertDescription, Box, Wrap, WrapItem, Center } from '@chakra-ui/react';
import MentorCard from '../components/MentorCard';
import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getMentors } from '../redux/actions/mentorActions';


const MentorsScreen = () => {
    const dispatch = useDispatch();
    const { loading, error, mentors } = useSelector((state) => state.mentor);

    useEffect(() => {
        dispatch(getMentors());
    }, [dispatch]);

    return (
        <>
            {error ? (
                <Alert status='error'>
                    <AlertIcon />
                    <AlertTitle>We are sorry!</AlertTitle>
                    <AlertDescription>{error}</AlertDescription>
                </Alert>
            ) : (
                <>
                <Text fontSize='xl' fontWeight='bold'>
                                Meet Our Mentors
                            </Text>
                {mentors && mentors.length >= 1 && (
                <Box>
                    <Wrap spacing='30px' justify='center' minHeight='80vh' mx={{base: '12', md: '20', lg: '32' }}>
                            {mentors.map((mentor) => (
                                <WrapItem key={mentor._id}>
                                    <Center w='250px' h='450px'>
                                        <MentorCard mentor = {mentor} loading={loading} />
                                    </Center>
                                </WrapItem>
                            ))
                        }   
                    </Wrap>
                </Box>)}
                </>
            )}
        </>
    );
};

export default MentorsScreen;
