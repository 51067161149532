import { Box, Image, Text, Badge, Flex, IconButton, Skeleton, useToast, Tooltip } from '@chakra-ui/react';
import { BiExpand } from 'react-icons/bi';

import React from 'react';
import { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
// import { BiChat } from 'react-icons/bi';
import { LiaCertificateSolid } from 'react-icons/lia';
// import { MdNoteAdd } from "react-icons/md";
import { SiGoogleclassroom } from "react-icons/si";
import { Link as ReactLink } from 'react-router-dom';
import { addCartItem } from '../redux/actions/cartActions';
import { useEffect } from 'react';
// import { TbShoppingCartPlus } from 'react-icons/tb';
// import { getGroupClassByProgramId } from '../redux/actions/groupClassActions';

const ProgramCard = ({ program, mostRecentGroupClass, loading }) => {
    const today = new Date();

    const dispatch = useDispatch();
    // const { cartItems } = useSelector((state) => state.cart);
    const toast = useToast();
    // const [ cartPlusDisabled, setCartPlusDisabled ] = useState(false);
    const [ isGroupClassOpen, setIsGroupClassOpen ] = useState(false);
    const [ numberOfApplicants, setNumberOfApplicants ] = useState(0);
    const [ hasCertificate, setHasCertificate ] = useState(false);
    const [ certificateId, setCertificateId ] = useState('');
    const [ estStartDate, setEstStartDate ] = useState(today.toLocaleString('en-US', {
        timeZone: 'America/New_York',  // EST Timezone
        year: 'numeric',
        month: 'short',
        day: 'numeric'
    }));
    useEffect(() => {
        if(program) {
            if(program.certificateId && program.certificateId !== ''){
                setHasCertificate(true);
                setCertificateId(program.certificateId);
            }
            if(mostRecentGroupClass){
                if((new Date(mostRecentGroupClass.registrationDeadLine) - today >= 0 ) || (mostRecentGroupClass.allowAudit && ((today - new Date(mostRecentGroupClass.classStartDateTime)) <= (1000 * 60 * 60 * 24 * 3)))) {
                    setIsGroupClassOpen(true);
                    setNumberOfApplicants(mostRecentGroupClass.numberOfApplicants + mostRecentGroupClass.numberOfAuditApplicants);
                    setEstStartDate(new Date(mostRecentGroupClass.classStartDateTime).toLocaleString('en-US', {
                        timeZone: 'America/New_York',  // EST Timezone
                        year: 'numeric',
                        month: 'short',
                        day: 'numeric'
                    }));
                }
            }
        }
    }, [dispatch, program]);
    

    const addItem = (id) => {
        dispatch(addCartItem(id, 1));
        toast({
          description: 'You added this mentor session to your shopping cart.',
          status: 'success',
          isClosable: true,
        });
      };

  return (
    <Skeleton isLoaded={!loading}>
        <Box
            _hover={{transform: 'scale(1.1)', transitionDuration: '0.5s' }}
            borderWidth='1px'
            overflow='hidden'
            p='4'
            shadow='md'>
            <Image 
                src={program.image}
                fallbackSrc='https://via.placeholder.com/150'
                alt={program.title}
                height='200px'
            />
            {!isGroupClassOpen ? (
                    <Badge colorScheme='blue'> Open to Subscribe!</Badge>
            ) : <Badge colorScheme='green'>Open to apply!</Badge>}
            <Text noOfLines = {2} fontSize='xl' fontWeight='semibold' mt='2'>
                {program.title} 
            </Text>
            {!isGroupClassOpen ? (
                    <Flex justify='space-between' alignItems='center' mt='2'>
                    <Text fontSize='sm' fontWeight='semibold' color='darkCerulean.200'>{program.numberOfSubscribers} has subscribed for next class.</Text>
                </Flex>
            ) : (
                <>
                <Flex justify='space-between' alignItems='center' mt='2'>
                <Text fontSize='sm'>
                    Class will start at {estStartDate}.
                </Text>
                </Flex>
                <Flex justify='space-between' alignItems='center' mt='2'>
                    <Text fontSize='sm' fontWeight='semibold' color='darkCerulean.200'>
                    {numberOfApplicants} has applied current class.
                    </Text>
                </Flex>
                
            </>
            )}
            <Flex justify='space-between' alignItems='center' mt='2'>
                    <Text fontSize='sm' fontWeight='semibold' color='darkCerulean.200'>
                    {program.totalTakers} has taken in the past.
                    </Text>
    
                </Flex>
            <Flex justify='space-between' mt='2'>
                <Tooltip
                    hasArrow
                    label={hasCertificate ? 'This program offers Certificate!' : 'This program has no certificate yet'}>
                    <IconButton
                        isDisabled = {!hasCertificate}
                        as={ReactLink}
                        to={`/certificate/${certificateId}`}
                        icon={<LiaCertificateSolid size='20' />}
                        colorScheme='darkCerulean'
                        size='sm'
                    />
                </Tooltip>
                
                <Tooltip
                hasArrow
                label={isGroupClassOpen ? 'Look into program detail' : 'Look into program detail or subscribe'}>
                <IconButton 
                icon={<BiExpand size='20'/>} 
                as={ReactLink}
                to={`/program/${program._id}`}
                colorScheme='darkCerulean' size='sm' />
                </Tooltip>
                <Tooltip
                    hasArrow
                    label={isGroupClassOpen ? 'Apply now!' : 'No class open yet, will notice you if you subscribe'}>
                    <IconButton 
                    isDisabled = {!isGroupClassOpen}
                    icon={<SiGoogleclassroom size='20'/>} 
                    as={ReactLink}
                    to={`/groupclass/${mostRecentGroupClass._id}`}
                    colorScheme='darkCerulean' size='sm' />
                </Tooltip>  
            </Flex>
            </Box>
    </Skeleton> 
  );
};


export default ProgramCard;
