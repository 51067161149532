/* import { MinusIcon, SmallAddIcon } from '@chakra-ui/icons'; */
import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Badge,
  Box,
  Button,
  Flex,
  HStack,
  Heading,
  Image,
  SimpleGrid,
  Spinner,
  Stack,
  Text,
  Wrap,
  useToast,
  Textarea,
  Input,
  Tooltip,
  IconButton,
} from '@chakra-ui/react';
import { BiChat } from 'react-icons/bi';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Link as ReactLink } from 'react-router-dom';
import { getMentor } from '../redux/actions/mentorActions';
import { addCartItemGeneral } from '../redux/actions/cartActions';
import { useEffect, useState } from 'react';
import Star from '../components/Star';
import { createMentorReview } from '../redux/actions/mentorActions';

const MentorScreen = () => {
    /* const [amount, setAmount] = useState(1);  */
  const { id } = useParams();
  const dispatch = useDispatch();
  const { loading, error, mentor, reviewed } = useSelector((state) => state.mentor);
  /* const { cartItems } = useSelector((state) => state.cart); */
  const toast = useToast();
  const [comment, setComment] = useState('');
  const [rating, setRating] = useState(1);
  const [title, setTitle] = useState('');
  const [reviewBoxOpen, setReviewBoxOpen] = useState(false);
  const { userInfo } = useSelector((state) => state.user);
  const [buttonLoading, setButtonLoading] = useState(false);
  
  function getInitials(namestr) {
    return namestr
      .split(' ')
      .map(word => word.charAt(0).toUpperCase())
      .join('.')
      .concat('.');
  }

  useEffect(() => {
    dispatch(getMentor(id));
    setReviewBoxOpen(false);

    if(reviewed) {
      toast({
        description: 'Mentor review saved.',
        status: 'success',
        isClosable: 'true',
      });
      setReviewBoxOpen(false);
    }
  }, [dispatch, id, toast, reviewed]);


  const addItem = () => {
    dispatch(addCartItemGeneral(id, 'Mentor', [mentor.email], mentor.name, mentor.fee, mentor.stripeId));
    toast({
      description: 'You added this mentor session to your shopping cart.',
      status: 'success',
      isClosable: true,
    });
  };

  const hasUserReviewed = () => mentor.reviews.some((item) => item.userId.toString() === userInfo._id.toString());
  const isUserQualifiedReview = () => mentor.mentees.some((item) => (item.userId.toString() === userInfo._id.toString() && item.isPlaced ));

  const onSubmit = () => {
    setButtonLoading(true);
    dispatch(createMentorReview(mentor._id, userInfo._id, comment, rating, title));
  };

  return (
    <Wrap spacing='30px' justify='center' minHeight='100vh'>
      {loading ? (
        <Stack direction='row' spacing='4'>
          <Spinner mt='20' thickness='2px' speed='0.65s' emptyColor='gray.200' color='cyan.500' size='xl' />
        </Stack>
      ) : error ? (
        <Alert status='error'>
          <AlertIcon />
          <AlertTitle>We are sorry!</AlertTitle>
          <AlertDescription>{error}</AlertDescription>
        </Alert>
      ) : (
        mentor && (
          <Box
            maxW={{ base: '3xl', lg: '5xl' }}
            mx='auto'
            px={{ base: '4', md: '8', lg: '12' }}
            py={{ base: '6', md: '8', lg: '12' }}>
              <Stack direction={{ base: 'column', lg: 'row' }} align='flex-start'>
                <Stack pr={{ base:'0', md: 'row' }} flex='1.5' mb={{ base: '12', md: 'none' }}>
                { !mentor.isAvailable && (
                  <Badge rounded='full' w='200px' fontSize='0.8em' colorScheme='red'>
                  Not available, {mentor.busyReason}
                  </Badge>
                )}
                <Heading fontSize='2xl' fontWeight='extrabold'>
                  {mentor.name}
                </Heading>
                <Stack spacing='5'>
                  <Box>
                    <Text fontSize='xl'>${mentor.fee} for {mentor.minutes} minutes</Text>
                    <Flex>
                      <HStack spacing='2px'>
                        <Star />
                        <Star rating={mentor.rating} star={2}/>
                        <Star rating={mentor.rating} star={3}/>
                        <Star rating={mentor.rating} star={4}/>
                        <Star rating={mentor.rating} star={5}/>
                      </HStack>
                      <Text fontSize='md' fontWeight='bold' ml='4px'>
                        {mentor.numberOfReviews} Reviews
                      </Text>
                    </Flex>
                  </Box>
                  <Text fontSize='xl' >{mentor.title} In {mentor.industry}</Text>
                  <Text fontSize='md'>Has helped 1{mentor.mentees.length} mentees.</Text>
                  {mentor.twobrain && mentor.twobrain !=='' && (<Flex>
                    <IconButton
                        icon={<BiChat size='20px' />}
                        colorScheme='darkCerulean'
                        size='sm'
                        as={ReactLink}
                        to={`/mentorchat/${mentor._id}`}
                    />
                    <Text fontSize='md' ml='2' >Chat with Mentor {mentor.name} 24/7 !!!</Text>
                    </Flex>
                  )}
                  <Text>{mentor.introduction}</Text>
                  <Button variant='outline' isDisabled={!mentor.isAvailable} colorScheme='cyan' onClick={() => addItem()}>
                    Add this mentor session to cart
                  </Button>
                </Stack>
              </Stack>
              <Flex direction='column' align='center' flex='1' _dark={{ bg: 'gray.900' }}>
                <Image
                  mb='30px'
                  src={mentor.image}
                  alt={mentor.name}
                  fallbackSrc='https://via.placeholder.com/250'
                />
              </Flex>
              </Stack>

              {userInfo && isUserQualifiedReview() && (
                <>
                  <Tooltip label={hasUserReviewed() && 'You have already reviewed this product.'} fontSize='medium'>
                    <Button
                      isDisabled={hasUserReviewed()}
                      my='20px'
                      w='140px'
                      colorScheme='darkCerulean'
                      onClick={() => setReviewBoxOpen(!reviewBoxOpen)}>
                        Write a review
                      </Button>
                  </Tooltip>
                  {reviewBoxOpen && (
                    <Stack mb='20px'>
                      <Wrap>
                        <HStack spacing='2px'>
                          <Button variant='outline' onClick={() => setRating(1)}>
                            <Star />
                          </Button>
                          <Button variant='outline' onClick={() => setRating(2)}>
                            <Star rating={rating} star={2}/>
                          </Button>  
                          <Button variant='outline' onClick={() => setRating(3)}>
                            <Star rating={rating} star={3}/>
                          </Button>
                          <Button variant='outline' onClick={() => setRating(4)}>
                            <Star rating={rating} star={4}/>
                          </Button>
                          <Button variant='outline' onClick={() => setRating(5)}>
                            <Star rating={rating} star={5}/>
                          </Button>                                                                      
                        </HStack>
                      </Wrap>
                      <Input
                        onChange={(e) => {
                          setTitle(e.target.value);
                        }}
                        placeholder='Review title'
                      />
                      <Textarea
                        onChange={(e) => {
                          setComment(e.target.value);
                        }}
                        placeholder={`Mentor ${mentor.name} is...`}
                      />
                      <Button
                        isLoading={buttonLoading}
                        loadingText='Saving'
                        w='140px'
                        colorScheme='darkCerulean'
                        onClick={() => onSubmit()}>
                          Publish review
                      </Button>
                    </Stack>
                  )}
                </>
              )}
              <Stack>
                <Text fontSize='xl' fontWeight='bold'>
                  Reviews
                </Text>
                <SimpleGrid minChildWidth='300px' spacingX='40px' spacingY='20px'>
                  {mentor.reviews.map((review) => (
                    <Box key={review._id}>
                      <Flex spacing='2px' alignItems='center'>
                        <Star color='cyan.500' />
                        <Star rating={review.rating} star={2}/>
                        <Star rating={review.rating} star={3}/>
                        <Star rating={review.rating} star={4}/>
                        <Star rating={review.rating} star={5}/>
                        <Text fontWeight='semibold' ml='4px'>
                          {review.title && review.title}
                        </Text>
                      </Flex>
                      <Box py='12px'>{review.comment}</Box>
                      <Text fontSize='sm' color='gray.400'>
                        by {getInitials(review.name)} at {new Date(review.createdAt).toDateString()}
                      </Text>
                    </Box>
                  ))}
                </SimpleGrid>
              </Stack>
            </Box>
        )
      ) }
    </Wrap>
  );
};

export default MentorScreen;