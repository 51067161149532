import { combineReducers, configureStore } from '@reduxjs/toolkit';

import cart from './slices/cart';
import user from './slices/user';
import order from './slices/order';
import admin from './slices/admin';
import mentor from './slices/mentor';
import job from './slices/job';
import event from './slices/event';
import program from './slices/program';
import groupClass from './slices/groupClass';
import certificate from './slices/certificate';
import accounting from './slices/accounting';
import chat from './slices/chat';



const reducer = combineReducers({
    cart,
    user,
    order,
    admin,
    mentor,
    job,
    event,
    program,
    groupClass,
    certificate,
    accounting,
    chat,
});

export default configureStore({ reducer });
