import axios from 'axios';

import {
    setLoading,
    setError, 
    setUserRecords,
    setServerResponseMsg, 
    setServerResponseStatus, 
    userLogin, 
    userLogout, 
    stateReset, 
    verificationEmail,
    userUpdateSelf,
    setUserMentor,
    setReferId,
    setUserGroupClassTakenSetList,
    setUserJobTakenSetList,
} from '../slices/user';

import { clearCart } from '../slices/cart';


export const login = (email, password) => async (dispatch) => {
    dispatch(setLoading(true));
    try {
        // console.log( `${email} ${password} herehere` );
        const config = {headers: { 'Content-Type': 'application/json'}};

        const { data } = await axios.post('api/users/login', { email, password }, config);
        console.log(data);

        dispatch(userLogin(data));

        localStorage.setItem('userInfo', JSON.stringify(data));
        // get all classes taken
        const config2 = {headers: { Authorization: `Bearer ${data.token}`, 'Content-Type': 'application/json' }};
        const { data: data2 } = await axios.get(`/api/groupclasses/self/taken`, config2);
        dispatch(setUserGroupClassTakenSetList(data2.groupClassTakenSetList));
        const { data: data3 } = await axios.get(`/api/jobs/self/taken`, config2);
        dispatch(setUserJobTakenSetList(data3.jobTakenSetList));
    } catch (error) {
        dispatch(
            setError(
                error.response && error.response.data.message 
                    ? error.response.data.message 
                    : error.message 
                    ? error.message : 'Unexpected error has occured. Please try again later.'
            )
        );
    }
};

export const logout = () => (dispatch) => {
    localStorage.removeItem('userInfo');
    localStorage.removeItem('cartItems');
    localStorage.removeItem('couponCode');
    localStorage.removeItem('referId');
    dispatch(clearCart());
    dispatch(userLogout());
}


export const register = (name, email, password) => async (dispatch) => {
    dispatch(setLoading(true));
    try {
        const config = {headers: { 'Content-Type': 'application/json'}};

        const { data } = await axios.post('api/users/register', { name, email, password }, config);

        dispatch(userLogin(data));
        localStorage.setItem('userInfo', JSON.stringify(data));
    } catch (error) {
        dispatch(
            setError(
                error.response && error.response.data.message 
                    ? error.response.data.message 
                    : error.message 
                    ? error.message : 'Unexpected error has occured. Please try again later.'
            )
        );
    }
};

export const verifyEmail = (token) => async (dispatch) => {
    dispatch(setLoading(true));
    try {
        const config = {headers: { Authorization: `Bearer ${token}`, 'Content-Type': 'application/json'}};

        await axios.get(`${process.env.REACT_APP_WEBSITE_NAME}/api/users/verify-email`, config);

        dispatch(verificationEmail());
        const userInfo = JSON.parse(localStorage.getItem('userInfo'));
        if(userInfo) {
            userInfo.active = true;
            localStorage.setItem('userInfo', JSON.stringify(userInfo));
        }
    } catch (error) {
        dispatch(
            setError(
                error.response && error.response.data.message 
                    ? error.response.data.message 
                    : error.message 
                    ? error.message : 'Unexpected error has occured. Please try again later.'
            )
        );
    }
};

export const sendResetEmail = (email) => async (dispatch) => {
    dispatch(setLoading(true));
    try {
        const config = {headers: { 'Content-Type': 'application/json' }};
        const { data, status } = await axios.post('api/users/password-reset-request', { email } , config);
        dispatch(setServerResponseMsg(data));
        dispatch(setServerResponseStatus(status));
    } catch (error) {
        dispatch(
            setError(
                error.response && error.response.data.message 
                    ? error.response.data.message 
                    : error.message 
                    ? error.message : 'Unexpected error has occured. Please try again later.'
            )
        );
    }
};

export const sendCoachEmail = (title, content) => async (dispatch, getState) => {
    dispatch(setLoading(true));

    const {
        user: { userInfo }
    } = getState();

    
    try {
        /* const postData = {
            email: userInfo.email,
            name: userInfo.name,
            title: title,
            content: content,
        } */
        const config = {headers: { Authorization: `Bearer ${userInfo.token}`, 'Content-Type': 'application/json' }};
        const { data, status } = await axios.post('api/users/askforacoach', {email: userInfo.email, name: userInfo.name, title: title, content: content} , config);
        dispatch(setServerResponseMsg(data, status));
        dispatch(setServerResponseStatus(status));
    } catch (error) {
        dispatch(
            setError(
                error.response && error.response.data.message 
                    ? error.response.data.message 
                    : error.message 
                    ? error.message : 'Unexpected error has occured. Please try again later.'
            )
        );
    }
};



export const resetPassword = (password, token) => async (dispatch) => {
    dispatch(setLoading(true));
    try {
        const config = {headers: { Authorization: `Bearer ${token}`, 'Content-Type': 'application/json' }};
        // const { data, status } = await axios.post('api/users/password-reset', { password } , config);
        console.log(process.env.REACT_APP_WEBSITE_NAME);
        const { data, status } = await axios.post(`${process.env.REACT_APP_WEBSITE_NAME}/api/users/password-reset`, { password } , config);
        dispatch(setServerResponseMsg(data, status));
        dispatch(setServerResponseStatus(status));

    } catch (error) {
        dispatch(
            setError(
                error.response && error.response.data.message 
                    ? error.response.data.message 
                    : error.message 
                    ? error.message : 'Unexpected error has occured. Please try again later.'
            )
        );
    }
};

export const resetState = () => async (dispatch) => {
    dispatch(stateReset());
};

export const googleLogin = (googleId, email, name, googleImage) => async (dispatch) => {
    dispatch(setLoading(true));
    try {
        const config = { headers: { 'Content-Type': 'application/json' }};
        const { data } = await axios.post('/api/users/google-login', { googleId, email, name, googleImage }, config);
        dispatch(userLogin(data));
        localStorage.setItem('userInfo', JSON.stringify(data));
        // get all classes taken
        const config2 = {headers: { Authorization: `Bearer ${data.token}`, 'Content-Type': 'application/json' }};
        const { data: data2 } = await axios.get(`api/groupclasses/self/taken`, config2);
        dispatch(setUserGroupClassTakenSetList(data2.groupClassTakenSetList));
        const { data: data3 } = await axios.get(`/api/jobs/self/taken`, config2);
        dispatch(setUserJobTakenSetList(data3.jobTakenSetList));
    } catch (error) {
        dispatch(
        setError(
            error.response && error.response.data.message 
                ? error.response.data.message 
                : error.message 
                ? error.message : 'Unexpected error has occured. Please try again later.'
        )
        );
    }
};

export const getUserRecords = () => async (dispatch, getState) => {
    dispatch(setLoading(true));
    const {
        user: { userInfo }
    } = getState();

    try {
        const config = {headers: { Authorization: `Bearer ${userInfo.token}`, 'Content-Type': 'application/json' }};

        const { data } = await axios.get(`/api/records/self/${userInfo._id}`, config);

        dispatch(setUserRecords(data));
    } catch (error) {
        dispatch(
            setError(
                error.response && error.response.data.message 
                    ? error.response.data.message 
                    : error.message 
                    ? error.message : 'Unexpected error has occured. Please try again later.'
            )
        );
    }
};

export const getUserMentor = () => async (dispatch, getState) => {
    dispatch(setLoading(true));
    const {
        user: { userInfo }
    } = getState();

    try {
        const config = {headers: { Authorization: `Bearer ${userInfo.token}`, 'Content-Type': 'application/json' }};
        const { data } = await axios.get(`/api/users/mentor/${userInfo._id}`, config);

        dispatch(setUserMentor(data));
    } catch (error) {
        dispatch(
            setError(
                error.response && error.response.data.message 
                    ? error.response.data.message 
                    : error.message 
                    ? error.message : 'Unexpected error has occured. Please try again later.'
            )
        );
    }
};

export const updateUser = (id, name, wechatId, phoneNumber, gender) => async (dispatch, getState) => {
    dispatch(setLoading(true));
    const {
        user: { userInfo }
    } = getState();

    try {
        const config = {headers: { Authorization: `Bearer ${userInfo.token}`, 'Content-Type': 'application/json' }};
        // need implement following in server side
        const { data } = await axios.put(`/api/users/self`, {id, name, wechatId, phoneNumber, gender}, config);
        dispatch(userUpdateSelf(data));
        localStorage.setItem('userInfo', JSON.stringify(data));
    } catch (error) {
        dispatch(
            setError(
                error.response && error.response.data.message 
                    ? error.response.data.message 
                    : error.message 
                    ? error.message : 'Unexpected error has occured. Please try again later.'
            )
        );
    }
};

export const addReferId = (id) => async (dispatch) => {
    dispatch(setLoading(true));
    try {
        dispatch(setReferId(id));
        localStorage.setItem('referId', JSON.stringify(id));
    } catch (error) {
        dispatch(
            setError(
                error.response && error.response.data.message 
                    ? error.response.data.message 
                    : error.message 
                    ? error.message : 'Unexpected error has occured. Please try again later.'
            )
        );
    }
};

