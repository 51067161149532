import { 
    setLoading, 
    setError, 
    setGroupClasses, 
    setGroupClass,
    groupClassReviewed,
    groupClassApplied,
    groupClassAuditApplied,
    setGroupClassUpdateFlag,
    resetError,
    setReviewRemovalFlag,
    setApplicantUpdateFlag,
    setAuditApplicantUpdateFlag,
} from '../slices/groupClass';
import axios from 'axios';

export const getGroupClasses = () => async (dispatch) => {
    dispatch(setLoading());
    try {
        const { data } = await axios.get(`/api/groupclasses/`);
        dispatch(setGroupClasses(data));
    } catch (error) {
        dispatch(
            setError(
                error.response && error.response.data.message 
                    ? error.response.data.message 
                    : error.message 
                    ? error.message : 'Unexpected error has occured. Please try again later.'
            )
        );
    }
};

export const getGroupClass = (id) => async (dispatch) => {
    dispatch(setLoading(true));
    try {
        const { data } = await axios.get(`/api/groupclasses/${id}`);
        dispatch(setGroupClass(data));
    } catch (error) {
        dispatch(
            setError(
                error.response && error.response.data.message 
                    ? error.response.data.message 
                    : error.message 
                    ? error.message : 'Unexpected error has occured. Please try again later.'
            )
        );
    }

};

export const getGroupClassByProgramId = (id) => async (dispatch) => {
    dispatch(setLoading(true));
    try {
        const { data } = await axios.get(`/api/groupclasses/recent/${id}`);
        dispatch(setGroupClass(data));
    } catch (error) {
        dispatch(
            setError(
                error.response && error.response.data.message 
                    ? error.response.data.message 
                    : error.message 
                    ? error.message : 'Unexpected error has occured. Please try again later.'
            )
        );
    }

};

export const createGroupClassReview = (groupClassId, userId, comment, rating, title) => async (dispatch, getState) => {
    const {
        user: {userInfo },
    } = getState();
    try {
        const config = { headers: { Authorization: `Bearer ${userInfo.token}`, 'Content-Type': 'application/json' }};
        await axios.post(`/api/groupclasses/reviews/${groupClassId}`, { rating, comment, userId, title }, config);
        dispatch(groupClassReviewed(true));
    } catch (error) {
        dispatch(
            setError(
                error.response && error.response.data.message 
                    ? error.response.data.message 
                    : error.message 
                    ? error.message : 'Unexpected error has occured. Please try again later.'
            )
        );
    }
}


export const resetGroupClassError = () => async (dispatch) => {
    dispatch(resetError());
};

// update applicant
export const updateApplicant = (name, level, ratingInThis, secretNote, email, userId, isApproved) => async (dispatch, getState) => {
    setLoading();
    const {
        user: { userInfo },
        groupClass: { groupClass },
    } = getState();
    const config = { headers: { Authorization: `Bearer ${userInfo.token}`, 'Content-Type': 'application/json' }};

    try {
        if( groupClass.manager1UserId !== userInfo._id &&  groupClass.manager2UserId !== userInfo._id) {
            throw new Error('You should be the manager of this groupclass in order to update this applicant!');
        }
        const { data } = await axios.put(`${process.env.REACT_APP_WEBSITE_NAME}/api/groupclasses/applicants/${groupClass._id}`, {name, level, ratingInThis, secretNote, email, userId, isApproved}, config);
        dispatch(setGroupClass(data));
        dispatch(setGroupClassUpdateFlag());
        dispatch(setApplicantUpdateFlag());
    } catch (error) {
        setError(
            error.response && error.response.data.message 
                ? error.response.data.message 
                : error.message 
                ? error.message : 'Unexpected error has occured. Please try again later.'
        );
    }
};


export const createApplicant = (data) => async (dispatch, getState) => {
    const {
        user: {userInfo },
    } = getState();
    try {
        console.log(data.get('groupClassId'));
        const config = { headers: { Authorization: `Bearer ${userInfo.token}`, 'Content-Type': 'multipart/form-data' }};
        await axios.post(`/api/groupclasses/applicants/${data.get('groupClassId')}`, data, config);
        dispatch(groupClassApplied(true));
    } catch (error) {
        dispatch(
            setError(
                error.response && error.response.data.message 
                    ? error.response.data.message 
                    : error.message 
                    ? error.message : 'Unexpected error has occured. Please try again later.'
            )
        );
    }
}
