import { MinusIcon, SmallAddIcon } from '@chakra-ui/icons';
import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Badge,
  Box,
  Button,
  Flex,
  HStack,
  Heading,
  Image,
  SimpleGrid,
  Spinner,
  Stack,
  Text,
  Wrap,
  useToast,
  Textarea,
  Input,
  Tooltip,
  FormControl,
  FormLabel,
  Divider,
  Link,
} from '@chakra-ui/react';
import { BiChat } from 'react-icons/bi';
import { MdContactMail } from "react-icons/md";
import { PiChalkboardTeacherThin } from "react-icons/pi";
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { getJob } from '../redux/actions/jobActions';
import { useEffect, useState } from 'react';
import { createApplicant} from '../redux/actions/jobActions';
import { Link as ReactLink } from 'react-router-dom';
import Papa from 'papaparse';
import { saveAs } from 'file-saver';


const JobScreen = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const { loading, error, job, applied } = useSelector((state) => state.job);
  const toast = useToast();

  /* const [comment, setComment] = useState('');
  const [rating, setRating] = useState(1);
  const [title, setTitle] = useState(''); */
  const [formData, setFormData] = useState({
    coverLetter: null,
    resumeFile: null,
  });
  const [applyBoxOpen, setApplyBoxOpen] = useState(false);
  const { userInfo } = useSelector((state) => state.user);
  const [buttonLoading, setButtonLoading] = useState(false);
  // check if expire soon

  const willExpireSoon = (expireDate) => {
    const today = new Date();  // Get today's date
    const fiveDaysLater = new Date();  // Start from today
  
    fiveDaysLater.setDate(today.getDate() + 5);  // Add 5 days to today
  
    // Compare the expiration date with the date 5 days from now
    return (expireDate <= fiveDaysLater) && (expireDate > today);
  }

  useEffect(() => {
    dispatch(getJob(id));
    setApplyBoxOpen(false);

    if(applied) {
      toast({
        description: 'You applied this job.',
        status: 'success',
        isClosable: 'true',
      });
      setApplyBoxOpen(false);
    }
  }, [dispatch, id, toast, applied]);

  const hasUserApplied = () => job.applicants.some((item) => item.userId.toString() === userInfo._id.toString());
  /* const onSubmit = () => {
    setButtonLoading(true);
    dispatch(createApplicant(job._id, userInfo.rating, userInfo.email, userInfo._id, userInfo.name, coverLetter, resumeFile));
  } */

  /* const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  }; */

  const handleSubmit = async (e) => {
    // e.preventDefault();
    if(userInfo) {
    const data = new FormData();
    data.append('jobId', job._id);
    data.append('title', job.title);
    data.append('referEmail', job.emailSentTo);
    data.append('rating', userInfo.rating);
    data.append('email', userInfo.email);
    data.append('userId', userInfo._id);
    data.append('name', userInfo.name);
    data.append('coverLetter', formData.coverLetter);  // Attach the file
    data.append('resumeFile', formData.resumeFile);  // Attach the file
    setButtonLoading(true);
    data.forEach((value, key) => {
      console.log(`${key}: ${value}`);  // Logs each key-value pair
    });
    dispatch(createApplicant(data));
    }
  };

  const handleResumeFileChange = (e) => {
    setFormData({ ...formData, resumeFile: e.target.files[0] });
  };

  const handleCoverLetterChange = (e) => {
    setFormData({ ...formData, coverLetter: e.target.files[0] });
  };

  const isManager = () => {
    if(userInfo ) {
      if(userInfo.isAdmin || (userInfo.managerRoles && userInfo.managerRoles.includes(3))) return true;
      if(job.referUserId === userInfo._id) return true;
    }
    return false;
  }

  const convertToCSV = (data) => {
    const csv = Papa.unparse(data);
    return csv;
  };

  const downloadCSV = (csvData) => {
    const blob = new Blob([csvData], { type: 'text/csv;charset=utf-8;' });
    saveAs(blob, 'applicants.csv');
  };

  const downloadApplicants = () => {
    const csvData = convertToCSV(job.applicants);
    downloadCSV(csvData);
  };


  return (
    <Wrap spacing='30px' justify='center' minHeight='100vh'>
      {loading ? (
        <Stack direction='row' spacing='4'>
          <Spinner mt='20' thickness='2px' speed='0.65s' emptyColor='gray.200' color='cyan.500' size='xl' />
        </Stack>
      ) : error ? (
        <Alert status='error'>
          <AlertIcon />
          <AlertTitle>We are sorry!</AlertTitle>
          <AlertDescription>{error}</AlertDescription>
        </Alert>
      ) : (
        job && (
          <Box
            maxW={{ base: '3xl', lg: '5xl' }}
            mx='auto'
            px={{ base: '4', md: '8', lg: '12' }}
            py={{ base: '6', md: '8', lg: '12' }}>
              <Stack direction={{ base: 'column', lg: 'row' }} align='flex-start'>
                <Stack pr={{ base:'0', md: 'row' }} flex='1.5' mb={{ base: '12', md: 'none' }}>
                { willExpireSoon(new Date(job.expireDate)) && (
                  <Badge p='2' rounded='md' w='50px' fontSize='0.8em' colorScheme='yellow'>
                    Expire soon
                  </Badge>
                )}
                {(new Date(job.expireDate)) < (new Date()) && (
                  <Badge rounded='full' w='70px' fontSize='0.8em' colorScheme='red'>
                  Expired
                  </Badge>
                )}

                <Heading fontSize='2xl' fontWeight='extrabold'>
                  {job.title} 
                </Heading>
                <Divider />
                <Stack spacing='5'>
                    <Box>
                        <Text fontSize='xl'>{job.companyShown} {job.category}</Text>
                        <Text fontSize='xl'>{job.duration} {job.level}</Text>
                        <Text fontSize='md'>Application Deadline: {(new Date(job.applicationDeadLine)).toLocaleDateString()}</Text>
                        <Text fontSize='md'>Expire at {(new Date(job.expireDate)).toLocaleDateString()} </Text>
                        <Text fontSize='md'>{job.location}</Text>
                    </Box>
                    <Divider />
                    {job.applicantLevel === 0 ? (
                          <Text fontSize='md'>All members can apply.</Text>
                        ) : (
                          <Text fontSize='md'>Gold or upper members only.</Text>
                        )}
                    <Divider />
                    <Text fontSize='md'>Description</Text>
                    <Text >{job.description}</Text>
                    <Divider />
                    <Text fontSize='md'>Requirement</Text>
                    <Text >{job.requirement}</Text>
                  
                  <Divider />
                  <Stack width='300px'>
                    <Flex alignItems='center'>
                      <MdContactMail size='20px' />
                      <Link fontWeight='medium' fontSize='sm' ml='2' as={ReactLink} to='/chat/8' >
                      Need a reference letter from Qishi Club?
                      </Link>
                    </Flex>
                    <Flex alignItems='center'>
                      <PiChalkboardTeacherThin size='20px' />
                      <Link fontWeight='medium' fontSize='sm' ml='2' as={ReactLink} to='/services' >
                        Need a coach to improve your skill?
                        </Link>
                    </Flex>
                    <Flex alignItems='center'>
                      <BiChat size='20px' />
                      <Link fontWeight='medium' fontSize='sm' ml='2' as={ReactLink} to='/chat/1' >
                        We're here for you 24/7
                      </Link>
                    </Flex>
                  </Stack>
                </Stack>
              </Stack>
              </Stack>

              {userInfo && (
                <>
                  <Tooltip label={hasUserApplied() && 'You have already applied this job.'} fontSize='medium'>
                    <Button
                      isDisabled={hasUserApplied()}
                      my='20px'
                      w='140px'
                      colorScheme='cyan'
                      onClick={() => setApplyBoxOpen(!applyBoxOpen)}>
                        Apply this job
                      </Button>
                  </Tooltip>
                  {applyBoxOpen && (
                    <Box as="form" onSubmit={handleSubmit}>
                        <FormControl id="coverLetter" mt={4}>
                        <FormLabel>Upload your cover letter</FormLabel>
                        <Input type="file" onChange={handleCoverLetterChange} required />
                        </FormControl>
                        <FormControl id="resumeFile" mt={4}>
                        <FormLabel>Upload your resume file</FormLabel>
                        <Input type="file" onChange={handleResumeFileChange} required />
                        </FormControl>
                    <Button type="submit" colorScheme="blue" mt={4}>Send your application</Button>
                  </Box>
                  )}
                  {isManager() && job.numberOfApplicants > 0 && (
                  <>
                  <Divider mt='2' mb='2'/>
                  <Text>Information for manager: {job.numberOfApplicants} have applied. </Text>
                  <Button onClick={downloadApplicants}>Download Applicants</Button>

                  <Flex direction='row' justifyContent='space-between' alignItems='center'>
                    <Text>Name</Text>
                    <Text>Email</Text>
                  </Flex>
                  {job.applicants.map((applicant) => (
                    <Flex direction='row' justifyContent='space-between' alignItems='center'>
                      <Text>{applicant.name}</Text>
                      <Text>{applicant.email}</Text>
                    </Flex>
                  ))}
                  </>
                )}
                </>
              )}
            </Box>
        )
      ) }
    </Wrap>
  )
}

export default JobScreen
