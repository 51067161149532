import {
    Tr,
    Td,
    Button,
    VStack,
    Textarea,
    Tooltip,
    Input,
    FormControl,
    Text,
    FormLabel,
    Switch,
    Badge,
    Spacer,
    Flex,
} from '@chakra-ui/react';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { uploadJob } from '../redux/actions/adminActions';

const AddNewJob = () => {
    const dispatch = useDispatch();
    const today = new Date();

    const [title, setTitle ] = useState('');
    const [duration, setDuration ] = useState('');
    const [level, setLevel ] = useState('');
    const [companyShown, setCompanyShown ] = useState('');
    const [companyReal, setCompanyReal ] = useState('');
    const [category, setCategory ] = useState('');
    const [applicationDeadLine, setApplicationDeadLine ] = useState(new Date(today.setMonth(today.getMonth() + 1)));
    const [expireDate, setExpireDate ] = useState(new Date(today.setMonth(today.getMonth() + 1)));
    const [referUserId, setReferUserId ] = useState('');
    const [emailSentTo, setEmailSentTo ] = useState('');
    const [applicantLevel, setApplicantLevel ] = useState(0);
    const [location, setLocation ] = useState('');
    const [description, setDescription ] = useState('');
    const [requirement, setRequirement ] = useState('');

    const createNewJob = () => {
        dispatch(
            uploadJob({
                title,
                duration,
                level,
                companyShown,
                companyReal,
                category,
                applicationDeadLine,
                expireDate,
                referUserId,
                emailSentTo,
                applicantLevel,
                location,
                description,
                requirement
            })
        );
    };

  return (
    <>
    <Tr>
        <Td><Flex direction='row' gap='2'>
            <Text fontSize='sm'>Description</Text>
            <Textarea
                value={description}
                w='80vh'
                h='120px'
                onChange={(e) => setDescription(e.target.value)}
                placeholder='Job Description'
                size='sm'
            />
            </Flex>
        </Td>
        </Tr><Tr>
        <Td><Flex direction='row' gap='2'>
            <Text fontSize='sm'>Requirement</Text>
            <Textarea
                value={requirement}
                w='80vh'
                h='120px'
                onChange={(e) => setRequirement(e.target.value)}
                placeholder='Job Requirement to applicant'
                size='sm'
            />
            </Flex>
        </Td>
        </Tr><Tr>
        <Flex direction='row' gap='2'>
        <Td>Title & Duration</Td>
        <Td>
            <Flex direction='column' gap='2'>
                <Input size='sm' value={title} onChange={(e) => setTitle(e.target.value)} placeholder='Job Title'/>
                <Input size='sm' value={duration} onChange={(e) => setDuration(e.target.value)} placeholder='Full time, Internship, Part time, etc'/>
            </Flex>
        </Td>
        </Flex>
    </Tr><Tr><Flex direction='row' gap='2'>
        <Td>Level & Category</Td>
        <Td>
            <Flex direction='column' gap='2'>
                <Input size='sm' value={level} onChange={(e) => setLevel(e.target.value)} placeholder='Entry Level, Junior, Senior'/>
                <Input size='sm' value={category} onChange={(e) => setCategory(e.target.value)} placeholder='Finance, High Tech'/>
            </Flex>
        </Td></Flex>
    </Tr><Tr><Flex direction='row' gap='2'>
        <Td>CompanyShown & CompanyReal</Td>
        <Td>
            <Flex direction='column' gap='2'>
                <Input size='sm' value={companyShown} onChange={(e) => setCompanyShown(e.target.value)} placeholder='Company name shown in public page'/>
                <Input size='sm' value={companyReal} onChange={(e) => setCompanyReal(e.target.value)} placeholder='Real company name to job manager '/>
            </Flex>
        </Td></Flex>
    </Tr><Tr><Flex direction='row' gap='2'>
        <Td>ApplicationDeadLine & ExpireDate</Td>
        <Td>
            <Flex direction='column' gap='2'>
                <Input w='400px' size='sm' value={applicationDeadLine} onChange={(e) => setApplicationDeadLine(e.target.value)} placeholder='deadline'/>
                <Input w='400px' size='sm' value={expireDate} onChange={(e) => setExpireDate(e.target.value)} placeholder='expire date'/>
            </Flex>
        </Td></Flex>
    </Tr><Tr><Flex direction='row' gap='2'>
        <Td>ReferUserId & EmailSentTo</Td>
        <Td>
            <Flex direction='column' gap='2'>
                <Input w='300px' size='sm' value={referUserId} onChange={(e) => setReferUserId(e.target.value)} placeholder='Refer person Id in User database'/>
                <Input w='300px' size='sm' value={emailSentTo} onChange={(e) => setEmailSentTo(e.target.value)} placeholder='Refer person email'/>
            </Flex>
        </Td></Flex>
    </Tr><Tr><Flex direction='row' gap='2'>
        <Td>ApplicantLevel & Location</Td>
        <Td>
            <Flex direction='column' gap='2'>
                <Input size='sm' value={applicantLevel} onChange={(e) => setApplicantLevel(e.target.value)} placeholder='0 - all, 1- gold & up, 2 - premium member only'/>
                <Input size='sm' value={location} onChange={(e) => setLocation(e.target.value)} placeholder='New York City ...'/>
            </Flex>
        </Td></Flex>
    </Tr>
    <Tr>
        <Td>
            <VStack>
                <Button variant='outline' w='160px' colorScheme='cyan' onClick={createNewJob} >
                    <Text ml='2'>Save Job</Text>
                </Button>
            </VStack>
        </Td>
    </Tr>
    </>
  );
};

export default AddNewJob;