import {
    Badge,
    Button,
    Flex,
    FormControl,
    FormLabel,
    Input,
    Switch,
    Td,
    Textarea,
    Tr,
    VStack,
    useDisclosure,
    useToast,
} from '@chakra-ui/react';
import { useRef, useState } from 'react';
import { MdOutlineDataSaverOn } from 'react-icons/md';
import { useDispatch, useSelector } from 'react-redux';
import { updateMentee } from '../redux/actions/mentorActions';

const MenteeTableItemForMentor = ({mentee}) => {
    const { userInfo, usermentor } = useSelector((state) => state.user);
    const toast = useToast();

    const cancelRef= useRef();
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [isPlaced, setIsPlaced ] = useState(mentee.isPlaced);
    const [placedAt, setPlacedAt ] = useState(mentee.placedAt);
    const [commentFromMentor, setCommentFromMentor ] = useState(mentee.commentFromMentor);

    const dispatch = useDispatch();

    const onSaveMentee = () => {
        dispatch(updateMentee(mentee.name, mentee.email, mentee.userId, isPlaced, placedAt, commentFromMentor));
        toast({
          description: `Mentee ${mentee.name} is updated.`,
          status: 'success',
          isClosable: true,
      });
    };


  return (
    <>
    <Tr>
                              <Td> Name: </Td>
                              <Td> {mentee.name}</Td>
                            </Tr>
                            <Tr>
                              <Td> Email: </Td>
                              <Td> {mentee.email}</Td>
                            </Tr>
                            <Tr>
                              <Td> is Placed : </Td>

                              <Td>
                                <FormControl display='flex' alignItems='center'>
                                    <FormLabel htmlFor='isPlacedFlag' mb='0' fontSize='sm'>
                                        Set
                                        <Badge rounded='full' px='1' mx='1' fontSize='0.8m' colorScheme='green'>
                                            is Placed
                                        </Badge>
                                    </FormLabel>
                                    <Switch id='isPlacedFlag' onChange={() => setIsPlaced(!isPlaced)} isChecked={isPlaced} />
                                </FormControl>
                              </Td>
                            </Tr>
                            <Tr>
                              <Td> Placed At </Td>
                              <Input size='sm' value={placedAt} onChange={(e) => setPlacedAt(e.target.value)} />
                            </Tr>
                            <Tr>
                              <Td> Fee: </Td>
                              <Td> ${mentee.fee}</Td>
                            </Tr>
                            <Tr>
                            <Td> Secret Memo Note: </Td>
                            <Td>
                                <Textarea
                                    w='80vh' 
                                    h='120px'
                                    value={commentFromMentor}
                                    onChange={(e) => setCommentFromMentor(e.target.value)}
                                    size='m'
                                />
                           </Td>
                        </Tr>
                        <Tr>
                            <Td>Action</Td>
                            <Td>
                                <Button colorScheme='green' variant='outline' onClick={onSaveMentee}>
                                <MdOutlineDataSaverOn style={{marginRight: '5px'}} />
                                    Save Changes
                                </Button>
                            </Td>
    </Tr>
    </>
  );
};

export default MenteeTableItemForMentor;