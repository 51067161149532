import { createSlice } from '@reduxjs/toolkit';

export const initialState = {
    error: null,
    userList: null,
    pagination: {},
    userRemoval: false,
    orders: null,
    orderRemoval: false,
    deliveredFlag: false
};

export const adminSlice = createSlice({
    name: 'admin',
    initialState,
    reducers: {
        setLoading: (state) => {
            state.loading = true;
        },
        setError: (state, {payload} ) => {
            state.error = payload;
            state.loading = false;
        },
        getUsers: (state, {payload} ) => {
            state.userList = payload;
            state.error = null;
            state.loading = false;
        },
        setPagination: (state, { payload }) => {
			state.loading = false;
			state.error = null;
			state.pagination = payload;
		},
        getOrders: (state, {payload} ) => {
            state.orders = payload;
            state.error = null;
            state.loading = false;
        },
        userDelete: (state) => {
            state.error = null;
            state.loading = false;
            state.userRemoval = true;
        },
        orderDelete: (state) => {
            state.error = null;
            state.loading = false;
            state.orderRemoval = true;
        },
        resetError: (state) => {
            state.error = null;
            state.loading = false;
            state.userRemoval = false;
            state.deliveredFlag = false;
            state.orderRemoval = false;
        },
        setDeliveredFlag: (state) => {
            state.deliveredFlag = true;
            state.loading = false;
        },
    },
});

export const { setDeliveredFlag, setError, resetError, setLoading, getOrders, getUsers, userDelete, orderDelete, setPagination } = adminSlice.actions;

export default adminSlice.reducer;

export const adminSelector = (state) => state.admin;