import {
  Accordion,
  AccordionButton,
  AccordionItem,
  AccordionPanel,
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Box,
  Spinner,
  Stack,
  Table,
  Tbody,
  Text,
  Th,
  Thead,
  Tr,
  Wrap,
  useToast,
} from '@chakra-ui/react';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getPrograms, resetProgramError } from '../redux/actions/programActions';
import ProgramTableItem from './ProgramTableItem';
import AddNewProgram from './AddNewProgram';

const ProgramsTab = () => {
  const dispatch = useDispatch();
  const { error, loading } = useSelector((state) => state.admin);
  const { programs, programUpdate } = useSelector((state) => state.program);
  const toast = useToast();

  useEffect(() => {
      dispatch(getPrograms());
      dispatch(resetProgramError()); 
      if(programUpdate){
          toast({
              description: 'Program is updated.',
              status: 'success',
              isClosable: true,
          });
      }
  }, [dispatch, toast, programUpdate]);

return (
  <Box>
  {error && (
      <Alert status='error'>
          <AlertIcon />
          <AlertTitle>Upps!</AlertTitle>
          <AlertDescription>{error}</AlertDescription>
      </Alert>
  )}
  {loading && (
      <Wrap justify='center'>
          <Stack direction='row' spacing='4'>
              <Spinner mt='20' thickness='2px' speed='0.65s' emptyColor='gray.200' color='cyan.500' size='xl' />
          </Stack>
      </Wrap>
  )}
  
  {!error && !loading && (
      <Box>
          <Accordion allowToggle>
              <AccordionItem>
                  <h2>
                      <AccordionButton>
                          <Box flex='1' textAlign='right'>
                              <Box>
                                  <Text mr='8px' fontWeight='bold'>Add a new Program</Text>
                              </Box>
                          </Box>
                      </AccordionButton>
                  </h2>
                  <AccordionPanel pb='4'>
                      <Table>
                          <Tbody>
                              <AddNewProgram />
                          </Tbody>
                      </Table>
                  </AccordionPanel>
              </AccordionItem>
          </Accordion>
          {programs && programs.length > 0 &&
                      programs.map((program) => (
                          
          <Accordion key={program._id} allowToggle>
              <AccordionItem>
                  <h2>
                      <AccordionButton>
                          <Box flex='1' textAlign='right'>
                              <Box>
                                  <Text key={program._id} mr='8px' fontWeight='bold'>{program.title}</Text>
                              </Box>
                          </Box>
                      </AccordionButton>
                  </h2>
                  <AccordionPanel pb='4'>
                      <Table>
                          <Tbody>
                          <ProgramTableItem key={program._id} program={program} />
                          </Tbody>
                      </Table>
                  </AccordionPanel>
              </AccordionItem>
          </Accordion>
          
          ))}
      </Box>
  )}
</Box>
);
};

export default ProgramsTab;