import axios from 'axios';
import { setMentors, setMentorUpdateFlag, setReviewRemovalFlag as setMentorReviewRemovalFlag } from '../slices/mentor';
import { setJobs, setJobUpdateFlag} from '../slices/job';
import { setEvents, setEventUpdateFlag} from '../slices/event';
import { setPrograms, setProgramUpdateFlag} from '../slices/program';
import { setGroupClasses, setGroupClassUpdateFlag, setReviewRemovalFlag as setGroupClassReviewRemovalFlag } from '../slices/groupClass';
import { setCertificates, setCertificateUpdateFlag } from '../slices/certificate';
import { setAccountings, setAccountingUpdateFlag } from '../slices/accounting';

import { 
    setDeliveredFlag, 
    setError, 
    resetError, 
    setLoading, 
    getOrders, 
    getUsers, 
    setPagination,
    userDelete, 
    orderDelete 
} from '../slices/admin';

export const getAllUsers = () => async (dispatch, getState) => {
    setLoading();
    const {
        user: { userInfo },
    } = getState();

    const config = { headers: { Authorization: `Bearer ${userInfo.token}`, 'Content-Type': 'application/json' }};

    try {
        const { data } = await axios.get('api/users', config);
        dispatch(getUsers(data));
    } catch (error) {
        dispatch(setError(
            error.response && error.response.data.message 
                ? error.response.data.message 
                : error.message 
                ? error.message : 'Unexpected error has occured. Please try again later.'
        ));
    }
};

export const getAllUsersByPage = (page) => async (dispatch, getState) => {
	dispatch(setLoading());

    const {
        user: { userInfo },
    } = getState();

    const config = { headers: { Authorization: `Bearer ${userInfo.token}`, 'Content-Type': 'application/json' }};

	try {
		const { data } = await axios.get(`/api/users/pages/${page}/${10}`, config);
		const { users, pagination } = data;
		dispatch(getUsers(users));
		dispatch(setPagination(pagination));
	} catch (error) {
		dispatch(
			setError(
				error.response && error.response.data.message
					? error.response.data.message
					: error.message
					? error.message
					: 'An expected error has occured. Please try again later.'
			)
		);
	}
};


export const getAllUsersBySearch = (quest, page) => async (dispatch, getState) => {
	dispatch(setLoading());

    const {
        user: { userInfo },
    } = getState();

    const config = { headers: { Authorization: `Bearer ${userInfo.token}`, 'Content-Type': 'application/json' }};

	try {
		const { data } = await axios.get(`/api/users/search/${quest}/${page}`, config);
		const { users, pagination } = data;
		dispatch(getUsers(users));
		dispatch(setPagination(pagination));
	} catch (error) {
		dispatch(
			setError(
				error.response && error.response.data.message
					? error.response.data.message
					: error.message
					? error.message
					: 'An expected error has occured. Please try again later.'
			)
		);
	}
};

export const deleteUser = (id) => async (dispatch, getState) => {
    setLoading();
    const {
        user: { userInfo },
    } = getState();
    const config = { headers: { Authorization: `Bearer ${userInfo.token}`, 'Content-Type': 'application/json' }};

    try {
        const { data } = await axios.delete(`api/users/${id}`, config);
        dispatch(userDelete(data));
    } catch (error) {
        dispatch(setError(
            error.response && error.response.data.message 
                ? error.response.data.message 
                : error.message 
                ? error.message : 'Unexpected error has occured. Please try again later.'
        ));
    }
};

export const changeMembership = (level, id) => async (dispatch, getState) => {
    setLoading();
    const {
        user: { userInfo },
    } = getState();
    const config = { headers: { Authorization: `Bearer ${userInfo.token}`, 'Content-Type': 'application/json' }};

    try {
        const { data } = await axios.put(`${process.env.REACT_APP_WEBSITE_NAME}/api/users/membership`, {level, id}, config);
        dispatch(getUsers(data));
    } catch (error) {
        dispatch(setError(
            error.response && error.response.data.message 
                ? error.response.data.message 
                : error.message 
                ? error.message : 'Unexpected error has occured. Please try again later.'
        ));
    }
};

export const getAllOrders = () => async (dispatch, getState) => {
    setLoading();
    const {
        user: { userInfo },
    } = getState();

    const config = { headers: { Authorization: `Bearer ${userInfo.token}`, 'Content-Type': 'application/json' }};

    try {
        const { data } = await axios.get('api/orders', config);
        dispatch(getOrders(data));
    } catch (error) {
        dispatch(setError(
            error.response && error.response.data.message 
                ? error.response.data.message 
                : error.message 
                ? error.message : 'Unexpected error has occured. Please try again later.'
        ));
    }
};

export const deleteOrder = (id) => async (dispatch, getState) => {
    setLoading();
    const {
        user: { userInfo },
    } = getState();

    const config = { headers: { Authorization: `Bearer ${userInfo.token}`, 'Content-Type': 'application/json' }};

    try {
        const { data } = await axios.delete(`api/orders/${id}`, config);
        dispatch(orderDelete(data));
    } catch (error) {
        dispatch(setError(
            error.response && error.response.data.message 
                ? error.response.data.message 
                : error.message 
                ? error.message : 'Unexpected error has occured. Please try again later.'
        ));
    }
};


export const setDelivered = (id) => async (dispatch, getState) => {
    setLoading();
    const {
        user: { userInfo },
    } = getState();

    const config = { headers: { Authorization: `Bearer ${userInfo.token}`, 'Content-Type': 'application/json' }};

    try {
        await axios.put(`api/orders/${id}`, {}, config);
        dispatch(setDeliveredFlag());
    } catch (error) {
        dispatch(setError(
            error.response && error.response.data.message 
                ? error.response.data.message 
                : error.message 
                ? error.message : 'Unexpected error has occured. Please try again later.'
        ));
    }
};

export const resetErrorAndRemoval = () => async (dispatch) => {
    dispatch(resetError());
};

export const updateMentor = (mentorid, name, email, industry, title, linkedin, twobrain, image, introduction, 
    isAvailable, busyReason, stripeId, fee, minutes) => async (dispatch, getState) => {
    setLoading(true);
    const {
        user: { userInfo },
    } = getState();

    const config = { headers: { Authorization: `Bearer ${userInfo.token}`, 'Content-Type': 'application/json' }};

    try {
        const { data } = await axios.put('api/mentors', {mentorid, name, email, industry, title, linkedin, twobrain, image, introduction, 
            isAvailable, busyReason, stripeId, fee, minutes}, config);
        dispatch(setMentors(data));
        dispatch(setMentorUpdateFlag());
    } catch (error) {
        dispatch(setError(
            error.response && error.response.data.message 
                ? error.response.data.message 
                : error.message 
                ? error.message : 'Unexpected error has occured. Please try again later.'
        ));
    }
};


export const deleteMentor = (id) => async (dispatch, getState) => {
    setLoading(true);
    const {
        user: { userInfo },
    } = getState();

    const config = { headers: { Authorization: `Bearer ${userInfo.token}`, 'Content-Type': 'application/json' }};

    try {
        const { data } = await axios.delete(`api/mentors/${id}`, config);
        dispatch(setMentors(data));
        dispatch(setMentorUpdateFlag());
        dispatch(resetError());
    } catch (error) {
        dispatch(setError(
            error.response && error.response.data.message 
                ? error.response.data.message 
                : error.message 
                ? error.message : 'Unexpected error has occured. Please try again later.'
        ));
    }
};


export const uploadMentor = (newMentor) => async (dispatch, getState) => {
    // console.log(newProduct);
    setLoading(true);
    const {
        user: { userInfo },
    } = getState();

    const config = { headers: { Authorization: `Bearer ${userInfo.token}`, 'Content-Type': 'application/json' }};

    try {
        const { data } = await axios.post('api/mentors', newMentor, config);
        dispatch(setMentors(data));
        dispatch(setMentorUpdateFlag());
    } catch (error) {
        dispatch(setError(
            error.response && error.response.data.message 
                ? error.response.data.message 
                : error.message 
                ? error.message : 'Unexpected error has occured. Please try again later.'
        ));
    }
};

export const removeMentorReview = (mentorId, reviewId) => async (dispatch, getState) => {
    setLoading();
    const {
        user: { userInfo },
    } = getState();

    const config = { headers: { Authorization: `Bearer ${userInfo.token}`, 'Content-Type': 'application/json' }};

    try {
        const { data } = await axios.put(`api/mentors/reviews/${mentorId}/${reviewId}`, {}, config);
        dispatch(setMentors(data));
        dispatch(setMentorReviewRemovalFlag());
    } catch (error) {
        dispatch(setError(
            error.response && error.response.data.message 
                ? error.response.data.message 
                : error.message 
                ? error.message : 'Unexpected error has occured. Please try again later.'
        ));
    }
};



export const updateJob = (jobId, title, duration, level, companyShown, companyReal, category, applicationDeadLine, expireDate, referUserId, emailSentTo, 
    applicantLevel, location, description, requirement) => async (dispatch, getState) => {
    setLoading(true);
    const {
        user: { userInfo },
    } = getState();

    const config = { headers: { Authorization: `Bearer ${userInfo.token}`, 'Content-Type': 'application/json' }};

    try {
        const { data } = await axios.put('api/jobs', {jobId, title, duration, level, companyShown, companyReal, category, applicationDeadLine, expireDate, referUserId, emailSentTo, 
            applicantLevel, location, description, requirement}, config);
        dispatch(setJobs(data));
        dispatch(setJobUpdateFlag());
    } catch (error) {
        dispatch(setError(
            error.response && error.response.data.message 
                ? error.response.data.message 
                : error.message 
                ? error.message : 'Unexpected error has occured. Please try again later.'
        ));
    }
};


export const deleteJob = (id) => async (dispatch, getState) => {
    setLoading(true);
    const {
        user: { userInfo },
    } = getState();

    const config = { headers: { Authorization: `Bearer ${userInfo.token}`, 'Content-Type': 'application/json' }};

    try {
        const { data } = await axios.delete(`api/jobs/${id}`, config);
        dispatch(setJobs(data));
        dispatch(setJobUpdateFlag());
        dispatch(resetError());
    } catch (error) {
        dispatch(setError(
            error.response && error.response.data.message 
                ? error.response.data.message 
                : error.message 
                ? error.message : 'Unexpected error has occured. Please try again later.'
        ));
    }
};


export const uploadJob = (newJob) => async (dispatch, getState) => {
    // console.log(newJob);
    setLoading(true);
    const {
        user: { userInfo },
    } = getState();

    const config = { headers: { Authorization: `Bearer ${userInfo.token}`, 'Content-Type': 'application/json' }};

    try {
        const { data } = await axios.post('api/jobs', newJob, config);
        dispatch(setJobs(data));
        dispatch(setJobUpdateFlag());
    } catch (error) {
        dispatch(setError(
            error.response && error.response.data.message 
                ? error.response.data.message 
                : error.message 
                ? error.message : 'Unexpected error has occured. Please try again later.'
        ));
    }
};

// events
export const updateEvent = (eventId, title, subtitle, image, partner, category, location, description, participantLevel,
    isInvitationOnly, voucherUrl, duration, honorGuestsIntro, hostIntro, hostUserId, topics, registrationDeadLine,
    eventStartDateTime, eventEndDateTime, stock, manager1UserId, manager2UserId, 
    isFree, level0Fee, level1Fee, level0StripeId, level1StripeId) => async (dispatch, getState) => {
    setLoading(true);
    const {
        user: { userInfo },
    } = getState();

    const config = { headers: { Authorization: `Bearer ${userInfo.token}`, 'Content-Type': 'application/json' }};

    try {
        const { data } = await axios.put('api/events', {eventId, title, subtitle, image, partner, category, location, description, participantLevel,
            isInvitationOnly, voucherUrl, duration, honorGuestsIntro, hostIntro, hostUserId, topics, registrationDeadLine,
            eventStartDateTime, eventEndDateTime, stock, manager1UserId, manager2UserId, 
            isFree, level0Fee, level1Fee, level0StripeId, level1StripeId}, config);
        dispatch(setEvents(data));
        dispatch(setEventUpdateFlag());
    } catch (error) {
        dispatch(setError(
            error.response && error.response.data.message 
                ? error.response.data.message 
                : error.message 
                ? error.message : 'Unexpected error has occured. Please try again later.'
        ));
    }
};


export const deleteEvent = (id) => async (dispatch, getState) => {
    setLoading(true);
    const {
        user: { userInfo },
    } = getState();

    const config = { headers: { Authorization: `Bearer ${userInfo.token}`, 'Content-Type': 'application/json' }};

    try {
        const { data } = await axios.delete(`api/events/${id}`, config);
        dispatch(setEvents(data));
        dispatch(setEventUpdateFlag());
        dispatch(resetError());
    } catch (error) {
        dispatch(setError(
            error.response && error.response.data.message 
                ? error.response.data.message 
                : error.message 
                ? error.message : 'Unexpected error has occured. Please try again later.'
        ));
    }
};


export const uploadEvent = (newEvent) => async (dispatch, getState) => {
    // console.log(newJob);
    setLoading(true);
    const {
        user: { userInfo },
    } = getState();

    const config = { headers: { Authorization: `Bearer ${userInfo.token}`, 'Content-Type': 'application/json' }};

    try {
        const { data } = await axios.post('api/events', newEvent, config);
        dispatch(setEvents(data));
        dispatch(setEventUpdateFlag());
    } catch (error) {
        dispatch(setError(
            error.response && error.response.data.message 
                ? error.response.data.message 
                : error.message 
                ? error.message : 'Unexpected error has occured. Please try again later.'
        ));
    }
};

// program
export const updateProgram = (id, title, subtitle, image, isTentative, isPublic, description, specialNotice, memberComposed, 
    requirement, courseContent, syllabus, duration, weight, managerUserId, certificateId, isFree, level0Fee, 
    level1Fee, level0StripeId, level1StripeId, applicantLevel, maxOfApplicants, material, baseOfTotalTakers, totalTakers) => async (dispatch, getState) => {
    setLoading(true);
    const {
        user: { userInfo },
    } = getState();

    const config = { headers: { Authorization: `Bearer ${userInfo.token}`, 'Content-Type': 'application/json' }};

    try {
        const { data } = await axios.put('api/programs', {id, title, subtitle, image, isTentative, isPublic, description, specialNotice, memberComposed, 
            requirement, courseContent, syllabus, duration, weight, managerUserId, certificateId, isFree, level0Fee, 
            level1Fee, level0StripeId, level1StripeId, applicantLevel, maxOfApplicants, material, baseOfTotalTakers, totalTakers}, config);
        dispatch(setPrograms(data));
        dispatch(setProgramUpdateFlag());
    } catch (error) {
        dispatch(setError(
            error.response && error.response.data.message 
                ? error.response.data.message 
                : error.message 
                ? error.message : 'Unexpected error has occured. Please try again later.'
        ));
    }
};


export const deleteProgram = (id) => async (dispatch, getState) => {
    setLoading(true);
    const {
        user: { userInfo },
    } = getState();

    const config = { headers: { Authorization: `Bearer ${userInfo.token}`, 'Content-Type': 'application/json' }};

    try {
        const { data } = await axios.delete(`api/programs/${id}`, config);
        dispatch(setPrograms(data));
        dispatch(setProgramUpdateFlag());
        dispatch(resetError());
    } catch (error) {
        dispatch(setError(
            error.response && error.response.data.message 
                ? error.response.data.message 
                : error.message 
                ? error.message : 'Unexpected error has occured. Please try again later.'
        ));
    }
};


export const uploadProgram = (newProgram) => async (dispatch, getState) => {
    setLoading(true);
    const {
        user: { userInfo },
    } = getState();

    const config = { headers: { Authorization: `Bearer ${userInfo.token}`, 'Content-Type': 'application/json' }};

    try {
        const { data } = await axios.post('api/programs', newProgram, config);
        dispatch(setPrograms(data));
        dispatch(setProgramUpdateFlag());
    } catch (error) {
        dispatch(setError(
            error.response && error.response.data.message 
                ? error.response.data.message 
                : error.message 
                ? error.message : 'Unexpected error has occured. Please try again later.'
        ));
    }
};



// certificate
export const updateCertificate = (id, name, category, description, expireDateTime, managerUserId, examFee, examFeeStripeId) => async (dispatch, getState) => {
    setLoading(true);
    const {
        user: { userInfo },
    } = getState();

    const config = { headers: { Authorization: `Bearer ${userInfo.token}`, 'Content-Type': 'application/json' }};

    try {
        const { data } = await axios.put('api/certificates', {id, name, category, description, expireDateTime, managerUserId, examFee, examFeeStripeId}, config);
        dispatch(setCertificates(data));
        dispatch(setCertificateUpdateFlag());
    } catch (error) {
        dispatch(setError(
            error.response && error.response.data.message 
                ? error.response.data.message 
                : error.message 
                ? error.message : 'Unexpected error has occured. Please try again later.'
        ));
    }
};


export const deleteCertificate = (id) => async (dispatch, getState) => {
    setLoading(true);
    const {
        user: { userInfo },
    } = getState();

    const config = { headers: { Authorization: `Bearer ${userInfo.token}`, 'Content-Type': 'application/json' }};

    try {
        const { data } = await axios.delete(`api/certificates/${id}`, config);
        dispatch(setCertificates(data));
        dispatch(setCertificateUpdateFlag());
        dispatch(resetError());
    } catch (error) {
        dispatch(setError(
            error.response && error.response.data.message 
                ? error.response.data.message 
                : error.message 
                ? error.message : 'Unexpected error has occured. Please try again later.'
        ));
    }
};


export const uploadCertificate = (newCertificate) => async (dispatch, getState) => {
    setLoading(true);
    const {
        user: { userInfo },
    } = getState();

    const config = { headers: { Authorization: `Bearer ${userInfo.token}`, 'Content-Type': 'application/json' }};

    try {
        const { data } = await axios.post('api/certificates', newCertificate, config);
        dispatch(setCertificates(data));
        dispatch(setCertificateUpdateFlag());
    } catch (error) {
        dispatch(setError(
            error.response && error.response.data.message 
                ? error.response.data.message 
                : error.message 
                ? error.message : 'Unexpected error has occured. Please try again later.'
        ));
    }
};



// groupClass
export const updateGroupClass = (id, title, subtitle, programId, isMostRecent, description, specialNotice, memberComposed, requirement, courseContent, syllabus,
    registrationDeadLine, classStartDateTime, classEndDateTime, duration, weight, isNeedResume, manager1UserId, manager2UserId,
    isFree, level0Fee, level1Fee, level0StripeId, level1StripeId, applicantLevel, maxOfApplicants, allowAudit, auditManagerUserId, 
    isAuditFree, level0AuditFee, level1AuditFee, level0AuditStripeId, level1AuditStripeId, auditApplicantLevel, 
    maxOfAuditApplicants, material) => async (dispatch, getState) => {
    setLoading(true);
    const {
        user: { userInfo },
    } = getState();

    const config = { headers: { Authorization: `Bearer ${userInfo.token}`, 'Content-Type': 'application/json' }};

    try {
        const { data } = await axios.put('api/groupclasses', {id, title, subtitle, programId, isMostRecent, description, specialNotice, memberComposed, requirement, courseContent, syllabus,
            registrationDeadLine, classStartDateTime, classEndDateTime, duration, weight, isNeedResume, manager1UserId, manager2UserId,
            isFree, level0Fee, level1Fee, level0StripeId, level1StripeId, applicantLevel, maxOfApplicants, allowAudit, auditManagerUserId, 
            isAuditFree, level0AuditFee, level1AuditFee, level0AuditStripeId, level1AuditStripeId, auditApplicantLevel, 
            maxOfAuditApplicants, material}, config);
        dispatch(setGroupClasses(data));
        dispatch(setGroupClassUpdateFlag());
    } catch (error) {
        dispatch(setError(
            error.response && error.response.data.message 
                ? error.response.data.message 
                : error.message 
                ? error.message : 'Unexpected error has occured. Please try again later.'
        ));
    }
};


export const deleteGroupClass = (id) => async (dispatch, getState) => {
    setLoading(true);
    const {
        user: { userInfo },
    } = getState();

    const config = { headers: { Authorization: `Bearer ${userInfo.token}`, 'Content-Type': 'application/json' }};

    try {
        const { data } = await axios.delete(`api/groupclasses/${id}`, config);
        dispatch(setGroupClasses(data));
        dispatch(setGroupClassUpdateFlag());
        dispatch(resetError());
    } catch (error) {
        dispatch(setError(
            error.response && error.response.data.message 
                ? error.response.data.message 
                : error.message 
                ? error.message : 'Unexpected error has occured. Please try again later.'
        ));
    }
};


export const uploadGroupClass = (newGroupClass) => async (dispatch, getState) => {
    setLoading(true);
    const {
        user: { userInfo },
    } = getState();

    const config = { headers: { Authorization: `Bearer ${userInfo.token}`, 'Content-Type': 'application/json' }};

    try {
        const { data } = await axios.post('api/groupclasses', newGroupClass, config);
        dispatch(setGroupClasses(data));
        dispatch(setGroupClassUpdateFlag());
    } catch (error) {
        dispatch(setError(
            error.response && error.response.data.message 
                ? error.response.data.message 
                : error.message 
                ? error.message : 'Unexpected error has occured. Please try again later.'
        ));
    }
};

export const removeGroupClassReview = (groupClassId, reviewId) => async (dispatch, getState) => {
    setLoading();
    const {
        user: { userInfo },
    } = getState();

    const config = { headers: { Authorization: `Bearer ${userInfo.token}`, 'Content-Type': 'application/json' }};

    try {
        const { data } = await axios.put(`api/groupclasses/${groupClassId}/${reviewId}`, {}, config);
        dispatch(setGroupClasses(data));
        dispatch(setGroupClassReviewRemovalFlag());
    } catch (error) {
        dispatch(setError(
            error.response && error.response.data.message 
                ? error.response.data.message 
                : error.message 
                ? error.message : 'Unexpected error has occured. Please try again later.'
        ));
    }
};

// accounting
export const updateAccounting = (id, userId, isCharge, isPay, amount, placeDate, description, category, paymentMethod, stripeId, referenceId) => async (dispatch, getState) => {
    setLoading(true);
    const {
        user: { userInfo },
    } = getState();

    const config = { headers: { Authorization: `Bearer ${userInfo.token}`, 'Content-Type': 'application/json' }};
    
    try {
        const { data } = await axios.put(`${process.env.REACT_APP_WEBSITE_NAME}/api/accountings`, {id, userId, isCharge, isPay, amount, placeDate, description, category, paymentMethod, stripeId, referenceId}, config);
        dispatch(setAccountings(data));
        dispatch(setAccountingUpdateFlag());
    } catch (error) {
        dispatch(setError(
            error.response && error.response.data.message 
                ? error.response.data.message 
                : error.message 
                ? error.message : 'Unexpected error has occured. Please try again later.'
        ));
    }
};


export const deleteAccounting = (id) => async (dispatch, getState) => {
    setLoading(true);
    const {
        user: { userInfo },
    } = getState();

    const config = { headers: { Authorization: `Bearer ${userInfo.token}`, 'Content-Type': 'application/json' }};

    try {
        const { data } = await axios.delete(`${process.env.REACT_APP_WEBSITE_NAME}/api/accountings/${id}`, config);
        dispatch(setAccountings(data));
        dispatch(setAccountingUpdateFlag());
        dispatch(resetError());
    } catch (error) {
        dispatch(setError(
            error.response && error.response.data.message 
                ? error.response.data.message 
                : error.message 
                ? error.message : 'Unexpected error has occured. Please try again later.'
        ));
    }
};

export const uploadAccounting = (newAccounting) => async (dispatch, getState) => {
    setLoading(true);
    const {
        user: { userInfo },
    } = getState();

    console.log(newAccounting);

    const config = { headers: { Authorization: `Bearer ${userInfo.token}`, 'Content-Type': 'application/json' }};

    try {
        const { data } = await axios.post(`${process.env.REACT_APP_WEBSITE_NAME}/api/accountings`, newAccounting, config);
        dispatch(setAccountings(data));
        dispatch(setAccountingUpdateFlag());
    } catch (error) {
        dispatch(setError(
            error.response && error.response.data.message 
                ? error.response.data.message 
                : error.message 
                ? error.message : 'Unexpected error has occured. Please try again later.'
        ));
    }
};

export const checkUserRecords = (id) => async (dispatch, getState) => {
    dispatch(setLoading(true));
    const {
        user: { userInfo }
    } = getState();

    try {
        const config = {headers: { Authorization: `Bearer ${userInfo.token}`, 'Content-Type': 'application/json' }};

        const { data } = await axios.get(`/api/records/self/${id}`, config);

        dispatch(getOrders(data));
    } catch (error) {
        dispatch(
            setError(
                error.response && error.response.data.message 
                    ? error.response.data.message 
                    : error.message 
                    ? error.message : 'Unexpected error has occured. Please try again later.'
            )
        );
    }
};
