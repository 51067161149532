import { DeleteIcon } from '@chakra-ui/icons';
import {
    Badge,
    Button,
    Flex,
    FormControl,
    FormLabel,
    Input,
    Switch,
    Td,
    Textarea,
    Tr,
    VStack,
    useDisclosure,
} from '@chakra-ui/react';
import { useRef, useState } from 'react';
import { MdOutlineDataSaverOn } from 'react-icons/md';
import { useDispatch, useSelector } from 'react-redux';
import { deleteMentor, updateMentor } from '../redux/actions/adminActions';
import ConfirmRemovalAlert from './ConfirmRemovalAlert';

const MentorTableItem = ({mentor}) => {

    const { userInfo } = useSelector((state) => state.user);

    const cancelRef= useRef();
    const { isOpen, onOpen, onClose } = useDisclosure();
    const userId = mentor.userId; // userId is not allowed to be changed
    const [name, setName ] = useState(mentor.name);
    const [email, setEmail ] = useState(mentor.email);
    const [industry, setIndustry ] = useState(mentor.industry);
    const [title, setTitle ] = useState(mentor.title);
    const [linkedin, setLinkedin ] = useState(mentor.linkedin);
    const [twobrain, setTwobrain ] = useState(mentor.twobrain);
    const [image, setImage ] = useState(mentor.image);
    const [introduction, setIntroduction ] = useState(mentor.introduction);
    const [isAvailable, setIsAvailable ] = useState(mentor.isAvailable);
    const [busyReason, setBusyReason ] = useState(mentor.busyReason);
    const [fee, setFee ] = useState(mentor.fee);
    const [minutes, setMinutes ] = useState(mentor.minutes);
    const [stripeId, setStripeId ] = useState(mentor.stripeId);
    const dispatch = useDispatch();

    const onSaveMentor = () => {
        dispatch(updateMentor(mentor._id, name, email, industry, title, linkedin, twobrain, image, introduction, 
            isAvailable, busyReason, stripeId, fee, minutes));
    };

    const openDeleteConfirmBox = () => {
        onOpen();
    };

  return (
    <>
    <Tr>
        <Td> Set Image: </Td>
        <Td>
            <Input size='sm' value={image} isDisabled={!userInfo.isAdmin} onChange={(e) => setImage(e.target.value)} />
        </Td>
    </Tr>
    <Tr>
        <Td> Give Introduction: </Td>
        <Td>
            <Textarea
                w='80vh' 
                h='120px'
                value={introduction}
                onChange={(e) => setIntroduction(e.target.value)}
                size='m'
            />
        </Td>
    </Tr><Tr>
        <Td>UserId & Mentor Name</Td>
        <Td>
            <Flex direction='column' gap='2'>
                <Input size='sm' value={userId} isDisabled />
                <Input size='sm' value={name} onChange={(e) => setName(e.target.value)} />
            </Flex>
        </Td>
    </Tr><Tr>
        <Td>Email & Industry</Td>
        <Td>
            <Flex direction='column' gap='2'>
                <Input size='sm' value={email} onChange={(e) => setEmail(e.target.value)}  />
                <Input size='sm' value={industry} onChange={(e) => setIndustry(e.target.value)} />
            </Flex>
        </Td>
    </Tr><Tr>
        <Td>Title & LinkedIn</Td>
        <Td>
            <Flex direction='column' gap='2'>
                <Input size='sm' value={title} onChange={(e) => setTitle(e.target.value)}  />
                <Input size='sm' value={linkedin} onChange={(e) => setLinkedin(e.target.value)} />
            </Flex>
        </Td>
    </Tr><Tr>
        <Td>StripeId & Twobrain</Td>
        <Td>
            <Flex direction='column' gap='2'>
                <Input size='sm' value={stripeId} isDisabled={!userInfo.isAdmin} onChange={(e) => setStripeId(e.target.value)} />
                <Input size='sm' value={twobrain} onChange={(e) => setTwobrain(e.target.value)} />
            </Flex>
        </Td>
    </Tr><Tr>
        <Td>Is Available & Busy Reason</Td>
        <Td>
            <Flex direction='column' gap='2'>
                <FormControl display='flex' alignItems='center'>
                    <FormLabel htmlFor='mentorIsAvailableFlag' mb='0' fontSize='sm'>
                        Disable
                        <Badge rounded='full' px='1' mx='1' fontSize='0.8m' colorScheme='green'>
                            Available
                        </Badge>
                        badge and give busy reason?
                    </FormLabel>
                    <Switch id='mentorIsAvailableFlag' onChange={() => setIsAvailable(!isAvailable)} isChecked={isAvailable} />
                </FormControl>
                {/* <Input size='sm' value={isAvailable} onChange={(e) => setIsAvailable(e.target.value)} /> */}
                <Input size='sm' value={busyReason} onChange={(e) => setBusyReason(e.target.value)} />
            </Flex>
        </Td>
    </Tr><Tr>
        <Td>Fee In USD & In Minutes</Td>
        <Td>
            <Flex direction='column' gap='2'>
                <Input size='sm' value={fee} isDisabled={!userInfo.isAdmin} onChange={(e) => setFee(e.target.value)} />
                <Input size='sm' value={minutes} isDisabled={!userInfo.isAdmin} onChange={(e) => setMinutes(e.target.value)} />
            </Flex>
        </Td>
        </Tr><Tr>
            <Td>Action</Td>
            <Td>
            <VStack align='start'>
            {userInfo.isAdmin && (
                <Button colorScheme='red' variant='outline' onClick={openDeleteConfirmBox}>
                    <DeleteIcon mr='5px' />
                    Remove Mentor
                </Button>  
            )}
                <Button colorScheme='green' variant='outline' onClick={onSaveMentor}>
                    <MdOutlineDataSaverOn style={{marginRight: '5px'}} />
                    Save Changes
                </Button>
            </VStack>
        </Td>
    </Tr>
    {userInfo.isAdmin && (
    <ConfirmRemovalAlert 
                    isOpen={isOpen} 
                    onOpen={onOpen} 
                    onClose={onClose} 
                    cancelRef={cancelRef} 
                    itemToDelete={mentor}
                    deleteAction={deleteMentor}
    />)}
    </>
  );
};

export default MentorTableItem;