import { createSlice } from '@reduxjs/toolkit';

export const initialState = {
    loading: false,
    error: null,
    programs: [],
    selectedPrograms: [],
    program: null,
    subscribed: false,
    subscribeRemoval: false,
    programUpdate: false,
    mostRecentGroupClassesMap: {},
    mostRecentGroupClass: null,

};

export const programsSlice = createSlice({
    name: 'programs',
    initialState,
    reducers: {
        setLoading: (state) => {
            state.loading = true;
        },
        setPrograms: (state, { payload }) => {
            state.loading = false;
            state.error = null;
            state.programs = payload.programs;
            state.selectedPrograms = payload.programs;
            state.subscribeRemoval = false;
            state.mostRecentGroupClassesMap = payload.mostRecentGroupClassesMap;
        },
        setSelectedPrograms: (state, { payload }) => {
            state.loading = false;
            state.error = null;
            state.selectedPrograms = payload;
        },
        setProgram: (state, { payload }) => {
            state.loading = false;
            state.error = null;
            state.program = payload.program;
            state.mostRecentGroupClass = payload.mostRecentGroupClass;
            state.subscribed = false;
        },
        setError: (state, { payload }) => {
            state.loading = false;
            state.error = payload;
        },
        programSubscribed: (state, {payload}) => {
            state.loading = false;
            state.error = null;
            state.subscribed = payload;
        },
        resetError: (state) => {
            state.error = null;
            state.subscribed = false;
            state.programUpdate = false;
            state.subscribeRemoval = false;
        },
        setProgramUpdateFlag: (state) => {
            state.programUpdate = true;
            state.loading = false;
        },
        setSubscribeRemovalFlag: (state) => {
            state.error = null;
            state.subscribeRemoval = true;
            state.loading = false;
        }
    },
});

export const {
    setLoading, 
    setError, 
    setPrograms, 
    setSelectedPrograms,
    setProgram,
    programSubscribed,
    setProgramUpdateFlag,
    resetError,
    setSubscribeRemovalFlag
} = programsSlice.actions;

export default programsSlice.reducer;

export const programSelector = (state) => state.programs;

