import {
    Accordion,
    AccordionButton,
    AccordionItem,
    AccordionPanel,
    Alert,
    AlertDescription,
    AlertIcon,
    AlertTitle,
    Box,
    Spinner,
    Stack,
    Table,
    Tbody,
    Text,
    Th,
    Thead,
    Tr,
    Wrap,
    useToast,
} from '@chakra-ui/react';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { getAccountingsByUserId, resetAccountingError } from '../redux/actions/accountingActions';
import AccountingTableItem from '../components/AccountingTableItem';
import AddNewAccounting from '../components/AddNewAccounting';

const AccountingsScreen = () => {
    const { id } = useParams();
    const dispatch = useDispatch();
    const { error, loading } = useSelector((state) => state.admin);
    const { accountings, accountingUpdate, annualFee, annualFeeMessage } = useSelector((state) => state.accounting);
    const toast = useToast();

    useEffect(() => {
        dispatch(getAccountingsByUserId(id));
        dispatch(resetAccountingError()); 
        if(accountingUpdate){
            toast({
                description: 'Accounting is updated.',
                status: 'success',
                isClosable: true,
            });
        }
    }, [dispatch, toast, id, accountingUpdate]);

  return (
    <Box>
    {error && (
        <Alert status='error'>
            <AlertIcon />
            <AlertTitle>Upps!</AlertTitle>
            <AlertDescription>{error}</AlertDescription>
        </Alert>
    )}
    {loading ? (
        <Wrap justify='center'>
            <Stack direction='row' spacing='4'>
                <Spinner mt='20' thickness='2px' speed='0.65s' emptyColor='gray.200' color='cyan.500' size='xl' />
            </Stack>
        </Wrap>
    ) : (
        <Box>
            <Text>For user ID: {id} </Text>
            {annualFee > 0 && (
                <Text>{annualFeeMessage}</Text>
            )}
            <Accordion allowToggle>
                <AccordionItem>
                    <h2>
                        <AccordionButton>
                            <Box flex='1' textAlign='right'>
                                <Box>
                                    <Text mr='8px' fontWeight='bold'>Add a new Accounting</Text>
                                </Box>
                            </Box>
                        </AccordionButton>
                    </h2>
                    <AccordionPanel pb='4'>
                        <Table>
                            <Tbody>
                                <AddNewAccounting id={id}/>
                            </Tbody>
                        </Table>
                    </AccordionPanel>
                </AccordionItem>
            </Accordion>
            {accountings && accountings.length > 0 &&
                        accountings.map((accounting) => (
                            
            <Accordion key={accounting._id} allowToggle>
                <AccordionItem>
                    <h2>
                        <AccordionButton>
                            <Box flex='1' textAlign='right'>
                                <Box>
                                    <Text key={accounting._id} mr='8px' fontWeight='bold'>{accounting.category} {accounting.isCharge ? '(ClubCharge)' : '(ClubPaid)'}: ${accounting.amount} </Text>
                                </Box>
                            </Box>
                        </AccordionButton>
                    </h2>
                    <AccordionPanel pb='4'>
                        <Table>
                            <Tbody>
                            <AccountingTableItem key={accounting._id} accounting={accounting} />
                            </Tbody>
                        </Table>
                    </AccordionPanel>
                </AccordionItem>
            </Accordion>
            ))}
        </Box>
    )}
</Box>
  );
};

export default AccountingsScreen;