import { Box, Center, Image, Text, Badge, Flex, IconButton, Skeleton, useToast, Tooltip } from '@chakra-ui/react';
import { BiExpand } from 'react-icons/bi';
import { FcInvite } from "react-icons/fc";
import React from 'react';
import { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link as ReactLink } from 'react-router-dom';
import { addCartItemGeneral } from '../redux/actions/cartActions';
import { useEffect } from 'react';
import { TbShoppingCartPlus } from 'react-icons/tb';



const EventCard = ({ event, loading }) => {

    const today = new Date()
    const dispatch = useDispatch();
    const { cartItems } = useSelector((state) => state.cart);
    const { userInfo } = useSelector((state) => state.user);
    const toast = useToast();
    const [ cartPlusDisabled, setCartPlusDisabled ] = useState(false);
    const voucherDisabled = !event.isInvitationOnly;
    
    const estStartDate = new Date(event.eventStartDateTime).toLocaleString('en-US', {
        timeZone: 'America/New_York',  // EST Timezone
        year: 'numeric',
        month: 'short',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
        hour12: true,
    });
        

    useEffect(() => {
        const item = cartItems.find((cartItem) => cartItem.id === event._id);
        if(item ) { // anyone can only buy one event 
            setCartPlusDisabled(true);
        }
    }, [event, cartItems]);

    const addItem = (id) => {
        // addCartItemGeneral = (id, category, emails, name, price, stripeId)
        /* if(cartItems.some((cartItem) => cartItem.id === id)) {
          const item = cartItems.find((cartItem) => cartItem.id === id);
          dispatch(addCartItem(id, item.qty + 1));
        } else {
          dispatch(addCartItem(id, 1));
        } */
       // get fee
       let fee = 0.0;
       let stripeId = '';
       if(!event.isFree) {
        if(userInfo && userInfo.membershipLevel >= 2) {
            fee = event.level1Fee;
            stripeId = event.level1StripeId;
        }
        else {
            fee = event.level0Fee;
            stripeId = event.level0StripeId;

        }
       }

        if(!(cartItems.some((cartItem) => cartItem.id === id))) {
            // addCartItemGeneral = (id, category, emails, name, price, stripeId)
            // dispatch(addCartItemGeneral(id, 'Mentor', [mentor.email], mentor.name, mentor.fee, mentor.stripeId));
            // const eventManagerIds = (event.manager2UserId && event.manager2UserId !== '') ? [event.manager1UserId, event.manager2UserId] : [event.manager1UserId];
            dispatch(addCartItemGeneral(id, 'Event', [], event.title, fee, stripeId));
        }
        toast({
          description: 'Item has been added.',
          status: 'success',
          isClosable: true,
        });
      };

    const isRegOpen = (regDate) => {
        // registrationDeadLine
        if((new Date(regDate)) < today) {
            return false;
        }
        else {
            return true;
        }
    }

    const isApplied = () => {
        if(userInfo ) {
            return event.participants.some(participant => participant.userId === userInfo._id);
        }
        else {
            return false;
        }
    }

  return (
    <Skeleton isLoaded={!loading}>
        <Box
            _hover={{transform: 'scale(1.1)', transitionDuration: '0.5s' }}
            borderWidth='1px'
            overflow='hidden'
            p='4'
            shadow='md'>
                <Center>
            <Image 
                src={event.image}
                fallbackSrc='https://via.placeholder.com/150'
                alt={event.title}
                height='200px'
            /></Center>
            {isRegOpen(event.registrationDeadLine) && (event.stock < 5 ? (
                    <Badge colorScheme='yellow'>only {event.stock} left</Badge>
            ) : event.stock < 1 ? (
                    <Badge colorScheme='red'>Sold out</Badge>
            ) : <Badge colorScheme='green'>In Stock</Badge>)}
            {!isRegOpen(event.registrationDeadLine) && (
                <Badge colorScheme='black'>Closed</Badge>
            )}
            {event.isInvitationOnly && (
                <Badge ml='2' colorScheme='purple'>Invite Only</Badge>
            )}
            <Text fontSize='md' fontWeight='semibold' mt='2'>
                {event.title}
                </Text>
            <Text noOfLines = {1} fontSize='sm' mt='2'>
                {event.location} 
            </Text>
            <Text noOfLines = {1} fontSize='sm' color='gray.600'>
                {estStartDate} NYT
            </Text>
            {event.isFree && (
                <Flex
                justify="space-between"
                alignItems="center"
                mt='2'>
                <Text fontSize="lg" fontWeight="semibold" color='darkCerulean.500'>Fee</Text>
                <Text fontSize="lg" fontWeight="semibold" color='red'>
                    Free!
                </Text>
            </Flex>
            )}
            {!event.isFree && event.level0Fee === event.level1Fee && (
                <>
                <Flex
                justify="space-between"
                alignItems="center"
                mt='2'>
                <Text fontSize="sm" fontWeight="semibold" color='darkCerulean.500'>Fee</Text>
                <Text fontSize="sm" fontWeight="semibold" color='darkCerulean.500'>
                    ${event.level0Fee}
                </Text>
                </Flex>
                </>
            )}
            {!event.isFree && event.level0Fee !== event.level1Fee && (
                <>
                <Flex
                justify="space-between"
                alignItems="center"
                mt='2'>
                <Text fontSize="sm" fontWeight="semibold" color='darkCerulean.500'>Regular Fee</Text>
                <Text fontSize="sm" fontWeight="semibold" color='darkCerulean.500'>
                    ${event.level0Fee}
                </Text>
                </Flex>
                <Flex
                justify="space-between"
                alignItems="center"
                mt='2'>
                <Text fontSize="sm" fontWeight="semibold" color='darkCerulean.500'>Gold Member Fee</Text>
                <Flex justify="space-between" alignItems="center">
                <Text fontSize="sm" textDecoration="line-through" fontWeight="semibold" color='red'>
                    ${event.level0Fee}
                </Text>
                <Text fontSize="sm" fontWeight="semibold" color='red' ml='1'>
                    ${event.level1Fee}
                </Text>
                </Flex>
                </Flex>
                </>
            )}
           {/*  {event.fees && event.fees.map((fee, index) => (
                <Flex
                    key={index}
                    justify="space-between"
                    alignItems="center"
                    mt='2'>
                    <Badge colorScheme="darkCerulean" fontSize="lg">{fee.levelName}</Badge>
                    <Text fontSize="lg" fontWeight="semibold" color='darkCerulean.500'>
                        ${fee.price.toFixed(2)}
                    </Text>
                </Flex>
            ))} */}
            <Flex justify='space-between' mt='2'>
                <Tooltip 
                isDisabled = {voucherDisabled} 
                hasArrow
                label="Ask for a voucher to enter this event!" >
                <IconButton
                    isDisabled = {voucherDisabled} 
                    icon={<FcInvite size='20px' />}
                    colorScheme='darkCerulean'
                    size='sm'
                    as={ReactLink}
                    to={event.voucherUrl}
                />
                </Tooltip>
                <IconButton 
                icon={<BiExpand size='20'/>} 
                as={ReactLink}
                to={`/event/${event._id}`}
                colorScheme='darkCerulean' size='sm' />
                <Tooltip
                    isDisabled={!((isApplied() || event.isInvitationOnly || !isRegOpen(event.registrationDeadLine)) || event.stock <= 0 || cartPlusDisabled)}
                    hasArrow
                    label={
                        isApplied() 
                        ? 'You have already applied this event.'
                        : event.isInvitationOnly 
                        ? 'Invite only, check detail and ask for a voucher'
                        : !isRegOpen(event.registrationDeadLine) 
                        ? 'Registration Closed'
                        : !cartPlusDisabled
                        ? 'You reached the maximum quantity of the product. '
                        : event.stock <= 0
                        ? 'Out of stock'
                        : ''
                    }>
                        <IconButton
                            isDisabled = {(isApplied() || event.isInvitationOnly || !isRegOpen(event.registrationDeadLine)) || event.stock <= 0 || cartPlusDisabled}
                            onClick={() => addItem(event._id)}
                            icon={<TbShoppingCartPlus size='20' />}
                            colorScheme='darkCerulean'
                            size='sm'
                        />
                    </Tooltip>
            </Flex>
            </Box>
    </Skeleton>
  );
};

export default EventCard;