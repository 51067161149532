import {
    TableContainer,
    Stack,
    Spinner,
    Alert,
    AlertIcon,
    AlertDescription,
    AlertTitle,
    Th,
    Tbody,
    Tr,
    Thead,
    Table,
    Wrap,
    Text,
    Accordion,
    AccordionItem,
    AccordionButton,
    AccordionPanel,
    Box,
    Link,
  } from '@chakra-ui/react';
import { Navigate, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { Link as ReactLink } from 'react-router-dom';
import MenteeTableItem from '../components/MenteeTableItem';
import { getUserMentor } from '../redux/actions/userActions';
import MentorTableItem from '../components/MentorTableItem';
import MenteeTableItemForMentor from '../components/MenteeTableItemForMentor';

/* import { updateMentor } from '../redux/actions/adminActions'; */
const MentorConsoleScreen = () => {
    const dispatch = useDispatch();
  const { usermentor, userInfo } = useSelector((state) => state.user);
  const { loading, error, mentorUpdate, menteeUpdate } = useSelector((state) => state.mentor);
  const { error: adminError } = useSelector((state) => state.admin);
  const location = useLocation();

  useEffect(() => {
    if(mentorUpdate || menteeUpdate) {
        dispatch(getUserMentor());
    }
    if(userInfo) {
      dispatch(getUserMentor());
      // you may add toast later here
    }
  }, [dispatch, userInfo, mentorUpdate, menteeUpdate]);

  return userInfo ? (
    <>
      {loading ? (
        <Wrap direction='column' align='center' mt='20px' justify='center' minHeight='100vh'>
            <Stack direction='row' spacing='4'>
              <Spinner mt='20' thickness='2px' speed='0.65s' emptyColor='gray.200' color='cyan.500' size='xl' />
            </Stack>
        </Wrap>
      ) : (error || adminError) ? (
        <Alert status='error'>
          <AlertIcon />
          <AlertTitle>We are sorry!</AlertTitle>
          <AlertDescription><Link as={ReactLink} textDecoration='underline' to='/logout' >You might need to logout and login again to access this page. </Link></AlertDescription>
        </Alert>
      ) : (
        usermentor && (
            <> 
            <Accordion key={usermentor._id} allowToggle>
                <AccordionItem>
                    <h2>
                        <AccordionButton>
                            <Box flex='1' textAlign='right'>
                                <Box>
                                    <Text key={usermentor._id} mr='8px' fontWeight='bold'>Update Your Mentor Profile</Text>
                                </Box>
                            </Box>
                        </AccordionButton>
                    </h2>
                    <AccordionPanel pb='4'>
            <Table variant='simple' size='lg'>
            <Tbody>
                <MentorTableItem key={usermentor._id} mentor={usermentor} />
            </Tbody>
            </Table>
            </AccordionPanel>
                </AccordionItem>
            </Accordion>
                <Text fontWeight='bold'>Managing your Mentee portofolio:</Text>
                { usermentor.mentees.map((mentee) => (
                  
                <Accordion key={mentee._id} allowToggle>
                <AccordionItem>
                    <h2>
                        <AccordionButton>
                            <Box flex='1' textAlign='right'>
                                <Box>
                                    <Text key={mentee._id} mr='8px' fontWeight='bold'>{mentee.name}</Text>
                                </Box>
                            </Box>
                        </AccordionButton>
                    </h2>
                    <AccordionPanel pb='4'>
                        <Table>
                            <Tbody>
                            <MenteeTableItemForMentor key={mentee._id} mentee={mentee} />
                            </Tbody>
                        </Table>
                    </AccordionPanel>
                </AccordionItem>
            </Accordion>
            
                ))} 
        </>)
      )}
    </>
  ) : (<Navigate to='/login' replace={true} state={{from: location }} />);
};

export default MentorConsoleScreen;
