import { createSlice } from '@reduxjs/toolkit';

const calculateSubtotal = (cartState) => {
    let result = 0;
    cartState.map((item) => (result += item.qty * item.price));
    return result;
};

const prepareCouponSet = (couponBalance, subtotal) => {
    let hasCoupon = false;
    let couponAmount = 0.0;
    let couponCode = '';
    if(couponBalance >= subtotal) {
        hasCoupon = true;
        couponAmount = subtotal;
        couponCode = 'COUPONPAYALL';
    }
    else if(couponBalance < 5 || subtotal < 5) {
        hasCoupon = false;
        couponAmount = 0.0;
        couponCode = '';
    } else {
        hasCoupon = true;
        if ( couponBalance >= 100 && subtotal >= 100) {
            couponCode='CASHCOUPON100';
            couponAmount = 100.00;
        } else if  (couponBalance >= 50 && subtotal >= 50 ) {
            couponCode='CASHCOUPON50';
            couponAmount=50.00;
        } else if  (couponBalance >= 10 && subtotal >= 10 ) {
            couponCode='CASHCOUPON10';
            couponAmount = 10.00;
        } else {
            couponCode='CASHCOUPON5';
            couponAmount = 5.00;
        }
    }
    return {
        'hasCoupon': hasCoupon,
        'couponCode': couponCode,
        'couponAmount': couponAmount,
    }
};

export const initialState = {
    loading: false,
    error: null,
    cartItems: JSON.parse(localStorage.getItem('cartItems')) ?? [],
    shipping: JSON.parse(localStorage.getItem('shipping')) ?? Number(4.99),
    hasCoupon: false,
    couponCode: JSON.parse(localStorage.getItem('couponCode')) ?? '',
    couponAmount: 0.0,
    subtotal: localStorage.getItem('cartItems') ? calculateSubtotal(JSON.parse(localStorage.getItem('cartItems'))) : 0,
};

const updateLocalStorage = (cart) => {
    localStorage.setItem('cartItems', JSON.stringify(cart));
    localStorage.setItem('subtotal', JSON.stringify(calculateSubtotal(cart)));
};

export const cartSlice = createSlice({
    name: 'cart',
    initialState,
    reducers: {
        setLoading: (state) => {
            state.loading = true;
        },
        setError: (state, { payload }) => {
            state.error = payload;
            state.loading = false;
        },
        cartItemAdd: (state, { payload }) => {
            const existingItem = state.cartItems.find((item) => item.id === payload.id);
            if (existingItem) {
                state.cartItems = state.cartItems.map((item) => (item.id === existingItem.id ? payload : item));
            } else {
                state.cartItems = [...state.cartItems, payload];
            }
            
            state.loading = false;
            state.error = null;
            updateLocalStorage(state.cartItems);
            state.subtotal = Number(calculateSubtotal(state.cartItems));
        },
        cartItemRemoval: (state, { payload}) => {
            state.cartItems = [...state.cartItems].filter((item) => item.id !== payload);
            updateLocalStorage(state.cartItems);
            state.subtotal = calculateSubtotal(state.cartItems);
            state.loading = false;
            state.error = null;
        },
        setShippingCosts: (state, { payload }) => {
            state.shipping = payload;
            localStorage.setItem('shipping', payload);
        },
        clearCart: (state) => {
            localStorage.removeItem('cartItems');
            localStorage.removeItem('shipping');
            localStorage.removeItem('subtotal');
            localStorage.removeItem('couponCode');
            state.cartItems = [];
            state.shipping = Number(4.99);
            state.subtotal = 0;
            state.loading = false;
            state.error = null;
            state.hasCoupon = false;
            state.couponAmount = 0.0;
            state.couponCode = '';
        },
        setCouponSet: (state, { payload }) => {
            const couponSet = prepareCouponSet(payload, state.subtotal);
            state.hasCoupon = couponSet['hasCoupon'];
            state.couponAmount = couponSet['couponAmount'];
            state.couponCode = couponSet['couponCode'];
            localStorage.setItem('couponCode', JSON.stringify(state.couponCode));
            state.loading = false;
            state.error = null;
        }
    },

});

export const {setError, setLoading, cartItemAdd, cartItemRemoval, setShippingCosts, clearCart, setCouponSet } = cartSlice.actions;

export default cartSlice.reducer;
export const cartSelector = (state) => state.cart;
