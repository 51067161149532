import {
    TableContainer,
    Stack,
    Spinner,
    Alert,
    AlertIcon,
    AlertDescription,
    AlertTitle,
    Th,
    Tbody,
    Tr,
    Thead,
    Button,
    ListItem,
    UnorderedList,
    Table,
    Td,
    Wrap,
    Box,
    Heading,
    Text,
    Divider,
  } from '@chakra-ui/react';
  import { FiArrowRight } from 'react-icons/fi';
  import { useDispatch, useSelector } from 'react-redux';
  import { getJobs } from '../redux/actions/jobActions';
  import { useEffect } from 'react';
  import { Navigate, useLocation } from 'react-router-dom';
  import { Link as ReactLink } from 'react-router-dom';

  
  const JobsScreen = () => {
    const dispatch = useDispatch();
    const { loading, error, jobs } = useSelector((state) => state.job);

    const location = useLocation();

    useEffect(() => {
        dispatch(getJobs());
    }, [dispatch]);
  
    return (
      <>
        {error ? (
            <Alert status='error'>
                <AlertIcon />
                <AlertTitle>We are sorry!</AlertTitle>
                <AlertDescription>{error}</AlertDescription>
            </Alert>
        ) : (
            jobs && jobs.length >= 1 && jobs.map((job) => (
            <Box
            key={job._id}
            maxW={{ base: '3xl', lg: '5xl' }}
            mx='auto'
            px={{ base: '2', md: '4', lg: '6' }}
            py={{ base: '2', md: '4', lg: '6' }}>
              
                <Stack pr={{ base:'0', md: 'row' }} flex='1.5' mb={{ base: '12', md: 'none' }}>
                <Heading fontSize='2xl' fontWeight='extrabold'>
                  {job.title}
                </Heading>
                <Stack spacing='2'>
                  <Text fontSize='xl'> {job.companyShown} ({job.category})</Text>
                  <Text fontSize='md'>{job.duration}, {job.level} </Text>
                  <Text >{job.location}</Text>
                  <Button 
                  colorScheme='darkCerulean'
                  rightIcon={<FiArrowRight />}
                  as={ReactLink}
                  to={`/job/${job._id}`}
                  size='sm'>
                    View Detail
                  </Button>
                  </Stack>
              </Stack>
              <Divider borderWidth="2px" />
            </Box>
            
        )))}
      </>
    );
  };
  
  export default JobsScreen;