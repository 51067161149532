import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Badge,
  Box,
  Button,
  Flex,
  Heading,
  Image,
  Spinner,
  Stack,
  Text,
  Wrap,
  useToast,
  Tooltip,
  Divider,
  Link,
} from '@chakra-ui/react';
import { BiSupport } from 'react-icons/bi';
import { FcInvite } from "react-icons/fc";
import { MdOutlineTipsAndUpdates } from "react-icons/md";
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Link as ReactLink } from 'react-router-dom';
import { getEvent } from '../redux/actions/eventActions';
import { addCartItemGeneral } from '../redux/actions/cartActions';
import { useEffect, useState } from 'react';
import Papa from 'papaparse';
import { saveAs } from 'file-saver';


const EventScreen = () => {

  const { id } = useParams();
  const dispatch = useDispatch();
  const { loading, error, event } = useSelector((state) => state.event);
  const { cartItems } = useSelector((state) => state.cart);
  const { userInfo } = useSelector((state) => state.user);
  const toast = useToast();
  const today = new Date();

  useEffect(() => {
    dispatch(getEvent(id));
  }, [dispatch, id]);

  const estRegDate = event ? new Date(event.registrationDeadLine).toLocaleString('en-US', {
    timeZone: 'America/New_York',  // EST Timezone
    year: 'numeric',
    month: 'short',
    day: 'numeric',
  }) : new Date();

  const estStartDate = event ? new Date(event.eventStartDateTime).toLocaleString('en-US', {
    timeZone: 'America/New_York',  // EST Timezone
    year: 'numeric',
    month: 'short',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    hour12: true,
  }) : new Date();

  const estEndDate = event ? new Date(event.eventEndDateTime).toLocaleString('en-US', {
    timeZone: 'America/New_York',  // EST Timezone
    year: 'numeric',
    month: 'short',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    hour12: true,
  }) : new Date();

  const addItem = (id) => {
   let fee = 0.0;
   let stripeId = '';
   if(!event.isFree) {
    if(userInfo && userInfo.membershipLevel >= 2) {
        fee = event.level1Fee;
        stripeId = event.level1StripeId;
    }
    else {
        fee = event.level0Fee;
        stripeId = event.level0StripeId;
    }
   }

    if(!(cartItems.some((cartItem) => cartItem.id === id))) {
        // addCartItemGeneral = (id, category, emails, name, price, stripeId)
        // dispatch(addCartItemGeneral(id, 'Mentor', [mentor.email], mentor.name, mentor.fee, mentor.stripeId));
        //const eventManagerIds = (event.manager2UserId && event.manager2UserId !== '') ? [event.manager1UserId, event.manager2UserId] : [event.manager1UserId];
        dispatch(addCartItemGeneral(id, 'Event', [], event.title, fee, stripeId));
    }
    toast({
      description: 'Item has been added.',
      status: 'success',
      isClosable: true,
    });
  };

  const isRegOpen = (regDate) => {
    // registrationDeadLine
    if((new Date(regDate)) < today) {
        return false;
    }
    else {
        return true;
    }
  }

  const isApplied = () => {
    if(userInfo ) {
        return event.participants.some(participant => participant.userId === userInfo._id);
    }
    else {
        return false;
    }
  }

  const isManager = () => {
    if(userInfo ) {
      if(userInfo.isAdmin || (userInfo.managerRoles && userInfo.managerRoles.includes(2))) return true;
      if(event.manager1UserId === userInfo._id) return true;
      if(event.manager2UserId && event.manager2UserId === userInfo._id) return true; 
    }
    return false;
  }

  const getMembership = (level) => {
    if(level > 2) return 'Platinum';
    if(level > 1) return 'Gold';
    return 'Basic';
  }

  
  const convertToCSV = (data) => {
    const csv = Papa.unparse(data);
    return csv;
  };

  const downloadCSV = (csvData) => {
    const blob = new Blob([csvData], { type: 'text/csv;charset=utf-8;' });
    saveAs(blob, 'participants.csv');
  };

  const downloadParticipants = () => {
    const csvData = convertToCSV(event.participants);
    downloadCSV(csvData);
  };
  
  


  return (
    <Wrap spacing='30px' justify='center' minHeight='100vh'>
      {loading ? (
        <Stack direction='row' spacing='4'>
          <Spinner mt='20' thickness='2px' speed='0.65s' emptyColor='gray.200' color='cyan.500' size='xl' />
        </Stack>
      ) : error ? (
        <Alert status='error'>
          <AlertIcon />
          <AlertTitle>We are sorry!</AlertTitle>
          <AlertDescription>{error}</AlertDescription>
        </Alert>
      ) : (
        event && (
          <Box
            maxW={{ base: '3xl', lg: '5xl' }}
            mx='auto'
            px={{ base: '4', md: '8', lg: '12' }}
            py={{ base: '6', md: '8', lg: '12' }}>
              
                <Stack pr={{ base:'0', md: 'row' }} flex='1.5' mb={{ base: '12', md: 'none' }} mr={{base:'0', lg: '10'}}>
                {event.isInvitationOnly && (
                  <Badge p='2' rounded='md' w='100px' fontSize='0.8em' colorScheme='purple'>
                    Invite Only
                  </Badge>
                )}
                {event.stock === 0 && (
                  <Badge rounded='full' w='70px' fontSize='0.8em' colorScheme='red'>
                  Sold out
                  </Badge>
                )}
                {!isRegOpen(event.registrationDeadLine)&& (
                  <Badge rounded='full' w='70px' fontSize='0.8em' colorScheme='red'>
                  Closed
                  </Badge>
                )}
                </Stack>
                <Stack direction={{ base: 'column', lg: 'row' }} align='flex-start'>
                <Stack direction='column'>
                <Heading fontSize='2xl' fontWeight='extrabold'>
                  {event.title} 
                </Heading>
                <Text noOfLines = {1} fontSize='xl' mt='2'>
                    {event.location}, {event.category}
                </Text>
                <Text noOfLines = {1} fontSize='md' color='gray.600'>
                    {event.subtitle}
                </Text>
                <Divider />
                <Stack spacing='5'>
                  <Box>
            {event.isFree && (
                <Flex
                justify="space-between"
                alignItems="center"
                mt='2'>
                <Text fontSize="lg" fontWeight="semibold" color='darkCerulean.500'>Price</Text>
                <Text fontSize="lg" fontWeight="semibold" color='red'>
                    Free!
                </Text>
            </Flex>
            )}
            {!event.isFree && event.level0Fee === event.level1Fee && (
                <>
                <Flex
                justify="space-between"
                alignItems="center"
                mt='2'>
                <Text fontSize="sm" fontWeight="semibold" color='darkCerulean.500'>Fee</Text>
                <Text fontSize="sm" fontWeight="semibold" color='darkCerulean.500'>
                    ${event.level0Fee}
                </Text>
                </Flex>
                </>
            )}
            {!event.isFree && event.level0Fee !== event.level1Fee && (
                <>
                <Flex
                justify="space-between"
                alignItems="center"
                mt='2'>
                <Text fontSize="sm" fontWeight="semibold" color='darkCerulean.500'>Regular Fee</Text>
                <Text fontSize="sm" fontWeight="semibold" color='darkCerulean.500'>
                    ${event.level0Fee}
                </Text>
                </Flex>
                <Flex
                justify="space-between"
                alignItems="center"
                mt='2'>
                <Text fontSize="sm" fontWeight="semibold" color='darkCerulean.500'>Gold Member Fee</Text>
                <Flex justify="space-between" alignItems="center">
                <Text fontSize="sm" textDecoration="line-through" fontWeight="semibold" color='red'>
                    ${event.level0Fee}
                </Text>
                <Text fontSize="sm" fontWeight="semibold" color='red'>
                    ${event.level1Fee}
                </Text>
                </Flex>
                </Flex>
                </>
            )}
                    
                      <Text fontSize='md' fontWeight='bold' mt='4px'>
                        {event.numberOfParticipants} has joined.
                      </Text>
                    
                  </Box>
                  <Divider />
                  {event.partner && event.partner !== '' && (
                    <Text noOfLines = {1} fontSize='xl' fontWeight='semibold' mt='2'>
                      {event.partner} X QiShi
                      </Text>
                     )}
                  <Text fontSize='xl' mt='2'>
                    {event.location}, {event.category}
                    </Text>
                  <Text>{event.subtitle}</Text>
                  {event.participantLevel === 0 ? (<Text>All registered users can apply</Text>) : 
                  event.participantLevel === 10 ? (<Text>Only Platinum members can apply</Text>) 
                  : (<Text>Only Gold members and up can apply</Text>)}
                  <Text>Registration Deadline: {estRegDate} (NYT)</Text>
                  <Text>Start: {estStartDate} (NYT)</Text>
                  <Text>End: {estEndDate} (NYT)</Text>
                  <Text>Duration: {event.duration}</Text>
                  </Stack>
                  </Stack>
                  <Stack align='flex-center'>
                  <Image
                  flex={{ base: '1', lg: '5' }}
                  mb='30px'
                  ml='30px'
                  src={event.image}
                  alt={event.title}
                  fallbackSrc='https://via.placeholder.com/250'
                  width="300px"
                />
                </Stack>
                </Stack>
                  <Stack direction='column' align='flex-start'>
                  <Divider />
                  <Text whiteSpace="pre-wrap">{event.description}</Text>
                  <Divider />
                  <Text whiteSpace="pre-wrap">Honor Guests Brief Introduction: {event.honorGuestsIntro}</Text>
                  <Text whiteSpace="pre-wrap">Host Brief Introduction: {event.hostIntro}</Text>
                  <Text whiteSpace="pre-wrap">Topics: {event.topics}</Text>
                  <Text fontWeight='bold'>Quantity</Text>
                  <Badge fontSize='lg' width='170px' textAlign='center' colorScheme='gray'>
                    Tickets left: {event.stock}
                  </Badge>
                  {!event.isInvitationOnly &&
                  <Button variant='outline' isDisabled={ isApplied() || (!isRegOpen(event.registrationDeadLine)) || event.stock === 0} colorScheme='cyan' onClick={() => addItem(event._id)}>
                    Add to cart
                  </Button>
                  }
                  {isApplied() && (
                    <Text>You have already applied this event.</Text>
                  )}
                  <Stack width='270px'>
                    {event.isInvitationOnly && (
                  <Flex alignItems='center'>
                  
                    <FcInvite size='20px' />
                    
                    <Link
                    colorScheme='darkCerulean'
                    as={ReactLink}
                    to={event.voucherUrl}
                    ml='2'
                    fontWeight='medium'
                    fontSize='sm'
                    >
                  { isRegOpen(event.registrationDeadLine) 
                  ? 'Ask for a voucher to enter this event!'
                  : 'Ask for a voucher to enter similar future event!'
                  }</Link>
                
                </Flex>
                )}
                    <Flex alignItems='center'>
                      <MdOutlineTipsAndUpdates size='20px' />
                      <Link
                    colorScheme='darkCerulean'
                    as={ReactLink}
                    to='/chat/7'
                    ml='2'
                    fontWeight='medium'
                    fontSize='sm'
                >
                    Networking tips</Link>
                    </Flex>
                    <Flex alignItems='center'>
                      <BiSupport size='20px' />
                      <Link
                    colorScheme='darkCerulean'
                    as={ReactLink}
                    to='/chat/1'
                    ml='2'
                    fontWeight='medium'
                    fontSize='sm'
                >
                    We're here for you 24/7</Link>
                    </Flex>
                  </Stack>
                </Stack>
                {isManager() && event.numberOfParticipants > 0 && (
                  <>
                  <Divider mt='2' mb='2'/>
                  <Text>Information for manager: {event.numberOfParticipants} has applied. </Text>
                  <Button onClick={downloadParticipants}>Download Participants</Button>

                  <Flex direction='row' justifyContent='space-between' alignItems='center'>
                    <Text>Name</Text>
                    <Text>Email</Text>
                    <Text>WechatId</Text>
                    <Text>Membership</Text>
                  </Flex>
                  {event.participants.map((participant) => (
                    <Flex direction='row' justifyContent='space-between' alignItems='center'>
                      <Text>{participant.name}</Text>
                      <Text>{participant.email}</Text>
                      <Text>{participant.wechatId ? participant.wechatId : ''}</Text>
                      <Text>{getMembership(participant.level)}</Text>
                    </Flex>
                    
                  ))}
                  </>
                )}
            </Box>
        )
      
      ) }
    </Wrap>
  )
};

export default EventScreen;