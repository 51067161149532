import {
    Tr,
    Td,
    Button,
    VStack,
    Textarea,
    Tooltip,
    Input,
    FormControl,
    Text,
    FormLabel,
    Switch,
    Badge,
    Spacer,
    Flex,
    Stack,
} from '@chakra-ui/react';
import { Alert, AlertIcon, AlertTitle, AlertDescription, Box, CloseButton } from '@chakra-ui/react';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { uploadEvent } from '../redux/actions/adminActions'; 

const AddNewEvent = () => {
    const dispatch = useDispatch();
    const today = new Date();

    const [title, setTitle ] = useState('');
    const [subtitle, setSubtitle ] = useState('');
    const [image, setImage ] = useState('');
    const [partner, setPartner ] = useState('');
    const [category, setCategory ] = useState('');
    const [location, setLocation ] = useState('');
    const [description, setDescription ] = useState('');
    const [participantLevel, setParticipantLevel ] = useState(0);
    const [isInvitationOnly, setIsInvitationOnly ] = useState(false);
    const [voucherUrl, setVoucherUrl ] = useState('');
    const [duration, setDuration ] = useState('');
    const [honorGuestsIntro, setHonorGuestsIntro ] = useState('');
    const [hostIntro, setHostIntro ] = useState('');
    const [hostUserId, setHostUserId ] = useState('');
    const [isHostIdError, setIsHostIdError] = useState(false);
    const [errorMessageHostId, setErrorMessageHostId] = useState('');
    const [topics, setTopics ] = useState('');
    const [registrationDeadLine, setRegistrationDeadLine ] = useState(new Date(today.setMonth(today.getMonth() + 1)));
    const [eventStartDateTime, setEventStartDateTime ] = useState(new Date(today.setMonth(today.getMonth() + 1)));
    const [eventEndDateTime, setEventEndDateTime ] = useState(new Date(today.setMonth(today.getMonth() + 1)));
    const [stock, setStock ] = useState(0);
    const [manager1UserId, setManager1UserId ] = useState('');
    const [isManager1IdError, setIsManager1IdError] = useState(false);
    const [errorMessageManager1, setErrorMessageManager1] = useState('');
    const [manager2UserId, setManager2UserId ] = useState('');
    const [isManager2IdError, setIsManager2IdError] = useState(false);
    const [errorMessageManager2, setErrorMessageManager2] = useState('');
    const [isFree, setIsFree ] = useState(false);
    const [level0Fee, setLevel0Fee ] = useState(0.00);
    const [level1Fee, setLevel1Fee ] = useState(0.00);
    const [level0StripeId, setLevel0StripeId ] = useState('');
    const [level1StripeId, setLevel1StripeId ] = useState('');
    const createNewEvent = () => {
        if( !hostUserId){
            setIsHostIdError(true);
            setErrorMessageHostId('Host ID cannot be empty');
            return;
        }
        if (hostUserId.length < 24) {
            setIsHostIdError(true);
            setErrorMessageHostId('Invalid ObjectId for Host ID, you can use 66e353129070050ffcaae6dd as default ');
            return;
        } else {
            setIsHostIdError(false);
            setErrorMessageHostId('');
        }
        if( !manager1UserId){
            setIsManager1IdError(true);
            setErrorMessageManager1('Manager 1 ID cannot be empty');
            return;
        }
        if (manager1UserId.length < 24) {
            setIsManager1IdError(true);
            setErrorMessageManager1('Invalid ObjectId for Manager 1 ID, you can use 66e353129070050ffcaae6dd as default ');
            return;
        } else {
            setIsManager1IdError(false);
            setErrorMessageManager1('');
        }

        if( !manager2UserId){
            setIsManager2IdError(true);
            setErrorMessageManager2('Manager 2 ID cannot be empty');
            return;
        }
        if (manager2UserId.length < 24) {
            setIsManager2IdError(true);
            setErrorMessageManager2('Invalid ObjectId for Manager 2 ID, you can use 6711962afa46477291496e96 as default ');
            return;
        } else {
            setIsManager2IdError(false);
            setErrorMessageManager2('');
        }
        dispatch(
            uploadEvent({
                title, subtitle, image, partner, category, location, description, participantLevel,
    isInvitationOnly, voucherUrl, duration, honorGuestsIntro, hostIntro, hostUserId, topics, registrationDeadLine,
    eventStartDateTime, eventEndDateTime, stock, manager1UserId, manager2UserId, 
    isFree,level0Fee, level1Fee, level0StripeId, level1StripeId
            })
        );
    };
  return (
    <>
    <Tr>
        <Td>
            <Text fontSize='sm'>Description</Text></Td><Td>
            <Textarea
                value={description}
                w='80vh'
                h='120px'
                onChange={(e) => setDescription(e.target.value)}
                placeholder='Event Description'
                size='sm'
            />
            
        </Td>
        </Tr><Tr>
        <Td> Honor Guests Introduction: </Td>
        <Td>
            <Textarea
                w='80vh' 
                h='120px'
                value={honorGuestsIntro}
                onChange={(e) => setHonorGuestsIntro(e.target.value)}
                placeholder='Honor Guests Introduction'
                size='m'
            />
        </Td>
        </Tr><Tr>
        <Td> Host Introduction: </Td>
        <Td>
            <Textarea
                w='80vh' 
                h='120px'
                value={hostIntro}
                onChange={(e) => setHostIntro(e.target.value)}
                placeholder='Host Introduction'
                size='m'
            />
        </Td>
        </Tr><Tr>
        <Td> Topics: </Td>
        <Td>
            <Textarea
                w='80vh' 
                h='120px'
                value={topics}
                onChange={(e) => setTopics(e.target.value)}
                placeholder='Event major topics'
                size='m'
            />
        </Td>
        </Tr><Tr>
        <Td>Title & Subtitle</Td>
        <Td>
            <Flex direction='column' gap='2'>
                <Input size='sm' value={title} onChange={(e) => setTitle(e.target.value)} placeholder='Event Title'/>
                <Input size='sm' value={subtitle} onChange={(e) => setSubtitle(e.target.value)} placeholder='Event subtitle'/>
            </Flex>
        </Td>
    </Tr><Tr>
        <Td>Partner & Category</Td>
        <Td>
            <Flex direction='column' gap='2'>
                <Input size='sm' value={partner} onChange={(e) => setPartner(e.target.value)} placeholder='Menos AI ..'/>
                <Input size='sm' value={category} onChange={(e) => setCategory(e.target.value)} placeholder='Offline ..'/>
            </Flex>
        </Td>
    </Tr><Tr>
        <Td>Image & Location</Td>
        <Td>
            <Flex direction='column' gap='2'>
                <Input size='sm' value={image} onChange={(e) => setImage(e.target.value)} />
                <Input size='sm' value={location} onChange={(e) => setLocation(e.target.value)} placeholder='New York City'/>
            </Flex>
        </Td>
    </Tr><Tr>
        <Td>Participant Level & Duration</Td>
        <Td>
            <Flex direction='column' gap='2'>
                <Input size='sm' value={participantLevel} onChange={(e) => setParticipantLevel(e.target.value)} placeholder='0'/>
                <Input size='sm' value={duration} onChange={(e) => setDuration(e.target.value)} placeholder='three hours..'/>
            </Flex>
        </Td>
    </Tr><Tr>
        <Td>is Invitation Only & Voucher URL </Td>
        <Td>
            <Flex direction='column' gap='2'>
                <FormControl display='flex' alignItems='center'>
                    <FormLabel htmlFor='eventIsInvitationOnlyFlag' mb='0' fontSize='sm'>
                        Enable
                        <Badge rounded='full' px='1' mx='1' fontSize='0.8m' colorScheme='green'>
                            Invitation Only
                        </Badge>
                        badge ?
                    </FormLabel>
                    <Switch id='eventIsInvitationOnlyFlag' onChange={() => setIsInvitationOnly(!isInvitationOnly)} isChecked={isInvitationOnly} />
                </FormControl>
                <Input size='sm' value={voucherUrl} onChange={(e) => setVoucherUrl(e.target.value)} placeholder='input voucher url'/>
            </Flex>
        </Td>
    </Tr><Tr>
        <Td>Host User ID & Stock</Td>
        <Td>
            <Flex direction='column' gap='2'>
                <Input size='sm' value={hostUserId} onChange={(e) => setHostUserId(e.target.value)} placeholder='Host User ID'/>
                {isHostIdError && (<Alert status="error">
                    <AlertIcon />
                    <Box flex="1">
                        <AlertTitle>Error!</AlertTitle>
                        <AlertDescription>{errorMessageHostId}</AlertDescription>
                        </Box>
                        <CloseButton position="absolute" right="8px" top="8px" onClick={() => setIsHostIdError(false)} />
                        </Alert>)}
                <Input size='sm' value={stock} onChange={(e) => setStock(e.target.value)} placeholder='100'/>
            </Flex>
        </Td>
        </Tr><Tr>
        <Td>is Free </Td>
        <Td>
                <FormControl display='flex' alignItems='center'>
                    <FormLabel htmlFor='isFreeFlag' mb='0' fontSize='sm'>
                        Enable
                        <Badge rounded='full' px='1' mx='1' fontSize='0.8m' colorScheme='green'>
                            Free
                        </Badge>
                        badge ?
                    </FormLabel>
                    <Switch id='isFreeFlag' onChange={() => setIsFree(!isFree)} isChecked={isFree} />
                </FormControl>
        </Td>
    </Tr><Tr>
        <Td>Level 0 Fee & Level 1 Fee</Td>
        <Td>
            <Flex direction='column' gap='2'>
                <Input size='sm' value={level0Fee} onChange={(e) => setLevel0Fee(e.target.value)} />
                <Input size='sm' value={level1Fee} onChange={(e) => setLevel1Fee(e.target.value)} />
            </Flex>
        </Td>
        </Tr><Tr>
        <Td>Level 0 Stripe ID & Level 1 Stripe ID</Td>
        <Td>
            <Flex direction='column' gap='2'>
                <Input size='sm' value={level0StripeId} onChange={(e) => setLevel0StripeId(e.target.value)} />
                <Input size='sm' value={level1StripeId} onChange={(e) => setLevel1StripeId(e.target.value)} />
            </Flex>
        </Td>
        </Tr><Tr>
        <Td>Registration Deadline</Td>
        <Td>
            <Input size='sm' value={registrationDeadLine} onChange={(e) => setRegistrationDeadLine(e.target.value)} />
            
        </Td>

    </Tr><Tr>
        <Td>Event Start DateTime & Event End DateTime</Td>
        <Td>
            <Flex direction='column' gap='2'>
                <Input size='sm' value={eventStartDateTime} onChange={(e) => setEventStartDateTime(e.target.value)} />
                <Input size='sm' value={eventEndDateTime} onChange={(e) => setEventEndDateTime(e.target.value)} />
            </Flex>
        </Td>
    </Tr><Tr>
        <Td>Manager 1 User ID & Manager 2 User ID</Td>
        <Td>
            <Flex direction='column' gap='2'>
                <Input size='sm' value={manager1UserId} onChange={(e) => setManager1UserId(e.target.value)} />
                {isManager1IdError && (<Alert status="error">
                    <AlertIcon />
                    <Box flex="1">
                        <AlertTitle>Error!</AlertTitle>
                        <AlertDescription>{errorMessageManager1}</AlertDescription>
                        </Box>
                        <CloseButton position="absolute" right="8px" top="8px" onClick={() => setIsManager1IdError(false)} />
                        </Alert>)}
                <Input size='sm' value={manager2UserId} onChange={(e) => setManager2UserId(e.target.value)} />
                {isManager2IdError && (<Alert status="error">
                    <AlertIcon />
                    <Box flex="1">
                        <AlertTitle>Error!</AlertTitle>
                        <AlertDescription>{errorMessageManager2}</AlertDescription>
                        </Box>
                        <CloseButton position="absolute" right="8px" top="8px" onClick={() => setIsManager2IdError(false)} />
                        </Alert>)}
            </Flex>
        </Td>
    </Tr>
    <Tr>
        <Td>
            <VStack>
                <Button variant='outline' w='160px' colorScheme='cyan' onClick={createNewEvent} >
                    <Text ml='2'>Save Event</Text>
                </Button>
            </VStack>
        </Td>
    </Tr>
    </>
  );
};

export default AddNewEvent;