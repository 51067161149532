import { DeleteIcon } from '@chakra-ui/icons';
import {
    Badge,
    Button,
    Flex,
    FormControl,
    FormLabel,
    Input,
    Switch,
    Td,
    Textarea,
    Tr,
    VStack,
    useDisclosure,
} from '@chakra-ui/react';
import { useRef, useState } from 'react';
import { MdOutlineDataSaverOn } from 'react-icons/md';
import { useDispatch, useSelector } from 'react-redux';
import { deleteCertificate, updateCertificate } from '../redux/actions/adminActions';
import ConfirmRemovalAlert from './ConfirmRemovalAlert';

const CertificateTableItem = ({certificate}) => {
    const { userInfo } = useSelector((state) => state.user);
    const cancelRef= useRef();
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [name, setName ] = useState(certificate.name);
    const [category, setCategory ] = useState(certificate.category);
    const [description, setDescription ] = useState(certificate.description);
    const [expireDateTime, setExpireDateTime ] = useState(certificate.expireDateTime);
    const [managerUserId, setManagerUserId ] = useState(certificate.managerUserId);
    const [examFee, setExamFee ] = useState(certificate.examFee);
    const [examFeeStripeId, setExamFeeStripeId ] = useState(certificate.examFeeStripeId);

    const dispatch = useDispatch();

    const onSaveCertificate = () => {
        dispatch(updateCertificate(certificate._id, name, category, description, expireDateTime, managerUserId, examFee, examFeeStripeId));
    };

    const openDeleteConfirmBox = () => {
        onOpen();
    };

  return (
    <>
    <Tr>
        <Td> Description: </Td>
        <Td>
            <Textarea
                w='80vh' 
                h='120px'
                value={description}
                onChange={(e) => setDescription(e.target.value)}
                size='m'
            />
        </Td>
    </Tr><Tr>
        <Td>Name & Category</Td>
        <Td>
            <Flex direction='column' gap='2'>
                <Input size='sm' value={name} onChange={(e) => setName(e.target.value)} />
                <Input size='sm' value={category} onChange={(e) => setCategory(e.target.value)} />
            </Flex>
        </Td>
    </Tr><Tr>
        <Td>Manager User ID & ExpireDateTime</Td>
        <Td>
            <Flex direction='column' gap='2'>
                <Input size='sm' value={managerUserId} onChange={(e) => setManagerUserId(e.target.value)} />
                <Input size='sm' value={expireDateTime} onChange={(e) => setExpireDateTime(e.target.value)} />
            </Flex>
        </Td>
    </Tr><Tr>
        <Td>Exam Fee & Exam Fee Stripe Id</Td>
        <Td>
            <Flex direction='column' gap='2'>
                <Input size='sm' value={examFee} onChange={(e) => setExamFee(e.target.value)} />
                <Input size='sm' value={examFeeStripeId} onChange={(e) => setExamFeeStripeId(e.target.value)} />
            </Flex>
        </Td>
    </Tr>
    {userInfo.isAdmin && (
    <ConfirmRemovalAlert 
                    isOpen={isOpen} 
                    onOpen={onOpen} 
                    onClose={onClose} 
                    cancelRef={cancelRef} 
                    itemToDelete={certificate}
                    deleteAction={deleteCertificate}
    />)}
    <Tr>
            <Td>Action</Td>
            <Td>
            <VStack align='start'>
                <Button colorScheme='red' variant='outline' onClick={openDeleteConfirmBox}>
                    <DeleteIcon mr='5px' />
                    Remove Certificate
                </Button>  
                <Button colorScheme='green' variant='outline' onClick={onSaveCertificate}>
                    <MdOutlineDataSaverOn style={{marginRight: '5px'}} />
                    Save Changes
                </Button>
            </VStack>
        </Td>
    </Tr>
    </>
  );
};

export default CertificateTableItem;

