import {
    Accordion,
    AccordionButton,
    AccordionItem,
    AccordionPanel,
    Alert,
    AlertDescription,
    AlertIcon,
    AlertTitle,
    Box,
    Spinner,
    Stack,
    Table,
    Tbody,
    Text,
    Th,
    Thead,
    Tr,
    Wrap,
    useToast,
    Divider,
} from '@chakra-ui/react';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getAccountingSummary } from '../redux/actions/accountingActions';

const AccountingSummary = () => {
    const dispatch = useDispatch();
    const { error, loading } = useSelector((state) => state.admin);
    const { accountingUpdate, creditSetList, couponSetList, startAccDate, beginOfThisYear, firstDayOfLastMonth, creditBalanceBefore,
        creditBalance, couponBalanceBefore, couponBalance } = useSelector((state) => state.accounting);
    const toast = useToast();
    const creditChange = creditBalance - creditBalanceBefore;
    const couponChange = couponBalance - couponBalanceBefore;
    const today = new Date();
    const todayOptions = { year: 'numeric', month: 'short', day: '2-digit' };
    const formattedToday = today.toLocaleDateString('en-US', todayOptions);

    useEffect(() => {
            dispatch(getAccountingSummary());
            if(accountingUpdate){
                toast({
                    description: 'Action is made.',
                    status: 'success',
                    isClosable: true,
                });
            }
        }, [dispatch, toast, accountingUpdate]);
    
    const turnDateFormat = (dateString) => {
        const date = new Date(dateString);
        const options = { year: 'numeric', month: 'short', day: '2-digit' };
        const formattedDate = date.toLocaleDateString('en-US', options);
        return formattedDate;
    };
    
  return (
    <Box>
    {error && (
        <Alert status='error'>
            <AlertIcon />
            <AlertTitle>Upps!</AlertTitle>
            <AlertDescription>{error}</AlertDescription>
        </Alert>
    )}
    {loading ? (
        <Wrap justify='center'>
            <Stack direction='row' spacing='4'>
                <Spinner mt='20' thickness='2px' speed='0.65s' emptyColor='gray.200' color='cyan.500' size='xl' />
            </Stack>
        </Wrap>
    ) : (
        <Box>
            <Text fontWeight='bold'>Summary: </Text>
            <Text>Annual Discount Credit Balance:</Text>
            <Text>Before {turnDateFormat(startAccDate)}: {creditBalanceBefore.toFixed(2)} USD </Text>
            <Text>Total up to {formattedToday}: {creditBalance.toFixed(2)} USD </Text>
            <Text>Increase from {turnDateFormat(startAccDate)} to {formattedToday}: {creditChange.toFixed(2)} USD</Text>
            <Divider/>
            <Text>Coupon Balance:</Text>
            <Text>Before {turnDateFormat(startAccDate)}: {couponBalanceBefore.toFixed(2)} USD </Text>
            <Text>Total up to {formattedToday}: {couponBalance.toFixed(2)} USD </Text>
            <Text>Increase from {turnDateFormat(startAccDate)} to {formattedToday}:  {couponChange.toFixed(2)} USD</Text>
            <Divider/>
            <Text fontWeight='bold'>Annual Discount Credit details from {turnDateFormat(startAccDate)} to today</Text>
            {creditSetList && creditSetList.length > 0 &&
                        creditSetList.map((creditSet) => (
                            
            <Accordion key={creditSet.id} allowToggle>
                <AccordionItem>
                    <h2>
                        <AccordionButton>
                            <Box flex='1' textAlign='left'>
                                <Box>
                                    <Text key={creditSet.id} mr='8px'>{creditSet.isCharge ? '(ClubCharge)' : '(ClubPaid)'}: ${creditSet.amount} for {creditSet.userName} on {turnDateFormat(creditSet.placeDate)}</Text>
                                </Box>
                            </Box>
                        </AccordionButton>
                    </h2>
                    <AccordionPanel pb='4'>
                        <Text>{creditSet.userEmail}</Text>
                        <Text>{creditSet.userName}</Text>
                        <Text>{creditSet.description}</Text>
                        <Text>{creditSet.category}</Text>
                    </AccordionPanel>
                </AccordionItem>
            </Accordion>
            ))}
            <Divider/>
            <Text fontWeight='bold'>Coupon details from {turnDateFormat(startAccDate)} to today</Text>
            {couponSetList && couponSetList.length > 0 &&
                        couponSetList.map((couponSet) => (
                            
            <Accordion key={couponSet.id} allowToggle>
                <AccordionItem>
                    <h2>
                        <AccordionButton>
                            <Box flex='1' textAlign='left'>
                                <Box>
                                    <Text key={couponSet.id} mr='8px' >Coupon {couponSet.isCharge ? '(ClubCharge)' : '(ClubPaid)'}: ${couponSet.amount} for {couponSet.userName} on {turnDateFormat(couponSet.placeDate)}</Text>
                                </Box>
                            </Box>
                        </AccordionButton>
                    </h2>
                    <AccordionPanel pb='4'>
                    <Text>{couponSet.userEmail}</Text>
                        <Text>{couponSet.userName}</Text>
                        <Text>{couponSet.description}</Text>
                        <Text>{couponSet.category}</Text>
                    </AccordionPanel>
                </AccordionItem>
            </Accordion>
            ))}
        </Box>
    )}
</Box>
  );
};

export default AccountingSummary;