import {
  Box,
  TableContainer,
  Th,
  Tr,
  Table,
  Td,
  Thead,
  Tbody,
  Button,
  useDisclosure,
  Alert,
  Stack,
  Spinner,
  AlertIcon,
  AlertTitle,
  AlertDescription,
  Wrap,
  useToast,
  Accordion,
  AccordionButton,
  AccordionItem,
  AccordionPanel,
  Flex,
  Text,
  Textarea,
  Spacer,
} from '@chakra-ui/react';
import { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { removeGroupClassReview } from '../redux/actions/adminActions';
import { getGroupClasses } from '../redux/actions/groupClassActions';

const GroupClassReviewsTab = () => {
  const dispatch = useDispatch();
  const { error, loading } = useSelector((state) => state.admin);
  const { groupClasses, reviewRemoval } = useSelector((state) => state.groupClass);
  const toast = useToast();

  useEffect(() => {
      dispatch(getGroupClasses());
      if(reviewRemoval) {
          toast({
              description: 'Review has been removed.',
              status: 'success',
              isClosable: true,
          });
      }
  }, [dispatch, toast, reviewRemoval, loading]);

  const onRemoveReview = (groupClassId, reviewId) => {
      dispatch(removeGroupClassReview(groupClassId, reviewId));
  };

return (
  <Box>
      {error && (
          <Alert status='error'>
              <AlertIcon />
              <AlertTitle>Upps!</AlertTitle>
              <AlertDescription>{error}</AlertDescription>
          </Alert>
      )}
      {loading && (
          <Wrap justify='center'>
              <Stack direction='row' spacing='4'>
                  <Spinner mt='20' thickness='2px' speed='0.65s' emptyColor='gray.200' color='cyan.500' size='xl' />
              </Stack>
          </Wrap>
      )}
      
      {!error && !loading && groupClasses && (
          <Box>
              {groupClasses.length > 0 &&
                  groupClasses.map((groupClass) => (
                      <Box key={groupClass._id}>
                          <Accordion allowToggle>
                              <AccordionItem>
                                  <h2>
                                      <AccordionButton>
                                          <Box flex='1'>
                                              <Flex>
                                                  <Text mr='8px' fontWeight='bold'>
                                                      {groupClass.title}
                                                  </Text>
                                                  <Spacer />
                                                  <Text mr='8px' fontWeight='bold'>
                                                      ({groupClass.reviews.length} Reviews)
                                                  </Text>
                                              </Flex>
                                          </Box>
                                      </AccordionButton>
                                  </h2>
                                  <AccordionPanel pb='4'>
                                      <TableContainer>
                                          <Table size='sm'>
                                              <Thead>
                                                  <Tr>
                                                      <Th>Name</Th>
                                                      <Th>Rating</Th>
                                                      <Th>Title</Th>
                                                      <Th>Comment</Th>
                                                      <Th>UserId</Th>
                                                      <Th>Created</Th>
                                                  </Tr>
                                              </Thead>
                                              <Tbody>
                                                  {groupClass.reviews.map((review) => (
                                                      <Tr key={review._id}>
                                                          <Td>{review.name}</Td>
                                                          <Td>{review.rating}</Td>
                                                          <Td>{review.title}</Td>
                                                          <Td>
                                                              <Textarea isDisabled value={review.comment} size='sm' />
                                                          </Td>
                                                          <Td>{review.UserId}</Td>
                                                          <Td>{new Date(review.createdAt).toDateString()}</Td>
                                                          <Td>
                                                              <Button
                                                                  variant='outline'
                                                                  colorScheme='red'
                                                                  onClick={() => onRemoveReview(groupClass._id, review._id)}>
                                                                      Removew Review
                                                              </Button>
                                                          </Td>
                                                      </Tr>
                                                  ))}
                                              </Tbody>
                                          </Table>
                                      </TableContainer>
                                  </AccordionPanel>
                              </AccordionItem>
                          </Accordion>
                          </Box>
                  )
              )
              }
          </Box>
      )}
  </Box>
);
};

export default GroupClassReviewsTab;
