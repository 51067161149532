import { DeleteIcon } from '@chakra-ui/icons';
import {
    Badge,
    Button,
    Flex,
    FormControl,
    FormLabel,
    Input,
    Switch,
    Td,
    Textarea,
    Tr,
    VStack,
    useDisclosure,
    Stack,
    Text,
} from '@chakra-ui/react';
import { useRef, useState } from 'react';
import { MdOutlineDataSaverOn } from 'react-icons/md';
import { useDispatch, useSelector } from 'react-redux';
import { deleteEvent, updateEvent } from '../redux/actions/adminActions';
import ConfirmRemovalAlert from './ConfirmRemovalAlert';

const EventTableItem = ({event}) => {
    const { userInfo } = useSelector((state) => state.user);

    const cancelRef= useRef();
    const { isOpen, onOpen, onClose } = useDisclosure();

    const [title, setTitle ] = useState(event.title);
    const [subtitle, setSubtitle ] = useState(event.subtitle);
    const [image, setImage ] = useState(event.image);
    const [partner, setPartner ] = useState(event.partner);
    const [category, setCategory ] = useState(event.category);
    const [location, setLocation ] = useState(event.location);
    const [description, setDescription ] = useState(event.description);
    const [participantLevel, setParticipantLevel ] = useState(event.participantLevel);
    const [isInvitationOnly, setIsInvitationOnly ] = useState(event.isInvitationOnly);
    const [voucherUrl, setVoucherUrl ] = useState(event.voucherUrl);
    const [duration, setDuration ] = useState(event.duration);
    const [honorGuestsIntro, setHonorGuestsIntro ] = useState(event.honorGuestsIntro);
    const [hostIntro, setHostIntro ] = useState(event.hostIntro);
    const [hostUserId, setHostUserId ] = useState(event.hostUserId);
    const [topics, setTopics ] = useState(event.topics);
    const [registrationDeadLine, setRegistrationDeadLine ] = useState(event.registrationDeadLine);
    const [eventStartDateTime, setEventStartDateTime ] = useState(event.eventStartDateTime);
    const [eventEndDateTime, setEventEndDateTime ] = useState(event.eventEndDateTime);
    const [stock, setStock ] = useState(event.stock);
    const [manager1UserId, setManager1UserId ] = useState(event.manager1UserId);
    const [manager2UserId, setManager2UserId ] = useState(event.manager2UserId);
    const [isFree, setIsFree ] = useState(event.isFree);
    const [level0Fee, setLevel0Fee] = useState(event.level0Fee);
    const [level1Fee, setLevel1Fee ] = useState(event.level1Fee);
    const [level0StripeId, setLevel0StripeId] = useState(event.level0StripeId);
    const [level1StripeId, setLevel1StripeId ] = useState(event.level1StripeId);
    const [estRegDate, setEstRegDate] = useState('');
    const [estStartDate, setEstStartDate] = useState('');
    const [estEndDate, setEstEndDate] = useState('');

    const turnDateIntoEst = (date, type) => {
        const estDate = new Date(date).toLocaleString('en-US', {
            timeZone: 'America/New_York',  // EST Timezone
            year: 'numeric',
            month: 'long',
            day: 'numeric',
            hour: 'numeric',
            minute: 'numeric',
            second: 'numeric',
            hour12: true,
          });
        type === 'Reg' ?  setEstRegDate(estDate) :
        type === 'Start' ? setEstStartDate(estDate) : setEstEndDate(estDate);
    }; 

    const dispatch = useDispatch();

    const onSaveEvent = () => {
        // const registrationDeadLine  = new Date(estRegDate);
        // console.log(estRegDate);
        // console.log(registrationDeadLine);
        dispatch(updateEvent(event._id, title, subtitle, image, partner, category, location, description, participantLevel,
            isInvitationOnly, voucherUrl, duration, honorGuestsIntro, hostIntro, hostUserId, topics, registrationDeadLine,
            eventStartDateTime, eventEndDateTime, stock, manager1UserId, manager2UserId, isFree, level0Fee, level1Fee, level0StripeId, level1StripeId));
    };
    const openDeleteConfirmBox = () => {
        onOpen();
    };

  return (
    <>
    <Tr>
        <Td> Description: </Td>
        <Td>
            <Textarea
                w='80vh' 
                h='120px'
                value={description}
                onChange={(e) => setDescription(e.target.value)}
                size='m'
            />
        </Td>
    </Tr><Tr>
        <Td> Honor Guests Introduction: </Td>
        <Td>
            <Textarea
                w='80vh' 
                h='120px'
                value={honorGuestsIntro}
                onChange={(e) => setHonorGuestsIntro(e.target.value)}
                size='m'
            />
        </Td>
    </Tr><Tr>
        <Td> Host Introduction: </Td>
        <Td>
            <Textarea
                w='80vh' 
                h='120px'
                value={hostIntro}
                onChange={(e) => setHostIntro(e.target.value)}
                size='m'
            />
        </Td>
    </Tr><Tr>
        <Td> Topics: </Td>
        <Td>
            <Textarea
                w='80vh' 
                h='120px'
                value={topics}
                onChange={(e) => setTopics(e.target.value)}
                size='m'
            />
        </Td>
    </Tr><Tr>
        <Td>Title & Subtitle</Td>
        <Td>
            <Flex direction='column' gap='2'>
                <Input size='sm' value={title} onChange={(e) => setTitle(e.target.value)} />
                <Input size='sm' value={subtitle} onChange={(e) => setSubtitle(e.target.value)} />
            </Flex>
        </Td>
    </Tr><Tr>
        <Td>Partner & Category</Td>
        <Td>
            <Flex direction='column' gap='2'>
                <Input size='sm' value={partner} onChange={(e) => setPartner(e.target.value)} />
                <Input size='sm' value={category} onChange={(e) => setCategory(e.target.value)} />
            </Flex>
        </Td>
    </Tr><Tr>
        <Td>Image & Location</Td>
        <Td>
            <Flex direction='column' gap='2'>
                <Input size='sm' value={image} onChange={(e) => setImage(e.target.value)} />
                <Input size='sm' value={location} onChange={(e) => setLocation(e.target.value)} />
            </Flex>
        </Td>
    </Tr><Tr>
        <Td>Participant Level & Duration</Td>
        <Td>
            <Flex direction='column' gap='2'>
                <Input size='sm' value={participantLevel} onChange={(e) => setParticipantLevel(e.target.value)} />
                <Input size='sm' value={duration} onChange={(e) => setDuration(e.target.value)} />
            </Flex>
        </Td>
    </Tr><Tr>
        <Td>is Invitation Only & Voucher URL </Td>
        <Td>
            <Flex direction='column' gap='2'>
                <FormControl display='flex' alignItems='center'>
                    <FormLabel htmlFor='eventIsInvitationOnlyFlag' mb='0' fontSize='sm'>
                        Enable
                        <Badge rounded='full' px='1' mx='1' fontSize='0.8m' colorScheme='green'>
                            Invitation Only
                        </Badge>
                        badge ?
                    </FormLabel>
                    <Switch id='eventIsInvitationOnlyFlag' onChange={() => setIsInvitationOnly(!isInvitationOnly)} isChecked={isInvitationOnly} />
                </FormControl>
                <Input size='sm' value={voucherUrl} onChange={(e) => setVoucherUrl(e.target.value)} />
            </Flex>
        </Td>
    </Tr><Tr>
        <Td>Host User ID & Stock</Td>
        <Td>
            <Flex direction='column' gap='2'>
                <Input size='sm' value={hostUserId} onChange={(e) => setHostUserId(e.target.value)} />
                <Input size='sm' value={stock} onChange={(e) => setStock(e.target.value)} />
            </Flex>
        </Td>
        </Tr><Tr>
        <Td>is Free </Td>
        <Td>
                <FormControl display='flex' alignItems='center'>
                    <FormLabel htmlFor='isFreeFlag' mb='0' fontSize='sm'>
                        Enable
                        <Badge rounded='full' px='1' mx='1' fontSize='0.8m' colorScheme='green'>
                            Free
                        </Badge>
                        badge ?
                    </FormLabel>
                    <Switch id='isFreeFlag' onChange={() => setIsFree(!isFree)} isChecked={isFree} />
                </FormControl>
        </Td>
    </Tr><Tr>
        <Td>Level 0 Fee & Level 1 Fee</Td>
        <Td>
            <Flex direction='column' gap='2'>
                <Input size='sm' value={level0Fee} onChange={(e) => setLevel0Fee(e.target.value)} />
                <Input size='sm' value={level1Fee} onChange={(e) => setLevel1Fee(e.target.value)} />
            </Flex>
        </Td>
        </Tr><Tr>
        <Td>Level 0 Stripe ID & Level 1 Stripe ID</Td>
        <Td>
            <Flex direction='column' gap='2'>
                <Input size='sm' value={level0StripeId} onChange={(e) => setLevel0StripeId(e.target.value)} />
                <Input size='sm' value={level1StripeId} onChange={(e) => setLevel1StripeId(e.target.value)} />
            </Flex>
        </Td>
    </Tr><Tr>
        <Td>Registration Deadline</Td>
        <Td><Flex direction='column' gap='2'>
            <Input size='sm' value={registrationDeadLine} onChange={(e) => {setRegistrationDeadLine(e.target.value)}} />
            <Flex direction='row' gap='2'>
            <Button colorScheme='green' variant='outline' onClick={ () => turnDateIntoEst(registrationDeadLine, 'Reg')}>
            Check Date
            </Button>
            <Text ml='2' mt='2'>New York Time: {estRegDate}</Text>
            </Flex>
            </Flex>
        </Td>

    </Tr><Tr>
        <Td>Event Start DateTime & Event End DateTime</Td>
        <Td>
            <Flex direction='column' gap='2'>
                <Input size='sm' value={eventStartDateTime} onChange={(e) => setEventStartDateTime(e.target.value)} />
                <Flex direction='row' gap='2'>
            <Button colorScheme='green' variant='outline' onClick={ () => turnDateIntoEst(eventStartDateTime, 'Start')}>
            Check Date
            </Button>
            <Text ml='2' mt='2'>New York Time: {estStartDate}</Text>
            </Flex>
                <Input size='sm' value={eventEndDateTime} onChange={(e) => setEventEndDateTime(e.target.value)} />
                <Flex direction='row' gap='2'>
            <Button colorScheme='green' variant='outline' onClick={ () => turnDateIntoEst(eventEndDateTime, 'End')}>
            Check Date
            </Button>
            <Text ml='2' mt='2'>New York Time: {estEndDate}</Text>
            </Flex>
            </Flex>
        </Td>
    </Tr><Tr>
        <Td>Manager 1 User ID & Manager 2 User ID</Td>
        <Td>
            <Flex direction='column' gap='2'>
                <Input size='sm' value={manager1UserId} onChange={(e) => setManager1UserId(e.target.value)} />
                <Input size='sm' value={manager2UserId} onChange={(e) => setManager2UserId(e.target.value)} />
            </Flex>
        </Td>
    </Tr>
    {userInfo.isAdmin && (
    <ConfirmRemovalAlert 
                    isOpen={isOpen} 
                    onOpen={onOpen} 
                    onClose={onClose} 
                    cancelRef={cancelRef} 
                    itemToDelete={event}
                    deleteAction={deleteEvent}
    />)}
    <Tr>
            <Td>Action</Td>
            <Td>
            <VStack align='start'>
                <Button colorScheme='red' variant='outline' onClick={openDeleteConfirmBox}>
                    <DeleteIcon mr='5px' />
                    Remove Event
                </Button>  
                <Button colorScheme='green' variant='outline' onClick={onSaveEvent}>
                    <MdOutlineDataSaverOn style={{marginRight: '5px'}} />
                    Save Changes
                </Button>
            </VStack>
        </Td>
    </Tr>
    </>
  );
};

export default EventTableItem;