import { Box, Stack, Heading, Tabs, TabList, Tab, TabPanels, TabPanel } from '@chakra-ui/react';
import { Navigate, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import EventsTab from '../components/EventsTab';

const EventAdminConsoleScreen = () => {
  const { userInfo } = useSelector((state) => state.user);
  const location = useLocation();

  return userInfo && (userInfo.isAdmin  || (userInfo.managerRoles && userInfo.managerRoles.includes(2)))? (
    <Box p='20px' minH='100vh'>
      <Stack direction={{ base: 'column', lg: 'row' }} align={{ lg: 'flex-start' }}>
        <Stack
          pr={{ base: '0', md: '14' }}
          spacing={{ base: '8', md: '10' }}
          flex='1.5'
          mb={{ base: '12', md: 'none' }}>
            <Heading fontSize='2xl' fontWeight='extrabold'>
              Event Admin Console
            </Heading>
            <Tabs size='md' variant='enclosed'>
              <TabList>
                <Tab>Events</Tab>
              </TabList>
              <TabPanels>
                <TabPanel>
                  <EventsTab />
                </TabPanel>
              </TabPanels>
            </Tabs>
          </Stack>
      </Stack>
    </Box>
  ) : (
    <Navigate to='/' replace={true} state={{ from: location }} />
  );
};

export default EventAdminConsoleScreen;
