import { 
    setLoading, 
    setError, 
    setMentors, 
    setMentor,
    setMentorChatMessage,
    mentorReviewed,
    resetError,
    setMentorUpdateFlag,
    setMenteeUpdateFlag,
} from '../slices/mentor';
import axios from 'axios';

export const getMentors = () => async (dispatch) => {
    dispatch(setLoading(true));
    try {
        const { data } = await axios.get('/api/mentors');
        dispatch(setMentors(data));
    } catch (error) {
        dispatch(
            setError(
                error.message ? error.message : 'Unexpected error has occured. Please try again later.'
            )
        );
    }
};

export const getMentor = (id) => async (dispatch) => {
    dispatch(setLoading(true));
    try {
        const { data } = await axios.get(`/api/mentors/${id}`);
        dispatch(setMentor(data));
    } catch (error) {
        dispatch(
            setError(
                error.message ? error.message : 'Unexpected error has occured. Please try again later.'
            )
        );
    }

};

export const chatWithMentor = (id, content) => async (dispatch, getState) => {
    // currently only can chat with Chanjuan Pan, whose mentor id is: 66e2f42d93a1a457d3402136
    if(id !== '66e2f42d93a1a457d3402136') {
        dispatch(setError("This Mentor has no chat box yet."));
    }

    dispatch(setLoading(true));

    const {
        user: { userInfo }
    } = getState();

    
    try {
        const postData = {
            messages: [{
               "role": "user",
               "content": content
            }],
            user_id: userInfo._id,
            //stream: true,
        };
        // const CHAT_CHANJUAN_API_KEY = '2B-Ipuz1pA1Tm5X7VDJAzs1nt2SQnyS9fAcpUxv12c7qO8D8MK4DE';
        const CHAT_CHANJUAN_API_KEY = '2B-3thfLhw02TPoXBqJTmcNP17SWp0PP3UNKjljWB3dVkQhyFwSGR';
        const config = {headers: { Authorization: `Bearer ${CHAT_CHANJUAN_API_KEY}`, 'Content-Type': 'application/json' }};
        const { data } = await axios.post('https://portal.2brain.ai/api/bot/chat/v1/chat/completions', postData , config);
        console.log(data.choices);
        const newDialog = {
            "you": content,
            "mentor": data.choices[0].message.content
        };
        dispatch(setMentorChatMessage(newDialog));
    } catch (error) {
        console.log(error);
        dispatch(
            setError(
                error.response && error.response.data.message 
                    ? error.response.data.message 
                    : error.message 
                    ? error.message : 'Unexpected error has occured. Please try again later.'
            )
        );
    }

};

export const createMentorReview = (mentorId, userId, comment, rating, title) => async (dispatch, getState) => {
    dispatch(setLoading(true));
    const {
        user: {userInfo },
    } = getState();
    try {
        const config = { headers: { Authorization: `Bearer ${userInfo.token}`, 'Content-Type': 'application/json' }};
        await axios.post(`/api/mentors/reviews/${mentorId}`, { comment, rating, userId, title }, config);
        dispatch(mentorReviewed(true));
    } catch (error) {
        dispatch(
            setError(
                error.response && error.response.data.message 
                    ? error.response.data.message 
                    : error.message 
                    ? error.message : 'Unexpected error has occured. Please try again later.'
            )
        );
    }
}

export const resetMentorError = () => async (dispatch) => {
    dispatch(resetError());
};

export const updateMentee = (name, email, userId, isPlaced, placedAt, commentFromMentor) => async (dispatch, getState) => {
    setLoading();
    const {
        user: { userInfo, usermentor },
    } = getState();
    const config = { headers: { Authorization: `Bearer ${userInfo.token}`, 'Content-Type': 'application/json' }};

    try {
        if( usermentor.userId.valueOf() !== userInfo._id.valueOf()) {
            throw new Error('You should be this mentor in order to update this mentee!');
        }
        const { data } = await axios.put(`${process.env.REACT_APP_WEBSITE_NAME}/api/mentors/mentees/${usermentor._id}`, {name, email, userId, isPlaced, placedAt, commentFromMentor}, config);
        dispatch(setMentor(data));
        dispatch(setMentorUpdateFlag());
        dispatch(setMenteeUpdateFlag());
    } catch (error) {
        dispatch(setError(
            error.response && error.response.data.message 
                ? error.response.data.message 
                : error.message 
                ? error.message : 'Unexpected error has occured. Please try again later.'
        ));
    }
};