import {
    Tr,
    Td,
    Button,
    VStack,
    Textarea,
    Tooltip,
    Input,
    FormControl,
    Text,
    FormLabel,
    Switch,
    Badge,
    Spacer,
} from '@chakra-ui/react';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { uploadMentor } from '../redux/actions/adminActions';

const AddNewMentor = () => {
    const dispatch = useDispatch();
    const [userId, setUserId] = useState('');
    const [name, setName ] = useState('');
    const [email, setEmail ] = useState('');
    const [industry, setIndustry ] = useState('');
    const [title, setTitle ] = useState('');
    const [linkedin, setLinkedin ] = useState('');
    const [twobrain, setTwobrain ] = useState('');
    const [image, setImage ] = useState('');
    const [introduction, setIntroduction ] = useState('');
/*     const [isAvailable, setIsAvailable ] = useState(true);
    const [busyReason, setBusyReason ] = useState(''); */
    const [fee, setFee ] = useState('');
    const [minutes, setMinutes ] = useState('');
    const [stripeId, setStripeId ] = useState('');

    const createNewMentor = () => {
        dispatch(
            uploadMentor({
                userId, 
                name, 
                email, 
                industry, 
                title, 
                linkedin, 
                twobrain, 
                image:`/images/${image}`, 
                introduction, 
                stripeId, 
                fee, 
                minutes
            })
        );
    };

  return (
    <>
    <Tr>
        <Td>
            <Text fontSize='sm'> Image File Name </Text>
            <Tooltip label={'Set the name of your image e.g., iPhone.jpg'} fontSize='sm'>
                <Input size='sm' value={image} onChange={(e) => setImage(e.target.value)} />
            </Tooltip>
        </Td>
        </Tr><Tr>
        <Td>
            <Text fontSize='sm'>Introduction</Text>
            <Textarea
                value={introduction}
                w='80vh'
                h='120px'
                onChange={(e) => setIntroduction(e.target.value)}
                placeholder='Introduction'
                size='sm'
            />
        </Td>
        </Tr><Tr>
        <Td>
            <Text fontSize='sm'>UserId</Text>
            <Input size='sm' value={userId} onChange={(e) => setUserId(e.target.value)} placeholder='mentor user database id' />
            <Text fontSize='sm'>Name</Text>
            <Input size='sm' value={name} onChange={(e) => setName(e.target.value)} placeholder='Mentor Pulbic Name' />
        </Td>
        </Tr><Tr>
        <Td>
            <Text fontSize='sm'>Email</Text>
            <Input size='sm' value={email} onChange={(e) => setEmail(e.target.value)} placeholder='mentor email' />
            <Text fontSize='sm'>Industry</Text>
            <Input size='sm' value={industry} onChange={(e) => setIndustry(e.target.value)} placeholder='Finance, Hedge Fund' />
        </Td>
        </Tr><Tr>
        <Td>
            <Text fontSize='sm'>Title</Text>
            <Input size='sm' value={title} onChange={(e) => setTitle(e.target.value)} placeholder='Senior Quant Researcher' />
            <Text fontSize='sm'>Linkedin</Text>
            <Input size='sm' value={linkedin} onChange={(e) => setLinkedin(e.target.value)} placeholder='Mentor Linkedin link' />
        </Td>
        </Tr><Tr>
        <Td>
            <Text fontSize='sm'>StripeId</Text>
            <Input size='sm' value={stripeId} onChange={(e) => setStripeId(e.target.value)} placeholder='price_...' />
            <Text fontSize='sm'>Twobrain</Text>
            <Input size='sm' value={twobrain} onChange={(e) => setTwobrain(e.target.value)} placeholder='Mentor Twobrain link' />
        </Td>
        </Tr><Tr>
        <Td>
            <Text fontSize='sm'>Fee</Text>
            <Input size='sm' value={fee} onChange={(e) => setFee(e.target.value)} placeholder='Mentor session fee' />
            <Text fontSize='sm'>Minutes</Text>
            <Input size='sm' value={minutes} onChange={(e) => setMinutes(e.target.value)} placeholder='Mentor session duration in minutes' />
        </Td>
        </Tr><Tr>
        <Td>
            <VStack>
                <Button variant='outline' w='160px' colorScheme='cyan' onClick={createNewMentor} >
                    <Text ml='2'>Save Mentor</Text>
                </Button>
            </VStack>
        </Td>
    </Tr>
    </>
  );
};

export default AddNewMentor;