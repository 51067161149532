import {
  Button,
  ButtonGroup,
  Container,
  Divider,
  IconButton,
  Input,
  Stack,
  Text,
  useColorModeValue as mode,
  Box,
  Flex,
  Icon,
  Link,
} from '@chakra-ui/react';
import { FaWeixin, FaLinkedin, FaTiktok } from 'react-icons/fa';
import { BsMortarboard } from 'react-icons/bs';
import { Link as ReactLink } from 'react-router-dom';

const Footer = () => {


  return (
  <Box w='100%' bg={mode('darkCerulean.100', 'gray.900')}>
    <Container as='footer' maxW='7xl'>
      <Stack
        spacing='8'
        direction={{ base: 'column', md: 'row' }}
        justify='space-between'
        py={{ base: '12', md: '16' }}>
          <Stack spacing={{ base: '6', md:'8' }} align='start'>
            <Flex alignItems='center'>
              <Icon as={BsMortarboard} h='10' w='10' color={mode('black', 'yellow.200')} />
              <Text fontSize='2xl' fontWeight='extrabold' ml='2' >
                Qishi Career Path Club
              </Text>
            </Flex>
            <Text color='muted'>If you want to go FAST, go ALONE; if you want to go FAR, go TOGETHER</Text>
          </Stack>
          <Stack direction={{ base: 'column-reverse', md: 'column', lg: 'row'}} spacing={{ base: '12', md:'8'}}>
            <Stack direction='row' spacing='8'>
              <Stack spacing='4' minW='36' flex='1'>
                <Link as={ReactLink} to='/programs'color='subtle'>Programs</Link>
                <Link as={ReactLink} to='/chat/2'color='subtle'>Membership</Link>
                <Link as={ReactLink} to='/chat/3'color='subtle'>Pricing</Link>
              </Stack>
              <Stack spacing='4' minW='36' flex='1'>
                <Link as={ReactLink} to='/events'color='subtle'>Events</Link>
                <Link as={ReactLink} to='/jobs'color='subtle'>Jobs</Link>
                <Link as={ReactLink} to='/services'color='subtle'>Services</Link>
              </Stack>
            </Stack>
            <Stack spacing='4'>
              <Link as={ReactLink} to='/mentors'color='subtle'>Mentors</Link>
              <Link as={ReactLink} to='/chat/4'color='subtle'>How it works</Link>
            </Stack>
          </Stack>
        </Stack>
        <Divider />
        <Stack pt='8' pb='12' justify='space-between' direction={{ base: 'column-reverse', md: 'row' }} align='center'>
          <Text fontSize='sm' color='subtle'>
            &copy; {new Date().getFullYear()} Flash Kick In Consulting, Inc. All rights reserved.
          </Text>
          <ButtonGroup variant='ghost'>
            <IconButton as={ReactLink} to='https://www.linkedin.com/groups/12179312/' icon={<FaLinkedin fontSize='1.25rem' /> } />
            <IconButton as='a' href='#' icon={<FaWeixin fontSize='1.25rem' /> } />
            <IconButton as='a' href='#' icon={<FaTiktok fontSize='1.25rem' /> } />
          </ButtonGroup>
        </Stack>
    </Container>
  </Box>
)};

export default Footer;