import { Text, Alert, AlertTitle, AlertIcon, AlertDescription, Box, Wrap, WrapItem, Center } from '@chakra-ui/react';
import ProgramCard from '../components/ProgramCard';
import ProgramHeader from '../components/ProgramHeader';
import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getPrograms } from '../redux/actions/programActions';


const ProgramsScreen = () => {
    const dispatch = useDispatch();
    const { loading, error, programs, selectedPrograms, mostRecentGroupClassesMap} = useSelector((state) => state.program);

    useEffect(() => {
        dispatch(getPrograms());
    }, [dispatch]);

    return (
        <>
            {error ? (
                <Alert status='error'>
                    <AlertIcon />
                    <AlertTitle>We are sorry!</AlertTitle>
                    <AlertDescription>{error}</AlertDescription>
                </Alert>
            ) : (
                <>
                {programs && programs.length >= 1 && (
                    <>
                    <ProgramHeader />
                <Box>
                    <Wrap spacing='30px' justify='center' minHeight='80vh' mx={{base: '12', md: '20', lg: '32' }}>
                            {selectedPrograms
                            .filter((program) => program.isPublic)
                            .map((program) => (
                                <WrapItem key={program._id}>
                                    <Center w='250px' h='450px'>
                                        <ProgramCard program={program} mostRecentGroupClass={mostRecentGroupClassesMap[program._id]} loading={loading} />
                                    </Center>
                                </WrapItem>
                            ))
                        }   
                    </Wrap>
                </Box>
                </>
                )}
                </>
            )}
        </>
    );
};

export default ProgramsScreen;
