import {
    Badge,
    Button,
    Flex,
    FormControl,
    FormLabel,
    Input,
    Switch,
    Td,
    Textarea,
    Tr,
    VStack,
    useDisclosure,
    useToast,
} from '@chakra-ui/react';
import { useRef, useState } from 'react';
import { MdOutlineDataSaverOn } from 'react-icons/md';
import { useDispatch, useSelector } from 'react-redux';
import { updateTaker } from '../redux/actions/certificateActions';

const TakerTableItem = ({taker}) => {
    const { userInfo } = useSelector((state) => state.user);
    const toast = useToast();
    const cancelRef= useRef();
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [secretNote, setSecretNote ] = useState(taker.secretNote);
    const [issueDate, setIssueDate ] = useState(taker.issueDate);
    const [ isPublic, setIsPublic ] = useState(taker.isPublic);
    const [ isApproved, setIsApproved ] = useState(taker.isApproved);
    const [ isFail, setIsFail ] = useState(taker.isFail);
    const [ lastFailDate, setLastFailDate ] = useState(taker.lastFailDate);
    
    
    const dispatch = useDispatch();

    const onSaveTaker = () => {
        dispatch(updateTaker(taker.name, taker.email, secretNote, issueDate, isPublic, isApproved, isFail, lastFailDate, taker.userId));
        toast({
            description: `Certificate taker ${taker.name} is updated.`,
            status: 'success',
            isClosable: true,
        });
    };


  return (
    <>
    <Tr>
                              <Td> Name: </Td>
                              <Td> {taker.name}</Td>
                            </Tr>
                            <Tr>
                              <Td> Email: </Td>
                              <Td> {taker.email}</Td>
                            </Tr>
                            <Tr>
                              <Td> if Public : </Td>
                              <Td>
                                <FormControl display='flex' alignItems='center'>
                                    <FormLabel htmlFor='isPublicFlag' mb='0' fontSize='sm'>
                                        Set
                                        <Badge rounded='full' px='1' mx='1' fontSize='0.8m' colorScheme='green'>
                                            Public
                                        </Badge>
                                    </FormLabel>
                                    <Switch id='isPublicFlag' onChange={() => setIsPublic(!isPublic)} isChecked={isPublic} />
                                </FormControl>
                              </Td>
                            </Tr>
                            <Tr>
                              <Td> if Approved : </Td>
                              <Td>
                                <FormControl display='flex' alignItems='center'>
                                    <FormLabel htmlFor='isApprovedFlag' mb='0' fontSize='sm'>
                                        Set
                                        <Badge rounded='full' px='1' mx='1' fontSize='0.8m' colorScheme='green'>
                                            is Approved
                                        </Badge>
                                    </FormLabel>
                                    <Switch id='isApprovedFlag' onChange={() => setIsApproved(!isApproved)} isChecked={isApproved} />
                                </FormControl>
                              </Td>
                            </Tr>
                            <Tr>
                            <Td> Issue Date: </Td>
                            <Td>
                            <Input size='sm' value={issueDate} onChange={(e) => setIssueDate(e.target.value)} />
                           </Td>
                            </Tr>
                            <Tr>
                              <Td> if Fail : </Td>
                              <Td>
                                <FormControl display='flex' alignItems='center'>
                                    <FormLabel htmlFor='isFailFlag' mb='0' fontSize='sm'>
                                        Set
                                        <Badge rounded='full' px='1' mx='1' fontSize='0.8m' colorScheme='green'>
                                            Fail
                                        </Badge>
                                    </FormLabel>
                                    <Switch id='isFailFlag' onChange={() => setIsFail(!isFail)} isChecked={isFail} />
                                </FormControl>
                              </Td>
                            </Tr>
                            <Tr>
                            <Td> Last Fail Date: </Td>
                            <Td>
                            <Input size='sm' value={lastFailDate} onChange={(e) => setLastFailDate(e.target.value)} />
                           </Td>
                            </Tr>
                            <Tr>
                            <Td> Secret Memo Note: </Td>
                            <Td>
                                <Textarea
                                    w='80vh' 
                                    h='120px'
                                    value={secretNote}
                                    onChange={(e) => setSecretNote(e.target.value)}
                                    size='m'
                                />
                           </Td>
                        </Tr>
                        <Tr>
                            <Td>Action</Td>
                            <Td>
                                <Button colorScheme='green' variant='outline' onClick={onSaveTaker}>
                                <MdOutlineDataSaverOn style={{marginRight: '5px'}} />
                                    Save Changes
                                </Button>
                            </Td>
    </Tr>
    </>
  );
};

export default TakerTableItem;