import {
    TableContainer,
    Stack,
    Spinner,
    Alert,
    AlertIcon,
    AlertDescription,
    AlertTitle,
    Th,
    Tbody,
    Tr,
    Thead,
    Button,
    ListItem,
    UnorderedList,
    Table,
    Td,
    Wrap,
    Link,
  } from '@chakra-ui/react';
  import { useDispatch, useSelector } from 'react-redux';
  import { Link as ReactLink } from 'react-router-dom';
  import { getUserRecords } from '../redux/actions/userActions';
  import { useEffect } from 'react';
  import { Navigate, useLocation } from 'react-router-dom';
  
  
  const YourRecordsScreen = () => {
    const dispatch = useDispatch();
    const { loading, error, records, userInfo } =  useSelector((state) => state.user);
    const location = useLocation();
  
    useEffect(() => {
      if(userInfo) {
        dispatch(getUserRecords());
      }
    }, [dispatch, userInfo]);
  
    return userInfo ? (
      <>
        {loading ? (
          <Wrap direction='column' align='center' mt='20px' justify='center' minHeight='100vh'>
              <Stack direction='row' spacing='4'>
                <Spinner mt='20' thickness='2px' speed='0.65s' emptyColor='gray.200' color='cyan.500' size='xl' />
              </Stack>
          </Wrap>
        ) : error ? (
          <Alert status='error'>
            <AlertIcon />
            <AlertTitle>We are sorry!</AlertTitle>
            <AlertDescription><Link as={ReactLink} textDecoration='underline' to='/logout' >You might need to logout and login again to access this page. </Link></AlertDescription>
          </Alert>
        ) : (
          records && (<TableContainer minH='100vh'>
            <Table variant='striped'>
              <Thead>
                <Tr>
                  <Th>Order Id</Th>
                  <Th>Order Date</Th>
                  <Th>Paid Total</Th>
                  <Th>Items</Th>
                  <Th>Confirmation Number</Th>
                </Tr>
              </Thead>
              <Tbody>
                {records.map((order) => (order.isConfirmed && (
                  <Tr key={order._id}>
                    <Td>{order._id}</Td>
                    <Td>{new Date(order.createdAt).toDateString()}</Td>
                    <Td>${order.totalPrice}</Td>
                    <Td>
                      {order.recordActivities.map((item) => (
                        <UnorderedList key={item._id}>
                          <ListItem>
                            {item.qty} x {item.name} (${item.price} each)
                          </ListItem>
                        </UnorderedList>
                      ))}
                    </Td>
                    <Td>
                      {order.confirmString}
                    </Td>
                  </Tr>
                )))}
              </Tbody>
            </Table>
          </TableContainer>)
        )}
      </>
    ) : (<Navigate to='/login' replace={true} state={{from: location }} />);
  };
  
  export default YourRecordsScreen;
