import {
    Box,
    TableContainer,
    Th,
    Tr,
    Table,
    Td,
    Thead,
    Tbody,
    Button,
    useDisclosure,
    Alert,
    Stack,
    Spinner,
    AlertIcon,
    AlertTitle,
    AlertDescription,
    Wrap,
    useToast,
    Link,
    Divider,
    Flex,
    Input,
    Text,
} from '@chakra-ui/react';
import { CheckCircleIcon, DeleteIcon } from '@chakra-ui/icons';
import { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link as ReactLink } from 'react-router-dom';
import { getAllUsersByPage, deleteUser, resetErrorAndRemoval } from '../redux/actions/adminActions';
import ConfirmRemovalAlert from './ConfirmRemovalAlert';
import Papa from 'papaparse';
import { saveAs } from 'file-saver';

const UserIssueTableItem = ({user}) => {
    const { isOpen, onOpen, onClose } = useDisclosure();
    const cancelRef = useRef();
    const [userToDelete, setUserToDelete ] = useState('');
    // const [selectedUsers, setSelectedUsers] = useState([]);
    // const [showSelected, setShowSelected] = useState(false);
    // const [searchTerm, setSearchTerm ] = useState('');

    const dispatch = useDispatch();
    const { error, loading, userRemoval, userList } = useSelector((state) => state.admin);
    const { userInfo } = useSelector((state) => state.user);
    const toast = useToast();

    useEffect(() => {
        // dispatch(getAllUsersByPage(1));
        dispatch(resetErrorAndRemoval());
        if(userRemoval) {
            toast({
                description: 'User has been removed.',
                status: 'success',
                isClosable: true,
            });
        }
    }, [dispatch, toast, userRemoval]);

    const openDeleteConfirmBox = (user) => {
        setUserToDelete(user);
        onOpen();
    };

        /*
    const searchUser = (searchTerm) => {
        if(searchTerm === '') {
            setShowSelected(false);
        } else {
            const filteredUsers = userList.filter(user => 
                user.name.toLowerCase().includes(searchTerm.toLowerCase()) || 
                user.email.toLowerCase().includes(searchTerm.toLowerCase())
            );
            setSelectedUsers(filteredUsers);
            setShowSelected(true);
        }
    }; 

    const convertToCSV = (data) => {
        const csv = Papa.unparse(data);
        return csv;
      };
    
      const downloadCSV = (csvData) => {
        const blob = new Blob([csvData], { type: 'text/csv;charset=utf-8;' });
        saveAs(blob, 'users.csv');
      };
    
      const downloadUsers = () => {
        const csvData = convertToCSV(userList);
        downloadCSV(csvData);
      };
 */
  return (
                                        <Stack>
                                            <Text>Name: {user.name} {user._id === userInfo._id ? '(You)' : ''}</Text>
                                            <Divider/>
                                            <Text>UserID: {user._id} ; If Is Admin: {user.isAdmin ? <CheckCircleIcon ml='2' color='cyan.500' /> : ''} ; Email: {user.email}</Text>
                                            <Text>Registered at: {new Date(user.createdAt).toDateString()}</Text>
                                        <Box>
                                            {user.membershipLevel === 1 ? (
                                                <>
                                                    <Text color='darkCerulean.200'>Basic</Text>
                                                    <Text><Link as={ReactLink} to={`/membership/2/${user._id}`}>
                                                        Upgrade to Gold
                                                    </Link></Text>
                                                    <Text><Link as={ReactLink} to={`/membership/10/${user._id}`}>
                                                        Upgrade to Platinum
                                                    </Link></Text>
                                                </>
                                            ) : user.membershipLevel === 2 ? (
                                                <>
                                                    <Text color='darkCerulean.200'>Gold</Text>
                                                    <Text><Link as={ReactLink} to={`/membership/1/${user._id}`}>
                                                        Downgrade to Basic
                                                    </Link></Text>
                                                    <Text><Link as={ReactLink} to={`/membership/10/${user._id}`}>
                                                        Upgrade to Platinum
                                                    </Link></Text>
                                                </>
                                            ) : (
                                                <>
                                                    <Text color='darkCerulean.200'>Platinum</Text>
                                                    <Text><Link as={ReactLink} to={`/membership/1/${user._id}`}>
                                                        Downgrade to Basic
                                                    </Link></Text>
                                                    <Text><Link as={ReactLink} to={`/membership/2/${user._id}`}>
                                                        Downgrade to Gold
                                                    </Link></Text>
                                                </>
                                            )}
                                        </Box>
                                        <Box>
                                            <Button 
                                                leftIcon={<DeleteIcon />}
                                                isDisabled={user._id === userInfo._id} 
                                                variant='outline' 
                                                onClick={() => openDeleteConfirmBox(user)}
                                                mb='2'>
                                                    Remove User
                                            </Button>
                                            <Divider/>
                                            <Text><Link fontWeight='bold' as={ReactLink} to={`/accountings/${user._id}`}>
                                                Check {user.name} Accounting
                                            </Link></Text>
                                            <Text><Link fontWeight='bold' as={ReactLink} to={`/one-user-orders/${user._id}`}>
                                                Check {user.name} Order History
                                            </Link></Text>
                                        </Box>
                                        <ConfirmRemovalAlert 
                                        isOpen={isOpen} 
                                        onOpen={onOpen} 
                                        onClose={onClose} 
                                        cancelRef={cancelRef} 
                                        itemToDelete={userToDelete}
                                        deleteAction={deleteUser}
                                        />
                                        </Stack>                     
  );
};

export default UserIssueTableItem;