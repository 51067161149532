import { 
    setLoading, 
    setError, 
    setCertificates, 
    setCertificate,
    certificateTaken,
    setCertificateUpdateFlag,
    resetError,
    setTakerUpdateFlag,
} from '../slices/certificate';
import axios from 'axios';


export const getCertificates = () => async (dispatch) => {
    dispatch(setLoading());
    try {
        const { data } = await axios.get(`/api/certificates/`);
        dispatch(setCertificates(data));
    } catch (error) {
        dispatch(
            setError(
                error.response && error.response.data.message 
                    ? error.response.data.message 
                    : error.message 
                    ? error.message : 'Unexpected error has occured. Please try again later.'
            )
        );
    }
};

export const getCertificate = (id) => async (dispatch) => {
    dispatch(setLoading(true));
    try {
        const { data } = await axios.get(`/api/certificates/${id}`);
        dispatch(setCertificate(data));
    } catch (error) {
        dispatch(
            setError(
                error.response && error.response.data.message 
                    ? error.response.data.message 
                    : error.message 
                    ? error.message : 'Unexpected error has occured. Please try again later.'
            )
        );
    }

};

export const resetCertificateError = () => async (dispatch) => {
    dispatch(resetError());
};


// update taker
export const updateTaker = (name, email, secretNote, issueDate, isPublic, isApproved, isFail, lastFailDate, userId) => async (dispatch, getState) => {
    setLoading();
    const {
        user: { userInfo },
        certificate: { certificate },
    } = getState();

    const config = { headers: { Authorization: `Bearer ${userInfo.token}`, 'Content-Type': 'application/json' }};

    try {
        if( certificate.managerUserId !== userInfo._id) {
            throw new Error('You should be the manager of this certificate in order to update this taker!');
        }
        const { data } = await axios.put(`${process.env.REACT_APP_WEBSITE_NAME}/api/certificates/takers/${certificate._id}`, {name, email, secretNote, issueDate, isPublic, isApproved, isFail, lastFailDate, userId}, config);
        dispatch(setCertificate(data));
        dispatch(setCertificateUpdateFlag());
        dispatch(setTakerUpdateFlag());
    } catch (error) {
        setError(
            error.response && error.response.data.message 
                ? error.response.data.message 
                : error.message 
                ? error.message : 'Unexpected error has occured. Please try again later.'
        );
    }
};