import {
    Tr,
    Td,
    Button,
    VStack,
    Textarea,
    Tooltip,
    Input,
    FormControl,
    Text,
    FormLabel,
    Switch,
    Badge,
    Spacer,
    Flex,
  } from '@chakra-ui/react';
  import { useEffect, useState } from 'react';
  import { useDispatch, useSelector } from 'react-redux';
  import { uploadGroupClass } from '../redux/actions/adminActions';
  import { getProgram } from '../redux/actions/programActions';
  
  const AddNewGroupClass = () => {
    const dispatch = useDispatch();
    const today = new Date();
    // const { userInfo } = useSelector((state) => state.user);
    const { programs } = useSelector((state) => state.program);
    const [selectedPrograms, setSelectedPrograms] = useState([]);
    const [showSelected, setShowSelected] = useState(false);
    const [searchTerm, setSearchTerm ] = useState('');
    const [title, setTitle ] = useState('');
    const [subtitle, setSubtitle ] = useState('');
    const [programId, setProgramId ] = useState('');
    const [toUseProgramId, setToUseProgramId ] = useState('');
    const [isMostRecent, setIsMostRecent ] = useState(true);
    const [description, setDescription ] = useState('');
    const [specialNotice, setSpecialNotice ] = useState('');
    const [memberComposed, setMemberComposed ] = useState('');
    const [requirement, setRequirement ] = useState('');
    const [courseContent, setCourseContent ] = useState('');
    const [syllabus, setSyllabus ] = useState('');
    const [registrationDeadLine, setRegistrationDeadLine ] = useState(new Date(today.setMonth(today.getMonth() + 1)));
    const [classStartDateTime, setClassStartDateTime ] = useState(new Date(today.setMonth(today.getMonth() + 1)));
    const [classEndDateTime, setClassEndDateTime ] = useState(new Date(today.setMonth(today.getMonth() + 3)));
    const [duration, setDuration ] = useState('');
    const [weight, setWeight ] = useState(2);
    const [isNeedResume, setIsNeedResume ] = useState(false);
    const [manager1UserId, setManager1UserId ] = useState('');
    const [manager2UserId, setManager2UserId ] = useState('');
    const [isFree, setIsFree ] = useState(false);
    const [level0Fee, setLevel0Fee ] = useState(0);
    const [level1Fee, setLevel1Fee ] = useState(0);
    const [level0StripeId, setLevel0StripeId ] = useState('');
    const [level1StripeId, setLevel1StripeId ] = useState('');
    const [applicantLevel, setApplicantLevel ] = useState(0);
    const [maxOfApplicants, setMaxOfApplicants] = useState(30);
    const [allowAudit, setAllowAudit ] = useState(false);
    const [auditManagerUserId, setAuditManagerUserId ] = useState('');
    const [isAuditFree, setIsAuditFree ] = useState(true);
    const [level0AuditFee, setLevel0AuditFee ] = useState(0);
    const [level1AuditFee, setLevel1AuditFee ] = useState(0);
    const [level0AuditStripeId, setLevel0AuditStripeId ] = useState('');
    const [level1AuditStripeId, setLevel1AuditStripeId ] = useState('');
    const [auditApplicantLevel, setAuditApplicantLevel ] = useState(0);
    const [maxOfAuditApplicants, setMaxOfAuditApplicants ] = useState(60);
    const [material, setMaterial ] = useState('');

    const searchProgram = (searchTerm) => {
        if(searchTerm === '') {
            setShowSelected(false);
        } else {
            const filteredPrograms = programs.filter(program => 
                program.title.toLowerCase().includes(searchTerm.toLowerCase()) || 
                program.description.toLowerCase().includes(searchTerm.toLowerCase())
            );
            setSelectedPrograms(filteredPrograms);
            setShowSelected(true);
        }
    }; 

    const useSelectedProgram = () => {
        if(selectedPrograms) {
            setToUseProgramId(selectedPrograms[0]._id);
            setProgramId(selectedPrograms[0]._id);
            setTitle(selectedPrograms[0].title);
            setSubtitle(selectedPrograms[0].subtitle);
            setDescription(selectedPrograms[0].description);
            setSpecialNotice(selectedPrograms[0].specialNotice);
            setMemberComposed(selectedPrograms[0].memberComposed);
            setRequirement(selectedPrograms[0].requirement);
            setCourseContent(selectedPrograms[0].courseContent);
            setSyllabus(selectedPrograms[0].syllabus);
            setDuration(selectedPrograms[0].duration);
            setWeight(selectedPrograms[0].weight);
            setIsFree(selectedPrograms[0].isFree);
            setLevel0Fee(selectedPrograms[0].level0Fee);
            setLevel1Fee(selectedPrograms[0].level1Fee);
            setLevel0StripeId(selectedPrograms[0].level0StripeId);
            setLevel1StripeId(selectedPrograms[0].level1StripeId);
            setApplicantLevel(selectedPrograms[0].applicantLevel);
            setMaxOfApplicants(selectedPrograms[0].maxOfApplicants);
            setIsAuditFree(selectedPrograms[0].isFree);
            setLevel0AuditFee(selectedPrograms[0].level0Fee);
            setLevel1AuditFee(selectedPrograms[0].level1Fee);
            setLevel0AuditStripeId(selectedPrograms[0].level0StripeId);
            setLevel1AuditStripeId(selectedPrograms[0].level1StripeId);
            setAuditApplicantLevel(selectedPrograms[0].applicantLevel);
            setMaxOfAuditApplicants(selectedPrograms[0].maxOfApplicants);
            setMaterial(selectedPrograms[0].material);
        }
    };

    const createNewGroupClass = () => {
        dispatch(
            uploadGroupClass({
                title, subtitle, programId, isMostRecent, description, specialNotice, memberComposed, requirement, courseContent, syllabus,
                registrationDeadLine, classStartDateTime, classEndDateTime, duration, weight, isNeedResume, manager1UserId, manager2UserId,
                isFree, level0Fee, level1Fee, level0StripeId, level1StripeId, applicantLevel, maxOfApplicants, allowAudit, auditManagerUserId, 
                isAuditFree, level0AuditFee, level1AuditFee, level0AuditStripeId, level1AuditStripeId, auditApplicantLevel, 
                maxOfAuditApplicants, material 
            })
        );
    };
  
  return (
    <>
    <Flex direction='row' gap='2'>
    <Input size='sm' value={searchTerm} onChange={(e) => {setSearchTerm(e.target.value)}} />
    <Button colorScheme='green' variant='outline' onClick={ () => searchProgram(searchTerm)}>
        search program
    </Button>
    </Flex>
    {showSelected && selectedPrograms && selectedPrograms.map((inProgram) => ( <Text>{inProgram.title}</Text> ))}
    <Tr>
        <Td> ProgramId & Duration</Td>
        <Td>
            <Flex direction='column' gap='2'>
            <Button colorScheme='green' variant='outline' onClick={useSelectedProgram}>
            Get Program Info
            </Button>
            <Input size='sm' value={programId} onChange={(e) => setProgramId(e.target.value)} placeholder='Related program ID'/>
            <Input size='sm' value={duration} onChange={(e) => setDuration(e.target.value)} placeholder='two months..'/>
            </Flex>
        </Td>

    </Tr>
    <Tr>
        <Td> Description: </Td>
        <Td>
            <Textarea
                w='80vh' 
                h='120px'
                value={description}
                onChange={(e) => setDescription(e.target.value)}
                placeholder='Group Class Description'
                size='m'
            />
        </Td>
    </Tr><Tr>
        <Td> Requirement: </Td>
        <Td>
            <Textarea
                w='80vh' 
                h='120px'
                value={requirement}
                onChange={(e) => setRequirement(e.target.value)}
                placeholder='Applicant Requirement'
                size='m'
            />
        </Td>
    </Tr><Tr>
        <Td> Special Notice: </Td>
        <Td>
            <Textarea
                w='80vh' 
                h='120px'
                value={specialNotice}
                onChange={(e) => setSpecialNotice(e.target.value)}
                placeholder='Special Notice'
                size='m'
            />
        </Td>

    </Tr><Tr>
        <Td> Member Composed: </Td>
        <Td>
            <Textarea
                w='80vh' 
                h='120px'
                value={memberComposed}
                onChange={(e) => setMemberComposed(e.target.value)}
                placeholder='One teaching assistant, eight members including one lead ..'
                size='m'
            />
        </Td>
    </Tr><Tr>
        <Td> Course Content: </Td>
        <Td>
            <Textarea
                w='80vh' 
                h='120px'
                value={courseContent}
                onChange={(e) => setCourseContent(e.target.value)}
                placeholder='Course content'
                size='m'
            />
        </Td>
    </Tr><Tr>
        <Td> Syllabus: </Td>
        <Td>
            <Textarea
                w='80vh' 
                h='120px'
                value={syllabus}
                onChange={(e) => setSyllabus(e.target.value)}
                placeholder='Eight classes in total. class 1: ... class 2: ...'
                size='m'
            />
        </Td>
    </Tr><Tr>
        <Td> Material: </Td>
        <Td>
            <Textarea
                w='80vh' 
                h='120px'
                value={material}
                onChange={(e) => setMaterial(e.target.value)}
                placeholder='Book ... or only disclosed to members..'
                size='m'
            />
        </Td>

    </Tr><Tr>
        <Td>Title & Subtitle</Td>
        <Td>
            <Flex direction='column' gap='2'>
                <Input size='sm' value={title} onChange={(e) => setTitle(e.target.value)} placeholder='Group Class Title'/>
                <Input size='sm' value={subtitle} onChange={(e) => setSubtitle(e.target.value)} placeholder='Group Class Subtitle'/>
            </Flex>
        </Td>
    </Tr><Tr>
        <Td> is Most Recent & Registration DeadLine </Td>
        <Td>
            <Flex direction='column' gap='2'>
                <FormControl display='flex' alignItems='center'>
                    <FormLabel htmlFor='isMostRecentFlag' mb='0' fontSize='sm'>
                        Enable
                        <Badge rounded='full' px='1' mx='1' fontSize='0.8m' colorScheme='green'>
                            Most Recent
                        </Badge>
                        badge ?
                    </FormLabel>
                    <Switch id='isMostRecentFlag' onChange={() => setIsMostRecent(!isMostRecent)} isChecked={isMostRecent} />
                </FormControl>
                <Input size='sm' value={registrationDeadLine} onChange={(e) => setRegistrationDeadLine(e.target.value)} />
            </Flex>
        </Td>
    </Tr><Tr>
        <Td> Class Start Date Time & End Date Time </Td>
        <Td>
            <Flex direction='column' gap='2'>
                <Input size='sm' value={classStartDateTime} onChange={(e) => setClassStartDateTime(e.target.value)} />
                <Input size='sm' value={classEndDateTime} onChange={(e) => setClassEndDateTime(e.target.value)} />
            </Flex>
        </Td>
        </Tr><Tr>
    <Td> is Free & Weight & is Need Resume</Td>
        <Td>
            <Flex direction='column' gap='2'>
                <FormControl display='flex' alignItems='center'>
                    <FormLabel htmlFor='isFreeFlag' mb='0' fontSize='sm'>
                        Enable
                        <Badge rounded='full' px='1' mx='1' fontSize='0.8m' colorScheme='green'>
                            Free
                        </Badge>
                        badge ?
                    </FormLabel>
                    <Switch id='isFreeFlag' onChange={() => setIsFree(!isFree)} isChecked={isFree} />
                </FormControl>
                <Input size='sm' value={weight} onChange={(e) => setWeight(e.target.value)} />
                <FormControl display='flex' alignItems='center'>
                    <FormLabel htmlFor='isNeedResumeFlag' mb='0' fontSize='sm'>
                        Enable
                        <Badge rounded='full' px='1' mx='1' fontSize='0.8m' colorScheme='green'>
                            Need Resume
                        </Badge>
                        badge ?
                    </FormLabel>
                    <Switch id='isNeedResumeFlag' onChange={() => setIsNeedResume(!isNeedResume)} isChecked={isNeedResume} />
                </FormControl>
            </Flex>
        </Td>
    </Tr><Tr>
        <Td>Manager 1 User ID & Manager 2 User ID</Td>
        <Td>
            <Flex direction='column' gap='2'>
                <Input size='sm' value={manager1UserId} onChange={(e) => setManager1UserId(e.target.value)} placeholder='Manager 1 userid'/>
                <Input size='sm' value={manager2UserId} onChange={(e) => setManager2UserId(e.target.value)} placeholder='Manager 2 userid'/>
            </Flex>
        </Td>
    </Tr><Tr>
        <Td>Level 0 Fee & Level 1 Fee</Td>
        <Td>
            <Flex direction='column' gap='2'>
                <Input size='sm' value={level0Fee} onChange={(e) => setLevel0Fee(e.target.value)} />
                <Input size='sm' value={level1Fee} onChange={(e) => setLevel1Fee(e.target.value)} />
            </Flex>
        </Td>
    </Tr><Tr>
        <Td>Level 0 Stripe ID & Level 1 Stripe ID</Td>
        <Td>
            <Flex direction='column' gap='2'>
                <Input size='sm' value={level0StripeId} onChange={(e) => setLevel0StripeId(e.target.value)} placeholder='price_test..'/>
                <Input size='sm' value={level1StripeId} onChange={(e) => setLevel1StripeId(e.target.value)} placeholder='price_test..'/>
            </Flex>
        </Td>
    </Tr><Tr>
        <Td>ApplicantLevel & Max Of Applications</Td>
        <Td>
            <Flex direction='column' gap='2'>
                <Input size='sm' value={applicantLevel} onChange={(e) => setApplicantLevel(e.target.value)} />
                <Input size='sm' value={maxOfApplicants} onChange={(e) => setMaxOfApplicants(e.target.value)} />
            </Flex>
        </Td>
    </Tr>

    <Tr>
    <Td> Allow Audit & is Audit Free  </Td>
        <Td>
            <Flex direction='column' gap='2'>
                <FormControl display='flex' alignItems='center'>
                    <FormLabel htmlFor='allowAuditFlag' mb='0' fontSize='sm'>
                        Enable
                        <Badge rounded='full' px='1' mx='1' fontSize='0.8m' colorScheme='green'>
                            Allow Audit
                        </Badge>
                        badge ?
                    </FormLabel>
                    <Switch id='allowAuditFlag' onChange={() => setAllowAudit(!allowAudit)} isChecked={allowAudit} />
                </FormControl>
                <FormControl display='flex' alignItems='center'>
                    <FormLabel htmlFor='isAuditFreeFlag' mb='0' fontSize='sm'>
                        Enable
                        <Badge rounded='full' px='1' mx='1' fontSize='0.8m' colorScheme='green'>
                            Audit Free
                        </Badge>
                        badge ?
                    </FormLabel>
                    <Switch id='isAuditFreeFlag' onChange={() => setIsAuditFree(!isAuditFree)} isChecked={isAuditFree} />
                </FormControl>
            </Flex>
        </Td>
    </Tr><Tr>
        <Td>Audit Manager User Id </Td>
        <Td>
            
        <Input size='sm' value={auditManagerUserId} onChange={(e) => setAuditManagerUserId(e.target.value)} placeholder='Audit Manager userid'/>
               
        </Td>
    </Tr><Tr>
        <Td>Level 0 Audit Fee & Level 1 Audit Fee</Td>
        <Td>
            <Flex direction='column' gap='2'>
                <Input size='sm' value={level0AuditFee} onChange={(e) => setLevel0AuditFee(e.target.value)} />
                <Input size='sm' value={level1AuditFee} onChange={(e) => setLevel1AuditFee(e.target.value)} />
            </Flex>
        </Td>
    </Tr><Tr>
        <Td>Level 0 Audit Stripe ID & Level 1 Audit Stripe ID</Td>
        <Td>
            <Flex direction='column' gap='2'>
                <Input size='sm' value={level0AuditStripeId} onChange={(e) => setLevel0AuditStripeId(e.target.value)} placeholder='price_test..'/>
                <Input size='sm' value={level1AuditStripeId} onChange={(e) => setLevel1AuditStripeId(e.target.value)} placeholder='price_test..'/>
            </Flex>
        </Td>
    </Tr><Tr>
        <Td>Audit ApplicantLevel & Max Of Audit Applications</Td>
        <Td>
            <Flex direction='column' gap='2'>
                <Input size='sm' value={auditApplicantLevel} onChange={(e) => setAuditApplicantLevel(e.target.value)} />
                <Input size='sm' value={maxOfAuditApplicants} onChange={(e) => setMaxOfAuditApplicants(e.target.value)} />
            </Flex>
        </Td>
    </Tr>
    <Tr>
        <Td>
            <VStack>
                <Button variant='outline' w='160px' colorScheme='cyan' onClick={createNewGroupClass} >
                    <Text ml='2'>Save Group Class</Text>
                </Button>
            </VStack>
        </Td>
    </Tr>
    </>
  );
  };
  
  export default AddNewGroupClass;
  