import { Alert, AlertTitle, AlertIcon, AlertDescription, Box, Wrap, WrapItem, Center } from '@chakra-ui/react';
import EventCard from '../components/EventCard';
import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getEvents } from '../redux/actions/eventActions';


const EventsScreen = () => {
    const dispatch = useDispatch();
    const { loading, error, events } = useSelector((state) => state.event);

    useEffect(() => {
        dispatch(getEvents());
    }, [dispatch]);

    return (
        <>
            {error ? (
                <Alert status='error'>
                    <AlertIcon />
                    <AlertTitle>We are sorry!</AlertTitle>
                    <AlertDescription>{error}</AlertDescription>
                </Alert>
            ) : (
                events && events.length >= 1 && (
                <Box>
                    <Wrap spacing='30px' justify='center' minHeight='80vh' mx={{base: '12', md: '20', lg: '32' }}>
                            {events.map((event) => (
                                <WrapItem key={event._id}>
                                    <Center w='250px' h='450px'>
                                        <EventCard event={event} loading={loading} />
                                    </Center>
                                </WrapItem>
                            ))
                        }   
                    </Wrap>
                </Box>
                )
            )}
        </>
    );
};

export default EventsScreen;