import {
    TableContainer,
    Stack,
    Spinner,
    Alert,
    AlertIcon,
    AlertDescription,
    AlertTitle,
    Th,
    Tbody,
    Tr,
    Thead,
    Button,
    ListItem,
    UnorderedList,
    Table,
    Td,
    Wrap,
    Text,
    Accordion,
    AccordionItem,
    AccordionButton,
    Box,
    AccordionPanel,
    Input,
    FormControl,
    FormLabel,
    Select,
    useToast,
    Link,
    Flex,
    Divider,
  } from '@chakra-ui/react';
  import { MdOutlineDataSaverOn } from 'react-icons/md';
  import { MdCardMembership } from "react-icons/md";
  import { CheckCircleIcon } from '@chakra-ui/icons';
  import { useDispatch, useSelector } from 'react-redux';
  import { updateUser, addReferId } from '../redux/actions/userActions';
  import { useEffect, useState } from 'react';
  import { Navigate, useLocation } from 'react-router-dom';
  import { addCartItemGeneral } from '../redux/actions/cartActions';
  import { Link as ReactLink } from 'react-router-dom';
  
  const YourProfileScreen = () => {
    const dispatch = useDispatch();
    const toast = useToast();
    const { loading, error, userInfo, groupClassTakenSetList, jobTakenSetList } =  useSelector((state) => state.user);
    const location = useLocation();
    const [name, setName ] = useState(userInfo.name);
    const [referId, setReferId ] = useState('');
    const [wechatId, setWechatId ] = useState(userInfo.wechatId);
    const [phoneNumber, setPhoneNumber ] = useState(userInfo.phoneNumber);
    const [gender, setGender] = useState(userInfo.gender);
  
    /* useEffect(() => {
      if(userInfo) {
        console.log(userInfo);
      }
    }, [dispatch, userInfo]); */

    const onSaveUser = () => {
        dispatch(updateUser(userInfo._id, name, wechatId, phoneNumber, gender));
    };

    const handleGenderChange = (event) => {
        setGender(event.target.value);
      };

    const isLevelOne = (value) => {
        if(value === 1) {
            return true;
        }
        else {
            return false;
        }
    }

    const addItem = (membershipSet) => {

        dispatch(addCartItemGeneral(`MembershipLevel${membershipSet['level']}`, 'Membership', membershipSet['emails'], membershipSet['name'], membershipSet['price'], membershipSet['stripeId']));
        if(referId !== '') {
            dispatch(addReferId(referId));
        }
        toast({
          description: 'You added this membership to your shopping cart. You might want to check out first before you apply other activities.',
          status: 'success',
          isClosable: true,
        });
      };
  
    return userInfo ? (
      <>
        {loading ? (
          <Wrap direction='column' align='center' mt='20px' justify='center' minHeight='100vh'>
              <Stack direction='row' spacing='4'>
                <Spinner mt='20' thickness='2px' speed='0.65s' emptyColor='gray.200' color='cyan.500' size='xl' />
              </Stack>
          </Wrap>
        ) : error ? (
          <Alert status='error'>
            <AlertIcon />
            <AlertTitle>We are sorry!</AlertTitle>
            <AlertDescription><Link as={ReactLink} textDecoration='underline' to='/logout' >You might need to logout and login again to access this page. </Link></AlertDescription>
          </Alert>
        ) : (
            <>
            <Text ml='8px' mt='8px' mb='8px'>Welcome to recommend your friends to Qishi Club Gold Membership with your referID : {userInfo._id} , and get 10 dollar bonus coupon. Notice: You need to have Gold and up membership first.</Text>
            <Accordion allowToggle>
                <AccordionItem>
                    <h2>
                        <AccordionButton>
                            <Box flex='1' textAlign='right'>
                                <Box>
                                    <Text mr='8px' fontWeight='bold'>Update your profile</Text>
                                </Box>
                            </Box>
                        </AccordionButton>
                    </h2>
                    <AccordionPanel pb='4'>
                        <Table>
                            <Tbody>
                            <Tr>
                                <Td> Name: </Td>
                                <Td>
                                <Input size='sm' value={name} onChange={(e) => setName(e.target.value)} />
                                </Td>
                            </Tr>
                            <Tr>
                                <Td> Wechat ID: </Td>
                                <Td>
                                <Input size='sm' value={wechatId} onChange={(e) => setWechatId(e.target.value)} />
                                </Td> 
                            </Tr><Tr>
                                <Td>Phone Number:</Td>
                                <Td>
                                <Input size='sm' value={phoneNumber} onChange={(e) => setPhoneNumber(e.target.value)} />
                                </Td> 
                            </Tr><Tr>
                                <Td>Gender</Td>
                                <Td>
                                <FormControl>
                                    <FormLabel htmlFor="genderSelect">Gender:</FormLabel>
                                    <Select id="genderSelect" value={gender} onChange={handleGenderChange} required>
                                    <option value="Male">Male</option>
                                     <option value="Female">Female</option>
                                    <option value="Other">Other</option>
                                  </Select>
                                  </FormControl>
                                </Td>
                            </Tr><Tr>
                             <Td>Action</Td>
                             <Td>
                                <Button colorScheme='green' variant='outline' onClick={onSaveUser}>
                                    <MdOutlineDataSaverOn style={{marginRight: '5px'}} />
                                Save Profile Changes
                                </Button>
            
                                </Td>
                            </Tr>
                            </Tbody>
                        </Table>
                    </AccordionPanel>
                </AccordionItem>
            </Accordion>
            <Text ml='8px' mt='8px' mb='8px' fontWeight='bold' >Your Membership: {userInfo.membershipName}</Text>
            {isLevelOne(userInfo.membershipLevel) && (
                <>
                <Text>If you have a qualified refer person, enter his/her refer ID to gain coupon when buying membership. Who is qualified: a person who has Gold or up membership in Qishi Club.</Text>
                <Input size='sm' value={referId} onChange={(e) => setReferId(e.target.value)} placeholder='reference persion ID' />
                <Button variant='outline' colorScheme='cyan' as={ReactLink} to='/cart' onClick={() => addItem(userInfo.toUpgradeMembershipSet)}>
                    Upgrade your membership
                </Button>
                </>
            )} 
            <Flex mt='5' mb='2' direction='row' alignItems='left'>
                <MdCardMembership size='20px' />
                <Link fontWeight='medium' fontSize='sm' ml='2' as={ReactLink} to='/chat/2' >
                        Learn more about memberships and benefits.
                </Link>
            </Flex>
            <Divider/>
            <Text ml='8px' mt='8px' mb='8px' fontWeight='bold' >Courses you have taken in most recent two years:</Text>
            <TableContainer mt='2' mb='2'>
            <Table variant='striped'>
              <Thead>
                <Tr>
                  <Th>Course Name</Th>
                  <Th>If is approved</Th>
                  <Th>If is audit class</Th>
                  <Th>Application Date</Th>
                  <Th>Class Start Date</Th>
                </Tr>
              </Thead>
              <Tbody>
                {groupClassTakenSetList.map((groupClass) => (
                  <Tr key={groupClass.groupClassId}>
                    <Td>{groupClass.groupClassName}</Td>
                    <Td>{( groupClass.isAudit || groupClass.isApproved) ? <CheckCircleIcon color='cyan.500' /> : ''}</Td>
                    <Td>{groupClass.isAudit ? <CheckCircleIcon color='cyan.500' /> : ''}</Td>
                    <Td>{new Date(groupClass.applicationDate).toDateString()}</Td>
                    <Td>{new Date(groupClass.startDate).toDateString()}</Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>
          </TableContainer>
          <Divider/>
          <Text ml='8px' mt='8px' mb='8px' fontWeight='bold' >Jobs you have applied in most recent two years:</Text>
          <TableContainer mt='2' mb='2'>
            <Table variant='striped'>
              <Thead>
                <Tr>
                  <Th>Job Title</Th>
                  <Th>Duration</Th>
                  <Th>Location</Th>
                  <Th>Application Date</Th>
                  <Th>Job Expire Date</Th>
                </Tr>
              </Thead>
              <Tbody>
                {jobTakenSetList.map((job) => (
                  <Tr key={job.jobId}>
                    <Td>{job.jobTitle}</Td>
                    <Td>{job.jobDuration}</Td>
                    <Td>{job.jobLocation}</Td>
                    <Td>{new Date(job.applicationDate).toDateString()}</Td>
                    <Td>{new Date(job.jobExpireDate).toDateString()}</Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>
          </TableContainer>
          </>
        )}
      </>
    ) : (<Navigate to='/login' replace={true} state={{from: location }} />);
  };
  
  export default YourProfileScreen;