import { DeleteIcon } from '@chakra-ui/icons';
import {
    Badge,
    Button,
    Flex,
    FormControl,
    FormLabel,
    Input,
    Switch,
    Td,
    Textarea,
    Tr,
    VStack,
    useDisclosure,
} from '@chakra-ui/react';
import { useRef, useState } from 'react';
import { MdOutlineDataSaverOn } from 'react-icons/md';
import { useDispatch, useSelector } from 'react-redux';
import { deleteProgram, updateProgram } from '../redux/actions/adminActions';
import ConfirmRemovalAlert from './ConfirmRemovalAlert';

const ProgramTableItem = ({program}) => {
    const { userInfo } = useSelector((state) => state.user);

    const cancelRef= useRef();
    const { isOpen, onOpen, onClose } = useDisclosure();


    const [title, setTitle ] = useState(program.title);
    const [subtitle, setSubtitle ] = useState(program.subtitle);
    const [image, setImage ] = useState(program.image);
    const [isTentative, setIsTentative ] = useState(program.isTentative);
    const [isPublic, setIsPublic ] = useState(program.isPublic);
    const [description, setDescription ] = useState(program.description);
    const [specialNotice, setSpecialNotice ] = useState(program.specialNotice);
    const [memberComposed, setMemberComposed ] = useState(program.memberComposed);
    const [requirement, setRequirement ] = useState(program.requirement);
    const [courseContent, setCourseContent ] = useState(program.courseContent);
    const [syllabus, setSyllabus ] = useState(program.syllabus);
    const [duration, setDuration ] = useState(program.duration);
    const [weight, setWeight ] = useState(program.weight);
    const [managerUserId, setManagerUserId ] = useState(program.managerUserId);
    const [certificateId, setCertificateId ] = useState(program.certificateId);
    const [isFree, setIsFree ] = useState(program.isFree);
    const [level0Fee, setLevel0Fee ] = useState(program.level0Fee);
    const [level1Fee, setLevel1Fee ] = useState(program.level1Fee);
    const [level0StripeId, setLevel0StripeId ] = useState(program.level0StripeId);
    const [level1StripeId, setLevel1StripeId ] = useState(program.level1StripeId);
    const [applicantLevel, setApplicantLevel ] = useState(program.applicantLevel);
    const [maxOfApplicants, setMaxOfApplicants] = useState(program.maxOfApplicants);
    const [material, setMaterial ] = useState(program.material);
    const [baseOfTotalTakers, setBaseOfTotalTakers ] = useState(program.baseOfTotalTakers);
    const [totalTakers, setTotalTakers ] = useState(program.totalTakers);
    

    const dispatch = useDispatch();

    const onSaveProgram = () => {
        dispatch(updateProgram(program._id, title, subtitle, image, isTentative, isPublic, description, specialNotice, memberComposed, 
            requirement, courseContent, syllabus, duration, weight, managerUserId, certificateId, isFree, level0Fee, 
            level1Fee, level0StripeId, level1StripeId, applicantLevel, maxOfApplicants, material, baseOfTotalTakers, totalTakers));
    };

    const openDeleteConfirmBox = () => {
        onOpen();
    };

  return (
    <>
    <Tr>
        <Td> Description: </Td>
        <Td>
            <Textarea
                w='80vh' 
                h='120px'
                value={description}
                onChange={(e) => setDescription(e.target.value)}
                size='m'
            />
        </Td>
    </Tr><Tr>
        <Td> Requirement: </Td>
        <Td>
            <Textarea
                w='80vh' 
                h='120px'
                value={requirement}
                onChange={(e) => setRequirement(e.target.value)}
                size='m'
            />
        </Td>
    </Tr><Tr>
        <Td> Special Notice: </Td>
        <Td>
            <Textarea
                w='80vh' 
                h='120px'
                value={specialNotice}
                onChange={(e) => setSpecialNotice(e.target.value)}
                size='m'
            />
        </Td>

    </Tr><Tr>
        <Td> Member Composed: </Td>
        <Td>
            <Textarea
                w='80vh' 
                h='120px'
                value={memberComposed}
                onChange={(e) => setMemberComposed(e.target.value)}
                size='m'
            />
        </Td>
    </Tr><Tr>
        <Td> Course Content: </Td>
        <Td>
            <Textarea
                w='80vh' 
                h='120px'
                value={courseContent}
                onChange={(e) => setCourseContent(e.target.value)}
                size='m'
            />
        </Td>
    </Tr><Tr>
        <Td> Syllabus: </Td>
        <Td>
            <Textarea
                w='80vh' 
                h='120px'
                value={syllabus}
                onChange={(e) => setSyllabus(e.target.value)}
                size='m'
            />
        </Td>
    </Tr><Tr>
        <Td> Material: </Td>
        <Td>
            <Textarea
                w='80vh' 
                h='120px'
                value={material}
                onChange={(e) => setMaterial(e.target.value)}
                size='m'
            />
        </Td>

    </Tr><Tr>
        <Td>Title & Subtitle</Td>
        <Td>
            <Flex direction='column' gap='2'>
                <Input size='sm' value={title} onChange={(e) => setTitle(e.target.value)} />
                <Input size='sm' value={subtitle} onChange={(e) => setSubtitle(e.target.value)} />
            </Flex>
        </Td>
    </Tr><Tr>
        <Td>image & duration</Td>
        <Td>
            <Flex direction='column' gap='2'>
                <Input size='sm' value={image} onChange={(e) => setImage(e.target.value)} />
                <Input size='sm' value={duration} onChange={(e) => setDuration(e.target.value)} />
            </Flex>
        </Td>

    </Tr><Tr>
        <Td> is Tentative & is Public </Td>
        <Td>
            <Flex direction='column' gap='2'>
                <FormControl display='flex' alignItems='center'>
                    <FormLabel htmlFor='isTentativeFlag' mb='0' fontSize='sm'>
                        Enable
                        <Badge rounded='full' px='1' mx='1' fontSize='0.8m' colorScheme='green'>
                            Tentative
                        </Badge>
                        badge ?
                    </FormLabel>
                    <Switch id='isTentativeFlag' onChange={() => setIsTentative(!isTentative)} isChecked={isTentative} />
                </FormControl>
                <FormControl display='flex' alignItems='center'>
                    <FormLabel htmlFor='isPublicFlag' mb='0' fontSize='sm'>
                        Enable
                        <Badge rounded='full' px='1' mx='1' fontSize='0.8m' colorScheme='green'>
                            Public
                        </Badge>
                        badge ?
                    </FormLabel>
                    <Switch id='isPublicFlag' onChange={() => setIsPublic(!isPublic)} isChecked={isPublic} />
                </FormControl>
            </Flex>
        </Td>
    </Tr><Tr>
        <Td>Weight & Manager User ID</Td>
        <Td>
            <Flex direction='column' gap='2'>
                <Input size='sm' value={weight} onChange={(e) => setWeight(e.target.value)} />
                <Input size='sm' value={managerUserId} onChange={(e) => setManagerUserId(e.target.value)} />
            </Flex>
        </Td>
    </Tr><Tr>
        <Td>Is Free & Certificate ID</Td>
        <Td>
            <Flex direction='column' gap='2'>
            <FormControl display='flex' alignItems='center'>
                <FormLabel htmlFor='isFreeFlag' mb='0' fontSize='sm'>
                        Enable
                        <Badge rounded='full' px='1' mx='1' fontSize='0.8m' colorScheme='green'>
                            Free
                        </Badge>
                        badge ?
                    </FormLabel>
                    <Switch id='isFreeFlag' onChange={() => setIsFree(!isFree)} isChecked={isFree} />
                </FormControl>
                <Input size='sm' value={certificateId} onChange={(e) => setCertificateId(e.target.value)} />
            </Flex>
        </Td>
    </Tr><Tr>
        <Td>Level 0 Fee & Level 1 Fee</Td>
        <Td>
            <Flex direction='column' gap='2'>
                <Input size='sm' value={level0Fee} onChange={(e) => setLevel0Fee(e.target.value)} />
                <Input size='sm' value={level1Fee} onChange={(e) => setLevel1Fee(e.target.value)} />
            </Flex>
        </Td>
    </Tr><Tr>
        <Td>Level 0 Stripe ID & Level 1 Stripe ID</Td>
        <Td>
            <Flex direction='column' gap='2'>
                <Input size='sm' value={level0StripeId} onChange={(e) => setLevel0StripeId(e.target.value)} />
                <Input size='sm' value={level1StripeId} onChange={(e) => setLevel1StripeId(e.target.value)} />
            </Flex>
        </Td>
    </Tr><Tr>
        <Td>ApplicantLevel & Max Of Applications</Td>
        <Td>
            <Flex direction='column' gap='2'>
                <Input size='sm' value={applicantLevel} onChange={(e) => setApplicantLevel(e.target.value)} />
                <Input size='sm' value={maxOfApplicants} onChange={(e) => setMaxOfApplicants(e.target.value)} />
            </Flex>
        </Td>
    </Tr>
    <Tr>
        <Td>Base of Total Takers & Total Takers</Td>
        <Td>
            <Flex direction='column' gap='2'>
                <Input size='sm' value={baseOfTotalTakers} onChange={(e) => setBaseOfTotalTakers(e.target.value)} />
                <Input size='sm' value={totalTakers} onChange={(e) => setTotalTakers(e.target.value)} />
            </Flex>
        </Td>
    </Tr>
    {userInfo.isAdmin && (
    <ConfirmRemovalAlert 
                    isOpen={isOpen} 
                    onOpen={onOpen} 
                    onClose={onClose} 
                    cancelRef={cancelRef} 
                    itemToDelete={program}
                    deleteAction={deleteProgram}
    />)}
    <Tr>
            <Td>Action</Td>
            <Td>
            <VStack align='start'>
                <Button colorScheme='red' variant='outline' onClick={openDeleteConfirmBox}>
                    <DeleteIcon mr='5px' />
                    Remove Program
                </Button>  
                <Button colorScheme='green' variant='outline' onClick={onSaveProgram}>
                    <MdOutlineDataSaverOn style={{marginRight: '5px'}} />
                    Save Changes
                </Button>
            </VStack>
        </Td>
    </Tr>
    </>
  );
};

export default ProgramTableItem;
