
import {
    Accordion,
    AccordionButton,
    AccordionItem,
    AccordionPanel,
    Alert,
    AlertDescription,
    AlertIcon,
    AlertTitle,
    Box,
    Spinner,
    Stack,
    Table,
    Tbody,
    Text,
    Th,
    Thead,
    Tr,
    Wrap,
    useToast,
} from '@chakra-ui/react';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getCertificates, resetCertificateError } from '../redux/actions/certificateActions';
import CertificateTableItem from './CertificateTableItem';
import AddNewCertificate from './AddNewCertificate';

const CertificatesTab = () => {
    const dispatch = useDispatch();
    const { error, loading } = useSelector((state) => state.admin);
    const { certificates, certificateUpdate } = useSelector((state) => state.certificate);
    const toast = useToast();

    useEffect(() => {
        dispatch(getCertificates());
        dispatch(resetCertificateError()); 
        if(certificateUpdate){
            toast({
                description: 'Certificate is updated.',
                status: 'success',
                isClosable: true,
            });
        }
    }, [dispatch, toast, certificateUpdate]);

  return (
    <Box>
    {error && (
        <Alert status='error'>
            <AlertIcon />
            <AlertTitle>Upps!</AlertTitle>
            <AlertDescription>{error}</AlertDescription>
        </Alert>
    )}
    {loading ? (
        <Wrap justify='center'>
            <Stack direction='row' spacing='4'>
                <Spinner mt='20' thickness='2px' speed='0.65s' emptyColor='gray.200' color='cyan.500' size='xl' />
            </Stack>
        </Wrap>
    ) : (
        <Box>
            <Accordion allowToggle>
                <AccordionItem>
                    <h2>
                        <AccordionButton>
                            <Box flex='1' textAlign='right'>
                                <Box>
                                    <Text mr='8px' fontWeight='bold'>Add a new Certificate</Text>
                                </Box>
                            </Box>
                        </AccordionButton>
                    </h2>
                    <AccordionPanel pb='4'>
                        <Table>
                            <Tbody>
                                <AddNewCertificate />
                            </Tbody>
                        </Table>
                    </AccordionPanel>
                </AccordionItem>
            </Accordion>
            {certificates && certificates.length > 0 &&
                        certificates.map((certificate) => (
                            
            <Accordion key={certificate._id} allowToggle>
                <AccordionItem>
                    <h2>
                        <AccordionButton>
                            <Box flex='1' textAlign='right'>
                                <Box>
                                    <Text key={certificate._id} mr='8px' fontWeight='bold'>{certificate.name}</Text>
                                </Box>
                            </Box>
                        </AccordionButton>
                    </h2>
                    <AccordionPanel pb='4'>
                        <Table>
                            <Tbody>
                            <CertificateTableItem key={certificate._id} certificate={certificate} />
                            </Tbody>
                        </Table>
                    </AccordionPanel>
                </AccordionItem>
            </Accordion>
            
            ))}
        </Box>
    )}
</Box>
  );
};

export default CertificatesTab;