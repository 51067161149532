import { createSlice } from '@reduxjs/toolkit';

export const initialState = {
    loading: false,
    error: null,
    accountings: [],
    accounting: null,
    accountingUpdate: false,
    annualFee: 0,
    annualFeeMessage: '',
    creditSetList: [],
    couponSetList: [],
    startAccDate: '',
    beginOfThisYear: '',
    firstDayOfLastMonth: '',
    creditBalanceBefore: 0.0,
    creditBalance: 0.0,
    couponBalanceBefore: 0.0,
    couponBalance: 0.0
};

export const accountingsSlice = createSlice({
    name: 'accountings',
    initialState,
    reducers: {
        setLoading: (state) => {
            state.loading = true;
        },
        setAccountings: (state, { payload }) => {
            state.loading = false;
            state.error = null;
            state.accountings = payload;
        },
        setAccounting: (state, { payload }) => {
            state.loading = false;
            state.error = null;
            state.accounting = payload;
        },
        setAnnualFeeMessage: (state, { payload }) => {
            state.loading = false;
            state.error = null;
            state.annualFeeMessage = payload;
        },
        setAnnualFee: (state, { payload }) => {
            state.loading = false;
            state.error = null;
            state.annualFee = Number(payload);
        },
        setError: (state, { payload }) => {
            state.loading = false;
            state.error = payload;
        },
        resetError: (state) => {
            state.error = null;
            state.taken = false;
            state.accountingUpdate = false;
        },
        setAccountingUpdateFlag: (state) => {
            state.accountingUpdate = true;
            state.loading = false;
        },
        setCreditSetList: (state, { payload }) => {
            state.loading = false;
            state.error = null;
            state.creditSetList = payload;
        },
        setCouponSetList: (state, { payload }) => {
            state.loading = false;
            state.error = null;
            state.couponSetList = payload;
        },
        setStartAccDate: (state, { payload }) => {
            state.loading = false;
            state.error = null;
            state.startAccDate = payload;
        },
        setBeginOfThisYear: (state, { payload }) => {
            state.loading = false;
            state.error = null;
            state.beginOfThisYear = payload;
        },
        setFirstDayOfLastMonth: (state, { payload }) => {
            state.loading = false;
            state.error = null;
            state.firstDayOfLastMonth = payload;
        },
        setCreditBalanceBefore: (state, { payload }) => {
            state.loading = false;
            state.error = null;
            state.creditBalanceBefore = Number(payload);
        },
        setCreditBalance: (state, { payload }) => {
            state.loading = false;
            state.error = null;
            state.creditBalance = Number(payload);
        },
        setCouponBalanceBefore: (state, { payload }) => {
            state.loading = false;
            state.error = null;
            state.couponBalanceBefore = Number(payload);
        },
        setCouponBalance: (state, { payload }) => {
            state.loading = false;
            state.error = null;
            state.couponBalance = Number(payload);
        },
    },
});

export const {
    setLoading, 
    setError, 
    setAccountings, 
    setAccounting,
    setAccountingUpdateFlag,
    resetError,
    setAnnualFee,
    setAnnualFeeMessage,
    setCreditSetList,
    setCouponSetList,
    setStartAccDate,
    setBeginOfThisYear,
    setFirstDayOfLastMonth,
    setCreditBalanceBefore,
    setCreditBalance,
    setCouponBalanceBefore,
    setCouponBalance,
} = accountingsSlice.actions;

export default accountingsSlice.reducer;

export const accountingSelector = (state) => state.accountings;
