import { Alert, AlertTitle, AlertIcon, AlertDescription, Box, Button, Center, Wrap, Stack, Heading, Text, Divider, Spinner, Flex, Input} from '@chakra-ui/react';
import {
    useToast,
} from '@chakra-ui/react';

import { useDispatch, useSelector } from 'react-redux';
import { ArrowLeftIcon, ArrowRightIcon } from '@chakra-ui/icons';
import UserIssueTableItem from '../components/UserIssueTableItem';

import { CheckCircleIcon, DeleteIcon } from '@chakra-ui/icons';
import { useEffect, useState, useRef } from 'react';
import { getAllUsersByPage, getAllUsersBySearch} from '../redux/actions/adminActions';
import Papa from 'papaparse';
import { saveAs } from 'file-saver';


const UserIssuesScreen = () => {

    const [searchTerm, setSearchTerm ] = useState('');

    const dispatch = useDispatch();
	const { loading, error, userList, pagination } = useSelector((state) => state.admin);

    useEffect(() => {
        if(searchTerm === '') {
		    dispatch(getAllUsersByPage(1));
        }
        else {
            dispatch(getAllUsersBySearch(searchTerm, 1));
        }
	}, [dispatch]);

    const handleKeyDown = (event) => { 
        if (event.key === 'Enter') { 
            searchUsers(searchTerm, 1); 
        } 
    };

    const searchUsers = (term, page) => {
        if(term === '') {
            setSearchTerm('');
            dispatch(getAllUsersByPage(page));
        } else {
            dispatch(getAllUsersBySearch(searchTerm, page));
        }
    };

    const convertToCSV = (data) => {
        const csv = Papa.unparse(data);
        return csv;
      };
    
      const downloadCSV = (csvData) => {
        const blob = new Blob([csvData], { type: 'text/csv;charset=utf-8;' });
        saveAs(blob, 'users.csv');
      };
    
      const downloadUsers = () => {
        const csvData = convertToCSV(userList);
        downloadCSV(csvData);
      };

	const paginationButtonClick = (page) => {
        searchUsers(searchTerm, page);
	};

  return (
 <Box>
        {error && (
            <Alert status='error'>
                <AlertIcon />
                <AlertTitle>Upps!</AlertTitle>
                <AlertDescription>{error}</AlertDescription>
            </Alert>
        )}
        {loading ? (
            <Wrap justify='center'>
                <Stack direction='row' spacing='4'>
                    <Spinner mt='20' thickness='2px' speed='0.65s' emptyColor='gray.200' color='cyan.500' size='xl' />
                </Stack>
            </Wrap>
        ) : (
            <>
            <Button onClick={downloadUsers}>Download Users</Button>
                <Flex direction='row' gap='2'>
                <Input size='sm' value={searchTerm} onChange={(e) => {setSearchTerm(e.target.value)}} onKeyDown={handleKeyDown}/>
                <Button colorScheme='green' variant='outline' onClick={ () => searchUsers(searchTerm, 1)}>
                search user
                </Button>
                <Button colorScheme='green' variant='outline' onClick={ () => searchUsers('', 1)}>
                back to all users
                </Button>
                </Flex>
			{userList && userList.length >= 1 && userList.map((user) => (
								<Box
                                key={user._id}
                                maxW={{ base: '3xl', lg: '5xl' }}
                                mx='auto'
                                px={{ base: '2', md: '4', lg: '6' }}
                                py={{ base: '2', md: '4', lg: '6' }}>
              
                    <Stack pr={{ base:'0', md: 'row' }} flex='1.5' mb={{ base: '12', md: 'none' }}>
                        <UserIssueTableItem key={user._id} user={user} />
                    </Stack>
                    <Divider borderWidth="2px" />
                </Box>
							))}
					
					{(
						<Wrap spacing='10px' justify='center' p='5'>
							<Button colorScheme='cyan' onClick={() => paginationButtonClick(1)}>
								<ArrowLeftIcon />
							</Button>
							{Array.from(Array(pagination.totalPages), (e, i) => {
								return (
									<Button
										colorScheme={pagination.currentPage === i + 1 ? 'cyan' : 'gray'}
										key={i}
										onClick={() => paginationButtonClick(i + 1)}>
										{i + 1}
									</Button>
								);
							})}
							<Button colorScheme='cyan' onClick={() => paginationButtonClick(pagination.totalPages)}>
								<ArrowRightIcon />
							</Button>
						</Wrap>
					)}
                    </>
		)}
        </Box>
  );
};

export default UserIssuesScreen;