import { createSlice } from '@reduxjs/toolkit';

export const initialState = {
    loading: false,
    error: null,
    events: [],
    event: null,
    bought: false,
    eventUpdate: false,
};

export const eventsSlice = createSlice({
    name: 'events',
    initialState,
    reducers: {
        setLoading: (state) => {
            state.loading = true;
        },
        setEvents: (state, { payload }) => {
            state.loading = false;
            state.error = null;
            state.events = payload;
        },
        setEvent: (state, { payload }) => {
            state.loading = false;
            state.error = null;
            state.event = payload;
            state.bought = false;
        },
        setError: (state, { payload }) => {
            state.loading = false;
            state.error = payload;
        },
        eventBought: (state, {payload}) => {
            state.loading = false;
            state.error = null;
            state.bought = payload;
        },
        resetError: (state) => {
            state.error = null;
            state.bought = false;
            state.eventUpdate = false;
        },
        setEventUpdateFlag: (state) => {
            state.eventUpdate = true;
            state.loading = false;
        },
    },
});

export const {
    setLoading,
    setEvents,
    setEvent,
    setError, 
    eventBought,
    setEventUpdateFlag,
    resetError,
} = eventsSlice.actions;

export default eventsSlice.reducer;

export const eventSelector = (state) => state.events;

