import { 
  Box,
  Stack,
  Spinner,
  Text,
 } from '@chakra-ui/react';
// import axios from 'axios';

import { Link as ReactLink } from 'react-router-dom';
import { goChatBot } from '../redux/actions/chatActions';
import { useDispatch, useSelector } from 'react-redux';
import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';

const ChatScreen = () => {

  const { queryid } = useParams();

  const dispatch = useDispatch();
  // const [isLoading, setIsLoading] = useState(false);
  const { loading, error } = useSelector((state) => state.chat);

  /* useEffect(() => {
    dispatch(goChatBot(queryid));
  }, [dispatch, queryid]); */

  const faqs = [ 
    { question: "Can you give me a brief introduction about qishi career path club?", 
      answer: `Founded in 2014 in New York, Qishi Career Path Club is a leading global study group platform, dedicated to empowering you to unlock your
      career potential through learning, networking, and shaping a growing path that aligns with your goals.
      
      Over the past decade, Qishi has launched various targeted learning groups in quantitative finance, financial markets, machine learning, and computer science.
      Our programs offer a comprehensive path from foundational knowledge to cutting-edge research, tailored to professionals at every career stage.
      Additionally, Qishi hosts various online and offline networking events, fostering technical exchange and providing insights into the latest industry trends.` }, 
    { question: "can you introduce different memberships and their benefits in Qishi Career Path Club?", 
      answer: `The Qishi Career Path Club offers three tiers of membership, each with its own benefits:
      
      Bronze (Basic Member): This is a free membership for anyone who registers QishiCPC website. Basic members can attend most audit-based study groups and participate in various online and offline events, but they do not receive any discounts.
      Gold (Wild Card Member): Users can upgrade to this level by paying an annual fee of $100. Gold members can participate in the majority of activities with discounts, including formal study groups and internal job referrals, providing them with enhanced opportunities for career development.
      Platinum (Premium Member): This membership is by invitation only, available to Gold Members who have actively participated in formal study groups and performed well. Platinum Members enjoy access to all club activities, all club resources, and have their own dedicated WeChat group, 
      fostering a close-knit community focused on mutual support and growth. The pricing for Platinum Membership is more complex and not disclosed.
      
      Each tier progressively offers more opportunities for engagement and support within the club.` }, 
    { question: "can you introduce the pricing of memberships, group classes, events, and certificates in Qishi Career Path Club?", 
      answer: `The pricing structure for Qishi Career Path Club is as follows:
      1. Membership Fees:
      Bronze (Basic) Membership: Free
      Gold (Wild Card) Membership: $100 annually
      Platinum (Premium) Membership: The pricing is more complex and not disclosed.
      2. Group Classes:
      Audit fees for online study groups typically range from $100 to $300.
      Gold and Platinum Members receive about 50% discount on these fees.
      Formal study groups are generally free, but require Gold or Platinum Membership.
      3. Events:
      Most online events are free.
      Large offline events cost between $20 and $50.
      Small offline events often require an admission ticket costing between $60 and $300 annually. Once an admission ticket is acquired, 
      small offline events typically follow a shared-cost system for each session.
      4. Certificates:
      The exam fee is typically $300.
      
      This overview provides the key pricing details related to memberships, group classes, events, and certificates within the Qishi Career Path Club.` }, 
    { question: "can you tell me how can I make use of Qishi Career Path Club?", 
      answer: `To make use of the Qishi Career Path Club, you can follow these steps:
      1. Join as a Member: Start by registering on the Qishi website to become a Bronze (Basic) member for free. 
      You can then upgrade to Gold or Platinum memberships for additional benefits.
      2. Participate in Study Groups: Engage in audit-based study groups and online/offline events to enhance your knowledge and skills. 
      Gold and Platinum members can attend formal study groups, which are more interactive and beneficial for learning.
      3. Network: Take advantage of the opportunities to meet and connect with other members in the community. 
      Participating in events can help you grow your professional network.
      4. Career Development: Utilize the internal job referral system as a Gold or Platinum member. 
      Performing well in courses (scoring above 7 out of 10) can help you qualify for referrals when members from top companies are hiring.
      5. Mentorship: Consider booking a session with a mentor available on the platform. Mentors can provide personalized advice and guidance tailored to your career goals.
      6. Obtain Certificates: Enroll in formal classes, complete the courses successfully, 
      and achieve excellent scores to become eligible for Qishi certificates, which can support your job applications.
      7. Request Recommendations: If you perform well in study programs, you can request a recommendation letter based on your achievements, 
      which can be useful when applying for jobs.
      
      By actively engaging in these aspects, you can maximize your experience and professional growth at Qishi Career Path Club.` },
    { question: "can you list the chatbot links of mentors of Qishi Career Path Club?", 
      answer: "Currently only Chanjuan Pan list her chatbot link: https://portal.2brain.ai/s/chat/yuWGDAoT . More will come in the future." },
    { question: "can you list the certificates and prices of Qishi Career Path Club?", 
      answer: `The Qishi Career Path Club offers the following certificates along with their exam fees:
      Qishi Algorithms Certificate - Exam Fee: $300
      Qishi Buyside Quant Certificate - Exam Fee: $300
      Qishi C++ Certificate - Exam Fee: $300
      Qishi Intermediate Machine Learning Certificate - Exam Fee: $300
      Qishi Advanced Python Certificate - Exam Fee: $300
      Qishi Venture Capital Analyst Certificate - Exam Fee: $300
      Qishi Machine Learning Systems Design Certificate - Exam Fee: $300
      Each certificate provides an opportunity for skill validation in its respective field. 
      If you have further questions or need assistance with any specific certificate, feel free to ask!` },
    { question: "can you give some networking tips when taking part in Qishi Club event?", 
      answer: `Here are some networking tips for participating in a Qishi Club event:
      1. Evaluate Your Purpose: Before attending, clarify your goals. This could involve expanding your peer circle, 
      connecting with workplace seniors, learning about the market, or deepening ties with specific individuals. 
      Skip those events in which you don't have clear goals, save time for study.
      2. Tailor Your Actions to Goals: If your aim is to meet seniors, research attendees to formulate insightful questions. 
      If connecting with a specific person, understand their reasons for attending and consider how you can assist them.
      3. Treat the Event as a Branding Opportunity:
      Arrive early and assist organizers with tasks.
      Be observant; engage with anyone who seems left out or help address any issues you notice.
      Respect seniors while being friendly to peers and juniors. Prepare to contribute unique insights that relate to the event's theme.
      
      By keeping these tips in mind, you can effectively maximize your networking opportunities at Qishi Club events or other similar events.` },
    { question: "how can I get a reference letter from Qishi Career Path Club for my job application?", 
      answer: `To obtain a reference letter from Qishi Career Path Club for your job application, you need to follow these steps:
      1. Complete a Qishi Course: Enroll in a Qishi course that lasts a minimum of eight weeks. 
      You must attend the formal class instead of being an auditor. You can take the same course in multiple times.
      2. Achieve a Good Grade: Your performance will be graded, and you need to score at least 7 out of 10 points to be eligible for a reference letter.
      3. Request the Letter: Once you have met the requirements, you can contact Qishi Club to request the recommendation letter. 
      Be sure to include details such as the target company, the position you are applying for, and how the course you completed is relevant to that job.
      
      Following these steps will help you secure a reference letter that supports your job application effectively.` }
  ];
  

  return (<>
    <Stack direction='column' align='flex-center' maxW="800px">
      <Text >Question: {faqs[queryid-1].question}</Text>
      <Text >Answer: {faqs[queryid-1].answer}</Text>
      <Text >You can also ask other questions once our chatbox is loaded. The chatbox supports Chinese language as well. Thank you for being patient.</Text>
    </Stack>
    <Box
      mt='4'
      mb='4'
      display="flex"
      justifyContent="center"
      alignItems="center"
      bg="gray.200" // Background color
      p={4} // Padding
      border="2px" // Border width
      borderColor="darkCerulean.500" // Border color
      borderRadius="md" // Border radius
      boxShadow="xl" // Shadow
      maxW="800px" // Max width
      w="full" // Width full
      h="500px" // Height
      mx="auto" // Margin auto for horizontal centering
    >
    <iframe
        src="https://portal.2brain.ai/s/chat/yuWGDAoT"
        title="Chat Box"
        frameborder="0"
        width="100%"
        height="100%"
        allowTransparency="true"
        allow="encrypted-media"
        style={{ borderRadius: "12px" }} 
    ></iframe>
    </Box>
  </>
  );
};

export default ChatScreen;