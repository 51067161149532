import { DeleteIcon } from '@chakra-ui/icons';
import {
    Badge,
    Button,
    Flex,
    FormControl,
    FormLabel,
    Input,
    Switch,
    Td,
    Tr,
    VStack,
    useDisclosure,
    Select,
} from '@chakra-ui/react';
import { useRef, useState } from 'react';
import { MdOutlineDataSaverOn } from 'react-icons/md';
import { useDispatch, useSelector } from 'react-redux';
import { deleteAccounting, updateAccounting } from '../redux/actions/adminActions';
import ConfirmRemovalAlert from './ConfirmRemovalAlert';

const AccountingTableItem = ({accounting}) => {
    const { userInfo } = useSelector((state) => state.user);
    const cancelRef= useRef();
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [isCharge, setIsCharge ] = useState(accounting.isCharge);
    const [isPay, setIsPay ] = useState(accounting.isPay);
    const [amount, setAmount ] = useState(accounting.amount);
    const [placeDate, setPlaceDate ] = useState(accounting.placeDate);
    const [description, setDescription ] = useState(accounting.description);
    const [category, setCategory ] = useState(accounting.category);
    const [paymentMethod, setPaymentMethod ] = useState(accounting.paymentMethod);
    const [stripeId, setStripeId ] = useState(accounting.stripeId);
    const [referenceId, setReferenceId ] = useState(accounting.referenceId);

    const dispatch = useDispatch();

    const handleCategoryChange = (event) => {
        setCategory(event.target.value);
      };

    const handlePaymentMethodChange = (event) => {
        setPaymentMethod(event.target.value);
      };

    const onSaveAccounting = () => {
        dispatch(updateAccounting(
            accounting._id, accounting.userId, isCharge, isPay, amount, placeDate, description, category, paymentMethod, stripeId, referenceId));
    };

    const openDeleteConfirmBox = () => {
        onOpen();
    };

  return (
    <>
    <Tr>
        <Td> isCharge or isPay </Td>
        <Td>
            <Flex direction='column' gap='2'>
                <FormControl display='flex' alignItems='center'>
                    <FormLabel htmlFor='isChargeFlag' mb='0' fontSize='sm'>
                        Enable
                        <Badge rounded='full' px='1' mx='1' fontSize='0.8m' colorScheme='green'>
                            Charge
                        </Badge>
                        badge ?
                    </FormLabel>
                    <Switch id='isChargeFlag' onChange={() => setIsCharge(!isCharge)} isChecked={isCharge} />
                </FormControl>
                <FormControl display='flex' alignItems='center'>
                    <FormLabel htmlFor='isPayFlag' mb='0' fontSize='sm'>
                        Enable
                        <Badge rounded='full' px='1' mx='1' fontSize='0.8m' colorScheme='green'>
                            Pay
                        </Badge>
                        badge ?
                    </FormLabel>
                    <Switch id='isPayFlag' onChange={() => setIsPay(!isPay)} isChecked={isPay} />
                </FormControl>
            </Flex>
        </Td>
    </Tr><Tr>
        <Td>Amount & Place Date</Td>
        <Td>
            <Flex direction='column' gap='2'>
                <Input size='sm' value={amount} onChange={(e) => setAmount(e.target.value)} />
                <Input size='sm' value={placeDate} onChange={(e) => setPlaceDate(e.target.value)} />
            </Flex>
        </Td>
    </Tr><Tr>
        <Td>Description & Category</Td>
        <Td>
            <Flex direction='column' gap='2'>
                <Input size='sm' value={description} onChange={(e) => setDescription(e.target.value)} />
                <FormControl>
          <FormLabel htmlFor="category">Category:</FormLabel>
          <Select id="category" value={category} onChange={handleCategoryChange} required>
            <option value="AnnualFeeCharge">AnnualFeeCharge</option>
            <option value="AnnualFeeCredit">AnnualFeeCredit</option>
            <option value="Coupon">Coupon</option>
            <option value="EventCoupon">EventCoupon</option>
            <option value="ClassCoupon">ClassCoupon</option>
            <option value="PayEvent">PayEvent</option>
            <option value="PayClass">PayClass</option>
            <option value="Other">Other</option>
          </Select>
        </FormControl>
            </Flex>
        </Td>
    </Tr><Tr>
        <Td>Payment Method</Td>
        <Td>
        <FormControl>
          <FormLabel htmlFor="paymentMethod">paymentMethod:</FormLabel>
          <Select id="paymentMethod" value={paymentMethod} onChange={handlePaymentMethodChange} required>
            <option value="Cash">Cash</option>
            <option value="Stripe">Stripe</option>
            <option value="Club">Club</option>
            <option value="Other">Other</option>
          </Select>
        </FormControl>
        </Td>
    </Tr><Tr>
        <Td>Stripe ID & Reference ID</Td>
        <Td>
            <Flex direction='column' gap='2'>
                <Input size='sm' value={stripeId} onChange={(e) => setStripeId(e.target.value)} />
                <Input size='sm' value={referenceId} onChange={(e) => setReferenceId(e.target.value)} />
            </Flex>
        </Td>
    </Tr>
    {userInfo.isAdmin && (
    <ConfirmRemovalAlert 
                    isOpen={isOpen} 
                    onOpen={onOpen} 
                    onClose={onClose} 
                    cancelRef={cancelRef} 
                    itemToDelete={accounting}
                    deleteAction={deleteAccounting}
    />)}
    <Tr>
            <Td>Action</Td>
            <Td>
            <VStack align='start'>
                <Button colorScheme='red' variant='outline' onClick={openDeleteConfirmBox}>
                    <DeleteIcon mr='5px' />
                    Remove Accounting
                </Button>  
                <Button colorScheme='green' variant='outline' onClick={onSaveAccounting}>
                    <MdOutlineDataSaverOn style={{marginRight: '5px'}} />
                    Save Changes
                </Button>
            </VStack>
        </Td>
    </Tr>
    </>
  );
};

export default AccountingTableItem;

