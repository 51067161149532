import { DeleteIcon } from '@chakra-ui/icons';
import {
    Badge,
    Button,
    Flex,
    FormControl,
    FormLabel,
    Input,
    Switch,
    Td,
    Textarea,
    Tr,
    VStack,
    useDisclosure,
} from '@chakra-ui/react';
import { useRef, useState } from 'react';
import { MdOutlineDataSaverOn } from 'react-icons/md';
import { useDispatch, useSelector } from 'react-redux';
import { deleteGroupClass, updateGroupClass } from '../redux/actions/adminActions';
import ConfirmRemovalAlert from './ConfirmRemovalAlert';


const GroupClassTableItem = ({groupClass}) => {
    const { userInfo } = useSelector((state) => state.user);

    const cancelRef= useRef();
    const { isOpen, onOpen, onClose } = useDisclosure();


    const [title, setTitle ] = useState(groupClass.title);
    const [subtitle, setSubtitle ] = useState(groupClass.subtitle);
    const [programId, setProgramId ] = useState(groupClass.programId);
    const [isMostRecent, setIsMostRecent ] = useState(groupClass.isMostRecent);
    const [description, setDescription ] = useState(groupClass.description);
    const [specialNotice, setSpecialNotice ] = useState(groupClass.specialNotice);
    const [memberComposed, setMemberComposed ] = useState(groupClass.memberComposed);
    const [requirement, setRequirement ] = useState(groupClass.requirement);
    const [courseContent, setCourseContent ] = useState(groupClass.courseContent);
    const [syllabus, setSyllabus ] = useState(groupClass.syllabus);
    const [registrationDeadLine, setRegistrationDeadLine ] = useState(groupClass.registrationDeadLine);
    const [classStartDateTime, setClassStartDateTime ] = useState(groupClass.classStartDateTime);
    const [classEndDateTime, setClassEndDateTime ] = useState(groupClass.classEndDateTime);
    const [duration, setDuration ] = useState(groupClass.duration);
    const [weight, setWeight ] = useState(groupClass.weight);
    const [isNeedResume, setIsNeedResume ] = useState(groupClass.isNeedResume);
    const [manager1UserId, setManager1UserId ] = useState(groupClass.manager1UserId);
    const [manager2UserId, setManager2UserId ] = useState(groupClass.manager2UserId);
    const [isFree, setIsFree ] = useState(groupClass.isFree);
    const [level0Fee, setLevel0Fee ] = useState(groupClass.level0Fee);
    const [level1Fee, setLevel1Fee ] = useState(groupClass.level1Fee);
    const [level0StripeId, setLevel0StripeId ] = useState(groupClass.level0StripeId);
    const [level1StripeId, setLevel1StripeId ] = useState(groupClass.level1StripeId);
    const [applicantLevel, setApplicantLevel ] = useState(groupClass.applicantLevel);
    const [maxOfApplicants, setMaxOfApplicants] = useState(groupClass.maxOfApplicants);
    const [allowAudit, setAllowAudit ] = useState(groupClass.allowAudit);
    const [auditManagerUserId, setAuditManagerUserId ] = useState(groupClass.auditManagerUserId);
    const [isAuditFree, setIsAuditFree ] = useState(groupClass.isAuditFree);
    const [level0AuditFee, setLevel0AuditFee ] = useState(groupClass.level0AuditFee);
    const [level1AuditFee, setLevel1AuditFee ] = useState(groupClass.level1AuditFee);
    const [level0AuditStripeId, setLevel0AuditStripeId ] = useState(groupClass.level0AuditStripeId);
    const [level1AuditStripeId, setLevel1AuditStripeId ] = useState(groupClass.level1AuditStripeId);
    const [auditApplicantLevel, setAuditApplicantLevel ] = useState(groupClass.auditApplicantLevel);
    const [maxOfAuditApplicants, setMaxOfAuditApplicants ] = useState(groupClass.maxOfAuditApplicants);
    const [material, setMaterial ] = useState(groupClass.material);
    

    const dispatch = useDispatch();

    const onSaveGroupClass = () => {
        dispatch(updateGroupClass(groupClass._id, title, subtitle, programId, isMostRecent, description, specialNotice, memberComposed, requirement, courseContent, syllabus,
            registrationDeadLine, classStartDateTime, classEndDateTime, duration, weight, isNeedResume, manager1UserId, manager2UserId,
            isFree, level0Fee, level1Fee, level0StripeId, level1StripeId, applicantLevel, maxOfApplicants, allowAudit, auditManagerUserId, 
            isAuditFree, level0AuditFee, level1AuditFee, level0AuditStripeId, level1AuditStripeId, auditApplicantLevel, 
            maxOfAuditApplicants, material));
    };

    const openDeleteConfirmBox = () => {
        onOpen();
    };

  return (
    <>
    <Tr>
        <Td> Description: </Td>
        <Td>
            <Textarea
                w='80vh' 
                h='120px'
                value={description}
                onChange={(e) => setDescription(e.target.value)}
                size='m'
            />
        </Td>
    </Tr><Tr>
        <Td> Requirement: </Td>
        <Td>
            <Textarea
                w='80vh' 
                h='120px'
                value={requirement}
                onChange={(e) => setRequirement(e.target.value)}
                size='m'
            />
        </Td>
    </Tr><Tr>
        <Td> Special Notice: </Td>
        <Td>
            <Textarea
                w='80vh' 
                h='120px'
                value={specialNotice}
                onChange={(e) => setSpecialNotice(e.target.value)}
                size='m'
            />
        </Td>

    </Tr><Tr>
        <Td> Member Composed: </Td>
        <Td>
            <Textarea
                w='80vh' 
                h='120px'
                value={memberComposed}
                onChange={(e) => setMemberComposed(e.target.value)}
                size='m'
            />
        </Td>
    </Tr><Tr>
        <Td> Course Content: </Td>
        <Td>
            <Textarea
                w='80vh' 
                h='120px'
                value={courseContent}
                onChange={(e) => setCourseContent(e.target.value)}
                size='m'
            />
        </Td>
    </Tr><Tr>
        <Td> Syllabus: </Td>
        <Td>
            <Textarea
                w='80vh' 
                h='120px'
                value={syllabus}
                onChange={(e) => setSyllabus(e.target.value)}
                size='m'
            />
        </Td>
    </Tr><Tr>
        <Td> Material: </Td>
        <Td>
            <Textarea
                w='80vh' 
                h='120px'
                value={material}
                onChange={(e) => setMaterial(e.target.value)}
                size='m'
            />
        </Td>

    </Tr><Tr>
        <Td>Title & Subtitle</Td>
        <Td>
            <Flex direction='column' gap='2'>
                <Input size='sm' value={title} onChange={(e) => setTitle(e.target.value)} />
                <Input size='sm' value={subtitle} onChange={(e) => setSubtitle(e.target.value)} />
            </Flex>
        </Td>
    </Tr><Tr>
        <Td> ProgramId & Duration</Td>
        <Td>
            <Flex direction='column' gap='2'>
                <Input size='sm' value={programId} onChange={(e) => setProgramId(e.target.value)} />
                <Input size='sm' value={duration} onChange={(e) => setDuration(e.target.value)} />
            </Flex>
        </Td>

    </Tr><Tr>
        <Td> is Most Recent & Registration DeadLine </Td>
        <Td>
            <Flex direction='column' gap='2'>
                <FormControl display='flex' alignItems='center'>
                    <FormLabel htmlFor='isMostRecentFlag' mb='0' fontSize='sm'>
                        Enable
                        <Badge rounded='full' px='1' mx='1' fontSize='0.8m' colorScheme='green'>
                            Most Recent
                        </Badge>
                        badge ?
                    </FormLabel>
                    <Switch id='isMostRecentFlag' onChange={() => setIsMostRecent(!isMostRecent)} isChecked={isMostRecent} />
                </FormControl>
                <Input size='sm' value={registrationDeadLine} onChange={(e) => setRegistrationDeadLine(e.target.value)} />
            </Flex>
        </Td>
    </Tr><Tr>
        <Td> Class Start Date Time & End Date Time </Td>
        <Td>
            <Flex direction='column' gap='2'>
                <Input size='sm' value={classStartDateTime} onChange={(e) => setClassStartDateTime(e.target.value)} />
                <Input size='sm' value={classEndDateTime} onChange={(e) => setClassEndDateTime(e.target.value)} />
            </Flex>
        </Td>
        </Tr><Tr>
    <Td> is Free & Weight  & is Need Resume</Td>
        <Td>
            <Flex direction='column' gap='2'>
                <FormControl display='flex' alignItems='center'>
                    <FormLabel htmlFor='isFreeFlag' mb='0' fontSize='sm'>
                        Enable
                        <Badge rounded='full' px='1' mx='1' fontSize='0.8m' colorScheme='green'>
                            Free
                        </Badge>
                        badge ?
                    </FormLabel>
                    <Switch id='isFreeFlag' onChange={() => setIsFree(!isFree)} isChecked={isFree} />
                </FormControl>
                <Input size='sm' value={weight} onChange={(e) => setWeight(e.target.value)} />
                <FormControl display='flex' alignItems='center'>
                    <FormLabel htmlFor='isNeedResumeFlag' mb='0' fontSize='sm'>
                        Enable
                        <Badge rounded='full' px='1' mx='1' fontSize='0.8m' colorScheme='green'>
                            Need Resume
                        </Badge>
                        badge ?
                    </FormLabel>
                    <Switch id='isNeedResumeFlag' onChange={() => setIsNeedResume(!isNeedResume)} isChecked={isNeedResume} />
                </FormControl>
            </Flex>
        </Td>
    </Tr><Tr>
        <Td>Manager 1 User ID & Manager 2 User ID</Td>
        <Td>
            <Flex direction='column' gap='2'>
                <Input size='sm' value={manager1UserId} onChange={(e) => setManager1UserId(e.target.value)} />
                <Input size='sm' value={manager2UserId} onChange={(e) => setManager2UserId(e.target.value)} />
            </Flex>
        </Td>
    </Tr><Tr>
        <Td>Level 0 Fee & Level 1 Fee</Td>
        <Td>
            <Flex direction='column' gap='2'>
                <Input size='sm' value={level0Fee} onChange={(e) => setLevel0Fee(e.target.value)} />
                <Input size='sm' value={level1Fee} onChange={(e) => setLevel1Fee(e.target.value)} />
            </Flex>
        </Td>
    </Tr><Tr>
        <Td>Level 0 Stripe ID & Level 1 Stripe ID</Td>
        <Td>
            <Flex direction='column' gap='2'>
                <Input size='sm' value={level0StripeId} onChange={(e) => setLevel0StripeId(e.target.value)} />
                <Input size='sm' value={level1StripeId} onChange={(e) => setLevel1StripeId(e.target.value)} />
            </Flex>
        </Td>
    </Tr><Tr>
        <Td>ApplicantLevel & Max Of Applications</Td>
        <Td>
            <Flex direction='column' gap='2'>
                <Input size='sm' value={applicantLevel} onChange={(e) => setApplicantLevel(e.target.value)} />
                <Input size='sm' value={maxOfApplicants} onChange={(e) => setMaxOfApplicants(e.target.value)} />
            </Flex>
        </Td>
    </Tr>

    <Tr>
    <Td> Allow Audit & is Audit Free  </Td>
        <Td>
            <Flex direction='column' gap='2'>
                <FormControl display='flex' alignItems='center'>
                    <FormLabel htmlFor='allowAuditFlag' mb='0' fontSize='sm'>
                        Enable
                        <Badge rounded='full' px='1' mx='1' fontSize='0.8m' colorScheme='green'>
                            Allow Audit
                        </Badge>
                        badge ?
                    </FormLabel>
                    <Switch id='allowAuditFlag' onChange={() => setAllowAudit(!allowAudit)} isChecked={allowAudit} />
                </FormControl>
                <FormControl display='flex' alignItems='center'>
                    <FormLabel htmlFor='isAuditFreeFlag' mb='0' fontSize='sm'>
                        Enable
                        <Badge rounded='full' px='1' mx='1' fontSize='0.8m' colorScheme='green'>
                            Audit Free
                        </Badge>
                        badge ?
                    </FormLabel>
                    <Switch id='isAuditFreeFlag' onChange={() => setIsAuditFree(!isAuditFree)} isChecked={isAuditFree} />
                </FormControl>
            </Flex>
        </Td>
    </Tr><Tr>
        <Td>Audit Manager User Id</Td>
        <Td>
            
        <Input size='sm' value={auditManagerUserId} onChange={(e) => setAuditManagerUserId(e.target.value)}/>
               
        </Td>
    </Tr><Tr>
        <Td>Level 0 Audit Fee & Level 1 Audit Fee</Td>
        <Td>
            <Flex direction='column' gap='2'>
                <Input size='sm' value={level0AuditFee} onChange={(e) => setLevel0AuditFee(e.target.value)} />
                <Input size='sm' value={level1AuditFee} onChange={(e) => setLevel1AuditFee(e.target.value)} />
            </Flex>
        </Td>
    </Tr><Tr>
        <Td>Level 0 Audit Stripe ID & Level 1 Audit Stripe ID</Td>
        <Td>
            <Flex direction='column' gap='2'>
                <Input size='sm' value={level0AuditStripeId} onChange={(e) => setLevel0AuditStripeId(e.target.value)} />
                <Input size='sm' value={level1AuditStripeId} onChange={(e) => setLevel1AuditStripeId(e.target.value)} />
            </Flex>
        </Td>
    </Tr><Tr>
        <Td>Audit ApplicantLevel & Max Of Audit Applications</Td>
        <Td>
            <Flex direction='column' gap='2'>
                <Input size='sm' value={auditApplicantLevel} onChange={(e) => setAuditApplicantLevel(e.target.value)} />
                <Input size='sm' value={maxOfAuditApplicants} onChange={(e) => setMaxOfAuditApplicants(e.target.value)} />
            </Flex>
        </Td>
    </Tr>

    {userInfo.isAdmin && (
    <ConfirmRemovalAlert 
                    isOpen={isOpen} 
                    onOpen={onOpen} 
                    onClose={onClose} 
                    cancelRef={cancelRef} 
                    itemToDelete={groupClass}
                    deleteAction={deleteGroupClass}
    />)}
    <Tr>
            <Td>Action</Td>
            <Td>
            <VStack align='start'>
                <Button colorScheme='red' variant='outline' onClick={openDeleteConfirmBox}>
                    <DeleteIcon mr='5px' />
                    Remove Group Class
                </Button>  
                <Button colorScheme='green' variant='outline' onClick={onSaveGroupClass}>
                    <MdOutlineDataSaverOn style={{marginRight: '5px'}} />
                    Save Changes
                </Button>
            </VStack>
        </Td>
    </Tr>
    </>
  );
};

export default GroupClassTableItem;
