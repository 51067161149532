import {
    Alert,
    AlertDescription,
    AlertIcon,
    AlertTitle,
    Badge,
    Box,
    Button,
    Flex,
    HStack,
    Heading,
    Image,
    SimpleGrid,
    Spinner,
    Stack,
    Text,
    Wrap,
    useToast,
    Textarea,
    Input,
    Tooltip,
    IconButton,
    Divider,
    Link,
    useColorModeValue as mode,
    Icon,
    FormControl,
    FormLabel,
    Accordion,
    AccordionItem,
    AccordionPanel,
    AccordionButton,
    Tbody,
    Table,
  } from '@chakra-ui/react';
  import { FaArrowRight } from 'react-icons/fa';
  import { BiChat } from 'react-icons/bi';
  /* import { BiCheckShield, BiPackage, BiSupport } from 'react-icons/bi'; */
  import { useDispatch, useSelector } from 'react-redux';
  import { useParams } from 'react-router-dom';
  import { Link as ReactLink } from 'react-router-dom';
  import { createApplicant, getGroupClass } from '../redux/actions/groupClassActions';
  import { addCartItemGeneral } from '../redux/actions/cartActions';
  import { useEffect, useState } from 'react';
  import Star from '../components/Star';
  import FormattedDate from '../components/FormattedDate';
  import Papa from 'papaparse';
  import { saveAs } from 'file-saver';
  import ApplicantTableItem from '../components/ApplicantTableItem';

  
  import { createGroupClassReview } from '../redux/actions/groupClassActions';
  
  const GroupClassScreen = () => {

      /* const [amount, setAmount] = useState(1);  */
    const today = new Date();
    const { id } = useParams();
    const dispatch = useDispatch();
    const { loading, error, groupClass, reviewed, applied, auditApplied } = useSelector((state) => state.groupClass);
    const { cartItems } = useSelector((state) => state.cart);
    const toast = useToast();
    const [formData, setFormData] = useState({
      coverLetter: null,
      resumeFile: null,
    });
    const [comment, setComment] = useState('');
  const [rating, setRating] = useState(1);
  const [title, setTitle] = useState('');
    
    /* const [ isGroupClassOpen, setIsGroupClassOpen ] = useState(false); */
    /* const [ numberOfApplicants, setNumberOfApplicants ] = useState(0); */
    
    const [applyBoxOpen, setApplyBoxOpen] = useState(false);
    const [reviewBoxOpen, setReviewBoxOpen] = useState(false);
    const { userInfo } = useSelector((state) => state.user);
    const [buttonLoading, setButtonLoading] = useState(false);


    useEffect(() => {
      dispatch(getGroupClass(id));
      setApplyBoxOpen(false);
      if(reviewed) {
        toast({
          description: 'Review has been saved.',
          status: 'success',
          isClosable: 'true',
        });
        setReviewBoxOpen(false);
      }
    }, [dispatch, id, toast, applied, reviewed]);

    const checkIsGroupClassOpen = () => {
        if(groupClass && ((new Date(groupClass.registrationDeadLine) - today) >= 0)) {
          if(groupClass.numberOfApplicants < groupClass.maxOfApplicants) {
            return true;
          }
          else {
            return false;
          }
        }
        else {
            return false;
        }
    }

    const checkIsAuditGroupClassOpen = () => {
      if(groupClass && groupClass.allowAudit && ((today - new Date(groupClass.classStartDateTime)) <= (1000 * 60 * 60 * 24 * 3))) {
        if(groupClass.numberOfAuditApplicants < groupClass.maxOfAuditApplicants) {
          return true;
        }
        else {
          return false;
        }
      }
      else {
          return false;
      }
  }

    const addItem = (category) => {
      let price = 0.0;
      let stripeId = '';
      if(category === 'FormalClass' ){
        if(!groupClass.isFree) {
          if(userInfo && userInfo.membershipLevel >= 2) {
              price = groupClass.level1Fee;
              stripeId = groupClass.level1StripeId;
          }
          else {
              price = groupClass.level0Fee;
              stripeId = groupClass.level0StripeId;
          }
         }
      } else if(category === 'AuditClass'){
        if(!groupClass.isAuditFree) {
          if(userInfo && userInfo.membershipLevel >= 2) {
            price = groupClass.level1AuditFee;
            stripeId = groupClass.level1AuditStripeId;
          }
          else {
            price = groupClass.level0AuditFee;
            stripeId = groupClass.level0AuditStripeId;
          }
        }
      }
      
      if(!(cartItems.some((cartItem) => cartItem.id === groupClass._id))) {
           // addCartItemGeneral = (id, category, emails, name, price, stripeId)
           // dispatch(addCartItemGeneral(id, 'Mentor', [mentor.email], mentor.name, mentor.fee, mentor.stripeId));
           //const eventManagerIds = (event.manager2UserId && event.manager2UserId !== '') ? [event.manager1UserId, event.manager2UserId] : [event.manager1UserId];
           dispatch(addCartItemGeneral(groupClass._id, category, [], groupClass.title, price, stripeId));
       }
       toast({
         description: 'Item has been added.',
         status: 'success',
         isClosable: true,
       });
     };

    const hasUserApplied = () => groupClass.applicants.some((item) => item.userId === userInfo._id);
    const hasUserAppliedAudit = () => groupClass.auditApplicants.some((item) => item.userId === userInfo._id);
    const isUserQualifiedFormal = () => (userInfo && userInfo.membershipLevel >= groupClass.applicantLevel && (new Date(groupClass.registrationDeadLine) >= today));
    const isUserQualifiedAudit = () => (checkIsAuditGroupClassOpen());

  const handleSubmit = async (e) => {
    e.preventDefault();
    if(userInfo) {
    const data = new FormData();
    data.append('groupClassId', groupClass._id);
    data.append('groupClassTitle', groupClass.title);
    data.append('manager1UserId', groupClass.manager1UserId);
    data.append('manager2UserId', groupClass.manager2UserId ? groupClass.manager2UserId : '' );
    data.append('rating', userInfo.rating);
    data.append('email', userInfo.email);
    data.append('userId', userInfo._id);
    data.append('name', userInfo.name);
    data.append('coverLetter', formData.coverLetter);  // Attach the file
    data.append('resumeFile', formData.resumeFile);  // Attach the file
    setButtonLoading(true);
    data.forEach((value, key) => {
      console.log(`${key}: ${value}`);  // Logs each key-value pair
    });
    dispatch(createApplicant(data));
    toast({
      description: 'Application has been sent.',
      status: 'success',
      isClosable: true,
    });
    }
  };
  const handleResumeFileChange = (e) => {
    setFormData({ ...formData, resumeFile: e.target.files[0] });
  };

  const handleCoverLetterChange = (e) => {
    setFormData({ ...formData, coverLetter: e.target.files[0] });
  };

  const hasUserReviewed = () => groupClass.reviews.some((item) => item.userId === userInfo._id);
  const onSubmit = () => {
    setButtonLoading(true);
    dispatch(createGroupClassReview(groupClass._id, userInfo._id, comment, rating, title));
  }

  const isManager = () => {
    if(userInfo ) {
      if(userInfo.isAdmin || (userInfo.managerRoles && userInfo.managerRoles.includes(1))) return true;
      if(groupClass.manager1UserId === userInfo._id) return true;
      if(groupClass.manager2UserId && groupClass.manager2UserId === userInfo._id) return true; 
    }
    return false;
  }

  const isAuditManager = () => {
    if(userInfo ) {
      if(userInfo.isAdmin) return true;
      if(groupClass.auditManagerUserId === userInfo._id) return true;
    }
    return false;
  }


  const convertToCSV = (data) => {
    const csv = Papa.unparse(data);
    return csv;
  };

  const downloadCSV = (csvData, filename) => {
    const blob = new Blob([csvData], { type: 'text/csv;charset=utf-8;' });
    saveAs(blob, filename);
  };

  const downloadApplicants = () => {
    const category = 'Formal';
    if(category === 'Formal') {
      const csvData = convertToCSV(groupClass.applicants);
      downloadCSV(csvData, 'applicants.csv');
    }
    else {
      const csvData = convertToCSV(groupClass.auditApplicants);
      downloadCSV(csvData, 'auditApplicants.csv');
    }
  };

  const downloadAuditApplicants = () => {
    const category = 'Audit';
    if(category === 'Formal') {
      const csvData = convertToCSV(groupClass.applicants);
      downloadCSV(csvData, 'applicants.csv');
    }
    else {
      const csvData = convertToCSV(groupClass.auditApplicants);
      downloadCSV(csvData, 'auditApplicants.csv');
    }
  };

  const getMembership = (level) => {
    if(level > 2) return 'Platinum';
    if(level > 1) return 'Gold';
    return 'Basic';
  }



    return (
      <Wrap spacing='30px' justify='center' minHeight='100vh'>
        {loading ? (
          <Stack direction='row' spacing='4'>
            <Spinner mt='20' thickness='2px' speed='0.65s' emptyColor='gray.200' color='cyan.500' size='xl' />
          </Stack>
        ) : error ? (
          <Alert status='error'>
            <AlertIcon />
            <AlertTitle>We are sorry!</AlertTitle>
            <AlertDescription>{error}</AlertDescription>
          </Alert>
        ) : (
          groupClass && (
            <Box
              maxW={{ base: '3xl', lg: '5xl' }}
              mx='auto'
              px={{ base: '4', md: '8', lg: '12' }}
              py={{ base: '6', md: '8', lg: '12' }}>
                <Stack direction={{ base: 'column', lg: 'column' }} align='flex-start'>
                  {((groupClass.allowAudit && checkIsAuditGroupClassOpen()) || checkIsGroupClassOpen()) ? (
                    <Badge colorScheme='green'>Open to apply!</Badge>
            ) : <Badge colorScheme='blue'> This class is closed to apply. Please go to subscribe the program for next class!</Badge>}
                  
                  <Heading fontSize='2xl' fontWeight='extrabold'>
                    {groupClass.title}
                  </Heading>

                  <Stack spacing='5'>
                    <Text fontSize='xl' >{groupClass.subtitle}</Text>
                    <Box>
            {groupClass.isFree && (
                <Flex
                justify="space-between"
                alignItems="center"
                mt='2'>
                <Text fontSize="lg" fontWeight="semibold" color='darkCerulean.500'>Formal Class Fee</Text>
                <Text fontSize="lg" fontWeight="semibold" color='red'>
                    Free!
                </Text>
            </Flex>
            )}
            {!groupClass.isFree && groupClass.level0Fee === groupClass.level1Fee && (
                <>
                <Flex
                justify="space-between"
                alignItems="center"
                mt='2'>
                <Text fontSize="sm" fontWeight="semibold" color='darkCerulean.500'>Formal Class Fee</Text>
                <Text fontSize="sm" fontWeight="semibold" color='darkCerulean.500'>
                    ${groupClass.level0Fee}
                </Text>
                </Flex>
                </>
            )}
            {!groupClass.isFree && groupClass.level0Fee !== groupClass.level1Fee && (
                <>
                <Flex
                justify="space-between"
                alignItems="center"
                mt='2'>
                <Text fontSize="sm" fontWeight="semibold" color='darkCerulean.500'>Basic Formal Class Fee </Text>
                <Text fontSize="sm" fontWeight="semibold" color='darkCerulean.500'>
                    ${groupClass.level0Fee}
                </Text>
                </Flex>
                <Flex
                justify="space-between"
                alignItems="center"
                mt='2'>
                <Text fontSize="sm" fontWeight="semibold" color='darkCerulean.500'>Gold and up Member Formal Class Fee</Text>
                <Flex justify="space-between" alignItems="center">
                <Text fontSize="sm" textDecoration="line-through" fontWeight="semibold" color='red'>
                    ${groupClass.level0Fee}
                </Text>
                <Text fontSize="sm" fontWeight="semibold" color='red'>
                    ${groupClass.level1Fee}
                </Text>
                </Flex>
                </Flex>
                </>
            )}

            {groupClass.allowAudit && groupClass.isAuditFree && (
                <Flex
                justify="space-between"
                alignItems="center"
                mt='2'>
                <Text fontSize="lg" fontWeight="semibold" color='darkCerulean.500'>Audit Fee</Text>
                <Text fontSize="lg" fontWeight="semibold" color='red'>
                    Free!
                </Text>
            </Flex>
            )}
            {groupClass.allowAudit && !groupClass.isAuditFree && groupClass.level0AuditFee === groupClass.level1AuditFee && (
                <>
                <Flex
                justify="space-between"
                alignItems="center"
                mt='2'>
                <Text fontSize="sm" fontWeight="semibold" color='darkCerulean.500'>Audit Fee</Text>
                <Text fontSize="sm" fontWeight="semibold" color='darkCerulean.500'>
                    ${groupClass.level0AuditFee}
                </Text>
                </Flex>
                </>
            )}
            {groupClass.allowAudit && !groupClass.isAuditFree && groupClass.level0AuditFee !== groupClass.level1AuditFee && (
                <>
                <Flex
                justify="space-between"
                alignItems="center"
                mt='2'>
                <Text fontSize="sm" fontWeight="semibold" color='darkCerulean.500'>Basic Audit Fee</Text>
                <Text fontSize="sm" fontWeight="semibold" color='darkCerulean.500'>
                    ${groupClass.level0AuditFee}
                </Text>
                </Flex>
                <Flex
                justify="space-between"
                alignItems="center"
                mt='2'>
                <Text fontSize="sm" fontWeight="semibold" color='darkCerulean.500'>Gold and up Member Audit Fee</Text>
                <Flex justify="space-between" alignItems="center">
                <Text fontSize="sm" textDecoration="line-through" fontWeight="semibold" color='red'>
                    ${groupClass.level0AuditFee}
                </Text>
                <Text fontSize="sm" fontWeight="semibold" color='red'>
                    ${groupClass.level1AuditFee}
                </Text>
                </Flex>
                </Flex>
                </>
            )}
                  </Box>
                <Flex justify='space-between' alignItems='center' mt='2'>
                    <Badge colorScheme='darkCerulean'> {groupClass.numberOfApplicants} </Badge>
                    <Text fontSize='sm' fontWeight='semibold' color='darkCerulean.200'>
                        has applied this formal class.
                    </Text>
                </Flex>
                {groupClass.allowAudit && (
                <Flex justify='space-between' alignItems='center' mt='2'>
                    <Badge colorScheme='darkCerulean'> {groupClass.numberOfAuditApplicants} </Badge>
                    <Text fontSize='sm' fontWeight='semibold' color='darkCerulean.200'>
                        has applied this audit class.
                    </Text>
                </Flex>
                )}
                <FormattedDate orgDate={groupClass.registrationDeadLine} title='Formal Class Registration Deadline:' shift='0' />
                {groupClass.allowAudit && (
                <FormattedDate orgDate={groupClass.classStartDateTime} title='Audit Class Registration Deadline:' shift='3' />
                )}
                <FormattedDate orgDate={groupClass.classStartDateTime} title='Start Date:' shift='0' />
                <FormattedDate orgDate={groupClass.classEndDateTime} title='End Date:' shift='0' />
                <Text fontSize='sm' fontWeight='semibold' color='darkCerulean.200'>
                        Duration: {groupClass.duration}
                    </Text>
                <Text fontSize='sm' fontWeight='semibold' color='darkCerulean.200'>
                        Weight: {groupClass.weight}
                    </Text>
                    { !userInfo && (checkIsAuditGroupClassOpen() || checkIsGroupClassOpen()) && (
                      <Text as={ReactLink} to='/login' fontSize='sm' fontWeight='semibold' color='red' variant='link'>
                      Log in to apply!
                  </Text>
                    )}
                  </Stack>
                </Stack>
                <Divider />
                <Box
                whiteSpace='pre-wrap' 
              maxW={{ base: '3xl', lg: '5xl' }}
              mx='auto'
              px={{ base: '4', md: '8', lg: '12' }}
              py={{ base: '1', md: '1', lg: '1' }}>
                <Text fontWeight='bold'>About:</Text>
                {groupClass.description}
              </Box>
                    <Divider />
                    <Box
                whiteSpace='pre-wrap' 
              maxW={{ base: '3xl', lg: '5xl' }}
              mx='auto'
              px={{ base: '4', md: '8', lg: '12' }}
              py={{ base: '1', md: '1', lg: '1' }}>
                <Text fontWeight='bold'>Member composed:</Text>
                {groupClass.memberComposed}
              </Box>
                    <Divider />
                    <Box
                whiteSpace='pre-wrap' 
              maxW={{ base: '3xl', lg: '5xl' }}
              mx='auto'
              px={{ base: '4', md: '8', lg: '12' }}
              py={{ base: '1', md: '1', lg: '1' }}>
                <Text fontWeight='bold'>Requirement:</Text>
                {groupClass.requirement}
              </Box>
              <Divider />
                    <Box
                whiteSpace='pre-wrap' 
              maxW={{ base: '3xl', lg: '5xl' }}
              mx='auto'
              px={{ base: '4', md: '8', lg: '12' }}
              py={{ base: '1', md: '1', lg: '1' }}>
                <Text fontWeight='bold'>Materials:</Text>
                {groupClass.material}
              </Box>
                    <Divider />
                    <Box
                whiteSpace='pre-wrap' 
              maxW={{ base: '3xl', lg: '5xl' }}
              mx='auto'
              px={{ base: '4', md: '8', lg: '12' }}
              py={{ base: '1', md: '1', lg: '1' }}>
                <Text fontWeight='bold'>Course Content:</Text>
                {groupClass.courseContent}
              </Box>
                    <Divider />
                    <Box
                whiteSpace='pre-wrap' 
              maxW={{ base: '3xl', lg: '5xl' }}
              mx='auto'
              px={{ base: '4', md: '8', lg: '12' }}
              py={{ base: '1', md: '1', lg: '1' }}>
                <Text fontWeight='bold'>Syllabus:</Text>
                {groupClass.syllabus}
              </Box>
                    <Divider />
                    <Box
                whiteSpace='pre-wrap' 
              maxW={{ base: '3xl', lg: '5xl' }}
              mx='auto'
              px={{ base: '4', md: '8', lg: '12' }}
              py={{ base: '1', md: '1', lg: '1' }}>
                <Text fontWeight='bold'>Special Notice:</Text>
                {groupClass.specialNotice}
              </Box>
                    <Divider />
                {userInfo && hasUserApplied() && (
                  <>
                  <Tooltip label={hasUserReviewed() && 'You have already reviewed this Class.'} fontSize='medium'>
                    <Button
                      isDisabled={hasUserReviewed()}
                      my='20px'
                      w='140px'
                      colorScheme='cyan'
                      onClick={() => setReviewBoxOpen(!reviewBoxOpen)}>
                        Write a review
                      </Button>
                  </Tooltip>
                  {reviewBoxOpen && (
                    <Stack mb='20px'>
                      <Wrap>
                        <HStack spacing='2px'>
                          <Button variant='outline' onClick={() => setRating(1)}>
                            <Star />
                          </Button>
                          <Button variant='outline' onClick={() => setRating(2)}>
                            <Star rating={rating} star={2}/>
                          </Button>  
                          <Button variant='outline' onClick={() => setRating(3)}>
                            <Star rating={rating} star={3}/>
                          </Button>
                          <Button variant='outline' onClick={() => setRating(4)}>
                            <Star rating={rating} star={4}/>
                          </Button>
                          <Button variant='outline' onClick={() => setRating(5)}>
                            <Star rating={rating} star={5}/>
                          </Button>                                                                      
                        </HStack>
                      </Wrap>
                      <Input
                        onChange={(e) => {
                          setTitle(e.target.value);
                        }}
                        placeholder='Review title (optional)'
                      />
                      <Textarea
                        onChange={(e) => {
                          setComment(e.target.value);
                        }}
                        placeholder={`The ${groupClass.title} is...`}
                      />
                      <Button
                        isLoading={buttonLoading}
                        loadingText='Saving'
                        w='140px'
                        colorScheme='cyan'
                        onClick={() => onSubmit()}>
                          Write a review
                      </Button>
                    </Stack>
                  )}
                  </>
                )}
                <Divider/>

              {userInfo && groupClass && checkIsGroupClassOpen() && !hasUserApplied() && isUserQualifiedFormal() && groupClass.isNeedResume && groupClass.isFree && (
                <>
                  <Tooltip label={hasUserApplied() && 'You have already applied this group class.'} fontSize='medium'>
                    <Button
                      isDisabled={hasUserApplied()}
                      my='20px'
                      mt='2'
                      w='180px'
                      colorScheme='cyan'
                      onClick={() => setApplyBoxOpen(!applyBoxOpen)}>
                        Apply formal class
                      </Button>
                  </Tooltip>
                  {applyBoxOpen && (
                    <Box as="form" onSubmit={handleSubmit}>
                        <FormControl id="coverLetter" mt={4}>
                        <FormLabel>Upload your cover letter</FormLabel>
                        <Input type="file" onChange={handleCoverLetterChange} />
                        </FormControl>
                        <FormControl id="resumeFile" mt={4}>
                        <FormLabel>Upload your resume file</FormLabel>
                        <Input type="file" onChange={handleResumeFileChange} />
                        </FormControl>
                    <Button type="submit" colorScheme="blue" mt={4}>Send your application</Button>
                  </Box>
                  )}
                </>
              )}

              {userInfo && groupClass && !hasUserApplied() && isUserQualifiedFormal() && (!groupClass.isNeedResume || !groupClass.isFree) && (
                <>
                  <Button mt = '2' variant='outline' isDisabled={!checkIsGroupClassOpen() } colorScheme='cyan' onClick={() => addItem('FormalClass')}>
                    Add to cart 
                  </Button>
                </>
              )}
              {groupClass.applicantLevel > 1 && !isUserQualifiedFormal() && (
                 <Text fontSize='sm' fontWeight='semibold' color='red'>
                 Only Gold and up can apply formal class! You can apply Audit Class or go to Profile to upgrade
                </Text>
              )}
              {userInfo && groupClass && hasUserApplied() && (
                <Text fontSize='sm' fontWeight='semibold' color='red'>
                  You have applied this formal class.
               </Text>
              )}
              <Divider/>
              {userInfo && groupClass && !hasUserAppliedAudit() && isUserQualifiedAudit() && groupClass.allowAudit && (
                  <Button mt = '2' variant='outline' isDisabled={!checkIsAuditGroupClassOpen() } colorScheme='cyan' onClick={() => addItem('AuditClass')}>
                    Add to cart for Auditing
                  </Button>
                  )}
              {userInfo && groupClass && hasUserAppliedAudit() && (
                <Text fontSize='sm' fontWeight='semibold' color='red'>
                  You have applied this audit class.
               </Text>
              )}
              {isManager() && groupClass.numberOfApplicants > 0 && (
                  <>
                  <Divider mt='2' mb='2'/>
                  <Text>Information for manager: {groupClass.numberOfApplicants} has applied. </Text>
                  <Button onClick={downloadApplicants}>Download Applicants</Button>
                  <Text fontWeight='bold'>Grade and comment your applicants:</Text>
                  { groupClass.applicants.map((applicant) => (
                  <>
                    <Accordion key={applicant._id} allowToggle>
                    <AccordionItem>
                      <h2>
                        <AccordionButton>
                          <Box flex='1' textAlign='right'>
                            <Box>
                              <Text key={applicant._id} mr='8px' fontWeight='bold'>{applicant.name}</Text>
                            </Box>
                          </Box>
                        </AccordionButton>
                    </h2>
                    <AccordionPanel pb='4'>
                        <Table>
                            <Tbody>
                            <ApplicantTableItem key={applicant._id} applicant={applicant} />
                            </Tbody>
                        </Table>
                    </AccordionPanel>
                  </AccordionItem>
                </Accordion>
                </>
                ))} 
                </>
              )}

              {isAuditManager() && groupClass.numberOfAuditApplicants > 0 && (
                <>
                <Divider mt='2' mb='2'/>
                  <Text>Information for audit manager: {groupClass.numberOfAuditApplicants} has applied. </Text>
                  <Button onClick={downloadAuditApplicants}>Download Audit Applicants</Button>
                <Flex direction='row' justifyContent='space-between' alignItems='center'>
                    <Text>Name</Text>
                    <Text>Email</Text>
                    <Text>WechatId</Text>
                    <Text>Membership</Text>
                  </Flex>
                  {groupClass.auditApplicants.map((applicant) => (
                    <Flex key={applicant._id} direction='row' justifyContent='space-between' alignItems='center'>
                      <Text>{applicant.name}</Text>
                      <Text>{applicant.email}</Text>
                      <Text>{applicant.wechatId ? applicant.wechatId : ''}</Text>
                      <Text>{getMembership(applicant.level)}</Text>
                    </Flex>
                  ))}
                  </>
                )}
              </Box>
          )
        ) }
      </Wrap>
    );
  };
  
  export default GroupClassScreen;
