import { CloseButton, Flex, Image, Select, Spacer, Text, VStack, useColorModeValue as mode } from "@chakra-ui/react";
import { useDispatch } from "react-redux";
import { removeCartItem } from '../redux/actions/cartActions';

const CartItem = ({cartItem}) => {
  const { name, category, id, price, qty} = cartItem;
  const dispatch = useDispatch();

  return (
    <Flex minWidth='300px' borderWidth='1px' rounded='lg' align='center'>
      <VStack p='2' w='100%' spacing='4' align='stretch'>
        <Flex alignItems='center' justify='space-between'>
          <Text fontWeight='medium'>
            {name}
          </Text>
          <Spacer />
          <CloseButton onClick={() => dispatch(removeCartItem(id))} />
        </Flex>
        <Spacer />
        <Flex alignItems='center' justify='space-between'>
          <Text fontWeight='bold'>{category}</Text>
          <Text fontWeight='bold'>{qty} on ${price}</Text>
        </Flex>
      </VStack>
    </Flex>
  )
};

export default CartItem;