
import {
    Alert,
    AlertDescription,
    AlertIcon,
    AlertTitle,
    Badge,
    Box,
    Button,
    Flex,
    HStack,
    Heading,
    Image,
    SimpleGrid,
    Spinner,
    Stack,
    Text,
    Wrap,
    useToast,
    Textarea,
    Input,
    Tooltip,
    IconButton,
    Divider,
    Link,
    useColorModeValue as mode,
    Icon,
  } from '@chakra-ui/react';
  import { FaArrowRight } from 'react-icons/fa';
  import { BiChat } from 'react-icons/bi';
  /* import { BiCheckShield, BiPackage, BiSupport } from 'react-icons/bi'; */
  import { useDispatch, useSelector } from 'react-redux';
  import { useParams } from 'react-router-dom';
  import { Link as ReactLink } from 'react-router-dom';
  import { getProgram } from '../redux/actions/programActions';
  import { addCartItem } from '../redux/actions/cartActions';
  import { useEffect, useState } from 'react';
  import Star from '../components/Star';
  import { createProgramSubscriber } from '../redux/actions/programActions';
  import Papa from 'papaparse';
  import { saveAs } from 'file-saver';

  
  const ProgramScreen = () => {
      /* const [amount, setAmount] = useState(1);  */
    const today = new Date();
    const { id } = useParams();
    const dispatch = useDispatch();
    const { loading, error, program, subscribed, mostRecentGroupClass } = useSelector((state) => state.program);
    /* const { cartItems } = useSelector((state) => state.cart); */
    const toast = useToast();
    const [noteToManager, setNoteToManager] = useState('');
    /* const [ isGroupClassOpen, setIsGroupClassOpen ] = useState(false); */
    /* const [ numberOfApplicants, setNumberOfApplicants ] = useState(0); */
    const [ hasCertificate, setHasCertificate ] = useState(false);
    const [ certificateId, setCertificateId ] = useState('');
    const [subscribeBoxOpen, setSubscribeBoxOpen] = useState(false);
    const { userInfo } = useSelector((state) => state.user);
    const [buttonLoading, setButtonLoading] = useState(false);

    useEffect(() => {
      dispatch(getProgram(id));
      if(program) {
        if(program.certificateId && program.certificateId !== ''){
            setHasCertificate(true);
            setCertificateId(program.certificateId);
        }
        /* if(mostRecentGroupClass && new Date(mostRecentGroupClass.registrationDeadLine) > today) {
            console.log("here inside date compare");
            console.log(mostRecentGroupClass.registrationDeadLine);
            setIsGroupClassOpen(true);
            setNumberOfApplicants(mostRecentGroupClass.numberOfApplicants);
        } */
        console.log(program.title);
    }
      setSubscribeBoxOpen(false);
  
      if(subscribed) {
        toast({
          description: 'Subcription has been saved.',
          status: 'success',
          isClosable: 'true',
        });
        setSubscribeBoxOpen(false);
      }
    }, [dispatch, id, toast, subscribed]);

    const checkIsGroupClassOpen = () => {
        if(mostRecentGroupClass){
          if((new Date(mostRecentGroupClass.registrationDeadLine) - today >= 0 ) || (mostRecentGroupClass.allowAudit && ((today - new Date(mostRecentGroupClass.classStartDateTime)) <= (1000 * 60 * 60 * 24 * 3)))) {
            /* setIsGroupClassOpen(true); */
            return true;
          }
          else {
            return false;
          }
        }
        else {
            return false;
        }
    }

    const checkNumberOfApplicants = () => {
        if(checkIsGroupClassOpen()) {
            return mostRecentGroupClass.numberOfApplicants + mostRecentGroupClass.numberOfAuditApplicants;
        }
        else {
            return 0;
        }
    }

    /* if(mostRecentGroupClass && new Date(mostRecentGroupClass.registrationDeadLine) > today) {
        console.log("here inside date compare");
        console.log(mostRecentGroupClass.registrationDeadLine);
        setIsGroupClassOpen(true);
        setNumberOfApplicants(mostRecentGroupClass.numberOfApplicants);
    } */

    const addItem = () => {
      dispatch(addCartItem(id, 1));
  
      /* if(cartItems.some((cartItem) => cartItem.id === id)) {
        cartItems.find((cartItem) => cartItem.id === id);
        dispatch(addCartItem(id, amount));
      } else {
        dispatch(addCartItem(id, amount));
      } */
      toast({
        description: 'You added this mentor session to your shopping cart.',
        status: 'success',
        isClosable: true,
      });
    };
  
    const hasUserSubscribed = () => program.subscribers.some((item) => item.userId.toString() === userInfo._id.toString());
    const onSubmit = () => {
      setButtonLoading(true);
      dispatch(createProgramSubscriber(program._id, userInfo._id, (!noteToManager || noteToManager === '') ? 'No Note' : noteToManager));
    };

    const isManager = () => {
      if(userInfo ) {
        if(userInfo.isAdmin || (userInfo.managerRoles && userInfo.managerRoles.includes(1))) return true;
        if(program.managerUserId === userInfo._id) return true;
      }
      return false;
    }
  
    const convertToCSV = (data) => {
      const csv = Papa.unparse(data);
      return csv;
    };
  
    const downloadCSV = (csvData) => {
      const blob = new Blob([csvData], { type: 'text/csv;charset=utf-8;' });
      saveAs(blob, 'subscribers.csv');
    };
  
    const downloadSubscribers = () => {
      const csvData = convertToCSV(program.subscribers);
      downloadCSV(csvData);
    };
    
  
    return (
      <Wrap spacing='30px' justify='center' minHeight='100vh'>
        {loading ? (
          <Stack direction='row' spacing='4'>
            <Spinner mt='20' thickness='2px' speed='0.65s' emptyColor='gray.200' color='cyan.500' size='xl' />
          </Stack>
        ) : error ? (
          <Alert status='error'>
            <AlertIcon />
            <AlertTitle>We are sorry!</AlertTitle>
            <AlertDescription>{error}</AlertDescription>
          </Alert>
        ) : (
          program && program.isPublic && (
            <Box
              maxW={{ base: '3xl', lg: '5xl' }}
              mx='auto'
              px={{ base: '4', md: '8', lg: '12' }}
              py={{ base: '6', md: '8', lg: '12' }}>
                <Stack direction={{ base: 'column', lg: 'row' }} align='flex-start'>
                  <Stack pr={{ base:'0', md: 'row' }} flex='1.5' mb={{ base: '12', md: 'none' }}>
                  {!checkIsGroupClassOpen() ? (
                    <Badge colorScheme='blue'> Open to Subscribe!</Badge>
            ) : <Badge colorScheme='green'>Open to apply!</Badge>}
                  
                  <Heading fontSize='2xl' fontWeight='extrabold'>
                    {program.title}
                  </Heading>

                  <Stack spacing='5'>
                    <Text fontSize='xl' >{program.subtitle}</Text>
                    {!checkIsGroupClassOpen() ? (
                    <Flex justify='space-between' alignItems='center' mt='2'>
                    <Badge colorScheme='darkCerulean'> {program.numberOfSubscribers} </Badge>
                    <Text fontSize='sm' fontWeight='semibold' color='darkCerulean.200'>
                        has subscribed for next class.
                    </Text>
    
                </Flex>
            ) : (
                <Flex justify='space-between' alignItems='center' mt='2'>
                    <Badge colorScheme='darkCerulean'> {checkNumberOfApplicants()} </Badge>
                    <Text fontSize='sm' fontWeight='semibold' color='darkCerulean.200'>
                        has applied current class.
                    </Text>
    
                </Flex>
            )}
            <Flex justify='space-between' alignItems='center' mt='2'>
                    <Badge colorScheme='darkCerulean'> {program.totalTakers} </Badge>
                    <Text fontSize='sm' fontWeight='semibold' color='darkCerulean.200'>
                        has taken in the past.
                    </Text>
    
                </Flex>
                {program.isTentative && (
                    <Text fontSize='sm' fontWeight='semibold' color='darkCerulean.200'>
                    This program is started up by club user and tentative. But we will change it into formal when there are enough subscribers.
                </Text>
                )}
                <Text fontSize='sm' fontWeight='semibold' color='darkCerulean.200'>
                        Duration: {program.duration}
                    </Text>
                <Text fontSize='sm' fontWeight='semibold' color='darkCerulean.200'>
                        Weight: {program.weight}
                    </Text>
                  </Stack>
                </Stack>
                <Flex direction='column' align='center' flex='1' _dark={{ bg: 'gray.900' }}>
                  <Image
                    mb='30px'
                    src={program.image}
                    alt={program.title}
                    fallbackSrc='https://via.placeholder.com/250'
                  />
                </Flex>
                </Stack>
                <Divider />
                <Box
                whiteSpace='pre-wrap' 
              maxW={{ base: '3xl', lg: '5xl' }}
              mx='auto'
              px={{ base: '4', md: '8', lg: '12' }}
              py={{ base: '1', md: '1', lg: '1' }}>
                <Text fontWeight='bold'>About:</Text>
                {program.description}
              </Box>
                    <Divider />
                    
                    <Box
                whiteSpace='pre-wrap' 
              maxW={{ base: '3xl', lg: '5xl' }}
              mx='auto'
              px={{ base: '4', md: '8', lg: '12' }}
              py={{ base: '1', md: '1', lg: '1' }}>
                <Text fontWeight='bold'>Member composed:</Text>
                {program.memberComposed}
              </Box>
                    <Divider />
                    <Box
                whiteSpace='pre-wrap' 
              maxW={{ base: '3xl', lg: '5xl' }}
              mx='auto'
              px={{ base: '4', md: '8', lg: '12' }}
              py={{ base: '1', md: '1', lg: '1' }}>
                <Text fontWeight='bold'>Requirement:</Text>
                {program.requirement}
              </Box>
                    <Divider />
                    <Box
                whiteSpace='pre-wrap' 
              maxW={{ base: '3xl', lg: '5xl' }}
              mx='auto'
              px={{ base: '4', md: '8', lg: '12' }}
              py={{ base: '1', md: '1', lg: '1' }}>
                <Text fontWeight='bold'>Materials:</Text>
                {program.material}
              </Box>
                    <Divider />
                    <Box
                whiteSpace='pre-wrap' 
              maxW={{ base: '3xl', lg: '5xl' }}
              mx='auto'
              px={{ base: '4', md: '8', lg: '12' }}
              py={{ base: '1', md: '1', lg: '1' }}>
                <Text fontWeight='bold'>Course Content:</Text>
                {program.courseContent}
              </Box>
                    <Divider />
                    <Box
                whiteSpace='pre-wrap' 
              maxW={{ base: '3xl', lg: '5xl' }}
              mx='auto'
              px={{ base: '4', md: '8', lg: '12' }}
              py={{ base: '1', md: '1', lg: '1' }}>
                <Text fontWeight='bold'>Syllabus:</Text>
                {program.syllabus}
              </Box>
                    <Divider />
                    <Box
                whiteSpace='pre-wrap' 
              maxW={{ base: '3xl', lg: '5xl' }}
              mx='auto'
              px={{ base: '4', md: '8', lg: '12' }}
              py={{ base: '1', md: '1', lg: '1' }}>
                <Text fontWeight='bold'>Special Notice:</Text>
                {program.specialNotice}
              </Box>
                    <Divider />
                {userInfo && (
                  <>
                    <Tooltip label={hasUserSubscribed() && 'You have already subscribed this program.'} fontSize='medium'>
                      <Button
                        isDisabled={hasUserSubscribed() || checkIsGroupClassOpen()}
                        my='20px'
                        w='140px'
                        colorScheme='darkCerulean'
                        onClick={() => setSubscribeBoxOpen(!subscribeBoxOpen)}>
                          Subscribe now
                        </Button>
                    </Tooltip>
                    {subscribeBoxOpen && (
                      <Stack mb='20px'>
                        <Textarea
                          onChange={(e) => {
                            setNoteToManager(e.target.value);
                          }}
                          placeholder={'Your note to manager, why you want to take this program..'}
                        />
                        <Button
                          isLoading={buttonLoading}
                          loadingText='Saving'
                          w='140px'
                          colorScheme='darkCerulean'
                          onClick={() => onSubmit()}>
                            Subscribe
                        </Button>
                      </Stack>
                    )}
                  </>
                )}
                { checkIsGroupClassOpen() && (<>
                <Divider />
                    <Box mt='2' mb='2' ml='4'>
            <Link fontWeight='bold' as={ReactLink} to={`/groupclass/${mostRecentGroupClass._id}`} color={mode('darkCerulean.900', 'yellow.200')}>
            Class is open now! Click to apply
            </Link>
            <Icon ml='2' color={mode('darkCerulean.900', 'yellow.200')} as={FaArrowRight} />
        </Box>
                    </>
                )}
                { mostRecentGroupClass && !checkIsGroupClassOpen() && (<>
                <Divider />
                    <Box mt='2' mb='2' ml='4'>
            <Link fontWeight='bold' as={ReactLink} to={`/groupclass/${mostRecentGroupClass._id}`} color={mode('darkCerulean.900', 'yellow.200')}>
            Have a peak on previous class
            </Link>
            <Icon ml='2' color={mode('darkCerulean.900', 'yellow.200')} as={FaArrowRight} />
        </Box>
                    </>
                )}
                {isManager() && program.numberOfSubscribers > 0 && (
                  <>
                  <Divider mt='2' mb='2'/>
                  <Text>Information for manager: {program.numberOfSubscribers} have subscribed. </Text>
                  <Button onClick={downloadSubscribers}>Download Subscribers</Button>

                  <Flex direction='row' justifyContent='space-between' alignItems='center'>
                    <Text>Name</Text>
                    <Text>Email</Text>
                  </Flex>
                  {program.subscribers.map((subscriber) => (
                    <Flex direction='row' justifyContent='space-between' alignItems='center'>
                      <Text>{subscriber.name}</Text>
                      <Text>{subscriber.email}</Text>
                    </Flex>
                  ))}
                  </>
                )}
              </Box>
          )
        ) }
      </Wrap>
    );
  };
  
  export default ProgramScreen;
