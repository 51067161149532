import { 
    setJobs, 
    setLoading, 
    setError, 
    setJob,
    jobApplied,
    resetError
} from '../slices/job';
import axios from 'axios';

export const getJobs = () => async (dispatch) => {
    dispatch(setLoading());
    try {
        const { data } = await axios.get(`/api/jobs`);
        dispatch(setJobs(data));
    } catch (error) {
        dispatch(
            setError(
                error.response && error.response.data.message 
                    ? error.response.data.message 
                    : error.message 
                    ? error.message : 'Unexpected error has occured. Please try again later.'
            )
        );
    }
};

export const getJob = (id) => async (dispatch) => {
    dispatch(setLoading(true));
    try {
        const { data } = await axios.get(`/api/jobs/${id}`);
        dispatch(setJob(data));
    } catch (error) {
        dispatch(
            setError(
                error.response && error.response.data.message 
                    ? error.response.data.message 
                    : error.message 
                    ? error.message : 'Unexpected error has occured. Please try again later.'
            )
        );
    }

};

export const createApplicant = (data) => async (dispatch, getState) => {
    const {
        user: {userInfo },
    } = getState();
    try {
        console.log(data.get('jobId'));
        const config = { headers: { Authorization: `Bearer ${userInfo.token}`, 'Content-Type': 'multipart/form-data' }};
        await axios.post(`/api/jobs/applicants/${data.get('jobId')}`, data, config);
        dispatch(jobApplied(true));
    } catch (error) {
        dispatch(
            setError(
                error.response && error.response.data.message 
                    ? error.response.data.message 
                    : error.message 
                    ? error.message : 'Unexpected error has occured. Please try again later.'
            )
        );
    }
}

export const resetJobError = () => async (dispatch) => {
    dispatch(resetError());
};


