import React from 'react';
import {
    IconButton,
    Box,
    Flex,
    HStack,
    Icon,
    Stack,
    Text,
    useColorModeValue as mode,
    useDisclosure,
    AlertDescription,
    Alert,
    AlertIcon,
    AlertTitle,
    Divider,
    Image,
    Menu,
    MenuButton,
    MenuDivider,
    MenuItem,
    MenuList,
    Spacer,
    useToast
} from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { BsMortarboard } from 'react-icons/bs';
import { Link as ReactLink } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import NavLink from './NavLink';
import ColorModeToggle from './ColorModeToggle';
import { BiUserCheck, BiLogInCircle } from 'react-icons/bi';
import { HamburgerIcon, CloseIcon, ChevronDownIcon } from '@chakra-ui/icons';
import { TbShoppingCart } from 'react-icons/tb';
import { logout } from '../redux/actions/userActions';
import { MdOutlineAdminPanelSettings } from 'react-icons/md';
import { FaChalkboardTeacher } from 'react-icons/fa';
import { FcGoogle } from 'react-icons/fc';
import { googleLogout } from '@react-oauth/google';


const Links = [
    { name: 'Programs', route: '/programs' },
    { name: 'Events', route: '/events' },
    { name: 'Jobs', route: '/jobs' },
    { name: 'Services', route: '/services' },
    { name: 'Chat', route: '/chat/1' },
];

const Header = () => {

    const componentColor = mode('black', 'yellow.200');
    const bgColor = mode('darkCerulean.100','darkCerulean.500');
    const { isOpen, onOpen, onClose } = useDisclosure();

    const dispatch = useDispatch();
    const toast = useToast();
    const { cartItems } = useSelector((state) => state.cart);
    const { userInfo } = useSelector((state) => state.user);
    const [ showBanner, setShowBanner ] = useState(userInfo ? !userInfo.active : false);

    useEffect(() => {
        if(userInfo && !userInfo.active) {
            setShowBanner(true);
        }
    }, [dispatch, userInfo]);

    const logoutHandler = () => {
        googleLogout();
        dispatch(logout());
        toast({
            description: 'You have been logged out.',
            status: 'success',
            isClosable: 'true'
        });
    };

    return (
        <Box bg={bgColor} px='4'>
            <Flex h='16' alignItems='center' justifyContent='space-between'>
                <Flex display={{base: 'flex', md: 'none'}} alignItems='center'>
                    <IconButton 
                        bg='parent' 
                        size='md' 
                        icon={isOpen ? <CloseIcon /> : <HamburgerIcon />} 
                        onClick={isOpen ? onClose : onOpen}
                    />
                    <IconButton
                        ml='12'
                        position='absolute'
                        icon={<TbShoppingCart size='20px'/>}
                        as={ReactLink}
                        to='/cart'
                        variant='ghost'
                    />
                    {cartItems.length > 0 && (
                        <Text fontWeight='bold' fontStyle='italic' position='absolute' ml='74px' mt='-6' fontSize='sm'>
                            {cartItems.length}
                        </Text>
                    )}
                </Flex>
                <HStack spacing='8' alignItems='center'>
                    <Box alignItems='center' display='flex' as={ReactLink} to='/'>
                        <Box>
                        <Icon as={BsMortarboard} h='6' w='6' color={componentColor} display={{ base: 'none', md: 'block' }}/>
                        </Box>
                        <Text as='b' color={componentColor} ml='2'>Qishi Career Path Club</Text>
                    </Box>

                    <HStack as='nav' spacing='4' display={{ base: 'none', md: 'flex'}}>
                        {Links.map((link) => (
                            <NavLink route={link.route} key={link.route}>
                                <Text fontWeight='medium' color={componentColor}>{link.name}</Text>
                            </NavLink>
                        ))}
                        <Box>
                            <IconButton
                                icon={<TbShoppingCart size='20px'/>}
                                as={ReactLink}
                                to='/cart'
                                variant='ghost'
                            />
                            {cartItems.length > 0 && (
                                <Text fontWeight='bold' fontStyle='italic' position='absolute' ml='26px' mt='-6' fontSize='sm'>
                                    {cartItems.length}
                                </Text>
                            )}
                        </Box>
                        <ColorModeToggle />
                    </HStack>
                </HStack>
                <Flex alignItems='center'>
                    {userInfo ? (
                        <Menu>
                            <MenuButton rounded='full' variant='link' cursor='pointer' minW='0'>
                                <HStack>
                                    {userInfo.googleImage ? (
                                        <Image
                                            borderRadius='full'
                                            boxSize='40px'
                                            src={userInfo.googleImage}
                                            referrerPolicy='no-referrer'
                                        />
                                    ) :(
                                        <BiUserCheck size='30' />
                                    )}
                                    <ChevronDownIcon />
                                </HStack>
                            </MenuButton>
                            <MenuList>
                                <HStack>
                                    <Text pl='3' as='i'>
                                        {userInfo.email}
                                    </Text>
                                    {userInfo.googleId && <FcGoogle />}
                                </HStack>
                                <Divider py='1' />
                                <MenuItem as={ReactLink} to='record-history'>
                                    Order History
                                </MenuItem>
                                <MenuItem as={ReactLink} to='accounting'>
                                    Accounting
                                </MenuItem>
                                <MenuItem as={ReactLink} to='/profile'>
                                    Profile
                                </MenuItem>
                                {userInfo.isAdmin && (
                                    <>
                                        <MenuDivider />
                                        <MenuItem as={ReactLink} to='/admin-console'>
                                            <MdOutlineAdminPanelSettings />
                                            <Text ml='2'>Admin Console</Text>
                                        </MenuItem>
                                    </>
                                )}
                                {userInfo.isMentor && (
                                    <>
                                        <MenuDivider />
                                        <MenuItem as={ReactLink} to='/mentor-console'>
                                            <FaChalkboardTeacher />
                                            <Text ml='2'>Mentor Console</Text>
                                        </MenuItem>
                                    </>
                                )}
                                {userInfo.managerRoles && userInfo.managerRoles.includes(1) && (
                                    <>
                                        <MenuDivider />
                                        <MenuItem as={ReactLink} to='/programadmin-console'>
                                            <FaChalkboardTeacher />
                                            <Text ml='2'>Program Admin Console</Text>
                                        </MenuItem>
                                    </>
                                )}
                                {userInfo.managerRoles && userInfo.managerRoles.includes(2) && (
                                    <>
                                        <MenuDivider />
                                        <MenuItem as={ReactLink} to='/eventadmin-console'>
                                            <FaChalkboardTeacher />
                                            <Text ml='2'>Event Admin Console</Text>
                                        </MenuItem>
                                    </>
                                )}
                                {userInfo.managerRoles && userInfo.managerRoles.includes(3) && (
                                    <>
                                        <MenuDivider />
                                        <MenuItem as={ReactLink} to='/jobadmin-console'>
                                            <FaChalkboardTeacher />
                                            <Text ml='2'>Job Admin Console</Text>
                                        </MenuItem>
                                    </>
                                )}
                                <MenuDivider />
                                <MenuItem onClick={logoutHandler}>Logout</MenuItem>
                            </MenuList>
                        </Menu>
                    ) : (
                        <Menu>
                            <MenuButton as={IconButton} variant='ghost' cursor='pointer' icon={<BiLogInCircle size='25px'/>} />
                            <MenuList>
                                <MenuItem as={ReactLink} to='/login' p='2' fontWeight='400' variant='link'>
                                    Sign in
                                </MenuItem>
                                <MenuDivider />
                                <MenuItem as={ReactLink} to='/registration' p='2' fontWeight='400' variant='link'>
                                    Sign up
                                </MenuItem>
                            </MenuList>
                        </Menu>
                    )}
                </Flex>
            </Flex>
            <Box display='flex'>
                {isOpen && (
                    <Box pb='4' display={{ md: 'none' }}>
                        <Stack as='nav' spacing='4'>
                            {Links.map((link) => (
                                <NavLink route={link.route} key={link.route}>
                                    <Text fontWeight='medium' color={componentColor} >{link.name}</Text>
                                </NavLink>
                            ))}
                        </Stack>
                        <ColorModeToggle />
                    </Box>
                )}
            </Box>
            {userInfo && !userInfo.active && showBanner && (
                <Box>
                    <Alert status='warning'>
                        <AlertIcon />
                        <AlertTitle>Email not verified!</AlertTitle>
                        <AlertDescription>You must verify your email address.</AlertDescription>
                        <Spacer />
                        <CloseIcon cursor={'pointer'} onClick={() => setShowBanner(false)} />
                    </Alert>
                </Box>
            )}
        </Box>
    );
};

export default Header;