import {
  Tr,
  Td,
  Button,
  VStack,
  Textarea,
  Tooltip,
  Input,
  FormControl,
  Text,
  FormLabel,
  Switch,
  Badge,
  Spacer,
  Flex,
} from '@chakra-ui/react';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { uploadProgram } from '../redux/actions/adminActions';

const AddNewProgram = () => {
  const dispatch = useDispatch();
  const { userInfo } = useSelector((state) => state.user);

  const [title, setTitle ] = useState('');
  const [subtitle, setSubtitle ] = useState('');
  const [description, setDescription ] = useState('');
  const [specialNotice, setSpecialNotice ] = useState('');
  const [memberComposed, setMemberComposed ] = useState('');
  const [requirement, setRequirement ] = useState('');
  const [courseContent, setCourseContent ] = useState('');
  const [syllabus, setSyllabus ] = useState('');
  const [duration, setDuration ] = useState('');
  const [material, setMaterial ] = useState('');

  const createNewProgram = () => {
      dispatch(
          uploadProgram({
              userId: userInfo._id,
              title, 
              subtitle, 
              description, 
              specialNotice, 
              memberComposed, 
              requirement, 
              courseContent, 
              syllabus, 
              duration, 
              material
          })
      );
  };

return (
  <>
  <Tr>
        <Td> Description: </Td>
        <Td>
            <Textarea
                w='80vh' 
                h='120px'
                value={description}
                onChange={(e) => setDescription(e.target.value)}
                placeholder='Program Description'
                size='m'
            />
        </Td>
    </Tr><Tr>
        <Td> Requirement: </Td>
        <Td>
            <Textarea
                w='80vh' 
                h='120px'
                value={requirement}
                onChange={(e) => setRequirement(e.target.value)}
                placeholder='Ideal Applicant Academia Requirement'
                size='m'
            />
        </Td>
    </Tr><Tr>
        <Td> Special Notice: </Td>
        <Td>
            <Textarea
                w='80vh' 
                h='120px'
                value={specialNotice}
                onChange={(e) => setSpecialNotice(e.target.value)}
                placeholder='Special Notice'
                size='m'
            />
        </Td>

    </Tr><Tr>
        <Td> Member Composed: </Td>
        <Td>
            <Textarea
                w='80vh' 
                h='120px'
                value={memberComposed}
                onChange={(e) => setMemberComposed(e.target.value)}
                placeholder='One teaching assistant, eight members including one lead.. '
                size='m'
            />
        </Td>
    </Tr><Tr>
        <Td> Course Content: </Td>
        <Td>
            <Textarea
                w='80vh' 
                h='120px'
                value={courseContent}
                onChange={(e) => setCourseContent(e.target.value)}
                placeholder='Course Content'
                size='m'
            />
        </Td>
    </Tr><Tr>
        <Td> Syllabus: </Td>
        <Td>
            <Textarea
                w='80vh' 
                h='120px'
                value={syllabus}
                onChange={(e) => setSyllabus(e.target.value)}
                placeholder='8 classes in total. class 1: ... Class 2: ...'
                size='m'
            />
        </Td>
    </Tr><Tr>
        <Td> Material: </Td>
        <Td>
            <Textarea
                w='80vh' 
                h='120px'
                value={material}
                onChange={(e) => setMaterial(e.target.value)}
                placeholder='Book .. or material only disclosed to members ...'
                size='m'
            />
        </Td>

    </Tr><Tr>
        <Td>Title & Subtitle</Td>
        <Td>
            <Flex direction='column' gap='2'>
                <Input size='sm' value={title} onChange={(e) => setTitle(e.target.value)} />
                <Input size='sm' value={subtitle} onChange={(e) => setSubtitle(e.target.value)} />
            </Flex>
        </Td>
    </Tr><Tr>
        <Td>Duration</Td>
        <Td>
           
        <Input size='sm' value={duration} onChange={(e) => setDuration(e.target.value)} />
            
        </Td>
  </Tr>
  <Tr>
      <Td>
          <VStack>
              <Button variant='outline' w='160px' colorScheme='cyan' onClick={createNewProgram} >
                  <Text ml='2'>Save Program</Text>
              </Button>
          </VStack>
      </Td>
  </Tr>
  </>
);
};

export default AddNewProgram;
