import { createSlice } from '@reduxjs/toolkit';


const prepareQuerySet = (queryType) => {
    let query = '';
    const share_code = 'lgJeZwOp';
    const uid = 'bb53498796cb983e34806b0eb131003a';
    if(queryType === 1) {
        // pricing
        query = 'can I know the pricing of qishi club membership?'
    }
    return {
        'query': query,
        'share_code': share_code,
        'uid': uid,
    }
};

export const initialState = {
    loading: false,
    error: null,
    hasQuery: false,
    query: '',
    share_code: '',
    uid: '',
};


export const chatSlice = createSlice({
    name: 'chat',
    initialState,
    reducers: {
        setLoading: (state) => {
            state.loading = true;
        },
        setError: (state, { payload }) => {
            state.error = payload;
            state.loading = false;
        },
        clearChat: (state) => {
            state.loading = false;
            state.error = null;
            state.hasQuery = false;
            state.query = '';
            state.share_code = '';
            state.uid = '';
        },
        setQuerySet: (state, { payload }) => {
            const querySet = prepareQuerySet(payload);
            state.query = querySet['query'];
            state.share_code = querySet['share_code'];
            state.uid = querySet['uid'];
            state.hasQuery = true;
            state.loading = false;
            state.error = null;
        }
    },

});

export const {setError, setLoading, clearChat, setQuerySet } = chatSlice.actions;

export default chatSlice.reducer;
export const chatSelector = (state) => state.chat;
