import { createSlice } from '@reduxjs/toolkit';

export const initialState = {
    loading: false,
    error: null,
    userInfo: JSON.parse(localStorage.getItem('userInfo')) ?? null,
    serverMsg: null,
    serverStatus: null,
    referId: JSON.parse(localStorage.getItem('referId')) ?? '',
    groupClassTakenSetList: [],
    jobTakenSetList: [],
};

export const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        setLoading: (state) => {
            state.loading = true;
        },
        userLogin: (state, {payload}) => {
            state.userInfo = payload;
            state.referId = '';
            state.error = null;
            state.loading = false;
        },
        userLogout: (state) => {
            state.userInfo = null;
            state.referId = '';
            state.error = null;
            state.loading = false;
            state.groupClassTakenSetList = [];
            state.jobTakenSetList = [];
        },
        setError: (state, { payload }) => {
            state.loading = false;
            state.error = payload;
        },
        verificationEmail: (state) => {
            state.userInfo && (state.userInfo.active = true);
            state.error = null;
            state.loading = false;
        },
        setServerResponseMsg:(state, {payload}) => {
            state.serverMsg = payload;
            state.loading=false;
        },
        setServerResponseStatus: (state, {payload}) => {
            state.serverStatus = payload;
            state.loading = false;
        },
        stateReset: (state) => {
            state.loading = false;
            state.serverMsg = null;
            state.error = null;
            state.referId = '';
        },
        setUserRecords: (state, {payload}) => {
            state.records = payload;
            state.error = null;
            state.loading = false;
        },
        setUserMentor: (state, {payload}) => {
            state.usermentor = payload;
            state.error = null;
            state.loading = false;
        },
        userUpdateSelf: (state, {payload}) => {
            state.userInfo = payload;
            state.error = null;
            state.loading = false;
            state.referId = '';
        },
        setReferId: (state, {payload}) => {
            state.referId = payload;
            state.error = null;
            state.loading = false;
        },
        setUserGroupClassTakenSetList: (state, {payload}) => {
            state.groupClassTakenSetList = payload;
            state.error = null;
            state.loading = false;
        },
        setUserJobTakenSetList: (state, {payload}) => {
            state.jobTakenSetList = payload;
            state.error = null;
            state.loading = false;
        }
    },
});

export const {
    setLoading,
    setError, 
    setServerResponseMsg, 
    setServerResponseStatus, 
    userLogin, 
    userLogout, 
    stateReset, 
    verificationEmail,
    setUserMentor,
    userUpdateSelf,
    setUserRecords,
    setReferId,
    setUserGroupClassTakenSetList,
    setUserJobTakenSetList,
} = userSlice.actions;

export default userSlice.reducer;

export const userSelector = (state) => state.user;

