import { createSlice } from '@reduxjs/toolkit';

export const initialState = {
    loading: false,
    error: null,
    groupClasses: [],
    groupClass: null,
    reviewed: false,
    reviewRemoval: false,
    groupClassUpdate: false,
    applied: false,
    applicantUpdate: false,
    auditApplied: false,
    auditApplicantUpdate: false,
};

export const groupClassesSlice = createSlice({
    name: 'groupClasses',
    initialState,
    reducers: {
        setLoading: (state) => {
            state.loading = true;
        },
        setGroupClasses: (state, { payload }) => {
            state.loading = false;
            state.error = null;
            state.groupClasses = payload;
            state.reviewRemoval = false;
        },
        setGroupClass: (state, { payload }) => {
            state.loading = false;
            state.error = null;
            state.groupClass = payload;
            state.reviewed = false;
            state.applied = false;
            state.applicantUpdate = false;
            state.auditApplied = false;
            state.auditApplicantUpdate = false;
        },
        setError: (state, { payload }) => {
            state.loading = false;
            state.error = payload;
        },
        groupClassReviewed: (state, {payload}) => {
            state.loading = false;
            state.error = null;
            state.reviewed = payload;
        },
        groupClassApplied: (state, {payload}) => {
            state.loading = false;
            state.error = null;
            state.applied = payload;
        },
        groupClassAuditApplied: (state, {payload}) => {
            state.loading = false;
            state.error = null;
            state.auditApplied = payload;
        },
        resetError: (state) => {
            state.error = null;
            state.reviewed = false;
            state.applied = false;
            state.groupClassUpdate = false;
            state.reviewRemoval = false;
        },
        setGroupClassUpdateFlag: (state) => {
            state.groupClassUpdate = true;
            state.loading = false;
        },
        setReviewRemovalFlag: (state) => {
            state.error = null;
            state.reviewRemoval = true;
            state.loading = false;
        },
        setApplicantUpdateFlag: (state) => {
            state.error = null;
            state.applicantUpdate = true;
            state.loading = false;
        },
        setAuditApplicantUpdateFlag: (state) => {
            state.error = null;
            state.auditApplicantUpdate = true;
            state.loading = false;
        },
    },
});

export const {
    setLoading, 
    setError, 
    setGroupClasses, 
    setGroupClass,
    groupClassReviewed,
    groupClassApplied,
    groupClassAuditApplied,
    setGroupClassUpdateFlag,
    resetError,
    setReviewRemovalFlag,
    setApplicantUpdateFlag,
    setAuditApplicantUpdateFlag,
} = groupClassesSlice.actions;

export default groupClassesSlice.reducer;

export const groupClassSelector = (state) => state.groupClasses;

